import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Card, CardBody, Col, Row } from "reactstrap";
import Breadcrumb from "../../../base/components/Common/Breadcrumb2";
import { BIOMARKERS_GROUP_LINKS } from "./config";
import { useLoading } from "../../../base/hooks/useLoading";
import { useNavigate, useParams } from "react-router-dom";
import { useService } from "../../../base/hooks/useService";
import BiomarkersService from "../../../services/BiomarkersService";
import PageSpinner from "../../../base/components/PageSpinner";
import ValueWithLabel from "./Details/ValueWIthLabel";
import { BUTTON_COLORS } from "../../../base/components/Button/appearance";
import Button from "../../../base/components/Button";
import Title from "./Details/Title";
import FiltersView from "./Details/Filters";
import RecommendationsView from "./Details/Recommendations";
import RichTextView from "./Details/RIchTextView";
import {
  arrayOfRichTextSections,
  arrayOfSkinSections, DNAm_CATEGORY_NAME, HautAiMetricTypesArray,
  SKIN_CATEGORY_NAME
} from "./CreateEdit/constants";
import InteractionsView from "./Details/InteractionsView";
import { replaceUrlParams } from "../../../base/helpers/url";
import RichTextWithTabs from "./Details/RichTextWithTabsView";
import {
  CAUSES_DNAm_TABS,
  CAUSES_SKIN_TABS, DNAm_RESULT_SUMMARY_SECTIONS,
  REC_RANGES_TO_APPLY_BY_ID,
  RECOMMENDATION_SECTIONS, SKIN_RESULT_SUMMARY_SECTIONS,
  WHAT_ARE_THE_CAUSES_TABS, WHAT_ARE_THE_RISKS_TABS
} from "./CreateEdit/Recommendations/constants";
import { transformGroupsModel } from "./CreateEdit/helpers/transformModels";
import { usePowerUnitFormat } from '../../../base/hooks/usePowerUnitFormat';

const breadcrumbs = {
  title: "Biomarker detailed view",
  breadcrumbItems: [
    {title: "Biomarkers", link: BIOMARKERS_GROUP_LINKS.BASE},
    {title: "Biomarker detailed view"}
  ]
}

export default function Details() {
  /**
   * @type {BiomarkersService}
   */
  const biomarkersService = useService(BiomarkersService);

  const [biomarker, updateBiomarker] = useState({});
  const [selectedFilterIndex, updateSelectedFilterIndex] = useState(0);
  const [isLoading, {registerPromise}] = useLoading(true);
  const {id} = useParams();
  const {powerFormat} = usePowerUnitFormat()

  const isSkinBiomarker = useMemo(() => biomarker?.category?.name === SKIN_CATEGORY_NAME, [biomarker?.category, id]);
  const isDNAmBiomarker = useMemo(() => biomarker?.category?.name === DNAm_CATEGORY_NAME, [biomarker?.category, id]);

  const navigate = useNavigate();

  const getBiomarker = useCallback(() => {
    registerPromise(biomarkersService.getBiomarkerById(id).then(({filters, ...rest}) => {
      updateBiomarker({
        ...rest,
        filters: filters.map(({groups, ...other}) => ({
          ...other,
          groups: transformGroupsModel(groups)
        }))
      })
    }));
  }, [id])

  const resultsSummaryTabs = () => {
    if (isSkinBiomarker) return SKIN_RESULT_SUMMARY_SECTIONS;
    if (isDNAmBiomarker) return DNAm_RESULT_SUMMARY_SECTIONS;
    return RECOMMENDATION_SECTIONS;
  }

  const whatAreTheCausesTabs = () => {
    if(isSkinBiomarker) return CAUSES_SKIN_TABS;
    if(isDNAmBiomarker) return CAUSES_DNAm_TABS;
    return WHAT_ARE_THE_CAUSES_TABS;
  }


  useEffect(() => {
    getBiomarker();
  }, [getBiomarker]);

  return (
    <>
      <Breadcrumb {...breadcrumbs} />
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              {isLoading ?
                <PageSpinner />
                :
                <>
                  <section className="d-flex align-items-center justify-content-between mb-4">
                    <label className="font-weight-semibold font-size-15">General information</label>
                    <Button
                      color={BUTTON_COLORS.primary}
                      onClick={() => navigate(replaceUrlParams(BIOMARKERS_GROUP_LINKS.EDIT, {id}))}
                      className="d-flex align-items-center"
                    >
                      <i className="bx bx-edit-alt me-2" />
                      Edit biomarker
                    </Button>
                  </section>

                  <ValueWithLabel
                    value={biomarker.name}
                    label="Full name"
                  />

                  <ValueWithLabel
                    value={biomarker.label}
                    label="Label name"
                    className="mt-3"
                  />

                  <ValueWithLabel
                    value={biomarker.shortName}
                    label="Short name"
                    className="mt-3"
                  />

                  <section className="alternative-names-list">
                    {biomarker.alternativeNames?.map(({name}, index) => (
                      <ValueWithLabel
                        key={index}
                        value={name}
                        label={`Alternative name ${index + 1}`}
                        className="me-5 mt-3"
                      />
                    ))}
                  </section>

                  <ValueWithLabel
                    label="Category"
                    value={biomarker?.category?.name || ''}
                    className="mt-3"
                  />

                  {!isDNAmBiomarker && <>
                    {isSkinBiomarker ?
                      <ValueWithLabel
                        label="Corresponding Haut.ai metric"
                        value={HautAiMetricTypesArray.find(({value}) => biomarker.hautAiMetricType === value)?.label}
                        className="mt-3"
                      />
                      :
                      <ValueWithLabel
                        label="Unit"
                        value={powerFormat( biomarker.unit?.unit )}
                        className="mt-3"
                      />
                    }
                  </>
                  }

                  <Title
                    title="Values and Recommendations"
                    className="mt-5 mb-4"
                  />

                  {biomarker.ruleName &&
                    <ValueWithLabel
                      label="Rule"
                      value={biomarker.ruleName}
                    />
                  }

                    <FiltersView
                      filters={biomarker.filters}
                      unit={biomarker.unit?.unit}
                      updateSelectedFilterIndex={updateSelectedFilterIndex}
                      selectedFilterIndex={selectedFilterIndex}
                      isSkinBiomarker={isSkinBiomarker}
                      isDNAmBiomarker={isDNAmBiomarker}
                    />

                  {!!biomarker.filters[selectedFilterIndex]?.filterRecommendations?.length &&
                    <RecommendationsView
                      filterRecommendations={biomarker.filters[selectedFilterIndex]?.filterRecommendations}
                      filter={biomarker.filters[selectedFilterIndex]}
                      isSkinBiomarker={isSkinBiomarker}
                      isDNAmBiomarker={isDNAmBiomarker}
                    />
                  }

                  <Title
                    title="About biomarker"
                    className="mt-5 mb-4"
                  />

                  <RichTextWithTabs
                    ranges={biomarker.filters[selectedFilterIndex]}
                    valuesObject={biomarker.filters[selectedFilterIndex].resultSummary}
                    groups={biomarker.filters[selectedFilterIndex].groups}
                    allTabsArray={resultsSummaryTabs()}
                    allTabsMap={(isSkinBiomarker || isDNAmBiomarker) ? {} : REC_RANGES_TO_APPLY_BY_ID}
                    className=""
                    label="Result Summary"
                  />


                    {((isSkinBiomarker || isDNAmBiomarker) ? arrayOfSkinSections : arrayOfRichTextSections).map(({
                                                                                              itemKey,
                                                                                              label
                                                                                            }, index) => {
                      if (!biomarker.filters[selectedFilterIndex][itemKey]) return null;
                      return (
                        <RichTextView
                          containerClassName="mt-4"
                          key={index}
                          label={isDNAmBiomarker ? "About biomarker" : label}
                          richText={biomarker.filters[selectedFilterIndex][itemKey]}
                        />
                      )
                    })}

                  {!isSkinBiomarker && !isDNAmBiomarker &&
                    <RichTextWithTabs
                      ranges={biomarker.filters[selectedFilterIndex]}
                      valuesObject={biomarker.filters[selectedFilterIndex].whatAreTheRisks}
                      groups={biomarker.filters[selectedFilterIndex].groups}
                      isCustomTabs
                      allTabsArray={WHAT_ARE_THE_RISKS_TABS}
                      allTabsMap={{}}
                      className="mt-4"
                      label="What are the risks?"
                      withBulletList
                    />
                  }

                  <RichTextWithTabs
                    ranges={biomarker.filters[selectedFilterIndex]}
                    valuesObject={biomarker.filters[selectedFilterIndex].whatAreTheCauses}
                    groups={biomarker.filters[selectedFilterIndex].groups}
                    isCustomTabs
                    allTabsArray={whatAreTheCausesTabs()}
                    allTabsMap={{}}
                    className="mt-4"
                    label="What are the causes?"
                    withBulletList
                  />

                  {!!biomarker.filters[selectedFilterIndex]?.interactions?.length &&
                    <>
                      <Title
                        title="Interactions"
                        className="mt-5 mb-4"
                      />

                      <InteractionsView
                        interactions={biomarker.filters[selectedFilterIndex].interactions}
                      />
                    </>
                  }
                </>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}
