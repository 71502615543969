import React, { useState } from 'react';
import { Carousel as RCarousel, CarouselCaption, CarouselControl, CarouselIndicators, CarouselItem } from 'reactstrap';
import { StoryblokComponent } from "@storyblok/react";

function Carousel({ blok }) {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === blok.items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? blok.items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = blok.items.map((item) => {
        return (
            <CarouselItem
                className="custom-tag"
                tag="div"
                key={blok._uid}
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
            >
                {item.body.map((blok) => (
                    <div key={blok._uid}>
                        <StoryblokComponent blok={blok}/>
                    </div>
                ))}
                <CarouselCaption
                    captionText={item.captionText}
                    captionHeader={item.captionHeader}
                />
            </CarouselItem>
        );
    });

    return (
        <div>
            <style>
                {`.custom-tag {
                    width: 100%;
                    background: black;
                    height: "auto";
                  }
                `}
            </style>
            <RCarousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                dark={Boolean(blok.dark)}
                fade={Boolean(blok.fade)}
                slide={Boolean(blok.slide)}
                interval={Boolean(blok.interval) && blok.interval}
            >
                <CarouselIndicators
                    items={blok.items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                />
                {slides}
                <CarouselControl
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={previous}
                />
                <CarouselControl
                    direction="next"
                    directionText="Next"
                    onClickHandler={next}
                />
            </RCarousel>
        </div>
    );
}

export default Carousel;