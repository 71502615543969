import React from 'react'
import { Card } from "reactstrap";
import AuthCardContainer from "../../components/AuthCardContainer";
import { useNavigate } from "react-router-dom";
import { AUTH_GROUP_LINKS } from "../../config";
import Button from "../../../../base/components/Button";
import Icon from "../../../../base/components/Icon";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";

export default function LinkSent() {
  const navigate = useNavigate();

  return (
    <AuthCardContainer metaText="Link was sent">
      <Card className="overflow-hidden p-4">

        <div className="auth-logo-light mt-2 mx-auto">
          <div className="avatar-md mb-4">
            <span className="avatar-title rounded-circle bg-body">
              <Icon icon="message" className="rounded-circle" height="34"/>
            </span>
          </div>
        </div>

        <h5 className="mt-2 text-center font-size-19">
          Success!
        </h5>

        <p className="mt-2 mb-4 text-center font-size-13 text-secondary">
          If we found your email in our system, we send an email with password recovery instructions.
        </p>

        <Button
          className="mb-2 mx-auto"
          color={BUTTON_COLORS.primary}
          onClick={() => navigate(AUTH_GROUP_LINKS.LINK_LOGIN)}
        >
          Back to Sign in
        </Button>
      </Card>
    </AuthCardContainer>
  )
}