import React, { useCallback, useEffect, useState } from 'react'
import Breadcrumb from "../../../base/components/Common/Breadcrumb2";
import { Card, CardBody, Col, Row } from "reactstrap";
import { BIOMARKERS_GROUP_LINKS } from "./config";
import { useLocation, useNavigate } from "react-router-dom";
import { useService } from "../../../base/hooks/useService";
import BiomarkersService from "../../../services/BiomarkersService";
import { useLoading } from "../../../base/hooks/useLoading";
import { useQueryString } from "../../../base/hooks/useQueryString";
import Table from "../../../base/components/Table";
import { DEFAULT_DEBOUNCE_DELAY, DEFAULT_TABLE_LIMIT, EMPTY_ARRAY } from "../../../base/constants/shared";
import {
  useSortProvider,
  usePaginationProvider,
  useLocationSource,
  parseSorting, useSearchProvider, useFilterProvider
} from "../../../base/components/Table/hooks";
import { useDebounce } from "../../../base/hooks/useDebounce";
import ConfirmPopup from "../../../base/components/ConfirmPopup";
import { SearchPlaceholder, TableHeader, columns } from "./ListComponents/components";
import BiomarkerFilters from "./ListComponents/BiomarkerFilters";
import { replaceUrlParams } from "../../../base/helpers/url";
import { DNAm_CATEGORY_NAME, SKIN_CATEGORY_NAME } from "./CreateEdit/constants";
import ToasterService from "../../../services/ToastService";

const breadcrumbs = {
  title: "Biomarkers", breadcrumbItems: []
}

export default function BiomarkersList() {
  /**
   * @type {BiomarkersService}
   */
  const biomarkersService = useService(BiomarkersService);
  /**
   * @type {ToasterService}
   */
  const toastService = useService(ToasterService);

  const [isLoading, { registerPromise }] = useLoading(true);
  const navigate = useNavigate();
  const { search: locationSearch, pathname } = useLocation();
  const {
    params: {
      limit = DEFAULT_TABLE_LIMIT,
      offset = 0,
      name,
      categoryName,
      search,
      categoryIds = EMPTY_ARRAY
    }
  } = useQueryString(locationSearch)

  const [biomarkers, updateBiomarkers] = useState([]);
  const [biomarkersPagination, updateBiomarkersPagination] = useState({});
  const [showDeletePopup, updateShowDeletePopup] = useState("");
  const [categories, updateCategories] = useState([]);
  const [collapsedRows, updateCollapsedRows] = useState([]);
  const [showActivatePopup, updateShowActivatePopup] = useState(false);

  const locationSource = useLocationSource();

  const paginationProvider = usePaginationProvider({
    source: locationSource,
    alias: "offset",
    scope: "",
    fallback: 0
  });

  const [debouncedSearch] = useDebounce(search, DEFAULT_DEBOUNCE_DELAY, () => paginationProvider.setValue(0));

  const nameSortProvider = useSortProvider({
    source: locationSource,
    alias: "name",
    scope: "",
    onApplyClearScope: ["categoryName", "offset"]
  });

  const categorySortProvider = useSortProvider({
    source: locationSource,
    alias: "categoryName",
    scope: "",
    onApplyClearScope: ["name", "offset"]
  });

  const searchProvider = useSearchProvider({
    source: locationSource,
    scope: "",
    alias: 'search',
    onApplyClearScope: [""]
  })

  const filterProvider = useFilterProvider({
    source: locationSource,
    scope: "",
    alias: 'categoryIds',
    onApplyClearScope: ["offset"]
  })

  const onDeleteBiomarker = (id) => {
    updateShowDeletePopup(id);
  }

  const onEditBiomarker = (id) => {
    navigate(replaceUrlParams(BIOMARKERS_GROUP_LINKS.EDIT, { id }))
  }

  const handleDeleteBiomarker = useCallback(() => {
    if(!showDeletePopup) return
    biomarkersService.deleteBiomarker(showDeletePopup).then(() => {
      getBiomarkers();
    });
  }, [showDeletePopup]);

  const handleActivateBiomarker = useCallback(() => {
    if(!showActivatePopup) return
    biomarkersService.activateBiomarker(showActivatePopup.id, { isActive: !showActivatePopup.isActive }).then(() => {
      getBiomarkers();
      toastService.success(`Biomarker has been successfully ${showActivatePopup.isActive ? "deactivated" : "activated"} `)
    });
  }, [showActivatePopup]);


  const toggleCollapse = (id) => {
    if(collapsedRows.includes(id)) {
      updateCollapsedRows(prevState => prevState.filter(item => item !== id))
      return
    }
    updateCollapsedRows(prevState => [...prevState, id]);
  }

  const getBiomarkers = useCallback(() => {
    updateCollapsedRows([]);
    const sortObject = parseSorting({ name, categoryName });
    registerPromise(biomarkersService.getBiomarkers({
      limit,
      offset,
      search: debouncedSearch,
      categoryIds, ...sortObject
    })
      .then(({ data, pagination }) => {
        updateBiomarkers(data)
        updateBiomarkersPagination(pagination)
      }))
  }, [limit, offset, name, categoryName, debouncedSearch, categoryIds]);

  const getCategories = useCallback(() => {
    biomarkersService.getCategories().then((data) => {
      updateCategories(data.categories)
    })
  }, [pathname]);

  useEffect(() => {
    getCategories()
  }, [getCategories]);

  useEffect(() => {
    getBiomarkers()
  }, [getBiomarkers])

  return (
    <>
      <Breadcrumb {...breadcrumbs}/>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Table
                columns={columns}
                data={biomarkers}
                isAddNewBiomarker
                loading={isLoading}
                HeaderComponent={TableHeader}
                totalCount={biomarkersPagination.totalCount}
                limit={DEFAULT_TABLE_LIMIT}
                offset={offset}
                sortProviders={{
                  name: nameSortProvider,
                  categoryName: categorySortProvider
                }}
                paginationProvider={paginationProvider}
                onDelete={onDeleteBiomarker}
                onEdit={onEditBiomarker}
                searchProvider={searchProvider}
                commonPlaceholder="No biomarkers found"
                placeholderForSearch={<SearchPlaceholder/>}
                filterProvider={filterProvider}
                categories={categories}
                isRowClick
                withLocation
                rowClickPath="/details"
                useCollapseRule={(biomarker) => biomarker.category?.name !== SKIN_CATEGORY_NAME && biomarker.category?.name !== DNAm_CATEGORY_NAME}
                collapsedState={collapsedRows}
                toggleCollapse={toggleCollapse}
                CollapsedComponent={BiomarkerFilters}
                onActivateItem={(biomarker) => updateShowActivatePopup(biomarker)}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {!!showDeletePopup &&
        <ConfirmPopup
          isOpen={showDeletePopup}
          updateIsOpen={() => updateShowDeletePopup("")}
          onSubmit={handleDeleteBiomarker}
          title="Remove"
          description="Are you sure you want to delete chosen biomarker?"
          submitBtnText="Delete"
        />
      }

      {!!showActivatePopup &&
        <ConfirmPopup
          isOpen={showActivatePopup}
          updateIsOpen={() => updateShowActivatePopup(undefined)}
          onSubmit={handleActivateBiomarker}
          title={showActivatePopup.isActive ? "Deactivate biomarker" : "Activate biomarker"}
          description={showActivatePopup.isActive ? "Are you sure you want to deactivate chosen biomarker?" : "Are you sure you want to activate chosen biomarker?"}
          submitBtnText={showActivatePopup.isActive ? "Deactivate" : "Activate"}
        />
      }

    </>
  )
}
