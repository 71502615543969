import * as _ from "lodash";
import Http from "./Http";

export const EmailTemplateTypes = {
  sendRestorePasswordEmail: 1,
  sendUserRestorePasswordEmail: 2,
  sendUserVerificationEmail: 3,
  sendAdminSampleIdError: 4,
  sendRoboGPErrorEmail: 5,
  sendEmailFactorAuthenticationEmail: 6,
  sendSuggesticErrorMessage: 7,
  sendEmailToFoodIntoleranceLab: 8,
  sendEurofinsErrorMessage: 9,
  emailSkeleton: 10
};

class EmailTemplatesService extends Http  {

  static $displayName = "EmailTemplatesService";
  baseUrl = "/system/admin/email-templates";

  async fetchEmailTemplates() {
    return await this.get(this.baseUrl);
  }

  async updateEmailTemplates(payload) {
    return await this.put(this.baseUrl, { data: payload });
  }
}

export default EmailTemplatesService;
