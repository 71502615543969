import React, { useEffect, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import Checkbox from "../../../../../base/components/Checkbox";
import Button from "../../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../../base/components/Button/appearance";
import isEqual from 'lodash.isequal';

export const MIN_GROUP_LENGTH = 2;

const stopPropagation = (event) => {
  event.stopPropagation();
}

export const GroupComponent = ({
  idForGroup,
  currentGroup = [],
  keyForGroupChange,
  canBeGroupedWith,
  setFieldValue,
  defaultLabel,
  onGroup,
}) => {
  const isPartOfGroup = currentGroup.includes(idForGroup);

  const [isOpen, updateIsOpen] = useState(false);
  const [chosenGroups, updateChosenGroups] = useState(isPartOfGroup ? currentGroup : []);

  const handleCheckboxChange = (id) => {
    updateChosenGroups(prevState => {
      return prevState.includes(id) ? prevState.filter(chosenId => chosenId !== id) : [...prevState, id];
    })
  }

  const submitGrouping = (event) => {
    event.stopPropagation()
    const withThisGroup = chosenGroups.includes(idForGroup);

    if(chosenGroups.length < MIN_GROUP_LENGTH && !!currentGroup.length && isPartOfGroup) {
      setFieldValue(keyForGroupChange, [])
      onGroup(idForGroup, []);
      updateIsOpen(false);
      return
    }

    const isInCorrectGroup = withThisGroup && chosenGroups.length === MIN_GROUP_LENGTH;
    const isRemovedFromGroup = !withThisGroup && currentGroup.includes(idForGroup);

    if(isInCorrectGroup || isRemovedFromGroup) {
      setFieldValue(keyForGroupChange, chosenGroups)
      onGroup(idForGroup, chosenGroups);
      updateIsOpen(false);
      return
    }

    const newGroups = Array.from(new Set([...chosenGroups, idForGroup]))?.sort()
    setFieldValue(keyForGroupChange, newGroups);
    onGroup(idForGroup, newGroups);
    updateIsOpen(false);
  }

  useEffect(() => {
    updateChosenGroups(isPartOfGroup ? currentGroup : [])
  }, [`${currentGroup}`, idForGroup, isPartOfGroup]);

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={(event) => {
        event.stopPropagation()
        updateIsOpen(prevState => !prevState)
      }}
      className="d-inline-block  me-4"
    >
      <DropdownToggle
        className="d-flex align-items-center "
        tag="div"
      >
        <label className="mb-0 text-primary d-flex align-items-center cursor-pointer">
          <i className="bx bx-folder-plus me-2"/>
          Group
        </label>
      </DropdownToggle>

      <DropdownMenu className="filter-menu pb-1 px-1" onClick={stopPropagation}>

        <section className="filter-options mx-1" onClick={stopPropagation}>
          {isPartOfGroup ?
            <Checkbox
              text={defaultLabel}
              value={chosenGroups?.includes(idForGroup)}
              onChange={() => handleCheckboxChange(idForGroup)}
              className="my-2 ms-1"
              labelClassName="font-weight-normal"
            />
            :
            <Checkbox
              disabled
              text={defaultLabel}
              value={true}
              onChange={() => {
              }}
              className="my-2 ms-1 "
              labelClassName="font-weight-normal"
            />
          }

          {canBeGroupedWith.map((groupItem, index) => {
            return (
              <Checkbox
                disabled={groupItem.id === idForGroup}
                text={groupItem.label}
                value={chosenGroups?.includes(groupItem.id)}
                onChange={() => handleCheckboxChange(groupItem.id)}
                key={index}
                className="my-2 ms-1"
                labelClassName="font-weight-normal"
              />
            )
          })}
        </section>

        <section className="w-100 px-2 pb-2" onClick={stopPropagation}>
          <Button
            color={BUTTON_COLORS.primary}
            onClick={submitGrouping}
            className="w-100"
            disabled={(isEqual(chosenGroups.sort(), currentGroup) && isPartOfGroup) || (!isPartOfGroup && !chosenGroups.length)}
          >
            Apply
          </Button>
        </section>

      </DropdownMenu>
    </Dropdown>
  )
}