import React from 'react'
import { Container } from "reactstrap";
import { Route, Routes } from "react-router-dom";
import { RECOMMENDATIONS_ROUTES } from "./config";
import RecommendationsList from "./List";
import CreateRecommendation from "./CreateEdit/Create";
import EditRecommendation from "./CreateEdit/Edit";
import RecommendationDetails from "./Details";

export default function Recommendations() {
  return (
    <Container fluid className="content">
      <Routes>
        <Route
          path={RECOMMENDATIONS_ROUTES.LIST}
          element={<RecommendationsList/>}
        />
        <Route
          path={RECOMMENDATIONS_ROUTES.CREATE}
          element={<CreateRecommendation/>}
        />
        <Route
          path={RECOMMENDATIONS_ROUTES.EDIT}
          element={<EditRecommendation/>}
        />
        <Route
          path={RECOMMENDATIONS_ROUTES.DETAILS}
          element={<RecommendationDetails/>}
        />
      </Routes>
    </Container>
  )
}