import React from 'react';
import InputWithDelete from "../../../../../base/components/InputWithDeleteIcon";
import { MAX_BIOMARKERS_NAME_LENGTH } from "../constants";
import joinClassNames from "../../../../../base/helpers/joinClassNames";

export default function StudyLink({
  studyListIndex,
  onChange,
  value,
  onDelete,
  className = "mb-2",
  placeholder = "https://",
  maxLength = MAX_BIOMARKERS_NAME_LENGTH
}){
  return (
    <section className={joinClassNames("d-flex align-items-center", className)}>
      <label className="bullet-list__counter font-weight-normal me-3 mb-0">{studyListIndex + 1}.</label>
      <InputWithDelete
        value={value}
        onChange={onChange}
        className="w-100"
        placeholder={placeholder}
        onDelete={onDelete}
        maxLength={maxLength}
      />
    </section>
  )
}