// Libs
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import { ModalFooter } from "reactstrap";

// Components, Views, Screens
import Button from "../../../../../../../base/components/Button";
import Table from "../../../../../../../base/components/Table";

// Hooks, Utils, Helpers
import { useService } from "../../../../../../../base/hooks/useService";
import joinClassNames from "../../../../../../../base/helpers/joinClassNames";
import ProgramsService from "../../../../../../../services/ProgramsService";
import ToastService from "../../../../../../../services/ToastService";
import { BUTTON_COLORS } from "../../../../../../../base/components/Button/appearance";
import { validationSchema, initialValues, columnsNutrients, sexOptions } from "./form";
import { useModal } from "../../../../../../../base/hooks/useModal";
import { MAX_AGE_RANGE, MIN_AGE_RANGE, MIN_DIFF_AGE_RANGE, SPECIAL_NUTRIENT_IDS } from "./constants";
import FormikReactSelect from "../../../../../../../base/components/FormikReactSelect";
import FormikInput from "../../../../../../../base/components/FormikInput";
import TableSearch from "../../../../../../../base/components/Table/tableSearch";
import { useLoading } from "../../../../../../../base/hooks/useLoading";

export function CreateEditGoal({ isOpen, close, editGoalId, copyGoalId }) {
    /**
     * @type {ProgramsService}
     */
    const programsService = useService(ProgramsService);
    /**
     * @type {ToastService}
     */
    const toastService = useService(ToastService);

    const { Modal } = useModal();
    const [isLoading, { registerPromise }] = useLoading(true);

    const [isSubmitting, updateIsSubmitting] = useState(false);
    const [nutrients, setNutrients] = useState([]);
    const [search, updateSearch] = useState("");
    const [goal, setGoal] = useState(null);

    const { programId } = useParams();

    const afterSuccess = () => {
        toastService.success("Goal has been successfully saved");
        updateIsSubmitting(false);
        close();
    };

    const apiFunction = (goal) => {
        if (editGoalId) {
            return programsService.updateGoal(editGoalId, programId, goal);
        }

        return programsService.createGoal(programId, goal);
    };

    const createGoal = (values) => {
        updateIsSubmitting(true);

        apiFunction({
            sex: values.sex,
            ageMinValue: Number(values.ageMinValue),
            ageMaxValue: Number(values.ageMaxValue),
            nutrients: values.nutrients.map(nutrient => ({
                nutrientId: nutrient.nutrientId,
                isEnabled: nutrient.isEnabled,
                quantity: Number(nutrient.quantity)
            }))
        })
            .then(afterSuccess)
            .finally(() => updateIsSubmitting(false));
    };

    const mapGoalToForm = (goal) => {
        const mappedNutrients = goal.programGoalNutrients.data.map(item => ({
            ...item,
            id: item.nutrient.id,
            name: item.nutrient.name
        }));

        return {
            sex: goal.sex,
            ageMinValue: goal.ageMinValue,
            ageMaxValue: goal.ageMaxValue,
            nutrients: mapNutrientToForm(mappedNutrients)
        };
    };

    const mapNutrientToForm = (nutrients) => {
        return nutrients
            .sort((a, b) => {
                const aIndex = SPECIAL_NUTRIENT_IDS.indexOf(a.id);
                const bIndex = SPECIAL_NUTRIENT_IDS.indexOf(b.id);

                const aIsSpecial = aIndex !== -1;
                const bIsSpecial = bIndex !== -1;

                if (aIsSpecial && !bIsSpecial) {
                    return -1;
                }
                if (!aIsSpecial && bIsSpecial) {
                    return 1;
                }
                if (aIsSpecial && bIsSpecial) {
                    return aIndex - bIndex;
                }
                return 0;
            })
            .map((nutrient, index) => {
                return {
                    nutrientId: nutrient.id,
                    name: nutrient.name,
                    isEnabled: nutrient.isEnabled || SPECIAL_NUTRIENT_IDS.includes(nutrient.id),
                    quantity: nutrient.quantity || 0,
                    index
                };
            });
    };

    const initialValuesWithNutrients = useMemo(() => (
        {
            ...initialValues,
            nutrients
        }
    ), [nutrients]);

    const filterNutrients = (nutrients = []) => {
        return nutrients.filter(item => item.name.toLowerCase().includes(search.trim().toLowerCase()));
    };

    useEffect(() => {
        if (editGoalId || copyGoalId) {
            registerPromise(programsService.getGoalById(editGoalId || copyGoalId))
                .then((data) => {
                    setGoal(mapGoalToForm(data));
                });
        } else {
            registerPromise(programsService.getNutrients())
                .then(({ data }) => {
                    setNutrients(mapNutrientToForm(data));
                });
        }
    }, [editGoalId, copyGoalId]);

    return (
        <Modal isOpen={isOpen} toggle={() => close()} size="xl">
            <Modal.Header onClose={() => close()}>
                {editGoalId ? "Edit goal" : "Add goal"}
            </Modal.Header>

            <Formik
                initialValues={goal || initialValuesWithNutrients}
                validationSchema={validationSchema}
                validateOnBlur
                onSubmit={createGoal}
                enableReinitialize
            >
                {({ errors, handleSubmit, values, setFieldValue, touched }) => {

                    const filteredValue = filterNutrients(values.nutrients || []);

                    return (
                        <form
                            className={joinClassNames("form-horizontal p-2", isSubmitting && "pointer-events-none")}
                            onSubmit={handleSubmit}
                        >
                            <Modal.Body>
                                <div>
                                    <div className="d-flex align-items-end justify-content-between mb-3">
                                        <FormikReactSelect
                                            options={sexOptions}
                                            name="sex"
                                            setFieldValue={setFieldValue}
                                            label="Sex"
                                            containerClassName="w-25"
                                            placeholder="Select (required)"
                                        />

                                        <div className="d-flex w-25 align-items-end gap-2">
                                            <FormikInput
                                                name="ageMinValue"
                                                label="Age range"
                                                containerClassName="w-100"
                                                inputClassName={!touched.ageMinValue && !editGoalId ? "text-secondary" : ""}
                                                min={MIN_AGE_RANGE}
                                                max={Number(values.ageMaxValue) - MIN_DIFF_AGE_RANGE}
                                                type="number"
                                            />
                                            <span className="mb-2">{"-"}</span>
                                            <FormikInput
                                                name="ageMaxValue"
                                                containerClassName="w-100"
                                                inputClassName={!touched.ageMaxValue && !editGoalId ? "text-secondary" : ""}
                                                min={Number(values.ageMinValue) + MIN_DIFF_AGE_RANGE}
                                                max={MAX_AGE_RANGE}
                                                type="number"
                                            />
                                        </div>

                                        <TableSearch
                                            search={search}
                                            onSearch={updateSearch}
                                            placeholder="Search nutrient"
                                        />
                                    </div>
                                    <Table
                                        columns={columnsNutrients}
                                        data={filteredValue || []}
                                        withoutPagination
                                        loading={isLoading}
                                    />

                                    {
                                        errors?.totalCountPercent &&
                                        <span className="invalid-feedback d-block">
                                            {errors.totalCountPercent}
                                        </span>
                                    }

                                </div>
                            </Modal.Body>

                            <ModalFooter>
                                <div className="d-flex justify-content-end gap-2 mt-5">
                                    <Button
                                        color={BUTTON_COLORS.primaryOutline}
                                        type="button"
                                        onClick={close}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        color={BUTTON_COLORS.primary}
                                        type="submit"
                                        disabled={!!Object.keys(errors).length || isSubmitting}
                                    >
                                        {editGoalId ? "Submit goal" : "Create goal"}
                                    </Button>
                                </div>
                            </ModalFooter>
                        </form>
                    );
                }}
            </Formik>
        </Modal>);
}