// Libs
import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { ModalFooter } from "reactstrap";

// Components, Views, Screens
import Button from "../../../../../../base/components/Button";
import FormikReactSelect from "../../../../../../base/components/FormikReactSelect";
import FormikInput from "../../../../../../base/components/FormikInput";


// Hooks, Utils, Helpers
import WorkoutService from "../../../../../../services/WorkoutService";
import ToastService from "../../../../../../services/ToastService";
import { useService } from "../../../../../../base/hooks/useService";
import joinClassNames from "../../../../../../base/helpers/joinClassNames";
import { useModal } from "../../../../../../base/hooks/useModal";
import { useLoading } from "../../../../../../base/hooks/useLoading";
import { validationSchema, initialValues, workTypeStatusOptions } from "./form";
import { BUTTON_COLORS } from "../../../../../../base/components/Button/appearance";

export function WorkoutTypeModalForm({ isOpen, close, editWorkoutTypeId }) {
    /**
     * @type {WorkoutService}
     */
    const workoutService = useService(WorkoutService);
    /**
     * @type {ToastService}
     */
    const toastService = useService(ToastService);

    const { Modal } = useModal();
    const [isLoading, { registerPromise }] = useLoading(true);

    const [isSubmitting, updateIsSubmitting] = useState(false);
    const [workoutType, setWorkoutType] = useState(null);

    const afterSuccess = () => {
        toastService.success("Workout type has been successfully saved");
        updateIsSubmitting(false);
        close();
    };

    const apiFunction = (workType) => {
        if (editWorkoutTypeId) {
            return workoutService.updateWorkoutTypes(editWorkoutTypeId, workType);
        }

        return workoutService.createWorkoutTypes(workType);
    };

    const createWorkoutType = (values) => {
        updateIsSubmitting(true);

        apiFunction({
            name: values.fullName,
            status: values.status
        })
            .then(afterSuccess)
            .finally(() => updateIsSubmitting(false));
    };

    const mapWorkoutTypeToForm = (workoutType) => {
        return {
            fullName: workoutType.name,
            status: workoutType.status.toString()
        };
    };

    useEffect(() => {
        if (editWorkoutTypeId) {
            registerPromise(workoutService.getWorkoutTypeById(editWorkoutTypeId))
                .then((data) => {
                    setWorkoutType(mapWorkoutTypeToForm(data));
                });
        }
    }, [editWorkoutTypeId]);

    return (
        <Modal isOpen={isOpen} toggle={() => close()} size="md">
            <Modal.Header onClose={() => close()}>
                {editWorkoutTypeId ? "Edit workout type" : "Create workout type"}
            </Modal.Header>

            <Formik
                initialValues={workoutType || initialValues}
                validationSchema={validationSchema}
                validateOnBlur
                onSubmit={createWorkoutType}
                enableReinitialize
            >
                {({ errors, handleSubmit, setFieldValue, values }) => {

                    return (
                        <form
                            className={joinClassNames("form-horizontal p-2", isSubmitting && "pointer-events-none")}
                            onSubmit={handleSubmit}
                        >
                            <Modal.Body>
                                <div className="d-flex flex-column mb-3">
                                    <FormikInput
                                        name="fullName"
                                        label="Full name"
                                        placeholder="Enter full name (required)"
                                    />

                                    <FormikReactSelect
                                        options={workTypeStatusOptions}
                                        name="status"
                                        setFieldValue={setFieldValue}
                                        label="Status"
                                        placeholder="Select status (required)"
                                        containerClassName="w-100 mt-3"
                                    />

                                </div>
                            </Modal.Body>

                            <ModalFooter>
                                <div className="d-flex justify-content-end gap-2">
                                    <Button
                                        color={BUTTON_COLORS.primaryOutline}
                                        type="button"
                                        onClick={close}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        color={BUTTON_COLORS.primary}
                                        type="submit"
                                        disabled={!!Object.keys(errors).length || isSubmitting}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </ModalFooter>
                        </form>
                    );
                }}
            </Formik>
        </Modal>);
}