import React from 'react';
import { Container } from 'reactstrap';
import { Navigate, Route, Routes } from 'react-router-dom';

import { CONTENTS_GROUP_LINKS, CONTENTS_GROUP_ROUTES } from './config';

import Lists from "./Lists";
import { CategoryForm } from "./Forms/CategoryForm";
import { ContentForm } from "./Forms/ContentForm";
import { CategoryDetails } from "./Details/CategoryDetails";
import { ContentDetails } from "./Details/ContentDetails";

export default function Content() {

  return (
    <Container fluid className="content">
      <Routes>
        <Route
          path={CONTENTS_GROUP_ROUTES.LISTS}
          element={<Lists/>}
        />

        <Route
          path={CONTENTS_GROUP_ROUTES.CATEGORIES_DETAILS}
          element={<CategoryDetails/>}
        />

        <Route
          path={CONTENTS_GROUP_ROUTES.CONTENTS_DETAILS}
          element={<ContentDetails/>}
        />

        <Route
          path={CONTENTS_GROUP_ROUTES.CATEGORIES_FORM}
          element={<CategoryForm/>}
        />

        <Route
          path={CONTENTS_GROUP_ROUTES.FORM_CONTENTS}
          element={<ContentForm/>}
        />

        <Route path={CONTENTS_GROUP_ROUTES.BASE} element={<Navigate to={CONTENTS_GROUP_LINKS.LISTS}/>}/>
      </Routes>
    </Container>
  );
}