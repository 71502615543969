import React from "react";
import ExerciseDropdown from "../../../../../../../../../base/components/Dropdowns/ExerciseDropdown/ExerciseDropdown";
import Icon from "../../../../../../../../../base/components/Icon";
import { useField } from "formik";
import { ExerciseSetsTable } from "./SetsTable";

export default function ExerciseItem({ name, exercises = [], dragHandleProps = {}, removeItem, isShowDelete = true  }) {
    const [{ value }, { error }, { setValue }] = useField(name);

    const handleChangeExercise = (exercise) => {
        setValue({
            ...value,
            exercise,
            sets: []
        });
    };

    return <div className="d-flex">
        <div className="w-100">
            <label>Exercise</label>
            <li>
                <div className="d-flex ps-2 gap-5">
                    <ExerciseDropdown
                        value={value.exercise}
                        onChange={handleChangeExercise}
                        exercises={exercises}
                        placeholder="Select exercise"
                        error={error}
                    />

                    <div className="d-flex align-items-center gap-3">
                        <div
                            {...dragHandleProps}
                        >
                            <Icon
                                icon="dndIcon"
                                width={24}
                                height={24}
                                className="cursor-pointer impacts-section__view"
                            />
                        </div>

                        {isShowDelete && <Icon
                            icon={"trashIcon"}
                            width={24}
                            height={24}
                            onClick={(event) => {
                                event.stopPropagation();
                                removeItem()
                            }}
                            className="cursor-pointer"
                        />}
                    </div>
                </div>

                {!!value.exercise && <ExerciseSetsTable name={`${name}.sets`} exerciseType={value.exercise.type}/>}

                {!!error?.sets && <div className="invalid-feedback d-block ms-4" >{ error.sets }</div>}


            </li>
        </div>
    </div>;
}