// Libs
import React, { useEffect, useRef, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem, Spinner } from "reactstrap";
import classnames from "classnames";
import { noop } from "lodash";

// Components, Views, Screens

// Hooks, Utils, Helpers
import { useService } from "../../../hooks/useService";
import { useLoading } from "../../../hooks/useLoading";


// Styles, assets
import classes from "./AuthorDropdown.module.scss";
import ContentsService from "../../../../services/ContentsService";
import ToasterService from "../../../../services/ToastService";
import { ENTER_KEY } from "../../../constants/system";


const DEFAULT_LIMIT = 100;


export const AuthorDropdown = ({ onChange, value, placeholder, label, error, handleBlur = noop, name = "author" }) => {
  /**
   * @type {ContentsService}
   */
  const contentService = useService(ContentsService);
  /**
   * @type {ToasterService}
   */
  const toastService = useService(ToasterService);

  const [isOpen, updateIsOpen] = useState(false);
  const [authors, setAuthors] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isLoading, { registerPromise }] = useLoading(true);
  const lastElementRef = useRef(null);

  const showPlaceholder = !value?.id;

  const mapAuthorsToDropdownOption = (data = []) => {
    return data.map((item) => ({ id: item.id, name: item.name, file: item.file }));
  };

  const formatFile = (file) => {
    return file
      ? [{
        ...file,
        preview: file?.link,
        file: { size: file?.bytes ?? 0 },
        cropped: true
      }]
      : [];
  };

  const handleChange = (e) => {
    updateIsOpen(true);
    onChange({ name: e.target.value });
  };

  const loadAuthors = () => {
    const params = { limit: DEFAULT_LIMIT, offset: 0, query: value?.name || undefined };

    setHasNextPage(false);
    setPage(1);

    registerPromise(contentService.getContentAuthor(params))
      .then((result => {
        setAuthors(mapAuthorsToDropdownOption(result.data));
        setHasNextPage(result?.pagination?.nextOffset < result?.pagination?.totalCount);
      }))
      .catch((error => console.error(error)));
  };

  const loadMoreAuthors = () => {
    if (isLoading || !hasNextPage) return;
    const nextOffset = page * DEFAULT_LIMIT;

    const params = { limit: DEFAULT_LIMIT, offset: nextOffset, query: value?.name || undefined };

    registerPromise(contentService.getContentAuthor(params))
      .then((result => {
        setAuthors([...authors, ...mapAuthorsToDropdownOption(result.data)]);
        setHasNextPage(result?.pagination?.nextOffset < result?.pagination?.totalCount);
        setPage(page + 1);
      }));
  };

  const createAuthor = () => {
    if (value.id) return;
    contentService.createContentAuthor({ name: value.name })
      .then((data) => {
        toastService.success("Author has been successfully saved");
        onChange({ name: value.name, id: data.id })
        loadAuthors()
      });
  };

  useEffect(() => {
    if (!lastElementRef.current) return;

    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        loadMoreAuthors();
      }
    });

    observer.observe(lastElementRef.current);

    return () => {
      observer.disconnect();
    };
  }, [loadMoreAuthors, lastElementRef.current]);

  useEffect(() => {
    loadAuthors();
  }, [value?.name]);

  return (<>
      {!!label && <label>{label}</label>}
      <section className="d-flex gap-2 w-100">
        <Dropdown
          isOpen={isOpen}
          toggle={() => {
            if (isOpen) {
              handleBlur();
            }
            updateIsOpen(prevState => !prevState);
          }}
          className="d-inline-block filter-dropdown cursor-pointer result-filter w-100"
          direction="down"
        >
          <DropdownToggle
            className={classnames('filter-toggle max-w-100 pe-0',
              {
                'with-border': isOpen,
                'is-invalid': !!error
              }
            )}
            tag="section"
          >
            <div
              className={classnames('me-1 w-95 user-select-none text-truncate', { 'text-secondary': showPlaceholder })}>
              <input
                className="w-100 ps-2 ms-1 border-0 no-outline"
                value={value?.name || ''}
                onChange={handleChange}
                placeholder={placeholder || "Select author"}
                onKeyDown={(event) => {
                  if (event.key === ENTER_KEY) {
                    createAuthor();
                  }
                }}
              />
            </div>
            <i className={classnames('mdi mdi-chevron-down pointer-events-none user-select-none me-2',
              { 'mdi-rotate-180': isOpen })}/>
          </DropdownToggle>

          <DropdownMenu className="filter-menu pb-1 px-1 w-100 top-50" flip={false}>
            <section>
              <ListGroup>
                <div className={classnames(classes.ItemsWrapper, "custom-scrollbar")}>
                  {authors.map((item, index) => (
                    <ListGroupItem className="bg-transparent border-0 p-0" key={item.id}>
                      <div
                        className={classnames(classes.Item, value?.id === item.id && classes.Active)}
                        onClick={() => {
                          onChange({
                            ...item,
                            file: formatFile(item.file)
                          });
                          updateIsOpen(false);
                        }}
                      >
                        {item.name}
                      </div>
                      {
                        index === authors.length - 1
                          ? <div
                            ref={lastElementRef}
                            className="d-flex justify-content-center">
                          </div>
                          : null
                      }
                    </ListGroupItem>
                  ))}
                  {isLoading && <div className="d-flex my-2 justify-content-center">
                    <Spinner size="sm" color="primary"/>
                  </div>}
                </div>
              </ListGroup>
            </section>
          </DropdownMenu>
        </Dropdown>

      </section>
      {error &&
        <span className="invalid-feedback d-block">{error}</span>
      }
    </>
  );
};



