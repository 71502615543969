import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from "react-router-dom";

import Table from "../../../../../base/components/Table";
import ConfirmPopup from "../../../../../base/components/ConfirmPopup";
import {
    SearchPlaceholder,
    TableHeader,
    columns,
    NoWorkoutTypePlaceholder, WORKOUT_TYPES_ACTIONS
} from "./components";

import { DEFAULT_TABLE_LIMIT } from "../../../../../base/constants/shared";
import {
    usePaginationProvider,
    useLocationSource,
    useSearchProvider, useSortProvider, useFilterProvider
} from "../../../../../base/components/Table/hooks";
import ToasterService from "../../../../../services/ToastService";
import { useService } from "../../../../../base/hooks/useService";
import { useLoading } from "../../../../../base/hooks/useLoading";
import { useQueryString } from "../../../../../base/hooks/useQueryString";
import { LIMIT_OPTIONS, MIN_SEARCH_LENGTH } from "../../../../../base/constants/pagination";
import { WorkoutTypeModalForm } from "./WorkoutTypeModalForm";
import WorkoutService from "../../../../../services/WorkoutService";

export default function WorkoutTypes() {

    /**
     * @type {ToasterService}
     */
    const toastService = useService(ToasterService);
    /**
     * @type {WorkoutService}
     */
    const workoutService = useService(WorkoutService);
    const [showFormPopup, updateShowFormPopup] = useState(false);
    const [isLoading, { registerPromise }] = useLoading(false);
    const { search: locationSearch, pathname } = useLocation();
    const {
        params: {
            limit = DEFAULT_TABLE_LIMIT,
            offset = 0,
            search,
            name,
            status,
            statusFilter,
        }
    } = useQueryString(locationSearch);

    const [workoutTypes, updateWorkoutTypes] = useState([]);
    const [pagination, updatePagination] = useState({});
    const [showDeletePopup, updateShowDeletePopup] = useState(null);
    const [editWorkoutTypeId, updateEditWorkoutTypeId] = useState(null)

    const locationSource = useLocationSource();

    const sortKeys = ["name", "status"];
    const getSortScope = (key) => [...sortKeys.filter(item => item !== key), "offset"];

    const nameSortProvider = useSortProvider({
        source: locationSource,
        alias: "name",
        scope: "",
        onApplyClearScope: getSortScope("name")
    });

    const statusSortProvider = useSortProvider({
        source: locationSource,
        alias: "status",
        scope: "",
        onApplyClearScope: getSortScope("status")
    });

    const searchRequest = useMemo(() => search && search.toString()?.trim()?.length >= MIN_SEARCH_LENGTH
        ? search
        : null,
        [search])

    const paginationProvider = usePaginationProvider({
        source: locationSource,
        alias: "offset",
        scope: "",
        fallback: 0
    });

    const filterProvider = useFilterProvider({
        source: locationSource,
        scope: "",
        alias: 'statusFilter',
        onApplyClearScope: ["offset"]
    });

    const searchProvider = useSearchProvider({
        source: locationSource,
        scope: "",
        alias: 'search',
        onApplyClearScope: ["offset"]
    });

    const limitProvider = usePaginationProvider({
        source: locationSource,
        alias: "limit",
        scope: "",
        fallback: 10,
        onApplyClearScope: ["offset"]
    });

    const handleClickCreate = () => {
        updateShowFormPopup(true)
    }

    const deleteWorkoutType = (id) => {
        workoutService.deleteWorkoutType(id).then(() => {
            getWorkoutTypes()
            toastService.success("The exercise has been successfully deleted")
        })
    }


    const getWorkoutTypes = useCallback(() => {
        const [orderBy, orderType] = Object.entries({
            name,
            status
        }).find(([_, value]) => value) || [];

        registerPromise(workoutService.getWorkoutTypes({
            limit,
            offset,
            query: searchRequest,
            orderBy,
            orderType,
            status: statusFilter
        })).then(({ data, pagination }) => {
            updateWorkoutTypes(data);
            updatePagination(pagination);
        });
    }, [limit, offset, searchRequest, name, status, statusFilter]);

    useEffect(() => {
        getWorkoutTypes();
    }, [getWorkoutTypes]);

    return (
        <>
            <Table
                columns={workoutTypes.length ? columns : []}
                data={workoutTypes}
                loading={isLoading}
                HeaderComponent={TableHeader}
                totalCount={pagination.totalCount}
                limit={limitProvider.getValue()}
                offset={offset}
                paginationProvider={paginationProvider}
                searchProvider={searchProvider}
                filterProvider={filterProvider}
                commonPlaceholder={<NoWorkoutTypePlaceholder/>}
                placeholderForSearch={<SearchPlaceholder/>}
                actions={{
                    [WORKOUT_TYPES_ACTIONS.EDIT]: (id) => {
                        updateShowFormPopup(true)
                        updateEditWorkoutTypeId(id)
                    },
                    [WORKOUT_TYPES_ACTIONS.DELETE]: updateShowDeletePopup,
                }}
                sortProviders={{
                    name: nameSortProvider,
                    status: statusSortProvider
                }}
                isLimitEditable
                limitProvider={limitProvider}
                limitOptions={LIMIT_OPTIONS}
                headerActions={{ handleClickCreate }}
            />
            {showDeletePopup &&
                <ConfirmPopup
                    isOpen={showDeletePopup}
                    updateIsOpen={updateShowDeletePopup}
                    onSubmit={() => {
                        deleteWorkoutType(showDeletePopup)
                        updateShowDeletePopup(null);
                    }}
                    title="Delete workout type"
                    description="Are you sure you want to delete the chosen workout type?"
                    submitBtnText="Delete"
                    className="upload-manually__popup"
                />
            }
            {showFormPopup && <WorkoutTypeModalForm
                isOpen={showFormPopup}
                editWorkoutTypeId={editWorkoutTypeId}
                close={() => {
                    updateShowFormPopup(false)
                    updateEditWorkoutTypeId(null)
                    getWorkoutTypes()
                }}
            />}
        </>
    );
}
