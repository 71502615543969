import yup from "../../../../../validation/yup";

export const initialValues = {
    value: ''
};

export const initialValuesRange = {
    minValue: '',
    maxValue: '',
};

export const validationSchema = yup.object().shape({
    value: yup.string().required()
});

export const validationRangeSchema = yup.object().shape({
    minValue: yup.string().required(),
    maxValue: yup.string().required()
});


