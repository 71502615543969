import React from 'react';

export default function BiomarkerFilters({ row }){
  return (
    <tr>
      <td/>
      <td className="d-flex flex-row align-items-start flex-wrap biomarker-table-filters" colSpan="42">
        {row.filters.map(({ name, id }) => {
          return (
            <div key={id} className="biomarker-table-filter">
              {name}
            </div>
          )
        })}
      </td>
      <td/>
      <td/>
    </tr>
  )
}