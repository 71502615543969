export const USER_SEGMENTS_GROUP_ROUTES = {
  BASE: "/user-segments/*",
  LIST: "/",
  CREATE_EDIT_SEGMENTS: `/create-edit-segments`,
  DETAILS: `/:id`,
  CREATE_EDIT_ATTRIBUTE: `/create-edit-attribute`,
};

export const USER_SEGMENTS_GROUP_LINKS = {
  BASE: "/app/user-segments",
  LIST: "/app/user-segments",
  CREATE_EDIT_SEGMENTS: `/app/user-segments/create-edit-segments`,
  DETAILS: `/app/user-segments/:id`,
  CREATE_EDIT_ATTRIBUTE: `/app/user-segments/create-edit-attribute`,
};