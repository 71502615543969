import React from "react";
import Wrapper from "./Wrapper";

export default function VimeoVideoSection({ blok }) {
    if (!blok) {
        return null;
    }

    return <Wrapper blok={blok}>
        <div className="video-container">
            <iframe
                src={`https://player.vimeo.com/video/${blok?.video?.vimeo_oembed?.response?.video_id}`}
                title={blok?.video?.vimeo_oembed?.response?.title}
                allowFullScreen
            />
        </div>
    </Wrapper>

}