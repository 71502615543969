import React, { useCallback, useEffect, useState } from 'react';
import BaseLayoutWithCard from "../../../../base/components/BaseLayoutWithCard";
import { RECOMMENDATIONS_LINKS } from "../config";
import RecommendationsForms from "./form";
import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import { useLoading } from "../../../../base/hooks/useLoading";
import { useService } from "../../../../base/hooks/useService";
import RecommendationsService from "../../../../services/RecommendationsService";
import { prepareRecommendation, transformRecommendation } from "./components";
import { useNavigate } from "react-router-dom";
import ToasterService from "../../../../services/ToastService";
import useUploadImages from "../../../../base/components/Dropzone/useUploadImages";
import { MAX_RECOMMENDATION_LENGTH } from "../../Biomarkers/CreateEdit/constants";

const breadcrumbs = {
  title: "Create recommendation",
  breadcrumbItems: [
    { title: "Recommendations", link: RECOMMENDATIONS_LINKS.LIST },
    { title: "Create recommendation" },
  ]
}

const COPY_OF = "Copy of ";

export default function CreateRecommendation() {
  /**
   * @type {RecommendationsService}
   */
  const recommendationsService = useService(RecommendationsService);
  /**
   * @type {ToasterService}
   */
  const toastService = useService(ToasterService);

  const { params: { copy } } = useLocationQuery();
  const [isSubmitting, updateIsSubmitting] = useState(false);
  const [recommendation, updateRecommendation] = useState({ customTagName : "" });
  const [isLoading, { registerPromise }] = useLoading(!!copy);
  const navigate = useNavigate()
  const uploadImage = useUploadImages();

  const afterSuccess = () => {
    toastService.success("Recommendation has been successfully saved");
    navigate(RECOMMENDATIONS_LINKS.LIST);
    updateIsSubmitting(false);
  }

  const createRecommendation = ({
    file,
    ...otherValues
  }) => {
    updateIsSubmitting(true);
    const fileId = file[0]?.id;

    const preparedValues = prepareRecommendation(otherValues)

    if(fileId) {
      recommendationsService.createRecommendation({
        ...preparedValues,
        fileId,
      }).then(afterSuccess).finally(() => updateIsSubmitting(false))
      return;
    }

    uploadImage(file[0].file).then(({ file }) => {
      return recommendationsService.createRecommendation({
        ...preparedValues,
        fileId: file.id,
      })
    }).then(afterSuccess).finally(() => updateIsSubmitting(false))
  }

  const getRecommendation = useCallback(() => {
    if(!copy) return;
    registerPromise(recommendationsService.getRecommendation(copy).then(({ title, ...data }) => {
        updateRecommendation(transformRecommendation(
          {
            ...data,
            title: COPY_OF + title.substring(0, MAX_RECOMMENDATION_LENGTH - COPY_OF.length)
          }))
      })
    )
  }, [copy])

  useEffect(() => {
    getRecommendation()
  }, [getRecommendation]);

  return (
    <BaseLayoutWithCard breadcrumbs={breadcrumbs} loading={isLoading}>
      <RecommendationsForms initialValues={recommendation} onSubmit={createRecommendation} isSubmitting={isSubmitting}/>
    </BaseLayoutWithCard>
  )
}