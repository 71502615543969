import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CustomModal, ModalBody, ModalHeader } from "../../../../base/components/CustomModal";
import { useLoading } from "../../../../base/hooks/useLoading";
import TableSearch from "../../../../base/components/Table/tableSearch";
import joinClassNames from "../../../../base/helpers/joinClassNames";
import {
  parseSorting,
  useLocationSource,
  usePaginationProvider,
  useSearchProvider, useSortProvider
} from "../../../../base/components/Table/hooks";
import { useLocation } from "react-router-dom";
import Table from "../../../../base/components/Table";
import { useLocationQuery, useQueryString } from "../../../../base/hooks/useQueryString";
import { useService } from "../../../../base/hooks/useService";
import { ThreeDotDropdown, THREE_DOT_TYPES } from "./components";
import UsersService from "../../../../services/UsersService";
import { useDebounce } from "../../../../base/hooks/useDebounce";
import { DEFAULT_DEBOUNCE_DELAY } from "../../../../base/constants/shared";
import { ConfirmPopupWithReason } from "../../../../base/components/ConfirmPopup";
import { UncontrolledTooltip } from "reactstrap";

const formatDate = (s = '') => s.split('T')[0].split('-').reverse().join('/');

const StatusLabel = ({ id, status }) => {
  return <div id={id} className={`lab-result-status-tag-${status}`}>{status}</div>;
};

const FILE_STATUSES = {
  1: 'pending',
  2: 'new',
  3: 'inProgress',
  4: 'verified',
  5: 'rejected',
};

const FILE_STATUSES_IDS = {
  pending: 1,
  new: 2,
  inProgress: 3,
  verified: 4,
  rejected: 5,
}

const columns = [
  {
    Header: "#",
    width: 45,
    className: 'text-center align-middle',
    Cell: (cellProps) => {
      const { params: { offset = 0 } } = useLocationQuery();
      return <label className="mb-0">{cellProps.row.index + 1 + (offset ?? 0)}</label>;
    },
  },
  {
    Header: "Name",
    accessor: "fileName",
    canSort: true,
    width: 250,
    Cell: ({ row: { original }}) => {
      return (
        <div className="w-100 text-truncate d-flex">
          <div>
            <p className={`mb-0`}>{original.fileName}</p>
          </div>
        </div>
      )
    }
  },
  {
    Header: "Uploaded date",
    accessor: "createdAt",
    canSort: true,
    width: 100,
    Cell: ({ row: { original } }) => {
      return (
        <div className="w-100 text-truncate d-flex">
          <div>
            <p className={`mb-0`}>{formatDate(original.createdAt)}</p>
          </div>
        </div>
      )
    }
  },
  {
    Header: 'Status',
    className: 'before-last-sticky-column',
    accessor: 'status',
    width: 100,
    canSort: true,
    Cell: ({ row: { original } }) => {
      return (
        <div className="d-flex align-items-center">
          <StatusLabel id={`pdfResultFileStatus-${original.id}`} status={FILE_STATUSES[original.status]} />
          <UncontrolledTooltip
              popperClassName={joinClassNames(
                'tooltip-alternative-name error-result-tooltip',
                (!original.meta) && "visibility-hidden"
              )}
              innerClassName="pre-line text-truncate error-inner-max-height"
              placement="bottom"
              target={`pdfResultFileStatus-${original.id}`}
            >
              {original?.meta}
            </UncontrolledTooltip>
        </div>
      );
    },
  },
  {
    Header: "Actions",
    accessor: "",
    width: 125,
    Cell: ({ row: { original }, actions }) => {
      const [isOpen, updateIsOpen] = useState(false);
      const UPLOAD_MANUALLY = { id: 1, label: 'Upload manually', icon: 'editPlus', type: THREE_DOT_TYPES.uploadManually };
      const DOWNLOAD_PDF_FILE = { id: 2, label: 'Download PDF file', icon: 'download', type: THREE_DOT_TYPES.downloadPDFFile };
      const MARK_AS_REJECTED = { id: 3, label: 'Mark as rejected', icon: 'markCancelled', type: THREE_DOT_TYPES.markAsRejected };

      const OPTIONS_BY_STATUS = [
        [],
        [UPLOAD_MANUALLY, MARK_AS_REJECTED, DOWNLOAD_PDF_FILE],
        [UPLOAD_MANUALLY, MARK_AS_REJECTED, DOWNLOAD_PDF_FILE],
        [UPLOAD_MANUALLY, MARK_AS_REJECTED, DOWNLOAD_PDF_FILE],
        [DOWNLOAD_PDF_FILE],
        [DOWNLOAD_PDF_FILE],
      ];

      const { markAsRejected, downloadPDFFile, uploadManually } = actions;
      const menuActions = {
        [THREE_DOT_TYPES.uploadManually]: (original) => uploadManually(original),
        [THREE_DOT_TYPES.downloadPDFFile]: (original) => downloadPDFFile(original),
        [THREE_DOT_TYPES.markAsRejected]: ({ id }) => markAsRejected(id),
      };

      return (
        <section
          className={joinClassNames('d-flex align-items-center justify-content-center text-primary', isOpen && 'force-z-index')}>
          <ThreeDotDropdown
            id={original.id}
            isOpen={isOpen}
            updateIsOpen={updateIsOpen}
            options={OPTIONS_BY_STATUS[original.status]}
            onSelect={type => {
              menuActions[type](original);
            }}
          />
        </section>
      )
    }
  },
];

export default function PDFFilePopup({
  isOpen,
  updateIsOpen,
  user = {},
  afterOnClose,
}) {
  const { id: userId } = user;
  const locationSource = useLocationSource();
  const { search: locationSearch } = useLocation();
  const {
    params: {
      fileName,
      orderByFileName,
      orderByCreatedAt,
      orderByStatus,
    }
  } = useQueryString(locationSearch);


  const generateSortProviderParams = (name) => {
    const allParams = [
      "orderByFileName",
      "orderByCreatedAt",
      "orderByStatus",
    ];
    return {
      source: locationSource,
      alias: name,
      scope: "",
      onApplyClearScope: allParams.filter(paramName => paramName !== name)
    };
  };

  const [isLoading, { registerPromise }] = useLoading(true);
  const [filesList, updateFilesList] = useState([]);
  const [userResultsPagination, updateUserResultsPagination] = useState({ totalCount: 0 });
  const usersService = useService(UsersService);
  const fileNameSortProvider = useSortProvider(generateSortProviderParams("orderByFileName"));
  const createdAtSortProvider = useSortProvider(generateSortProviderParams("orderByCreatedAt"));
  const statusSortProvider = useSortProvider(generateSortProviderParams("orderByStatus"));
  const [showCancelVerifyPopup, updateShowCancelVerifyPopup] = useState(false);

  const onClose = () => {
    updateIsOpen(false);
    afterOnClose()
  }

  const changePDFFileStatus = useCallback((id, status, additionalValues = {}) => {
    registerPromise(usersService.updateOtherLabPDFResult(id, { status, ...additionalValues })
      .then(getFilesList))
  }, []);

  const makeInProgressAndRedirect = async (url, id, status) => {
    if (status === FILE_STATUSES_IDS.new) {
      await changePDFFileStatus(id, FILE_STATUSES_IDS.inProgress, {
        labReceivedAt: new Date().toISOString(),
      });
    }
    window.open(url).focus();
  }

  const [debouncedSearch] = useDebounce(fileName, DEFAULT_DEBOUNCE_DELAY, () => paginationProvider.setValue(0));

  const paginationProvider = usePaginationProvider({
    source: locationSource,
    alias: "offset",
    scope: "",
    fallback: 0
  });

  const searchProvider = useSearchProvider({
    source: locationSource,
    scope: "",
    alias: 'fileName',
    onApplyClearScope: ["fileName"],
  });

  const sortProviders = useMemo(() => ({
    fileName: fileNameSortProvider,
    createdAt: createdAtSortProvider,
    status: statusSortProvider,
  }), [fileNameSortProvider, createdAtSortProvider, statusSortProvider]);

  const getFilesList = useCallback(() => {
    const sortObject = parseSorting({
      fileName: orderByFileName,
      createdAt: orderByCreatedAt,
      status: orderByStatus,
    });
    registerPromise(
      usersService.getOtherLabPDFResultsList(userId,  {
        ...sortObject,
        search: debouncedSearch,
      })
        .then(({ data, pagination }) => {
          updateFilesList(data);
          updateUserResultsPagination(pagination)
        })
    )
  }, [userId, debouncedSearch, orderByFileName, orderByCreatedAt, orderByStatus]);

  useEffect(() => {
    getFilesList()
  }, [getFilesList]);

  return (
    <CustomModal isOpen={isOpen} className="hl7-modal" toggle={onClose}>
      <ModalHeader onClose={onClose} className="text-dark" sectionClassName="border-none">
        Uploaded PDF files
      </ModalHeader>
      <ModalBody>
        <section className="d-flex align-items-center mb-3">
          <div className='w-50 d-flex align-items-center'>
            <TableSearch
              className="biomarkers-search"
              search={searchProvider.getValue()}
              onSearch={searchProvider.setValue}
            />
          </div>
        </section>

        <Table
          columns={columns}
          data={filesList}
          isAddNewBiomarker
          loading={isLoading}
          totalCount={userResultsPagination.totalCount}
          limit={100}
          offset={0}
          sortProviders={sortProviders}
          paginationProvider={paginationProvider}
          withoutPagination
          commonPlaceholder="No PDF results for now."
          hasActiveFilters={false}
          tableClassName="popup-table-pdf"
          actions={{
            downloadPDFFile: async ({ id, file, status }) => {
              await makeInProgressAndRedirect(file?.link, id, status)
            },
            uploadManually: async ({ id, status }) => {
              await makeInProgressAndRedirect(`/app/customer-management/${userId}/upload-manually?pdfFileId=${id}`, id, status)
            },
            markAsRejected: (id) => {
              updateShowCancelVerifyPopup(id);
            }
          }}
        />
      </ModalBody>

      {!!showCancelVerifyPopup &&
        <ConfirmPopupWithReason
          isOpen={!!showCancelVerifyPopup}
          updateIsOpen={updateShowCancelVerifyPopup}
          onSubmit={(values) => changePDFFileStatus(showCancelVerifyPopup, FILE_STATUSES_IDS.rejected, {
            meta: values?.cancellationReason,
            labReceivedAt: new Date().toISOString()
          })}
          title="Confirmation"
          description="Are you sure you want to mark the result as rejected?"
          labelText="Enter a reason of rejection:"
          submitBtnText="Confirm"
          className=""
        />
      }
    </CustomModal>
  )
}