import React, { useState } from 'react';
import UICustomisationLayoutWithCard from "../../../../base/components/UICustomisationLayoutWithCard";
import { useDarkMode } from "../../../../base/context/darkMode";
import {
  Col,
  Row,
  NavItem,
  NavLink, Nav
} from "reactstrap";
import { useService } from "../../../../base/hooks/useService";
import ConfigService from "../../../../services/ConfigService";
import classNames from "classnames";
import { UI_SETTINGS_TABS } from "../config";
import LogIn from "./LogIn";
import SignUp from "./SignUp";
import HomePageLayout from "./HomePageLayout";
import BloodBiomarker from "./BloodBiomarker";
import DatePicker from "./DatePicker";
import loginPreview from "../../../../assets/images/previews/login.jpg";
import signUpPreview from "../../../../assets/images/previews/signup.jpg";
import homepageLayoutPreview from "../../../../assets/images/previews/dashboard.jpg";
import biomarkerPreview from "../../../../assets/images/previews/biomarker.jpg";
import datePickerPreview from "../../../../assets/images/previews/date-picker.jpg";

export default function UISettings() {


  const [activeTab, updateActiveTab] = useState(UI_SETTINGS_TABS.SIGN_UP);

  const tabComponents = {
    [UI_SETTINGS_TABS.LOG_IN]: <LogIn />,
    [UI_SETTINGS_TABS.SIGN_UP]: <SignUp />,
    [UI_SETTINGS_TABS.HOMEPAGE_LAYOUT]: <HomePageLayout />,
    [UI_SETTINGS_TABS.BLOOD_BIOMARKER]: <BloodBiomarker />,
    [UI_SETTINGS_TABS.DATE_PICKER]: <DatePicker />,
  }

  const previews = {
    [UI_SETTINGS_TABS.LOG_IN]: loginPreview,
    [UI_SETTINGS_TABS.SIGN_UP]: signUpPreview,
    [UI_SETTINGS_TABS.HOMEPAGE_LAYOUT]: homepageLayoutPreview,
    [UI_SETTINGS_TABS.BLOOD_BIOMARKER]: biomarkerPreview,
    [UI_SETTINGS_TABS.DATE_PICKER]: datePickerPreview,
  }

  const breadcrumbs = {
    title: "UI Settings", breadcrumbItems: []
  };


  const isDarkMode = useDarkMode();
  /**
   * @type {ConfigService}
   */
  const configService = useService(ConfigService);

  return (
    <UICustomisationLayoutWithCard breadcrumbs={breadcrumbs}>
      <Row className="ui-customisation">
        <Col lg={5} className="side-form-container p-0">
          {tabComponents[activeTab]}
        </Col>
        <Col lg={7} className="px-0">
          <Nav tabs className="nav-tabs-custom mt-1">
            {Object.values(UI_SETTINGS_TABS).map((label, index) => (
              <NavItem key={`navItem${index}`}>
                <NavLink
                  className={classNames(
                    {
                      active: activeTab === label
                    },
                    "cursor-pointer py-1 mx-1"
                  )}
                  onClick={() => updateActiveTab(label)}
                >
                  <span>{label}</span>
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <div className="preview">
            <img src={previews[activeTab]} alt="preview" className="w-100" />
          </div>
        </Col>
      </Row>
    </UICustomisationLayoutWithCard>
  );
}
