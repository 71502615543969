import React from "react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

const GridCard = ({ blok }) => {
    if (!blok) {
        return null;
    }

    const wrapperStyles = {
        display: "flex",
        flexDirection: "column",
        width: blok.width && "100%",
        justifyContent: blok.contentPosition

    }

    return (
        <div style={wrapperStyles}>
            {blok.body
                ? blok.body.map((blok) => (
                    <div key={blok._uid}>
                        <StoryblokComponent blok={blok}/>
                    </div>
                ))
                : null
            }
        </div>
    );
};

export default GridCard;
