import React from "react";
import { Card, CardBody } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";

import FormikInput from "../../../../base/components/FormikInput";
import { useService } from "../../../../base/hooks/useService";

import AuthCardContainer from "../../components/AuthCardContainer";
import Logo from "../../components/Logo";
import AuthHeader from "../../components/Header";

import AuthService from "../../../../services/AuthService";

import { validationSchema } from "./form";
import { useLoading } from "../../../../base/hooks/useLoading";
import { AUTH_GROUP_LINKS } from "../../config";
import { parseQuery } from "../../../../base/hooks/useQueryString";
import Button from "../../../../base/components/Button/index";
import { BUTTON_COLORS, BUTTON_STYLES } from "../../../../base/components/Button/appearance";

const ForgetPasswordPage = () => {
  const [isLoading, { registerPromise }] = useLoading();
  const navigate = useNavigate();
  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);
  const { search } = useLocation();

  const { email = "" } = parseQuery(search);

  const sendEmailLink = (email) => {
    registerPromise(authService.sendRestorePasswordEmail(email))
      .then(() => {
        navigate(AUTH_GROUP_LINKS.LINK_SENT);
      });
  };

  return (
    <AuthCardContainer metaText="Restore password">
      <Card className="overflow-hidden">
        <AuthHeader
          headerText="Restore password"
          className="auth-header"
        />

        <CardBody className="pt-0">
          <Logo/>

          <Formik
            initialValues={{ email: email ? decodeURIComponent(email) : "" }}
            validationSchema={validationSchema}
            validateOnMount
            onSubmit={(values) => {
              sendEmailLink(values);
            }}
          >
            {({ errors, handleSubmit, values }) => (
              <form className="form-horizontal p-2" onSubmit={handleSubmit}>
                <FormikInput
                  id="email"
                  name="email"
                  label="Email"
                  containerClassName="mb-3"
                  placeholder="Enter email"
                  type="email"
                />
                <Button
                  color={BUTTON_COLORS.primary}
                  type="submit"
                  block={BUTTON_STYLES.block}
                  loading={isLoading}
                  disabled={isLoading || !!Object.keys(errors).length}
                >
                  Send restore link
                </Button>

                <div className="mt-3 text-center">
                  <p className="mb-1 text-secondary">
                    Remember it?{" "}
                    <Link
                      to={{
                        pathname: AUTH_GROUP_LINKS.LINK_LOGIN
                      }}
                      className="font-weight-medium text-primary"
                    >
                      Sign in
                    </Link>
                  </p>
                </div>

              </form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </AuthCardContainer>
  );
};

export default ForgetPasswordPage;
