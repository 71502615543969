import yup from "../../../../validation/yup";
import {
  MAX_TAG_STRING_LENGTH,
  MIN_STRING_LENGTH
} from "../../Biomarkers/CreateEdit/constants";
import { ALLOWED_QUIZ_KEY_MESSAGE, ALLOWED_QUIZ_KEY_REGEX } from "../constants";

const VALUE_VALIDATION_BY_TYPE = {
  text: yup.string()
    .min(MIN_STRING_LENGTH)
    .max(MAX_TAG_STRING_LENGTH)
    .required(),
  number: yup.number().required()
}

export const validationSchema = (type = "text") => yup.object().shape({
  key: yup.string()
    .min(MIN_STRING_LENGTH)
    .max(MAX_TAG_STRING_LENGTH)
    .matches(ALLOWED_QUIZ_KEY_REGEX, ALLOWED_QUIZ_KEY_MESSAGE)
    .required(),
  value: VALUE_VALIDATION_BY_TYPE[type],
  quizType: yup.number().required()
});