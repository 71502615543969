import React, { useMemo } from 'react';
import RecommendationsCollapse from "../CreateEdit/components/RecommendationsCollapse";
import {
  DNAm_RECOMMENDATION_VIEW_SECTIONS, DNAm_RECOMMENDATIONS_GROUPS,
  RECOMMENDATION_SECTIONS,
  SKIN_RECOMMENDATION_SECTIONS,
} from "../CreateEdit/Recommendations/constants";
import { prepareRecommendations } from "../CreateEdit/helpers/transformModels";
import { checkOnDefined } from "../CreateEdit/fromValidation";
import { getGroupData } from "../CreateEdit/Recommendations/helpers";

export default function RecommendationsView(
  {
    filterRecommendations = [],
    filter = {},
    isSkinBiomarker,
    isDNAmBiomarker
  }
) {
  const recommendations = prepareRecommendations(filterRecommendations);

  const getRecommendationsArray = () => {
    if (isSkinBiomarker) return SKIN_RECOMMENDATION_SECTIONS;
    if (isDNAmBiomarker) return DNAm_RECOMMENDATION_VIEW_SECTIONS;
    return RECOMMENDATION_SECTIONS;
  }

  const recommendationsArray = useMemo(() => getRecommendationsArray(), [isSkinBiomarker]);

  return (
    <section className="w-50">
      <label className="mt-3 font-weight-semibold">Recommendations</label>

      {recommendationsArray.map(({
                                   label,
                                   name,
                                   rangesToApply,
                                   idForGroup,
                                   groupName,
                                   canBeGroupedWith = [],
                                 }, index) => {
        if (!rangesToApply.some(rangeKey => checkOnDefined(filter[rangeKey])) || !recommendations[name]) {
          return null;
        }

        const {
          displayedLabel,
          emptyComponent
        } = getGroupData({
          group: isDNAmBiomarker ? DNAm_RECOMMENDATIONS_GROUPS[groupName] : filter.groups[groupName],
          groupName,
          idForGroup,
          canBeGroupedWith,
          ranges: filter,
          label
        })

        if (emptyComponent) {
          return null
        }

        return (
          <RecommendationsCollapse label={displayedLabel} key={index}>
            {recommendations[name].map(({id, order, title}) => (
              <section className="form-recommendations--section__item d-flex align-items-center" key={id}>
                <label className="me-3 mb-0 form-recommendations--section__item__label">{order}.</label>
                <label className="font-weight-normal mb-0 form-recommendations--max-width text-truncate">{title}</label>
              </section>
            ))}
          </RecommendationsCollapse>
        )
      })}
    </section>

  )
}
