import React from 'react';
import { Cell, Pie, PieChart, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export const TotalNutrientInfo = ({ mealPlanByDay = [] }) => {
    const {
        totalFat,
        totalProtein,
        totalCarbs,
        totalKcal
    } = mealPlanByDay.reduce((acc, item) => {
        const food = item.foods[0];

        return {
            totalKcal: acc.totalKcal + food.nutritionalInfo.kcal.value,
            totalFat: acc.totalFat + food.nutritionalInfo.fat.value,
            totalProtein: acc.totalProtein + food.nutritionalInfo.protein.value,
            totalCarbs: acc.totalCarbs + food.nutritionalInfo.carbs.value
        };
    }, {
        totalFat: 0,
        totalProtein: 0,
        totalCarbs: 0,
        totalKcal: 0
    });

    const data = [
        { name: 'Carbs', value: totalCarbs },
        { name: 'Protein', value: totalProtein },
        { name: 'Fat', value: totalFat },
    ];

    const COLORS = ['#07BC0C', '#F1B44C', '#556EE6'];

    const renderLegend = (props) => {
        const { payload } = props;

        return (
            <ul className="list-unstyled text-center mt-3">
                {payload.map((entry, index) => (
                    <li key={`item-${index}`} className="d-flex mb-2 align-items-center justify-content-start ps-5">
                        <div
                            style={{
                                width: '10px',
                                height: '10px',
                                backgroundColor: COLORS[index % COLORS.length],
                                marginRight: "8px",
                                borderRadius: "50%"
                            }}
                        />
                        <span className="me-1">
                            {entry.value}
                        </span>
                        {` (${Math.round(entry.payload.percent * 100)}%)`}
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <div className="d-flex justify-content-center align-items-center meal-plan-card">
            <div className="position-relative">
                <ResponsiveContainer width={250} height={250}>
                    <PieChart>
                        <Pie
                            data={data}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            outerRadius="100%"
                            innerRadius="75%"
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                            ))}
                        </Pie>
                        <Legend content={renderLegend} />
                    </PieChart>
                </ResponsiveContainer>
                <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center"
                     style={{ top: "-50px" }}>
                    <div className="text-center">
                        <strong>{Math.round(totalKcal)}</strong>
                        <div>
                            Calories
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
