import React, { useCallback, useEffect, useState } from "react";
import { useLocationQuery, useQueryString } from "../../../../base/hooks/useQueryString";
import TableSearch from "../../../../base/components/Table/tableSearch";
import Table from "../../../../base/components/Table";
import {
  parseSorting,
  useLocationSource,
  usePaginationProvider,
  useSearchProvider,
  useSortProvider
} from "../../../../base/components/Table/hooks";
import { DEFAULT_DEBOUNCE_DELAY } from "../../../../base/constants/shared";
import { useService } from "../../../../base/hooks/useService";
import { useLocation, useParams } from "react-router-dom";
import { useDebounce } from "../../../../base/hooks/useDebounce";
import { useLoading } from "../../../../base/hooks/useLoading";
import { LIMIT_OPTIONS } from "../../../../base/constants/pagination";
import UserSegmentsService from "../../../../services/UserSegments";
import Button from "../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";
import Icon from "../../../../base/components/Icon";
import UserAttributesService from "../../../../services/UserAttributes";
import { saveAs } from "file-saver";

const columns = [
  {
    Header: '#',
    width: 50,
    className: "bg-white",
    Cell: ({ row: { index } }) => {
      const {
        params: { offset = 0 },
      } = useLocationQuery();
      return <label className="mb-0">{index + 1 + (offset ?? 0)}</label>;
    },
  },
  {
    Header: 'Name',
    accessor: 'name',
    className: "bg-white",
    canSort: true,
    width: 200,
    Cell: ({ value }) => {
      return (
        <section className="w-fit-content d-flex align-items-center">
          {value}
        </section>
      );
    },
  },
  {
    Header: 'Priority',
    accessor: 'priority',
    canSort: true,
    className: "bg-white",
    width: 180,
    Cell: ({ value }) => {
      return <div className="w-100 text-truncate">{value}</div>;
    },
  },
  {
    Header: 'Attribute used',
    accessor: 'attributes',
    className: "bg-white",
    width: 180,
    Cell: ({ value }) => {
      return <div className="w-100 text-truncate">{value.join(", ")}</div>;
    },
  },
];

const SearchPlaceholder = () => (
  <>
    No matching User Segments.
  </>
);

const TableHeader = ({ searchProvider }) => {
  const { id: userId } = useParams();
  /**
   * @type {UserSegmentsService}
   */
  const userSegmentsService = useService(UserSegmentsService);

  const handleDownload = async () => {
    const result = await userSegmentsService.downloadUserSegmentsCSV(userId);
    const resultBlob = new Blob([result], { type: 'text/csv' });
    saveAs(resultBlob, `user-${userId}-segments.csv`);
  }

  return (
    <section className="d-flex mb-3 align-items-center justify-content-between">
      <section className="d-flex align-items-center justify-content-between">
        <TableSearch
          className="biomarkers-search me-3"
          search={searchProvider.getValue()}
          onSearch={searchProvider.setValue}
          placeholder="Search user segments..."
        />
      </section>
      <section>
        <Button
          className="btn-download-csv me-2 cursor-pointer"
          color={BUTTON_COLORS.primaryOutline}
          onClick={() => {
            handleDownload();
          }}>
          <Icon className="me-2" icon="download"/>
          Download CSV file
        </Button>
      </section>
    </section>
  );
};

export default function UserSegmentsList() {
  /**
   * @type {UserSegmentsService}
   */
  const userSegmentsService = useService(UserSegmentsService);

  const { id } = useParams();
  const { search: locationSearch } = useLocation();

  const [collapsedRows, updateCollapsedRows] = useState([]);
  const [userSegments, setUserSegments] = useState([]);
  const [resultsPagination, setResultsPagination] = useState([]);

  const locationSource = useLocationSource();
  const [isTableLoading, { registerPromise: registerTablePromise }] = useLoading(true);
  
  const {
    params: {
      search,
      limit = LIMIT_OPTIONS[0].value,
      offset = 0,
      name,
      priority
    }
  } = useQueryString(locationSearch)
  
  const paginationProvider = usePaginationProvider({
    source: locationSource,
    alias: "offset",
    scope: "",
    fallback: 0
  });
  
  const limitProvider = usePaginationProvider({
    source: locationSource,
    alias: "limit",
    scope: "",
    fallback: 15,
    onApplyClearScope: ["offset"]
  });
  
  const searchProvider = useSearchProvider({
    source: locationSource,
    scope: "",
    alias: 'search',
    onApplyClearScope: [""]
  });
  
  const [debouncedSearch] = useDebounce(search, DEFAULT_DEBOUNCE_DELAY, () => paginationProvider.setValue(0));
  
  const fetchTable = useCallback(
    () => {
      const sortObject = parseSorting({ name, priority });
      const params = {
        limit,
        offset,
        query: debouncedSearch,
        ...sortObject
      };
      return registerTablePromise(
        userSegmentsService
          .getUserSegmentsList(id, params)
          .then(({ data, pagination }) => {
            setUserSegments(data);
            setResultsPagination(pagination);
          })
      );
    },
    [
      id,
      offset,
      limit,
      name,
      priority,
      debouncedSearch
    ],
  )
  
  const generateSortProviderParams = (name) => {
    return {
      source: locationSource,
      alias: name,
      scope: "",
      onApplyClearScope: ["name", "priority"].filter(paramName => paramName !== name)
    }
  }
  
  const nameSortProvider = useSortProvider(generateSortProviderParams("name"));
  const prioritySortProvider = useSortProvider(generateSortProviderParams("priority"));
  
  const toggleCollapse = (id) => {
    if (collapsedRows.includes(id)) {
      updateCollapsedRows(prevState => prevState.filter(item => item !== id))
      return
    }
    updateCollapsedRows(prevState => [...prevState, id]);
  }
  
  useEffect(() => {
    fetchTable()
  }, [fetchTable]);
  
  return (
    <>
      <Table
        columns={columns}
        data={userSegments}
        loading={isTableLoading}
        HeaderComponent={TableHeader}
        totalCount={resultsPagination.totalCount}
    
        searchProvider={searchProvider}
        sortProviders={{
          ['name']: nameSortProvider,
          ['priority']: prioritySortProvider,
        }}
        actions={{}}
        limit={limitProvider.getValue()}
        limitProvider={limitProvider}
        offset={offset}
        paginationProvider={paginationProvider}
        commonPlaceholder="No matching User Segments"
        placeholderForSearch={<SearchPlaceholder/>}
        withLocation
        collapsedState={collapsedRows}
        toggleCollapse={toggleCollapse}
        isLimitEditable={true}
        limitOptions={LIMIT_OPTIONS}
      />
    </>
  )
}