// Libs
import React, { useEffect, useMemo } from "react";
import { useField } from "formik";


// Components, Views, Screens
import Table from "../../../../../../../../../../base/components/Table";

// Hooks, Utils, Helpers
import { columnGenerationFunctionsByExerciseType, setInitialValues } from "./form";


export function ExerciseSetsTable({ name, exerciseType }) {
    const [{ value = []}, meta, { setValue }] = useField(name)

    const addSet = () => {
        setValue([...value, setInitialValues])
    }

    const handleDeleteSet = (index) => {
        setValue(value.filter((_, i) => i !== index));
    };

    const columns = useMemo(() =>
        columnGenerationFunctionsByExerciseType[exerciseType](name),
        [name, exerciseType])

    const dataColumns = useMemo(() => value.map(() => ({})), [value.length])

    useEffect(() => {
        if (!value.length) {
            addSet()
        }
    }, [value]);

    return (
        <div className="ps-2 mt-3">
            <Table
                columns={columns}
                data={dataColumns}
                withoutPagination
                actions={{
                    delete: handleDeleteSet
                }}
                tableClassName="h-auto min-h-auto overflow-x-visible"
                key={value.length}
            />

            <div
                className="text-primary ms-3 cursor-pointer w-fit-content"
                onClick={addSet}
            >
                + Add set
            </div>
        </div>
    );
}