
import yup from "../../../../../../validation/yup";
import {
    MIN_STRING_LENGTH
} from "../../../../HealthProgram/Nutrition/CreateEditProgram/constants";
import { MAX_FULL_NAME, NAME_PATTERN } from "./constants";


export const initialValues = {
    name: '',
};

export const validationSchema = yup.object().shape({
    name: yup
        .string()
        .trim()
        .min(MIN_STRING_LENGTH)
        .max(MAX_FULL_NAME)
        .matches(NAME_PATTERN, "Invalid name")
        .required("Is required"),
});

