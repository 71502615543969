import Http from "./Http";

export const DEFAULT_LIMIT_AND_OFFSET = { limit: 100, offset: 0 }

class BiomarkersService extends Http {
  static $displayName = "BiomarkersService";

  async getCategories(params = DEFAULT_LIMIT_AND_OFFSET) {
    return await this.get("/biomarkers/categories", { params });
  }

  async getUnits(params = DEFAULT_LIMIT_AND_OFFSET) {
    return await this.get("/biomarkers/units", { params });
  }

  async getRecommendations(params) {
    return await this.get("/biomarkers/recommendations", { params: { ...DEFAULT_LIMIT_AND_OFFSET, ...params } });
  }

  async getRules(params = DEFAULT_LIMIT_AND_OFFSET) {
    return await this.get("/biomarkers/rules", { params });
  }

  async getAllFilters() {
    return await this.get("/biomarkers/filters/characteristics");
  }

  async createBloodBiomarker(type, values) {
    return await this.post(`/biomarkers/${type}`, values);
  }

  async editBiomarker(type, id, values) {
    return await this.put(`/biomarkers/${type}/${id}`, values);
  }

  async deleteRuleById(id) {
    return await this.delete(`/biomarkers/rules/${id}`);
  }

  async getBiomarkers(params) {
    return await this.get(`/biomarkers`, { params });
  }

  async getBiomarkerById(id) {
    return await this.get(`/biomarkers/${id}`);
  }

  async deleteBiomarker(id) {
    return await this.delete(`/biomarkers/${id}`);
  }

  async activateBiomarker(id, data) {
    return await this.patch(`/biomarkers/${id}`, data);
  }

  async getRuleById(id) {
    return await this.get(`/biomarkers/rules/${id}`);
  }
}

export default BiomarkersService;
