import React from "react";
import yup from "../../../../validation/yup";

import {
    ACTIVITY_LEVEL,
    ACTIVITY_LEVEL_LABEL,
    MEALPLAN_GOALS,
    MEALPLAN_GOALS_LABELS,
    TRAINING_INTENSITY,
    TRAINING_INTENSITY_LABELS
} from "../constants";

import { Badge } from "reactstrap";
import Icon from "../../../../base/components/Icon";
import { SEX, SEX_LABELS } from "../../HealthProgram/Nutrition/RulesGoalsList/GoalsList/CreateEditGoal/constants";
import {
    COOKING_PREFERENCE,
    COOKING_PREFERENCE_LABEL,
    MAX_AGE, MAX_CALORIES,
    MAX_HEIGHT, MAX_PREPTIME,
    MAX_WEIGHT,
    MIN_AGE, MIN_CALORIES,
    MIN_HEIGHT, MIN_PREPTIME,
    MIN_WEIGHT, PREP_TIME_INPUT_NAMES
} from "./constants";

export const initialValues = {
    goal: null,
    userSegments: [],
    allergens: [],
    activityLevel: null,
    mealType: []
};

export const validationSchema = yup.object().shape({
    goal: yup.string().required(),
    userSegments: yup.array().min(1),
    activityLevel: yup.string().required(),
    gender: yup.string().required(),
    age: yup.number()
        .required()
        .min(MIN_AGE)
        .max(MAX_AGE),
    weight: yup.number()
        .required()
        .min(MIN_WEIGHT)
        .max(MAX_WEIGHT),
    height: yup.number()
        .required()
        .min(MIN_HEIGHT)
        .max(MAX_HEIGHT),
    mealType: yup.array().min(1, '{label} field must have at least 1 items'),
    cookingPreference: yup.string().required(),
    carbs: yup.number().when('program', {
        is: (program) => {
            return program !== null && program !== undefined
        },
        then: yup.number().required().min(0).max(100),
        otherwise: yup.number().nullable()
    }),
    protein: yup.number().when('program', {
        is: (program) => program !== null && program !== undefined,
        then: yup.number().required().min(0).max(100),
        otherwise: yup.number().nullable()
    }),
    fat: yup.number().when('program', {
        is: (program) => program !== null && program !== undefined,
        then: yup.number().required().min(0).max(100),
        otherwise: yup.number().nullable()
    }),
    calories: yup
        .number()
        .required()
        .min(MIN_CALORIES),
    ...Object.keys(PREP_TIME_INPUT_NAMES).reduce((acc, key) => {
        acc[PREP_TIME_INPUT_NAMES[key]] = yup.number().when(['program', 'mealType'], {
            is: (program, mealTypes) => {
                return program !== null && program !== undefined && mealTypes.includes(key);
            },
            then: yup.number().min(MIN_PREPTIME).max(MAX_PREPTIME).required(),
            otherwise: yup.number().nullable()
        });
        return acc;
    }, {}),
    totalSum: yup.mixed().test(
        'macro-sum',
        'The sum of Carbs, Protein, and Fat must be 100%',
        function () {
            const { carbs, protein, fat, program } = this.parent;
            if (program || program === 0) {
                return (carbs + protein + fat) === 100;
            }
            return true;
        }
    )
})

export const mealplanGoalOption = Object.values(MEALPLAN_GOALS).map((value) => ({
    id: value,
    value,
    label: MEALPLAN_GOALS_LABELS[value]
}));

export const trainingIntensityOption = Object.values(TRAINING_INTENSITY).map((value) => ({
    id: value,
    value,
    label: TRAINING_INTENSITY_LABELS[value]
}));

export const sexOptions = Object.values(SEX).map((key) =>
    ({ label: SEX_LABELS[key], value: key, id: key }));


export const activityLevelOption = Object.values(ACTIVITY_LEVEL).map((value) => ({
    id: value,
    value,
    label: ACTIVITY_LEVEL_LABEL[value]
}));

export const cookingPreferenceOption = Object.values(COOKING_PREFERENCE).map((value) => ({
    id: value,
    value,
    label: COOKING_PREFERENCE_LABEL[value]
}));

export const MultiSelectValueItemView = ({ value, onDeleteItem, labels }) => {
    return (
        <div className="d-flex align-items-center overflow-x-auto hide-scrollbar">
            {
                value.map(item => <Badge
                    key={item}
                    color="light"
                    className="d-flex align-items-center gap-2 me-3 font-size-11 font-weight-normal"
                >
                    {labels ? labels[item] : item}
                    <div onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        onDeleteItem(item)
                    }}>
                        <Icon
                            icon={'close'}
                            width={8}
                            height={8}
                        />
                    </div>
                </Badge>)
            }
        </div>
    );
};

export const matchDataFieldNames = [
    'goal',
    'allergens',
    'userSegments',
    'activityLevel',
    'intensityLevel',
    'finalWeightGoal',
    'age',
    'gender',
    'height',
    'weight',
    'mealType'
];

export const executeFieldNames = [
    'program',
    'calories',
    'protein',
    'carbs',
    'fat',
    'cookingPreference',
    'breakfastTime',
    'snackTime',
    'lunchTime',
    'dinnerTime'
];

export const hasMatchDataError = (errors) => {
    return matchDataFieldNames.some((field) => errors[field]);
};
