import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../../../../../base/components/Button";
import TableSearch from "../../../../../base/components/Table/tableSearch";

import { useLocationQuery } from "../../../../../base/hooks/useQueryString";
import { useHighlight } from "../../../../../base/hooks/useHighlight";
import { BUTTON_COLORS } from "../../../../../base/components/Button/appearance";
import { FITNESS_GROUP_LINKS } from "../../config";
import { MIN_SEARCH_LENGTH } from "../../../../../base/constants/pagination";
import { EXERCISE_STATUSES_LABELS } from "../../ExerciseForm/constants";
import { map } from "lodash";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import Checkbox from "../../../../../base/components/Checkbox";
import { useService } from "../../../../../base/hooks/useService";
import ExercisesService from "../../../../../services/ExercisesService";
import { exerciseStatusesOption } from "../../ExerciseForm/form";
import Icon from "../../../../../base/components/Icon";

export const EXERCISE_ACTIONS = {
    DELETE: 'delete',
    EDIT: 'edit'
};

export const SearchPlaceholder = () => (
    <>
        No matching exercise.
    </>
);

const StatusLabel = ({ status }) => {
    return <div className={`status-tag-${EXERCISE_STATUSES_LABELS[status]}`}>{EXERCISE_STATUSES_LABELS[status]}</div>;
};

const getArray = (itemOrArray) => {
    if (!itemOrArray) return [];
    return typeof itemOrArray === "object" ? itemOrArray : [itemOrArray]
}


export const TableFilter = ({ filterProvider, equipmentsOptions = [] }) => {
    const { status, equipment } = filterProvider;

    const [isOpen, updateIsOpen] = useState(false);
    const [categories, updateCategories] = useState(getArray(equipment.getValue()));
    const [statusFilter, updateStatusFilter] = useState(getArray(status.getValue()));

    const isSelectAllEquipment = equipmentsOptions.length === categories.length

    const submitFilters = () => {
        equipment.setValue(categories);
        status.setValue(statusFilter);
        updateIsOpen(false);
    }

    const handleCheckboxChange = (id, setState) => {
        setState(prevState => {
            return prevState.includes(id) ? prevState.filter(item => item !== id) : [...prevState, id];
        });
    }

    const handleClickSelectAll = () => {
        if (isSelectAllEquipment) {
            updateCategories([])
        } else {
            updateCategories(equipmentsOptions.map(({id}) => id))
        }
    }

    return (
        <section className="d-flex align-items-center">
            <Dropdown
                isOpen={isOpen}
                toggle={() => updateIsOpen(prevState => !prevState)}
                className="d-inline-block"
            >
                <DropdownToggle
                    className=" btn btn-outline-primary d-flex align-items-center"
                    tag="button"
                >
                    <i className="bx bx-filter-alt me-2"/> Add filter
                </DropdownToggle>

                <DropdownMenu className="filter-menu p-2 pt-3 rec-filter">

                    <section className="d-flex justify-content-between">
                        <section className="filter-options mx-1 me-4 custom-scrollbar rec-category-filter">
                            <label className="text-secondary font-size-10 mb-1 font-weight-normal">BY EQUIPMENT</label>
                            <Checkbox
                                text={"All"}
                                value={isSelectAllEquipment}
                                onChange={handleClickSelectAll}
                                className="my-2 ms-1"
                            />

                            {equipmentsOptions.map((equipment, index) => {
                                return (
                                    <Checkbox
                                        id={equipment.id}
                                        text={equipment.label}
                                        value={categories?.includes(equipment.id)}
                                        onChange={() => {
                                            handleCheckboxChange(equipment.id, updateCategories)
                                        }}
                                        key={index}
                                        className="my-2 ms-1"
                                    />
                                )
                            })}
                        </section>

                        <section className="filter-options mx-1 custom-scrollbar rec-activity-filter">
                            <label className="text-secondary font-size-10 mb-1 font-weight-normal">BY STATUS</label>
                            {exerciseStatusesOption.map((activity, index) => {
                                return (
                                    <Checkbox
                                        id={activity.label}
                                        text={activity.label}
                                        value={statusFilter?.includes(activity.id)}
                                        onChange={() => handleCheckboxChange(activity.id, updateStatusFilter)}
                                        key={index}
                                        className="my-2 ms-1"
                                    />
                                )
                            })}
                        </section>
                    </section>

                    <section className="w-100 px-2 pb-2 mt-3">
                        <Button color={BUTTON_COLORS.primary} onClick={submitFilters} className="w-100">
                            Apply
                        </Button>
                    </section>

                </DropdownMenu>
            </Dropdown>

            {(!!status.getValue() || !!equipment.getValue()) &&
                <Button
                    color={BUTTON_COLORS.transparent}
                    onClick={() => {
                        updateCategories([]);
                        updateStatusFilter([])
                        status.setValue();
                        equipment.setValue();
                    }}
                    className="text-danger no-border ms-2"
                >
                    Clear filter
                </Button>
            }
        </section>
    )
}

export const NoExercisePlaceholder = () => (
    <div className="text-center">
        No exercises for now.<br/>
        Click the “Create exercise” option to add a new one.
    </div>
);
export const TableHeader = ({ searchProvider, filterProvider }) => {
    const [equipments, setEquipments] = useState([])
    const navigate = useNavigate();
    const goToCreate = () => {
        navigate(FITNESS_GROUP_LINKS.FORM_EXERCISES);
    };

    const exerciseService = useService(ExercisesService)

    const mapEquipmentsToDropdown = (data = []) => {
        return data.map((item) => ({ id: item.id, label: item.name }));
    };

    const getEquipments = () => {
        exerciseService.getEquipments().then((result) =>
            setEquipments(mapEquipmentsToDropdown(result.data))
        )
    }

    useEffect(() => {
        getEquipments()
    }, []);

    return (
        <section className="d-flex w-100 pt-1 my-3 align-items-center justify-content-between">

            <div className="d-flex align-items-center gap-4">
                <TableSearch
                    className="biomarkers-search"
                    search={searchProvider?.getValue()}
                    onSearch={searchProvider.setValue}
                    placeholder="Search exercises..."
                />

                <TableFilter filterProvider={filterProvider} equipmentsOptions={equipments}/>
            </div>


            <Button color={BUTTON_COLORS.primary} onClick={goToCreate} className="d-flex align-items-center">
                <i className="bx bx-plus me-2"/>
                Create exercise
            </Button>
        </section>
    );
};

export const columns = [
    {
        Header: "#",
        width: 40,
        className: "bg-white",
        Cell: (cellProps) => {
            const { params: { offset = 0 } } = useLocationQuery();
            return <label style={{width: "40px"}} className="mb-0 text-nowrap">{cellProps.row.index + 1 + (offset ?? 0)}</label>;
        }
    },
    {
        Header: "Name",
        accessor: "title",
        className: "bg-white",
        canSort: true,
        width: 300,
        Cell: ({ value }) => {
            const { params } = useLocationQuery();
            const searchRequest = useMemo(() => params.search && params.search.toString()?.trim()?.length >= MIN_SEARCH_LENGTH ? params.search : null, [params.search])
            const { decorateText } = useHighlight(searchRequest);

            return (
                <section style={{width: "300px", overflow: "hidden"}}>
                    <div {...decorateText(value)} className="text-truncate mb-0"/>
                </section>
            );
        }
    },

    {
        Header: "Equipments",
        accessor: "equipment",
        className: "bg-white",
        canSort: true,
        width: 600,
        Cell: ({ value }) => {
            const { params } = useLocationQuery();
            const searchRequest = useMemo(() => params.search && params.search.toString()?.trim()?.length >= MIN_SEARCH_LENGTH ? params.search : null, [params.search])
            const { decorateText } = useHighlight(searchRequest);

            return (
                <section style={{width: '600px', overflow: 'hidden'}} className="text-truncate">
                    <div {...decorateText(map(value).join(', ') || "-")} className="text-truncate mb-0"/>
                </section>
            );
        }
    },

    {
        Header: "Status",
        accessor: "status",
        className: "bg-white",
        canSort: true,
        width: 100,
        Cell: ({ value }) => {
            return (
                <section style={{width: "100px", overflow: "hidden"}}>
                    <StatusLabel status={value} />
                </section>
            );
        }
    },

    {
        Header: "Actions",
        className: "bg-white",
        width: 100,
        Cell: ({
                   row: {
                       original: { id }
                   },
                   actions
               }) => {

            return <section
                className="d-flex gap-1 align-items-center"
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >

                <Icon icon="edit"
                      width={20}
                      height={20}
                      className="me-4 cursor-pointer impacts-section__view"
                      onClick={(event) => {
                          event.stopPropagation();
                          actions[EXERCISE_ACTIONS.EDIT](id);
                      }}
                />

                <Icon
                    icon={"trashIcon"}
                    width={20}
                    height={20}
                    onClick={(event) => {
                        event.stopPropagation();
                        actions[EXERCISE_ACTIONS.DELETE](id);
                    }}
                    className="me-4 cursor-pointer"
                />

            </section>;
        }
    }
];