import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import Table from "../../../../../../base/components/Table";
import { DEFAULT_TABLE_LIMIT } from "../../../../../../base/constants/shared";
import {
    usePaginationProvider,
    useLocationSource, useSearchProvider, useFilterProvider, useDateRangeProvider, useSortProvider
} from "../../../../../../base/components/Table/hooks";
import { SearchPlaceholder, columns, NoProgramPlaceholder } from "./components";
import ToasterService from "../../../../../../services/ToastService";
import { useService } from "../../../../../../base/hooks/useService";
import ProgramsService from "../../../../../../services/ProgramsService";
import { useLoading } from "../../../../../../base/hooks/useLoading";
import { useQueryString } from "../../../../../../base/hooks/useQueryString";
import ConfirmPopup from "../../../../../../base/components/ConfirmPopup";
import { PROGRAM_STATUSES } from "../constants";
import { PROGRAMS_ACTIONS } from "../ProgramsList/components";
import { PROGRAMS_GROUP_LINKS } from "../../config";
import {TableHeader} from "../ProgramsList/components";

export default function PredefinedList() {
    /**
     * @type {ProgramsService}
     */
    const programsService = useService(ProgramsService);
    /**
     * @type {ToasterService}
     */
    const toastService = useService(ToasterService);

    const [isLoading, { registerPromise }] = useLoading(true);
    const { search: locationSearch, pathname } = useLocation();
    const {
        params: {
            limit = DEFAULT_TABLE_LIMIT,
            offset = 0,
            search,
            fullName,
            author,
            status,
            createdAt,
            statusFilter,
            fromDate,
            toDate
        }
    } = useQueryString(locationSearch);

    const [programs, updatePrograms] = useState([]);
    const [programsPagination, updateProgramsPagination] = useState({});
    const [showDeletePopup, updateShowDeletePopup] = useState(null);

    const locationSource = useLocationSource();
    const navigate = useNavigate();

    const generateFilterProvidersParams = (names = []) => {
        return {
            source: locationSource,
            alias: names,
            scope: "",
            onApplyClearScope: ["offset"]
        };
    };

    const dateRangeProviders = {
        createdAt: useDateRangeProvider(generateFilterProvidersParams(["fromDate", "toDate"]))
    };

    const paginationProvider = usePaginationProvider({
        source: locationSource,
        alias: "offset",
        scope: "",
        fallback: 0
    });

    const searchProvider = useSearchProvider({
        source: locationSource,
        scope: "",
        alias: 'search',
        onApplyClearScope: ["offset"]
    });

    const filterProvider = useFilterProvider({
        source: locationSource,
        scope: "",
        alias: 'statusFilter',
        onApplyClearScope: ["offset"]
    });

    const sortKeys = ["fullName", "author", "createdAt", "status"];
    const getSortScope = (key) => [...sortKeys.filter(item => item !== key), "offset"];

    const nameSortProvider = useSortProvider({
        source: locationSource,
        alias: "fullName",
        scope: "",
        onApplyClearScope: getSortScope("fullName")
    });

    const authorSortProvider = useSortProvider({
        source: locationSource,
        alias: "author",
        scope: "",
        onApplyClearScope: getSortScope("author")
    });

    const createdAtSortProvider = useSortProvider({
        source: locationSource,
        alias: "createdAt",
        scope: "",
        onApplyClearScope: getSortScope("createdAt")
    });

    const statusSortProvider = useSortProvider({
        source: locationSource,
        alias: "status",
        scope: "",
        onApplyClearScope: getSortScope("status")
    });

    const getPrograms = useCallback(() => {
        const [orderBy, orderType] = Object.entries({
            fullName,
            author,
            status,
            createdAt
        }).find(([_, value]) => value) || [];

        registerPromise(programsService.getPrograms({
            limit,
            offset,
            orderBy,
            orderType,
            query: search,
            fromDate,
            toDate,
            status: statusFilter,
            isPredefined: true,
        })
            .then(({ data, pagination }) => {
                updatePrograms(data);
                updateProgramsPagination(pagination);
            }));
    }, [limit, offset, fullName, author, status, createdAt, search, statusFilter, fromDate, toDate]);

    const handleClickDeleteProgram = (program) => {
      updateShowDeletePopup(program)
    }

    const deleteProgram = (program) => {
      registerPromise(programsService.deleteProgram(program.id))
          .then(() => {
              getPrograms()
              toastService.success("The program has been successfully deleted")
          })
    }

    const changeStatus = (newStatus = PROGRAM_STATUSES.ACTIVE) => (id) => {
        registerPromise(programsService.changeStatus(id, newStatus))
            .then(() => {
                getPrograms()
                toastService.success("The program status has been successfully updated")
            });
    }

    const goToCopy = (program) => {
        programsService.copyProgram(program.id)
            .then((data) => {
                const queryParams = new URLSearchParams({ editProgramId: data.id }).toString();
                navigate(`${PROGRAMS_GROUP_LINKS.CREATE_EDIT}?${queryParams}`)
            })
    };

    useEffect(() => {
        getPrograms();
    }, [getPrograms]);

    return (
        <>
          <Table
            columns={programs.length ? columns : []}
            data={programs}
            loading={isLoading}
            HeaderComponent={TableHeader}
            totalCount={programsPagination.totalCount}
            limit={DEFAULT_TABLE_LIMIT}
            offset={offset}
            paginationProvider={paginationProvider}
            filterProvider={filterProvider}
            dateRangeProviders={dateRangeProviders}
            searchProvider={searchProvider}
            commonPlaceholder={<NoProgramPlaceholder/>}
            placeholderForSearch={<SearchPlaceholder/>}
            isRowClick
            actions={{
                [PROGRAMS_ACTIONS.COPY]: goToCopy,
                [PROGRAMS_ACTIONS.ACTIVATE]: changeStatus(PROGRAM_STATUSES.ACTIVE),
                [PROGRAMS_ACTIONS.DEACTIVATE]: changeStatus(PROGRAM_STATUSES.INACTIVE),
            }}
            sortProviders={{
                fullName: nameSortProvider,
                author: authorSortProvider,
                status: statusSortProvider,
                createdAt: createdAtSortProvider
            }}
        />
          {showDeletePopup &&
              <ConfirmPopup
                  isOpen={showDeletePopup}
                  updateIsOpen={updateShowDeletePopup}
                  onSubmit={() => {
                    deleteProgram(showDeletePopup)
                    updateShowDeletePopup(null)
                  }}
                  title="Delete program"
                  description="Are you sure you want to delete the chosen program?"
                  submitBtnText="Delete"
                  className="upload-manually__popup"
              />
          }
          </>
    );
}
