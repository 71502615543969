import React, { useState } from "react";
import Wrapper from "../Wrapper";
import { ReactComponent as LikeIcon } from "./assets/like.svg";

const Reaction = ({ blok }) => {
    if (!blok) {
        return null;
    }

    const [ isLike, setIsLike ] = useState(false)
    const [ isDislike, setIsDislike ] = useState(false)

    const toggleLike = () => setIsLike(!isLike)
    const toggleDislike = () => setIsDislike(!isDislike)

    const buttonStyle = {
        border: 0,
        background: "transparent"
    }

    const styleLike = {
        ...buttonStyle,
        color: isLike ? "black" : "gray",
    }

    const styleDislike = {
        ...buttonStyle,
        color: isDislike ? "black" : "gray",
        transform: "rotate(180deg)"
    }

    return (
        <Wrapper blok={{ ...blok, gap: "20px", width: "content-width" }}>
            <button style={styleLike} onClick={toggleLike}><LikeIcon/></button>
            <button style={styleDislike} onClick={toggleDislike}><LikeIcon/></button>
        </Wrapper>
    );
};

export default Reaction;
