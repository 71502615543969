import React, { useMemo } from "react";

import Icon from "../../../../../base/components/Icon";
import Switch from "../../../../../base/components/Switch";

import { useLocationQuery } from "../../../../../base/hooks/useQueryString";
import { useHighlight } from "../../../../../base/hooks/useHighlight";
import { MIN_SEARCH_LENGTH } from "../../../../../base/constants/pagination";
import { HOMEPAGE_SECTIONS_TYPE_LABELS } from "../../constants";

export const HOMEPAGE_SECTIONS_ACTIONS = {
  DELETE: 'delete',
  EDIT: 'edit',
  CHANGE_STATUS: 'changeStatus'
};

export const SearchPlaceholder = () => (
  <>
    No matching homepage.
  </>
);

export const NoHomepageSectionPlaceholder = () => (
  <div className="text-center">
    No workout types for now.<br/>
    Click the “Create workout type” option to add a new one.
  </div>
);
export const columns = [
  {
    Header: "#",
    width: 40,
    className: "bg-white",
    Cell: (cellProps) => {
      const { params: { offset = 0 } } = useLocationQuery();
      return <label
        style={{ width: "40px" }}
        className="mb-0 text-nowrap"
      >
        {cellProps.row.index + 1 + (offset ?? 0)}
      </label>;
    }
  },
  {
    Header: "Title",
    accessor: "title",
    className: "bg-white",
    canSort: true,
    width: 100,
    Cell: ({ value }) => {
      const { params } = useLocationQuery();
      const searchRequest = useMemo(
        () => params.search && params.search.toString()?.trim()?.length >= MIN_SEARCH_LENGTH ? params.search : null,
        [params.search]);
      const { decorateText } = useHighlight(searchRequest);

      return (
        <section style={{ width: "100px", overflow: "hidden" }}>
          <div {...decorateText(value)} className="text-truncate mb-0"/>
        </section>
      );
    }
  },

  {
    Header: "Type",
    accessor: "type",
    className: "bg-white",
    canSort: true,
    width: 100,
    Cell: ({ value }) => {
      return (
        <section style={{ width: "100px", overflow: "hidden" }}>
          {HOMEPAGE_SECTIONS_TYPE_LABELS[value]}
        </section>
      );
    }
  },

  {
    Header: "Is active",
    accessor: "isActive",
    className: "bg-white",
    canSort: true,
    width: 100,
    Cell: ({ value, actions, row: { original } }) => {
      return (
        <section style={{ width: "100px", overflow: "hidden" }}>
          <Switch
            state={value}
            updateState={() => actions[HOMEPAGE_SECTIONS_ACTIONS.CHANGE_STATUS](original)}
          />
        </section>
      );
    }
  },

  {
    Header: "Actions",
    className: "bg-white",
    width: 100,
    Cell: ({
      row: {
        original: { id, isPredefined }
      },
      actions,
      provided
    }) => {

      return <section
        className="d-flex gap-1 align-items-center justify-content-end"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {
          isPredefined
            ? <section className="attribute__na-icon">N/A</section>
            : <>
              <Icon
                icon="edit"
                width={20}
                height={20}
                className="me-3 cursor-pointer impacts-section__view"
                onClick={(event) => {
                  event.stopPropagation();
                  actions[HOMEPAGE_SECTIONS_ACTIONS.EDIT](id);
                }}
              />

              <Icon
                icon={"trashIcon"}
                width={20}
                height={20}
                onClick={(event) => {
                  event.stopPropagation();
                  actions[HOMEPAGE_SECTIONS_ACTIONS.DELETE](id);
                }}
                className="cursor-pointer"
              />
            </>
        }
        <div
          {...provided.dragHandleProps}
        >
          <Icon
            icon="dndIcon"
            width={24}
            height={24}
            className="ms-3 cursor-pointer impacts-section__view"
          />
        </div>
      </section>;
    }
  }
];