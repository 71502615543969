export const ERROR_TYPES = {
  eurofinSampleReceivedNotFound: 1,
  eurofinResultReceivedNotFound: 2,
  eurofinCsvSampleNotFound: 3,
  clockFoundationRawUploadError: 4,
  clockFoundationCsvUploadError: 5,
  s3RawUploadError: 6,
  s3CsvUploadError: 7,
  processClockFoundationDNAmResultsSampleNotFound: 8,
  processAdminDNAmResultsSampleNotFound: 9,
  processClockFoundationDNAmResultsBiomarkerValueNotFound: 10,
  processAdminDNAmResultsBiomarkerValueNotFound: 11,
  kitObjectInvalidStatus: 12,
  kitObjectAlreadyProcessed: 13,
  foodIntoleranceFileNotFound: 14,
  foodIntoleranceFileWrongFormat: 15,
  foodIntoleranceCsvCountInconsistency: 16,
  foodIntoleranceCsvFoodGroupNotFound: 17,
  foodIntoleranceCsvFoodNotFound: 18,
  foodIntoleranceCsvResultInterpretationMismatch: 19,
  foodIntoleranceCustomError: 20,
  invalidSampleId: 21,
  hl7ResultFileFragmentIsNaN: 22
};