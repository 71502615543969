import React from "react";
import Icon from "../../Icon";
import SectionCarousel from "./SectionCarousel/SectionCarousel";

export const Section = ({ section }) => {

    return <div>
        <div className="d-flex justify-content-between align-items-center mb-2">
            <span className="font-size-13">{section.title}</span>
            <Icon icon="arrowDown" className="bx-rotate-270" />
        </div>

        <div>
            <SectionCarousel
                type={section.type}
                quantity={section.itemsQuantity}
                quantityPerLine={section.quantityPerLine}
                items={section.allWorkouts || section.recommendedPrograms || section.workouts || section.fitnessPrograms}
            />
        </div>
    </div>
}