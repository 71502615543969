import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CustomModal, ModalBody, ModalHeader } from "../../../../base/components/CustomModal";
import BiomarkerRange from "../../../../base/components/BiomarkerRange";
import { useLoading } from "../../../../base/hooks/useLoading";
import { useService } from "../../../../base/hooks/useService";
import { useDebounce } from "../../../../base/hooks/useDebounce";
import UserResultsService from "../../../../services/UserResults";
import { DEFAULT_DEBOUNCE_DELAY } from "../../../../base/constants/shared";
import Table from "../../../../base/components/Table";
import TableSearch from "../../../../base/components/Table/tableSearch";
import { useLocation } from "react-router-dom";
import { useHighlight } from "../../../../base/hooks/useHighlight";
import { useQueryString } from "../../../../base/hooks/useQueryString";
import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import { useLocationSource, usePaginationProvider, useSearchProvider, useSortProvider, parseSorting } from "../../../../base/components/Table/hooks";
import ValueWithLabel from "../../Biomarkers/Details/ValueWIthLabel";
import { formatISODate } from "../../../../base/helpers/date";
import { powerFormat } from '../../../../base/hooks/usePowerUnitFormat';
import Icon from '../../../../base/components/Icon';
import joinClassNames from '../../../../base/helpers/joinClassNames';
import { CUSTOMER_RESULTS_CATEGORIES } from "../constants";
import RoboGPService from "../../../../services/RoboGPService";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";

const BIOMARKER_RANGE_LABELS = [
  "",
  {label: "Critical low", color: 'biomarker-range-red-text', icon: 'biomarkerRedAlert' },
  {label: "Low", color: 'biomarker-range-red-text', icon: 'biomarkerRedAlert' },
  {label: "Sub optimal", color: 'biomarker-range-yellow-text', icon: 'biomarkerYellowWarning' },
  {label: "Optimal", color: 'biomarker-range-green-text', icon: 'biomarkerGreenStatus' },
  {label: "Supra optimal", color: 'biomarker-range-yellow-text', icon: 'biomarkerYellowWarning' },
  {label: "High", color: 'biomarker-range-red-text', icon: 'biomarkerRedAlert' },
  {label: "Critical high", color: 'biomarker-range-red-text', icon: 'biomarkerRedAlert' },
]

const columns = [
  {
    Header: "#",
    width: 45,
    className: 'text-center align-middle',
    Cell: (cellProps) => {
      const { params: { offset = 0 } } = useLocationQuery();
      return <label className="mb-0">{cellProps.row.index + 1 + (offset ?? 0)}</label>;
    },
  },
  {
    Header: "Biomarker name",
    accessor: "biomarker.name",
    canSort: true,
    width: 250,
    Cell: ({ value, row: { original }}) => {
      const { params } = useLocationQuery();
      const { decorateText } = useHighlight(params.rangeSearch);
      const props = BIOMARKER_RANGE_LABELS[original.recommendationRange]
      return (
        <div className="w-100 text-truncate d-flex">
          <div className='d-flex align-items-center'>
            <Icon className={'me-2'} icon={props.icon} />
          </div>
          <div>
            <p {...decorateText(value)} className='biomarker-range-title mb-0' />
            <p className={`f-10px mb-0 ${props.color}`}>{props.label}</p>
          </div>
        </div>
      )
    }
  },
  {
    Header: "Range",
    accessor: "recommendationRange",
    canSort: true,
    width: 400,
    Cell: ({
      row: {
        original
      },
    }) => {
      return (
        <div className='mt-1'>
          <BiomarkerRange
            chosenResult={{ ...original, unit: original.unit }}
            isResultScreen={true}
            textColor={'red'}
            filter={original.filter || original.biomarker.filters[0]}
          />
        </div>
      )
    }
  },
  {
    Header: "Result",
    accessor: "value",
    headerClassName: "text-end",
    className: "text-end",
    width: 120,
    Cell: ({ value }) => {
      return (
        <div className="w-100 text-truncate">{value}</div>
      )
    }
  },
  {
    Header: "Unit",
    accessor: "unit.unit",
    width: 125,
    Cell: ({ value }) => {
      return (
        <div className="w-100 text-truncate">{powerFormat( value )}</div>
      )
    }
  },
];

export default function ResultsPopupBlood({
  updateIsOpen,
  isOpen,
  row,
}) {
  /**
   * @type {UserResultsService}
   */
  const userResultsService = useService(UserResultsService);
  const roboGPService = useService(RoboGPService);
  const locationSource = useLocationSource();
  const { search: locationSearch } = useLocation();

  const {
    params: {
      rangeSearch,
      marker,
      range
    }
  } = useQueryString(locationSearch)

  const [isLoading, { registerPromise }] = useLoading(true);
  const [userResults, updateUserResults] = useState([]);
  const [userResultsPagination, updateUserResultsPagination] = useState({ totalCount: 0 });
  const [roboGPReport, updateRoboGPReport] = useState({});
  const hasPdf = useMemo(() => row?.pdfResultFile?.link, [row])

  const generateSortProviderParams = (name) => {
    const allParams = [
      "marker",
      "range"
    ];
    return {
      source: locationSource,
      alias: name,
      scope: "",
      onApplyClearScope: allParams.filter(paramName => paramName !== name)
    }
  }

  const filterByQuery = useCallback((i) => {
    return i.biomarker.name.toLowerCase().startsWith(rangeSearch?.toLowerCase() || '');
  }, [rangeSearch])

  const { id, firstName, lastName, testProductName, labReceivedAt, category } = row;

  const onClose = () => updateIsOpen(false);

  const markerSortProvider = useSortProvider(generateSortProviderParams("marker"));
  const valSortProvider = useSortProvider(generateSortProviderParams("range"));

  const getResultsList = useCallback(() => {
    const sortObject = parseSorting({
        marker,
        range
      });

    registerPromise(
      userResultsService.getResultsList(id, { ...sortObject })
        .then(({ data, pagination }) => {
          updateUserResults(data.map(i => ({ ...i, category })));
          updateUserResultsPagination(pagination);
          const ROBOGP_STATUS_ACCEPTED = 5;
          if (row.roboGPReportId && row.roboGPReportStatus === ROBOGP_STATUS_ACCEPTED) {
            roboGPService.getReportItem(row.roboGPReportId, { userId: row.userId }).then((report) => {
              updateRoboGPReport(report);
            })
          }
        })
    )
  }, [id, marker, range]);

  const paginationProvider = usePaginationProvider({
    source: locationSource,
    alias: "offset",
    scope: "",
    fallback: 0
  });

  const searchProvider = useSearchProvider({
    source: locationSource,
    scope: "",
    alias: 'rangeSearch',
    onApplyClearScope: ["rangeSearch"]
  });

  const downloadFile = useCallback(
    () => {
      if (hasPdf) {
        window.open(hasPdf, '_blank').focus()
      }
    },
    [hasPdf],
  )

  useEffect(() => {
    getResultsList()
  }, [getResultsList]);

  return (
    <CustomModal isOpen={isOpen} className="hl7-modal" toggle={onClose}>
      <ModalHeader onClose={onClose} className="text-dark">
        {firstName} {lastName}
      </ModalHeader>

      <ModalBody className="pb-0">
        <section className="d-flex align-items-center mb-3">
          <ValueWithLabel
            value={testProductName || "-"}
            label="Product name"
            className="me-4"
          />
          <ValueWithLabel
            value={formatISODate(labReceivedAt)}
            label="Test date"
          />

          <section className="d-flex flex-row flex-grow-1 justify-content-end">
            <button
              className={joinClassNames("btn btn-primary me-2", hasPdf && 'cursor-pointer', !hasPdf && 'opacity-30')}
              onClick={hasPdf ? downloadFile : undefined}
            >
              <Icon className="me-2" icon='downloadPDF'/>
              Download file
            </button>
            {roboGPReport?.report?.file?.link ?
              <>
                <button
                  className="btn btn-primary me-2 cursor-pointer"
                  onClick={() => {
                    window.open(
                      roboGPReport?.report?.file?.link,
                      '_blank'
                    );
                  }}>
                  <Icon className="me-2" icon='downloadRoboGP'/>
                  Download GP Report
                </button>
              </>
              : null}
          </section>
        </section>
        <section className="mb-3">
          <div className='ms-auto d-flex align-items-center'>
            <TableSearch
              className="biomarkers-search me-3"
              search={searchProvider.getValue()}
              onSearch={searchProvider.setValue}
            />
          </div>
        </section>

        <Table
          columns={columns}
          data={userResults.filter(filterByQuery)}
          isAddNewBiomarker
          loading={isLoading}
          totalCount={userResultsPagination.totalCount}
          limit={100}
          offset={0}

          sortProviders={{
            ['biomarker.name']: markerSortProvider,
            ['recommendationRange']: valSortProvider,
          }}

          paginationProvider={paginationProvider}
          withoutPagination
          commonPlaceholder="No customer results for now."
          hasActiveFilters={false}
          tableClassName="popup-table"
        />
      </ModalBody>
    </CustomModal>
  )
}
