export const RECOMMENDATIONS_ROUTES = {
  BASE: "/recommendations/*",
  LIST: "/",
  DETAILS: "/:id",
  EDIT: `/:id/edit`,
  CREATE: `/create`,
};

export const RECOMMENDATIONS_LINKS = {
  BASE: "/app/recommendations",
  LIST: "/app/recommendations",
  DETAILS: "/app/recommendations/:id",
  CREATE: `/app/recommendations/create`,
  EDIT: `/app/recommendations/:id/edit`,
};