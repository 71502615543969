import React from "react";
import { storyblokEditable } from "@storyblok/react";

const Header = ({ blok }) => {
    if (!blok) {
        return null;
    }

    const styles = {
        height: "60px",
        width: "100%",
        padding: "0 2rem",
        display: "flex",
        justifyContent: blok.logoPosition,
        backgroundColor: blok.backgroundColor.color,
        color: blok.textColor.color,
        alignItems: "center",
        position: blok.fixed ? "fixed" : "absolute",
        top: 0,
        left: 0,
        zIndex: 9
    }

    return (
        <header style={styles} {...storyblokEditable(blok)}>
            <img src={blok.logo.filename} alt="logo" loading="lazy" style={{
                maxHeight: "40px"
            }}/>
        </header>
    );
};

export default Header;
