import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import classnames from "classnames";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem
} from "reactstrap";

import { FieldArray, useField } from "formik";

import Icon from "../../../../../../base/components/Icon";
import { ContentDropdown } from "../../../../../../base/components/Dropdowns/ContentsDropdown/ContentsDropdown";
import PlusButtonWithTooltip from "../../../../../../base/components/PlusButtonWithTooltip";

const DEFAULT_FIELD_NAME = "relatedContents";
const ACCORDION_ITEM_ID = "1";
const MAX_RELATED_CONTENT = 10;

export function RelatedContentField() {
  const [open, setOpen] = useState([ACCORDION_ITEM_ID]);
  const [{ value }, { error }, { setValue }] = useField(DEFAULT_FIELD_NAME);

  const toggle = () => {
    setOpen(open.length ? [] : [ACCORDION_ITEM_ID]);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle
  });

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const items = Array.from(value);

    const [reorderedItem] = items.splice(source.index, 1);

    items.splice(destination.index, 0, reorderedItem);

    return setValue(items);
  };

  return <>
    <div className="bg-body">
      <Accordion flush open={open} toggle={toggle}>
        <AccordionItem
          id={ACCORDION_ITEM_ID}
        >
          <AccordionHeader
            className="bg-body-accordion-header border-0"
            targetId={ACCORDION_ITEM_ID}>
            <span className="text-dark font-weight-semibold">Add related content</span>
          </AccordionHeader>

          <AccordionBody accordionId={ACCORDION_ITEM_ID}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="relatedContentDND">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <FieldArray name={DEFAULT_FIELD_NAME}>
                      {({ push, replace, remove }) => (
                        <>
                          {value.map((item, index) =>
                            <Draggable
                              key={`${item?.id}-${index}`}
                              draggableId={index.toString()}
                              index={index}
                              isDragDisabled={false}>

                              {(provided, snapshot) => (
                                <div
                                  {...provided.draggableProps}
                                  ref={provided.innerRef}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                  className="mb-3"
                                >
                                  <div className="d-flex align-items-center gap-4 justify-content-between">
                                    <div className="d-flex flex-grow-1 align-items-center gap-1">
                                      <div className="d-flex" style={{ width: "20px" }}>{index + 1}.</div>

                                      <div className="flex-grow-1">
                                        <ContentDropdown
                                          value={item}
                                          onChange={(value) => replace(index, value)}
                                          placeholder="Select content (optional)"
                                        />
                                      </div>
                                    </div>

                                    <div className="d-flex gap-3">
                                      <div
                                        {...provided.dragHandleProps}
                                      >
                                        <Icon
                                          icon="dndIcon"
                                          width={24}
                                          height={24}
                                          className="cursor-pointer impacts-section__view"
                                        />
                                      </div>

                                      <div
                                        onClick={() => remove(index)}
                                      >
                                        <Icon
                                          icon="trashIcon"
                                          width={24}
                                          height={24}
                                          className="cursor-pointer impacts-section__view "
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>)}
                            </Draggable>)
                          }

                          {provided.placeholder}

                          <PlusButtonWithTooltip
                            id={"addRelatedContend"}
                            disabled={value.length >= MAX_RELATED_CONTENT}
                            onClick={() => push(null)}
                            buttonText="Add content"
                            tooltipText={`Max. ${MAX_RELATED_CONTENT} options can be added.`}
                            containerClassName="mt-3"
                          />
                        </>
                      )}

                    </FieldArray>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </div>

    {error &&
      <span className="invalid-feedback d-block">{error}</span>
    }
  </>;
}