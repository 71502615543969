import yup from "../../../../../validation/yup";
import {
    MIN_STRING_LENGTH,
} from "./constants";
import { MAX_FULL_NAME, NAME_PATTERN, ORDER_MIN } from "./constants";
import { TAG_NAMES_MAX_ITEMS_LENGTH } from "../../../HealthProgram/Fitness/Form/constants";
import { isUnique } from "../../helpers";
import { TAG_NAMES_MIN_ITEMS_LENGTH } from "../../constants";
export const initialValues = {
    fullName: "",
    order: null,
    tagNames: []
};

export const validationSchema = yup.object().shape({
    fullName: yup
      .string()
      .trim()
      .min(MIN_STRING_LENGTH)
      .max(MAX_FULL_NAME)
      .matches(NAME_PATTERN, "Invalid name")
      .required("Is required"),
    order: yup
      .number()
      .min(ORDER_MIN),
    tagNames: yup.array()
      .min(TAG_NAMES_MIN_ITEMS_LENGTH, `Is required`)
      .max(TAG_NAMES_MAX_ITEMS_LENGTH, `Maximum ${TAG_NAMES_MAX_ITEMS_LENGTH} tags allowed.`)
      .test('unique', 'Tags must be unique', isUnique),
});