import React, { useEffect, useMemo, useState } from "react";

import Button from "../../../../../base/components/Button";
import TableSearch from "../../../../../base/components/Table/tableSearch";

import { useLocationQuery } from "../../../../../base/hooks/useQueryString";
import { useHighlight } from "../../../../../base/hooks/useHighlight";
import { BUTTON_COLORS } from "../../../../../base/components/Button/appearance";
import { MIN_SEARCH_LENGTH } from "../../../../../base/constants/pagination";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { PROGRAM_STATUSES, PROGRAM_STATUSES_LABELS } from "../../../HealthProgram/Nutrition/List/constants";
import Checkbox from "../../../../../base/components/Checkbox";
import { WORKOUT_STATUSES_LABELS } from "../../WorkoutForm/constants";
import Icon from "../../../../../base/components/Icon";

export const WORKOUT_ACTIONS = {
    DELETE: 'delete',
    EDIT: 'edit'
};

const StatusLabel = ({ id, status }) => {
    return <div id={id} className={`status-tag-${WORKOUT_STATUSES_LABELS[status]}`}>
        {WORKOUT_STATUSES_LABELS[status]}
    </div>;
};

export const SearchPlaceholder = () => (
    <>
        No matching workout.
    </>
);

export const TableFilterByStatus = ({ filterProvider }) => {
    const [isOpen, updateIsOpen] = useState(false);
    const currentValue = useMemo(() => filterProvider?.getValue(), [filterProvider?.getValue()]);

    const initialValue = useMemo(() => {
        return currentValue ? (typeof currentValue === "object" ? currentValue : [currentValue]) : [];
    }, [currentValue])

    const [statuses, updateStatuses] = useState(initialValue ?? []);

    const submitFilters = () => {
        filterProvider.setValue(statuses);
        updateIsOpen(false);
    };

    const handleCheckboxChange = (id) => {
        updateStatuses(prevState => {
            return prevState.includes(id) ? prevState.filter(item => item !== id) : [...prevState, id];
        });
    };

    useEffect(() => {
        updateStatuses(initialValue)
    }, [initialValue]);

    return (
        <section className="d-flex align-items-center">
            <Dropdown
                isOpen={isOpen}
                toggle={() => updateIsOpen(prevState => !prevState)}
                className="d-inline-block"
            >
                <DropdownToggle
                    className="text-nowrap btn btn-outline-primary d-flex align-items-center"
                    tag="button"
                >
                    <i className="bx bx-filter-alt me-2"/> Filter by status
                </DropdownToggle>

                <DropdownMenu className="filter-menu pb-1 px-1">

                    <section className="filter-options mx-1 custom-scrollbar">
                        {Object.values(PROGRAM_STATUSES).map((status, index) => {
                            return (
                                <Checkbox
                                    id={status}
                                    text={PROGRAM_STATUSES_LABELS[status]}
                                    value={statuses?.includes(status)}
                                    onChange={() => handleCheckboxChange(status)}
                                    key={index}
                                    className="my-2 ms-1"
                                />
                            );
                        })}
                    </section>

                    <section className="w-100 px-2 pb-2">
                        <Button
                            color={BUTTON_COLORS.primary}
                            onClick={submitFilters}
                            className="w-100"
                            disabled={!statuses.length}>
                            Apply
                        </Button>
                    </section>

                </DropdownMenu>
            </Dropdown>
        </section>
    );
};

export const NoWorkoutPlaceholder = () => (
    <div className="text-center">
        No workouts for now.<br/>
        Click the “Create workout” option to add a new one.
    </div>
);
export const TableHeader = ({ searchProvider, headerActions, filterProvider }) => {

    const goToCreate = () => {
        headerActions.handleClickCreate()
    };

    return (
        <section className="d-flex w-100 pt-1 my-3 align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-4">
                <TableSearch
                    className="biomarkers-search"
                    search={searchProvider?.getValue()}
                    onSearch={searchProvider.setValue}
                    placeholder="Search workout..."
                />

                <TableFilterByStatus filterProvider={filterProvider}/>
            </div>

            <Button color={BUTTON_COLORS.primary} onClick={goToCreate} className="d-flex align-items-center">
                <i className="bx bx-plus me-2"/>
                Create workout
            </Button>
        </section>
    );
};

export const columns = [
    {
        Header: "#",
        width: 40,
        className: "bg-white",
        Cell: (cellProps) => {
            const { params: { offset = 0 } } = useLocationQuery();
            return <label style={{width: "40px"}} className="mb-0 text-nowrap">{cellProps.row.index + 1 + (offset ?? 0)}</label>;
        }
    },
    {
        Header: "Name",
        accessor: "name",
        className: "bg-white",
        canSort: true,
        width: 400,
        Cell: ({ value }) => {
            const { params } = useLocationQuery();
            const searchRequest = useMemo(() => params.search && params.search.toString()?.trim()?.length >= MIN_SEARCH_LENGTH ? params.search : null, [params.search])
            const { decorateText } = useHighlight(searchRequest);

            return (
                <section style={{width: "400px", overflow: "hidden"}}>
                    <div {...decorateText(value)} className="text-truncate mb-0"/>
                </section>
            );
        }
    },
    {
        Header: "Subscription",
        accessor: "subscriptionPlan",
        className: "bg-white",
        Cell: ({ value }) => {
            return (
              <section>
                  <div>{value?.adaptyProductName ? value?.adaptyProductName : '-'}</div>
              </section>
            );
        }
    },
    {
        Header: "Status",
        accessor: "status",
        className: "bg-white",
        canSort: true,
        width: 400,
        Cell: ({ value }) => {
            return (
                <section style={{width: "400px", overflow: "hidden"}}>
                    <StatusLabel status={value} />
                </section>
            );
        }
    },

    {
        Header: "Actions",
        className: "bg-white",
        width: 100,
        Cell: ({
                   row: {
                       original: { id }
                   },
                   actions
               }) => {

            return <section
                className="d-flex gap-1 align-items-center"
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                <Icon icon="edit"
                      width={20}
                      height={20}
                      className="me-4 cursor-pointer impacts-section__view"
                      onClick={(event) => {
                          event.stopPropagation();
                          actions[WORKOUT_ACTIONS.EDIT](id);
                      }}
                />

                <Icon
                    icon={"trashIcon"}
                    width={20}
                    height={20}
                    onClick={(event) => {
                        event.stopPropagation();
                        actions[WORKOUT_ACTIONS.DELETE](id);
                    }}
                    className="me-4 cursor-pointer"
                />
            </section>;
        }
    }
];