import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import React from "react";

const Page = ({ blok }) => {
    if (!blok) {
        return null
    }

    return (
        <div
            {...storyblokEditable(blok)}
            className="article-page"
        >
            {blok.body
                ? blok.body.map((blok) => (
                    <div key={blok._uid}>
                        <StoryblokComponent blok={blok}/>
                    </div>
                ))
                : <StoryblokComponent blok={blok} key={blok._uid}/>}
        </div>
    );
}

export default Page;