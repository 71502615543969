import Http from "./Http";
import { DEFAULT_LIMIT_AND_OFFSET } from "./BiomarkersService";

class UserAttributesService extends Http {
  static $displayName = "UserAttributesService";

  async getAttributesList(id, params = DEFAULT_LIMIT_AND_OFFSET) {
    return await this.get(`/user-attributes/user/${id}`, { params })
  }
  
  async createUserAttribute(values) {
    return await this.post(`/user-attributes`, values)
  }

  async updateUserAttribute(id, values) {
    return await this.put(`/user-attributes/${id}`, values)
  }
  
  async deleteUserAttribute(id) {
    return await this.delete(`/user-attributes/${id}`)
  }

  async downloadUserAttributeCSV(id) {
    return await this.get(`/user-attributes/user/${id}/file`);
  }
}

export default UserAttributesService;
