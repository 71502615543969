import Http from "./Http";

class TagService extends Http {
    static $displayName = "TagService";

    async getTags(params) {
        return await this.get(`/tags`, { params });
    }

    createTag(values) {
        return this.post(`/tags`, values);
    }

    getTagById(id) {
        return this.get(`/tags/${id}`);
    }

    updateTag(id, values) {
        return this.put(`/tags/${id}`, values);
    }

    deleteTag(id) {
        return this.delete(`/tags/${id}`);
    }
}

export default TagService;