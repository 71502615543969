import React, { useCallback, useEffect, useState } from 'react'
import joinClassNames from "../../../../base/helpers/joinClassNames";
import CustomInput from "../../../../base/components/Input";
import { MIN_LENGTH_FOR_SEARCH_REQUEST, useDebounce } from "../../../../base/hooks/useDebounce";
import { useService } from "../../../../base/hooks/useService";
import RecommendationsService from "../../../../services/RecommendationsService";
import { Dropdown, DropdownMenu, Spinner } from "reactstrap";
import { useLoading } from "../../../../base/hooks/useLoading";
import { useHighlight } from "../../../../base/hooks/useHighlight";
import { formatSearch } from "../../../../base/components/Table/tableSearch";


export const TypeAheadOption = ({ name, search, onClick }) => {
  const { decorateText } = useHighlight(search);
  return (
    <section
      onClick={onClick}
      className={joinClassNames("cursor-pointer type-ahead-option",)}
      {...decorateText(name)}
    />
  )
}

export default function TypeAhead({
  label,
  onChange,
  value,
  maxLength,
  placeholder,
  containerClassName,
}) {
  /**
   * @type {RecommendationsService}
   */
  const recommendationsService = useService(RecommendationsService);

  const [isOpen, updateIsOpen] = useState(false);
  const [search, updateSearch] = useState(value);
  const [options, updateOptions] = useState([]);
  const [isLoading, { registerPromise }] = useLoading(true);

  const [debouncedSearch] = useDebounce(search)

  const getTags = useCallback(() => {
    if(!!debouncedSearch && debouncedSearch.length < MIN_LENGTH_FOR_SEARCH_REQUEST) return;
    registerPromise(
      recommendationsService.getRecommendationTags({
        offset: 0,
        limit: 100,
        search: debouncedSearch,
      }).then(({ data }) => {
        updateOptions(data)
      })
    )
  }, [debouncedSearch]);

  useEffect(() => {
    getTags()
  }, [getTags]);

  useEffect(() => {
    if(search.length === 1) {
      onChange("")
      return;
    }
    onChange(search)
  }, [search]);

  return (
    <section className={joinClassNames(containerClassName)}>
      <label>{label}</label>
      <CustomInput
        type="text"
        value={search}
        onChange={(event) => {
          const formattedSearch = formatSearch(event.target.value)
          updateSearch(formattedSearch)
          if(formattedSearch.length !== 1) {
            updateIsOpen(true)
          }
        }}
        className=""
        maxLength={maxLength}
        placeholder={placeholder}
      />
      <Dropdown
        isOpen={isOpen}
        toggle={() => updateIsOpen(prevState => !prevState)}
        className="d-inline-block w-100"
      >
        <DropdownMenu className="w-100 type-ahead-menu custom-scrollbar">
          {isLoading ?
            <section className="mt-3 d-flex flex-column justify-content-center align-items-center w-100 h-100">
              <Spinner color="info"/>
            </section>
            :
            options.length ?
              options.map((name, index) => {
                return (
                  <TypeAheadOption
                    key={index}
                    name={name}
                    search={search}
                    onClick={() => {
                      updateSearch(name)
                      updateIsOpen(false)
                    }}
                  />
                )
              })
              :
              <section className="mt-4 d-flex flex-column justify-content-center align-items-center w-100 h-100">
                <label className="text-secondary ">
                  No matches found
                </label>
              </section>
          }
        </DropdownMenu>
      </Dropdown>
    </section>
  )
}