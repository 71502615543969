import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { CustomModal, ModalActions, ModalHeader } from "../../../../base/components/CustomModal";
import Button from "../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";
import { BiomarkerField } from "../../CustomerManagement/UploadManually/components";
import BiomarkersService, { DEFAULT_LIMIT_AND_OFFSET } from "../../../../services/BiomarkersService";
import { useLoading } from "../../../../base/hooks/useLoading";
import { useService } from "../../../../base/hooks/useService";
import { useDebounce } from "../../../../base/hooks/useDebounce";
import { Formik } from 'formik';
import { impactValidationSchema } from "./formValidation";
import RichTextEditor from "../../../../base/components/RichTextEditor";
import RangeSlider from "../../../../base/components/RangeSlider";
import { IMPACT_TYPE_FIELDS, LOW_RANGE } from "./constants";
import { addTrueObjectField, isObjectEmpty } from "../../../../base/helpers/object";
import { ImpactPopupNav } from "./components";
import StudyLink from "../../Biomarkers/CreateEdit/components/StudyLink";
import PlusButtonWithTooltip from "../../../../base/components/PlusButtonWithTooltip";
import { MAX_STUDY_LINKS } from "../../Biomarkers/CreateEdit/components/BulletList";
import {
  MAX_BIOMARKERS_NAME_LENGTH,
  MAX_RECOMMENDATION_LENGTH,
  SKIN_CATEGORY_ID
} from "../../Biomarkers/CreateEdit/constants";
import CustomInput from "../../../../base/components/Input";

const hasImpactChanges = (impact) => !!impact || impact === 0;

export default function ImpactPopup({
  isOpen,
  onClose,
  submitImpact,
  impacts = [],
  isDoctorOptionChosen,
  editOption,
  isEdit,
  isSkinRecommendation,
}) {
  /**
   * @type {BiomarkersService}
   */
  const biomarkersService = useService(BiomarkersService);

  const [isOpenDropdown, updateIsOpenDropdown] = useState(false);
  const [search, updateSearch] = useState("");
  const [biomarkers, updateBiomarkers] = useState([]);
  const [activeImpactTab, updateActiveImpactTab] = useState(LOW_RANGE)
  const [isLoading, { registerPromise }] = useLoading(true);

  const [debouncedSearch] = useDebounce(search);

  const options = useMemo(() => {
    if(!impacts.length) return biomarkers;

    const resultsIds = impacts.reduce((prevValue, { biomarker }) => {
      if(!biomarker) return prevValue;
      return {
        ...prevValue,
        ...addTrueObjectField(biomarker.id)
      }
    }, {})

    return biomarkers.filter(({ id }) => !resultsIds[id]);
  }, [biomarkers, impacts])

  const getBiomarkers = useCallback(() => {
    if(!isOpen) return;
    const categoryIds = isSkinRecommendation ? [SKIN_CATEGORY_ID] : undefined;
    registerPromise(biomarkersService.getBiomarkers({
      ...DEFAULT_LIMIT_AND_OFFSET,
      search: debouncedSearch,
      categoryIds,
    }).then((data) => updateBiomarkers(data.data)));
  }, [debouncedSearch, isOpen, isSkinRecommendation]);

  useEffect(() => {
    getBiomarkers()
  }, [getBiomarkers]);

  return (
    <CustomModal isOpen={isOpen} className="filter-modal">
      <ModalHeader onClose={onClose}>
        {isEdit ? "Edit impact on biomarker" : "Add impact on biomarker"}
      </ModalHeader>

      <Formik
        initialValues={isEdit ? editOption : {}}
        validationSchema={impactValidationSchema}
        onSubmit={(data) => {
          submitImpact(data)
          onClose();
        }}
      >
        {({ errors, handleSubmit, values, setFieldValue, setFieldTouched }) => {

          const keyForStudyLinks = IMPACT_TYPE_FIELDS[activeImpactTab].studyLinks;
          const lengthOfLinks = values[keyForStudyLinks]?.length;

          return (
            <form onSubmit={handleSubmit}>
              <BiomarkerField
                value={values.biomarker}
                search={search}
                onSearch={(search) => updateSearch(search)}
                loading={isLoading}
                options={options}
                onSelect={async (data) => {
                  await setFieldValue("biomarker", data)
                  setFieldTouched("biomarker", true)
                }}
                toggleDropdown={() => updateIsOpenDropdown(prevState => !prevState)}
                isOpen={isOpenDropdown}
                name="biomarker"
                label="Biomarker"
                placeholder="Select biomarker (required)"
                sectionClassName="px-3 pt-3"
              />

              <ImpactPopupNav
                activeImpactTab={activeImpactTab}
                updateActiveImpactTab={updateActiveImpactTab}
                isSkinRecommendation={isSkinRecommendation}
              />

              <section className="px-3">
                <RichTextEditor
                  value={values[IMPACT_TYPE_FIELDS[activeImpactTab].editor] ?? ""}
                  onChange={(data) => {
                    setFieldValue(IMPACT_TYPE_FIELDS[activeImpactTab].editor, data)
                  }}
                  label="Description"
                  placeholder="Enter a few sentences  about the recommendation and its effects on biomarker (required)"
                  name={IMPACT_TYPE_FIELDS[activeImpactTab].editor}
                />

                {!isSkinRecommendation && <>
                  <section>
                    <label className="font-weight-semibold mt-3">Study links:</label>
                  </section>

                  {!!lengthOfLinks &&
                    <section className="mt-2">
                      {values[keyForStudyLinks].map((link, index) => {
                        const keyForEdit = `${keyForStudyLinks}[${index}]`;
                        return (
                          <section className="d-flex flex-column" key={`${index}-${activeImpactTab}`}>
                            <StudyLink
                              studyListIndex={index}
                              onChange={(value) => setFieldValue(`${keyForEdit}.title`, value)}
                              value={values[keyForStudyLinks][index].title}
                              onDelete={() => {
                                setFieldValue(keyForStudyLinks, values[keyForStudyLinks].filter((_, listIndex) => listIndex !== index))
                              }}
                              className="mb-1"
                              placeholder="Enter link title"
                              maxLength={MAX_RECOMMENDATION_LENGTH}
                            />
                            <CustomInput
                              value={values[keyForStudyLinks][index].content}
                              onChange={(event) => {
                                setFieldValue(`${keyForEdit}.content`, event.target.value)
                              }}
                              placeholder="https://"
                              className="study-link-width mb-2"
                              maxLength={MAX_BIOMARKERS_NAME_LENGTH}
                            />
                          </section>
                        )
                      })}
                    </section>
                  }

                  <PlusButtonWithTooltip
                    id={`add${activeImpactTab}studyLink`}
                    disabled={lengthOfLinks >= MAX_STUDY_LINKS || values[keyForStudyLinks]?.some(({ content }) => !content)}
                    onClick={() => {
                      setFieldValue(keyForStudyLinks, [...(values[keyForStudyLinks] ?? []), ""])
                    }}
                    tooltipText="Max. 4 study links can be added"
                    buttonText="Add study link"
                    containerClassName=""
                    buttonClassName="ps-0"
                    showTooltip={lengthOfLinks >= MAX_STUDY_LINKS}
                  />

                  {!isDoctorOptionChosen && IMPACT_TYPE_FIELDS[activeImpactTab].impacts.map(({
                    fieldKey,
                    defaultValue,
                    ...impactItem
                  }) => {
                    return (
                      <RangeSlider
                        key={fieldKey}
                        {...impactItem}
                        value={values[fieldKey] ?? defaultValue}
                        onChange={(data) => {
                          if(data !== values[fieldKey]) {
                            setFieldValue(fieldKey, data);
                          }
                        }}
                      />
                    )
                  })}
                </>
                }
              </section>

              <ModalActions>
                <Button color={BUTTON_COLORS.primaryOutline} onClick={onClose} className="mb-0">
                  Cancel
                </Button>
                <Button
                  color={BUTTON_COLORS.primary}
                  type="submit"
                  className="mb-0"
                  disabled={
                    isObjectEmpty(errors) ||
                    !(
                      (values.descriptionLow && (hasImpactChanges(values.impactLevelLow) || isDoctorOptionChosen || isSkinRecommendation)) ||
                      (values.descriptionHigh && hasImpactChanges(values.impactLevelHigh || isDoctorOptionChosen || isSkinRecommendation))
                    )
                  }
                >
                  {isEdit ? "Save changes" : "Save"}
                </Button>
              </ModalActions>
            </form>
          )
        }}
      </Formik>
    </CustomModal>
  )
}