import React, { useEffect, useMemo, useState } from 'react'
import RichTextEditor from "../../../../../base/components/RichTextEditor";
import get from "lodash.get";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { REC_RANGES_TO_APPLY_BY_ID } from "../Recommendations/constants";
import BulletList from "./BulletList";
import joinClassNames from "../../../../../base/helpers/joinClassNames";
import { transformGroupsToTabs } from "../helpers/transformModels";

export const Tabs = ({ tabs, activeTab, updateActiveTab, className = "mb-3" }) => {
  return (
    <Nav tabs className={joinClassNames("nav-tabs-custom w-100 rich-text-nav", className)}>
      {tabs.map(({ label, name, className: tabClassName, }, index) => (
        <NavItem key={label} className={tabClassName}>
          <NavLink
            className={classnames({
              active: activeTab === index,
            }, joinClassNames("cursor-pointer"))}
            onClick={() => {
              if(activeTab === index) return;
              updateActiveTab(index)
            }}
          >
            <span>{label}</span>
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}

export const getGroupLabel = (prevLabel, newLabel) => {
  return prevLabel.length ? prevLabel + " / " + newLabel : newLabel
}

export const generateGroupTab = (group, allTabsMap) => {
  const groupTab = {
    label: "",
    name: "",
    idsForGroup: []
  }

  group.forEach(id => {
    const currentItem = allTabsMap[id];
    groupTab.label = getGroupLabel(groupTab.label, currentItem.label);
    groupTab.name = currentItem.name;
    groupTab.idsForGroup = [...groupTab.idsForGroup, currentItem.idForGroup];
  })
  return groupTab
}

export default function RichTextWithTabs({
  ranges,
  keyForEdit,
  setFieldValue,
  valuesObject,
  groups,
  isCustomTabs,
  allTabsArray,
  allTabsMap,
  className,
  label,
  placeholder,
  description,
  noRangePlaceholder,
  withBulletList,
  id,
}) {
  const [activeTab, updateActiveTab] = useState(0);
  const { low = [], high = [] } = groups;

  const tabs = useMemo(() => {
    return transformGroupsToTabs({
      isCustomTabs,
      allTabsArray,
      ranges,
      low,
      high,
      groups,
      allTabsMap,
    })
  }, [ranges, allTabsArray, allTabsMap, groups, isCustomTabs])

  const { name = "", idsForGroup = [], secondKey = "" } = tabs[activeTab] ?? {};

  useEffect(() => {
    if(tabs.length && !tabs[activeTab]) {
      updateActiveTab(0);
    }
  }, [activeTab, tabs]);

  useEffect(() => {
    updateActiveTab(0);
  }, [tabs.length, isCustomTabs]);

  return (
    <section className={className}>
      {tabs.length && name ?
        <section>
          <RichTextEditor
            value={valuesObject[name] ?? valuesObject[secondKey] ?? ""}
            onChange={(text) => {
              setFieldValue(`${keyForEdit}.${name}`, text)
              if(secondKey){
                setFieldValue(`${keyForEdit}.${secondKey}`, text)
              }
              idsForGroup.forEach((id) => {
                setFieldValue(
                  `${keyForEdit}.${REC_RANGES_TO_APPLY_BY_ID[id].name}`,
                  text
                )
              })
            }}
            name={`${keyForEdit}.${name}`}
            label={label}
            description={description}
            placeholder={placeholder}
            Tabs={<Tabs
              tabs={tabs}
              activeTab={activeTab}
              updateActiveTab={updateActiveTab}
            />}
          />

          {withBulletList &&
            <BulletList
              bulletList={get(valuesObject, `bulletList`, [])}
              setFieldValue={setFieldValue}
              keyForEdit={`${keyForEdit}.bulletList`}
              rangeName={name}
              id={id}
            />
          }
        </section>
        :
        <>
          <label className="font-weight-semibold">{label}</label>
          <p>{noRangePlaceholder}</p>
        </>
      }
    </section>
  )
}