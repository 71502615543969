import React, { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { CardBody, Card, Spinner } from "reactstrap";
import { Formik } from "formik";
import FormikInput from "../../../../base/components/FormikInput";
import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import { useService } from "../../../../base/hooks/useService";
import AuthCardContainer from "../../components/AuthCardContainer";
import Logo from "../../components/Logo";
import AuthHeader from "../../components/Header";
import AuthService from "../../../../services/AuthService";
import { validationSchema, initialValues } from "./form";
import { useLoading } from "../../../../base/hooks/useLoading";
import { AUTH_GROUP_LINKS } from "../../config";
import joinClassNames from "../../../../base/helpers/joinClassNames";
import { MAX_PASSWORD_LENGTH } from "../../../../validation/lengthConstants";
import ToasterService from "../../../../services/ToastService";
import { KEY_USER } from "../../../../base/constants/storage";
import StorageService from "../../../../services/StorageService";
import SessionStorage from "../../../../services/SessionStorage";
import { BUTTON_COLORS, BUTTON_STYLES } from "../../../../base/components/Button/appearance";
import Button from "../../../../base/components/Button/index";

export default function ResetPassword() {
  const [isLoading, { registerPromise }] = useLoading();
  const [isLoadingReset, { registerPromiseReset }] = useLoading();

  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);
  /**
   * @type {ToasterService}
   */
  const toatsService = useService(ToasterService);
  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);
  /**
   * @type {SessionStorage}
   */
  const storageSession = useService(SessionStorage);

  const navigate = useNavigate();

  const { params: { code: token, organisationId } } = useLocationQuery();

  const resetPassword = ({ password }) => {
    registerPromiseReset(authService.resetPassword({
      password,
      token,
      organisationId,
    }).then(({ user, session }) => {
      storage.set(KEY_USER, user);
      storageSession.setSession(session);
      navigate(AUTH_GROUP_LINKS.EXTERNAL.AFTER_LOGIN);
      toatsService.success("Your password has been updated successfully");
    }))
  };

  const checkLinkRelevance = useCallback(() => {
    registerPromise(authService.checkLinkRelevance({ token, organisationId })
      .catch(() => {
        navigate(AUTH_GROUP_LINKS.LINK_EXPIRED);
      }))
  }, [token, registerPromise]);

  useEffect(() => {
    checkLinkRelevance();
  }, [checkLinkRelevance]);

  return (
    <AuthCardContainer metaText="Login">
      <Card className="overflow-hidden">
        <AuthHeader headerText="Set new password" className="auth-header"/>

        <CardBody className="pt-0">
          <Logo/>
          {isLoading &&
            <section className="w-100 text-center">
              <Spinner color="info"/>
            </section>
          }
          {!isLoading &&
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnMount
              onSubmit={(values) => {
                resetPassword(values);
              }}
            >
              {({ errors, handleSubmit, values }) => (
                <form className="form-horizontal p-2" onSubmit={handleSubmit}>
                  <FormikInput
                    id="password"
                    label="New password"
                    name="password"
                    type="password"
                    maxLength={MAX_PASSWORD_LENGTH}
                    withEyeOption={values.password?.length}
                    containerClassName={joinClassNames("position-relative mb-3")}
                    placeholder="Create password"
                  />

                  <FormikInput
                    id="repeatPassword"
                    label="Confirm new password"
                    name="repeatPassword"
                    maxLength={MAX_PASSWORD_LENGTH}
                    containerClassName={joinClassNames("position-relative")}
                    type="password"
                    withEyeOption={values.repeatPassword?.length}
                    placeholder="Confirm new password"
                  />
                  <Button
                    color={BUTTON_COLORS.primary}
                    type="submit"
                    block={BUTTON_STYLES.block}
                    loading={isLoadingReset}
                    className="mt-3"
                    disabled={isLoadingReset || !!Object.keys(errors).length}
                  >
                    Confirm
                  </Button>
                </form>
              )}
            </Formik>
          }
        </CardBody>
      </Card>
    </AuthCardContainer>
  );
}
