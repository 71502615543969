import React from "react";
import { Badge } from "reactstrap";

const ArrayAttribute = ({ label, value }) => {
    if (!value?.length) return null

    return (
        <div key={label}>{label}: {value.map(text =>
            <Badge className="me-1" key={text}>{text}</Badge>)}
        </div>
    );
};

export default ArrayAttribute;
