import React, { useEffect, useRef } from "react";
import { Container } from "reactstrap";
import { useService } from "../../../base/hooks/useService";
import SessionStorage from "../../../services/SessionStorage";
import { sendRefreshToken } from "../../../services/refreshSession";
import { useNavigate } from "react-router-dom";
import { LINK_TO_LOGOUT } from "../../../base/links";

const FoodAndDiet = () => {
    /**
     * @type {SessionStorage}
     */
    const storage = useService(SessionStorage);
    const {accessToken, refreshToken} = storage.getSession();

    const navigate = useNavigate()

    const metaLink = process.env.REACT_APP_OM_META_LINK

    const iframeRef = useRef(null);

    useEffect(() => {
        if (iframeRef.current) {
            iframeRef.current.onload = () => {
                iframeRef.current.contentWindow.postMessage({
                    'type': 'access_token',
                    'token': accessToken
                }, metaLink);
            };
        }
    }, [accessToken]);

    useEffect(() => {
        async function handleMessage(event) {
            if (event.data.type && event.data.type === 'refresh_token_request') {
                try {
                    const { data: { session } } = await sendRefreshToken({ refreshToken })

                    iframeRef.current.contentWindow.postMessage({
                        'type': 'refresh_token_response',
                        'token': session.accessToken
                    }, metaLink);
                } catch (error) {
                    console.log("Refresh session error: ", error)
                    navigate(LINK_TO_LOGOUT)
                }


            }
        }

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage)
        }

    }, []);

    return <Container fluid className="content">
        <iframe
            src={`${metaLink}foods`}
            width="100%"
            style={{
                height: "calc(100vh - 170px)"
            }}
            ref={iframeRef}
        />
    </Container>
}

export default FoodAndDiet