export const formInitialValues = {
  id: null,
  remove: '',
  rotate: '',
  replaceWith: '',
  repair: '',
  reintroduce: '',
  theTest: '',
  howThisHelpMe: '',
  crossReactions: '',
};

export const planningDietSection = [
  {
    itemKey: "remove",
    label: "Remove",
    description: "Enter information on what foods should be removed.",
    placeholder: "Enter information on what foods should be removed."
  },
  {
    itemKey: "rotate",
    label: "Rotate",
    description: "Enter information on what foods should be rotated.",
    placeholder: "Enter information on what foods should be rotated."
  },
  {
    itemKey: "replaceWith",
    label: "Replace",
    description: "Enter information on what foods should be replaced.",
    placeholder: "Enter information on what foods should be replaced."
  },
  {
    itemKey: "repair",
    label: "Repair",
    description: "Enter information on what foods should be repaired.",
    placeholder: "Enter information on what foods should be repaired."
  },
  {
    itemKey: "reintroduce",
    label: "Reintroduce",
    description: "Enter information on what foods should be reintroduced.",
    placeholder: "Enter information on what foods should be reintroduced."
  },
];

export const learnMoreSection = [
  {
    itemKey: "theTest",
    label: "The test",
    description: "Enter information about testing for food Intolerance.",
    placeholder: "Enter information about testing for food Intolerance."
  },
  {
    itemKey: "howThisHelpMe",
    label: "How can this test help me?",
    description: "Enter information about benefits of testing for food intolerance.",
    placeholder: "Enter information about benefits of testing for food intolerance."
  },
  {
    itemKey: "crossReactions",
    label: "Cross-reactions",
    description: "Enter information about cross-reactivity.",
    placeholder: "Enter information about cross-reactivity."
  }
];