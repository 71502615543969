import React from "react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

const GridSection = ({ blok }) => {
    if (!blok) {
        return null;
    }

    const styles = {
        display: "grid",
        gridTemplateColumns: `repeat(${blok.columnCount || 1}, 1fr)`,
        gap: blok.gap
    };

    return (
        <section style={styles} className="grid-section" {...storyblokEditable(blok)}>
            {
                blok.cards
                    ? blok.cards.map((blok) => (
                        <div key={blok._uid}>
                            <StoryblokComponent blok={blok}/>
                        </div>
                    ))
                    : null
            }
        </section>
    );
};

export default GridSection;
