import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Table from "../../../../../../base/components/Table";
import { DEFAULT_TABLE_LIMIT } from "../../../../../../base/constants/shared";
import {
    usePaginationProvider,
    useLocationSource, useSearchProvider, useFilterProvider, useSortProvider
} from "../../../../../../base/components/Table/hooks";
import {
    SearchPlaceholder,
    TableHeader,
    columns,
    RULES_TABLE_ACTIONS,
    NoRulesPlaceholder
} from "./components";
import ToasterService from "../../../../../../services/ToastService";
import { useService } from "../../../../../../base/hooks/useService";
import ProgramsService from "../../../../../../services/ProgramsService";
import { useLoading } from "../../../../../../base/hooks/useLoading";
import { useQueryString } from "../../../../../../base/hooks/useQueryString";
import ConfirmPopup from "../../../../../../base/components/ConfirmPopup";
import { PROGRAMS_GROUP_LINKS } from "../../config";

export default function RulesList() {
    /**
     * @type {ProgramsService}
     */
    const programsService = useService(ProgramsService);
    /**
     * @type {ToasterService}
     */
    const toastService = useService(ToasterService);

    const [isLoading, { registerPromise }] = useLoading(true);
    const { search: locationSearch } = useLocation();
    const {
        params: {
            limit = DEFAULT_TABLE_LIMIT,
            offset = 0,
            search,
            fullName,
            userSegment,
            statusFilter,
            actionFilter
        }
    } = useQueryString(locationSearch);

    const [rules, updateRules] = useState([]);
    const [rulesPagination, updateRulesPagination] = useState({});
    const [showDeletePopup, updateShowDeletePopup] = useState(null);
    const [showDeleteProgramPopup, updateShowDeleteProgramPopup] = useState(null);
    const navigate = useNavigate();
    const { programId } = useParams();

    const locationSource = useLocationSource();

    const paginationProvider = usePaginationProvider({
        source: locationSource,
        alias: "offset",
        scope: "",
        fallback: 0
    });

    const searchProvider = useSearchProvider({
        source: locationSource,
        scope: "",
        alias: 'search',
        onApplyClearScope: [""]
    });


    const statusFilterProvider = useFilterProvider({
        source: locationSource,
        scope: "",
        alias: 'statusFilter',
        onApplyClearScope: ["offset"]
    });

    const actionFilterProvider = useFilterProvider({
        source: locationSource,
        scope: "",
        alias: 'actionFilter',
        onApplyClearScope: ["offset"]
    });

    const sortKeys = ["fullName", "author", "createdAt", "status"];
    const getSortScope = (key) => [...sortKeys.filter(item => item !== key), "offset"];

    const nameSortProvider = useSortProvider({
        source: locationSource,
        alias: "fullName",
        scope: "",
        onApplyClearScope: getSortScope("fullName")
    });

    const userSegmentSortProvider = useSortProvider({
        source: locationSource,
        alias: "userSegment",
        scope: "",
        onApplyClearScope: getSortScope("userSegment")
    });

    const getRules = useCallback(() => {
        const [orderBy, orderType] = Object.entries({
            fullName,
            userSegment
        }).find(([_, value]) => value) || [];

        registerPromise(programsService.getRules(programId, {
            limit,
            offset,
            orderBy,
            orderType,
            query: search,
            status: statusFilter,
            action: actionFilter
        }).then(({ data, pagination }) => {
            updateRules(data);
            updateRulesPagination(pagination);
        }));
    }, [limit, offset, fullName, userSegment, search, statusFilter, actionFilter]);

    const handleClickDeleteRule = (rule) => {
        updateShowDeletePopup(rule);
    };

    const goToEdit = (id) => {
        const queryParams = new URLSearchParams({ editRuleId: id }).toString();
        navigate(`${PROGRAMS_GROUP_LINKS.LIST}/${programId}/rule-form?${queryParams}`);
    };

    const goToCreate = () => {
        navigate(`${PROGRAMS_GROUP_LINKS.LIST}/${programId}/rule-form`);
    };

    const goToEditPrograms = () => {
        const queryParams = new URLSearchParams({ editProgramId: programId }).toString();
        navigate(`${PROGRAMS_GROUP_LINKS.CREATE_EDIT}?${queryParams}`);
    };

    const deleteRule = (id) => {
        registerPromise(programsService.deleteRule(id))
            .then(() => {
                getRules();
                toastService.success("The rule has been successfully deleted");
            });
    };

    const handleClickDeleteProgram = () => {
        updateShowDeleteProgramPopup(programId);
    };

    useEffect(() => {
        getRules();
    }, [getRules]);

    return (
        <>
            <Table
                columns={rules.length ? columns : []}
                data={rules}
                loading={isLoading}
                HeaderComponent={TableHeader}
                totalCount={rulesPagination.totalCount}
                limit={DEFAULT_TABLE_LIMIT}
                offset={offset}
                paginationProvider={paginationProvider}
                hasActiveFilters={!!actionFilterProvider.getValue() || !!statusFilterProvider.getValue()}
                filterProvider={{
                    statusFilter: statusFilterProvider,
                    actionsFilter: actionFilterProvider
                }}
                searchProvider={searchProvider}
                commonPlaceholder={<NoRulesPlaceholder/>}
                placeholderForSearch={<SearchPlaceholder/>}
                actions={{
                    [RULES_TABLE_ACTIONS.DELETE]: handleClickDeleteRule,
                    [RULES_TABLE_ACTIONS.EDIT]: goToEdit
                }}
                sortProviders={{
                    fullName: nameSortProvider,
                    userSegment: userSegmentSortProvider
                }}
                headerActions={{
                    goToCreate,
                    goToEditPrograms,
                    deleteProgram: handleClickDeleteProgram
                }}
            />

            {showDeletePopup &&
                <ConfirmPopup
                    isOpen={showDeletePopup}
                    updateIsOpen={updateShowDeletePopup}
                    onSubmit={() => {
                        deleteRule(showDeletePopup);
                        updateShowDeletePopup(null);
                    }}
                    title="Delete rule"
                    description="Are you sure you want to delete the chosen rule?"
                    submitBtnText="Delete"
                    className="upload-manually__popup"
                />
            }
        </>
    );
}
