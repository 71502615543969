import * as yup from "yup";
import { MIN_STRING_LENGTH, MAX_TITLE_LENGTH, ALLOWED_SYMBOLS_REGEX, MIN_ITEMS_QUANTITY } from "./constants";
import { ATTRIBUTE_TYPE_LABELS } from "../../UserSegments/constants";
import { HOMEPAGE_SECTIONS_TYPE_LABELS, QUANTITY_TO_DISPLAY_PER_LINE } from "../constants";


export const initialValues = {
    title: "",
    quantityPerLine: QUANTITY_TO_DISPLAY_PER_LINE.ONE,
    itemsQuantity: 1
};

export const validationSchema = yup.object().shape({
    title: yup
        .string()
        .trim()
        .min(MIN_STRING_LENGTH)
        .max(MAX_TITLE_LENGTH)
        .matches(ALLOWED_SYMBOLS_REGEX, "Invalid title")
        .required("Is required"),
    type: yup
        .string()
        .required(),
    itemsQuantity: yup
        .number()
        .min(MIN_ITEMS_QUANTITY)
        .required()
});

export const sectionTypeOptions = Object.entries(HOMEPAGE_SECTIONS_TYPE_LABELS)
    .map(([value, label]) => ({ value, label }));