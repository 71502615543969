export const BIOMARKERS_GROUP_ROUTES = {
  BASE: "/biomarkers/*",
  LIST: "/",
  CREATE_NEW: `/create`,
  EDIT: `/edit/:id`,
  DETAILS: `/details/:id`,
};

export const BIOMARKERS_GROUP_LINKS = {
  BASE: "/app/biomarkers",
  LIST: "/app/biomarkers",
  CREATE_NEW: `/app/biomarkers/create`,
  EDIT: `/app/biomarkers/edit/:id`,
  DETAILS: `/app/biomarkers/details/:id`,
};