import React, { useMemo } from "react";

import Button from "../../../../../base/components/Button";
import TableSearch from "../../../../../base/components/Table/tableSearch";
import { useLocationQuery } from "../../../../../base/hooks/useQueryString";
import { useHighlight } from "../../../../../base/hooks/useHighlight";
import { BUTTON_COLORS } from "../../../../../base/components/Button/appearance";
import { MIN_SEARCH_LENGTH } from "../../../../../base/constants/pagination";
import Icon from "../../../../../base/components/Icon";
import joinClassNames from "../../../../../base/helpers/joinClassNames";
import { Badge } from "reactstrap";
import { FITNESS_GROUP_LINKS } from "../../../Fitness/config";
import { useNavigate } from "react-router-dom";
import { CONTENTS_GROUP_LINKS, CONTENTS_GROUP_ROUTES } from "../../config";

export const CATEGORIES_ACTIONS = {
  DELETE: 'delete',
  EDIT: 'edit'
};

export const TagsCollapseRows = ({ row }) => {
  const { categoriesTags: tags } = row;

  return <tr>
    <td className="bg-white" />
    <td className="bg-white">
      <div className="d-flex flex-wrap gap-2" style={{ width: "400px" }}>
        {
          tags.map(({ tag, wellnessId }) => <Badge
              key={`${tag.id}-${wellnessId}`}
              color="light"
              pill
              className="d-flex font-weight-normal py-2 px-3 w-fit-content align-items-center bg-transparent border-1 border-secondary font-size-13 border"
            >
              {tag.name}
            </Badge>
          )}
      </div>
    </td>
    <td className="bg-white"/>
    <td className="bg-white"/>
  </tr>;
};

export const SearchPlaceholder = () => (
  <>
    No matching category.
  </>
);

export const NoCategoriesPlaceholder = () => (
  <div className="text-center">
    No categories for now.<br/>
    Click the “Create category” option to add a new one.
  </div>
);
export const TableHeader = ({ searchProvider }) => {
  const navigate = useNavigate()
  const goToCreate = () => {
    navigate(CONTENTS_GROUP_LINKS.FORM_CATEGORIES);
  };

  return (
    <section className="d-flex w-100 pt-1 my-3 align-items-center justify-content-between">
      <div className="d-flex align-items-center gap-3">
        <TableSearch
          className="biomarkers-search"
          search={searchProvider?.getValue()}
          onSearch={searchProvider.setValue}
          placeholder="Search category..."
        />
      </div>

      <Button color={BUTTON_COLORS.primary} onClick={goToCreate} className="d-flex align-items-center">
        <i className="bx bx-plus me-2"/>
        Create category
      </Button>
    </section>
  );
};

export const columns = [
  {
    Header: "#",
    width: 40,
    className: "bg-white",
    Cell: (cellProps) => {
      const { params: { offset = 0 } } = useLocationQuery();
      return <label style={{ width: "40px" }}
                    className="mb-0 text-nowrap">{cellProps.row.index + 1 + (offset ?? 0)}</label>;
    }
  },
  {
    Header: "Name",
    accessor: "fullName",
    className: "bg-white",
    canSort: true,
    width: 400,
    Cell: ({ value, row: { original }, collapsedState, toggleCollapse, useCollapseRule }) => {
      const { params } = useLocationQuery();
      const searchRequest = useMemo(
        () => params.search && params.search.toString()?.trim()?.length >= MIN_SEARCH_LENGTH ? params.search : null,
        [params.search]);
      const { decorateText } = useHighlight(searchRequest);
      const isCollapsed = collapsedState.includes(original.id)
      const canUseCollapse = useCollapseRule(original);

      return (
        <section
          style={{ width: "400px", overflow: "hidden" }}
          className="d-flex align-items-center"
          onClick={(event) => {
            event.stopPropagation();
            if (!canUseCollapse) return;

            toggleCollapse(original.id);
          }}
        >
          <div {...decorateText(value)} className="text-truncate mb-0"/>

          {canUseCollapse &&
            <Icon icon="arrowDown"
                  className={joinClassNames("table-expand-icon", isCollapsed ? "rotate-180" : "rotate-360")}/>
          }
        </section>
      );
    }
  },
  {
    Header: "Order",
    accessor: "order",
    className: "bg-white",
    width: 70,
    canSort: true,
    Cell: ({ value }) => {
      return (
        <section>
          <div>{value}</div>
        </section>
      )
    }
  },
  {
    Header: "Actions",
    className: "bg-white",
    width: 100,
    Cell: ({
      row: {
        original: { id }
      },
      actions
    }) => {

      return <section
        className="d-flex gap-1 align-items-center"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Icon icon="edit"
              width={20}
              height={20}
              className="me-4 cursor-pointer impacts-section__view"
              onClick={(event) => {
                event.stopPropagation();
                actions[CATEGORIES_ACTIONS.EDIT](id);
              }}
        />

        <Icon
          icon={"trashIcon"}
          width={20}
          height={20}
          onClick={(event) => {
            event.stopPropagation();
            actions[CATEGORIES_ACTIONS.DELETE](id);
          }}
          className="me-4 cursor-pointer"
        />
      </section>;
    }
  }
];