// Libs
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import { map } from "lodash";
import classnames from "classnames";

// Components, Views, Screens
import {
    IngredientGroupDropdown
} from "../../../../../base/components/Dropdowns/IngredientGroupDropdown/IngredientGroupDropdown";
import { RecipeTagDropdown } from "../../../../../base/components/Dropdowns/RecipeTagDropdown/RecipeTagDropdown";
import BaseLayoutWithCard from "../../../../../base/components/BaseLayoutWithCard";
import RadioButton from "../../../../../base/components/RadioButton";
import Switch from "../../../../../base/components/Switch";
import Button from "../../../../../base/components/Button";
import FormikInput from "../../../../../base/components/FormikInput";
import FormikReactSelect from "../../../../../base/components/FormikReactSelect";
import Title from "../../../Biomarkers/Details/Title";
import { UserSegmentsDropdown } from "../../../../../base/components/Dropdowns/UserSegmentsDropdown/UserSegmentsDropdown";
import RichTextEditor from "../../../../../base/components/RichTextEditor";
import { SourceItem } from "./SourceItem";

// Hooks, Utils, Helpers
import { useService } from "../../../../../base/hooks/useService";
import { useQueryString } from "../../../../../base/hooks/useQueryString";
import joinClassNames from "../../../../../base/helpers/joinClassNames";
import ProgramsService from "../../../../../services/ProgramsService";
import ToastService from "../../../../../services/ToastService";
import { PROGRAMS_GROUP_LINKS } from "../config";
import { BUTTON_COLORS } from "../../../../../base/components/Button/appearance";
import { LIST_TABS, RULES_STATUSES } from "../RulesGoalsList/constants";
import {
    MAX_PRIORITY,
    MAX_RULE_PUBLIC_COMMENT_LENGTH,
    MAX_RULE_SIMPLE_EXAMPLES_LENGTH,
    MIN_PRIORITY,
    RULE_TYPE,
    MAX_RULE_NAME_LENGTH, MIN_ORDER, MAX_ORDER
} from "./constants";
import { defaultLinksItem, initialValues, ruleActionOptions, validationSchema } from "./form";


export function CreateEditRule() {
    /**
     * @type {ProgramsService}
     */
    const programsService = useService(ProgramsService);
    /**
     * @type {ToastService}
     */
    const toastService = useService(ToastService);

    const { search: locationSearch } = useLocation();
    const navigate = useNavigate();

    const [isSubmitting, updateIsSubmitting] = useState(false);
    const [rule, setRule] = useState(null);

    const {
        params: {
            editRuleId
        }
    } = useQueryString(locationSearch);

    const { programId } = useParams();

    const afterSuccess = () => {
        const queryParams = new URLSearchParams({
            activeTab: LIST_TABS.RULES
        }).toString();
        toastService.success("Rule has been successfully saved");
        navigate(`${PROGRAMS_GROUP_LINKS.LIST}/${programId}/rule-goal?${queryParams}`);
        updateIsSubmitting(false);
    };

    const apiFunction = (rule) => {
        if (editRuleId) {
            return programsService.updateRule(editRuleId, programId, rule);
        }

        return programsService.createRule(programId, rule);
    };

    const createProgram = (values) => {
        updateIsSubmitting(true);

        apiFunction({
            actionType: values.actionType,
            fullName: values.fullName,
            order: Number(values.order),
            priority: Number(values.priority),
            publicComment: values.publicComment || null,
            publicName: values.publicName || null,
            simpleExamples: values.simpleExamples || null,
            simpleName: values.simpleName || null,
            sources: values.sources,
            status: values.status,
            type: values.type,
            collectionByTypeIds: map(values?.collectionByType, "id"),
            segmentIds: map(values?.userSegments, "id")
        })
            .then(afterSuccess)
            .finally(() => updateIsSubmitting(false));
    };

    const mapRuleToForm = (rule) => {
        return {
            fullName: rule.fullName,
            publicName: rule.publicName,
            simpleName: rule.simpleName,
            actionType: rule.actionType,
            type: rule.type,
            priority: rule.priority,
            status: rule.status,
            order: rule.order,
            publicComment: rule.publicComment || '',
            simpleExamples: rule.simpleExamples || '',
            collectionByType: rule.programRuleIngredientGroups?.data
                ? rule?.programRuleIngredientGroups?.data?.map(item => ({
                    id: item.ingredientGroupId,
                    name: item.name
                }))
                : rule?.programRuleRecipeTags?.data?.map(item => ({
                        id: item.recipeTagId,
                        name: item.name
                    })),
            userSegments: rule?.programRuleSegments?.data?.map(item => ({
                    id: item.segmentId,
                    name: item.segment?.fullName
                })),
            sources: rule?.programRuleSources?.data?.map(source => ({
                    title: source.title,
                    links: source?.programRuleSourceLinks?.data.map((rule) => rule?.link) || [],
                })) || []
        };
    };

    useEffect(() => {
        if (editRuleId) {
            programsService.getRuleById(editRuleId)
                .then((data) => {
                    setRule(mapRuleToForm(data));
                });
        }
    }, [editRuleId]);

    const breadcrumbs = {
        title: editRuleId ? "Edit rule" : "Create rule",
        breadcrumbItems: [
            { title: "Nutrition", link: PROGRAMS_GROUP_LINKS.BASE },
            { title: editRuleId ? "Edit rule" : "Create rule" }
        ]
    };

    return <BaseLayoutWithCard breadcrumbs={breadcrumbs}>
        <Formik
            initialValues={rule || initialValues}
            validationSchema={validationSchema}
            validateOnBlur
            onSubmit={createProgram}
            enableReinitialize
        >
            {({ errors, handleSubmit, values, setFieldValue }) => {
                const disabledAddRowSources = values.sources && !values.sources.every(item => {
                    return Boolean(item.title);
                });

                return <form className={joinClassNames("form-horizontal p-2", isSubmitting && "pointer-events-none")}
                             onSubmit={handleSubmit}>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <Title
                            title="General information"
                        />

                        <label className="d-flex align-items-center gap-1">
                            Visibility
                            <Switch state={values.status === RULES_STATUSES.ACTIVE}
                                    updateState={() => setFieldValue('status', values.status === RULES_STATUSES.ACTIVE
                                        ? RULES_STATUSES.INACTIVE
                                        : RULES_STATUSES.ACTIVE)
                                    }
                            />
                        </label>
                    </div>
                    <section className="w-50">
                        <FormikInput
                            placeholder="Please enter a full rule’s name (required)"
                            name="fullName"
                            maxLength={MAX_RULE_NAME_LENGTH}
                            label="Full name"
                            containerClassName="mt-3"
                        />

                        <FormikInput
                            placeholder="Please enter public name"
                            name="publicName"
                            maxLength={MAX_RULE_NAME_LENGTH}
                            label="Public name"
                            containerClassName="mt-3"
                        />

                        <FormikInput
                            placeholder="Please enter simple name"
                            name="simpleName"
                            maxLength={MAX_RULE_NAME_LENGTH}
                            label="Simple name"
                            containerClassName="mt-3"
                        />

                        <FormikReactSelect
                            name="actionType"
                            options={ruleActionOptions}
                            label="Action"
                            setFieldValue={setFieldValue}
                            containerClassName="mt-3"
                        />


                        <div className="mt-3">
                            <label>Select type:</label>
                            <div className="d-flex gap-3">
                                <RadioButton
                                    label="Ingredient group"
                                    onChange={() => {
                                        setFieldValue("collectionByType", []);
                                        setFieldValue("type", RULE_TYPE.INGREDIENT_GROUP);
                                    }}
                                    checked={values.type === RULE_TYPE.INGREDIENT_GROUP}
                                    name="type"
                                />
                                <RadioButton
                                    label="Recipe tag"
                                    onChange={() => {
                                        setFieldValue("collectionByType", []);
                                        setFieldValue("type", RULE_TYPE.RECIPE_TAG);
                                    }}
                                    checked={values.type === RULE_TYPE.RECIPE_TAG}
                                    name="type"
                                />
                            </div>
                        </div>
                        <div className="mt-3">
                            {
                                values.type === RULE_TYPE.INGREDIENT_GROUP
                                    ? <IngredientGroupDropdown
                                        value={values.collectionByType}
                                        onChange={(value) => setFieldValue("collectionByType", value)}
                                        placeholder="Select ingredient group (required)"
                                        label="Ingredient group"
                                    />
                                    : <RecipeTagDropdown
                                        value={values.collectionByType}
                                        onChange={(value) => setFieldValue("collectionByType", value)}
                                        placeholder="Select recipe tag (required)"
                                        label="Reсipe tag"
                                    />
                            }
                        </div>

                        <FormikInput
                            type="number"
                            placeholder="Please enter priority from 0 to 10"
                            name="priority"
                            max={MAX_PRIORITY}
                            min={MIN_PRIORITY}
                            label="Priority"
                            containerClassName="mt-3"
                        />

                        <div className="mt-3">
                            <UserSegmentsDropdown
                                value={values.userSegments}
                                onChange={(value) => setFieldValue("userSegments", value)}
                                placeholder="Select user segment"
                                label="User segments"
                            />
                        </div>

                        <FormikInput
                            type="number"
                            placeholder="Please enter order"
                            name="order"
                            min={MIN_ORDER}
                            max={MAX_ORDER}
                            label="Order"
                            containerClassName="mt-3"
                        />
                    </section>

                    <Title
                        title="About rule"
                        className="mt-5"
                    />

                    <RichTextEditor
                        value={values.publicComment || initialValues.publicComment}
                        onChange={(text) => {
                            setFieldValue('publicComment', text);
                        }}
                        maxLength={MAX_RULE_PUBLIC_COMMENT_LENGTH}
                        name="publicComment"
                        label="Public Comment"
                        description="Enter public comment about the rule."
                        placeholder="Enter public comment about the rule..."
                    />

                    <RichTextEditor
                        value={values.simpleExamples || initialValues.simpleExamples}
                        onChange={(text) => {
                            setFieldValue('simpleExamples', text);
                        }}
                        maxLength={MAX_RULE_SIMPLE_EXAMPLES_LENGTH}
                        name="simpleExamples"
                        label="Simple Examples"
                        description="Enter simple examples about the rule."
                        placeholder="Enter simple examples about the rule..."
                    />

                    <section className="mt-3 w-50">
                        <label>Add source:</label>

                        <div className="d-flex flex-column gap-2">
                            {values?.sources?.map((item, index) => (
                                <SourceItem
                                    key={`sources-${index}`}
                                    value={values.sources[index]}
                                    name={`sources.${index}`}
                                    onDeleteItem={(indexToRemove) => {
                                        const filteredSource = values.sources.filter((value, currentIndex) => currentIndex !== index);
                                        setFieldValue("sources", filteredSource);
                                    }}
                                    onDeleteLink={(indexToRemove) => {
                                        const filteredLinks = values.sources[index].links.filter((value, index) => index !== indexToRemove);
                                        setFieldValue(`sources.${index}.links`, filteredLinks);
                                    }}
                                    addLink={() => {
                                        setFieldValue(`sources.${index}.links`, [...values.sources[index].links, null]);
                                    }}
                                />
                            ))}
                        </div>


                        <div className={
                            classnames(
                                "d-flex align-items-center cursor-pointer mt-2 text-primary",
                                disabledAddRowSources && "opacity-50"
                            )}
                             onClick={() => {
                                 if (disabledAddRowSources) return;
                                 setFieldValue("sources", [...values.sources, defaultLinksItem]);
                             }
                             }>
                            <i className="bx bx-plus me-2"/>
                            Add row
                        </div>
                    </section>

                    <div className="d-flex justify-content-end mt-5">
                        {<Button
                            color={BUTTON_COLORS.primary}
                            type="submit"
                            disabled={!!Object.keys(errors).length || isSubmitting}
                        >
                            Save rule
                        </Button>}
                    </div>
                </form>;
            }}
        </Formik>
    </BaseLayoutWithCard>;
}