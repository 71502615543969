import React from "react";
import { renderRichText } from "@storyblok/react";
import Wrapper from "./Wrapper";

const RichText = ({ blok }) => {
    if (!blok) {
        return null;
    }

    return (
        <Wrapper blok={blok}>
            <div>
                {blok.content && (
                    <div dangerouslySetInnerHTML={{ __html: renderRichText(blok.content) }}/>
                )}
            </div>
        </Wrapper>
    );
};

export default RichText;
