import React, { useState, useEffect, useCallback } from "react";
import { stringifyParams, useLocationQuery } from "../../../../base/hooks/useQueryString";
import {
  CustomModal,
  ModalHeader,
  ModalBody,
} from "../../../../base/components/CustomModal";
import Table from "../../../../base/components/Table";
import { useSearchParams } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import { useLoading } from "../../../../base/hooks/useLoading";
import { useQueryString } from "../../../../base/hooks/useQueryString";
import { useHighlight } from "../../../../base/hooks/useHighlight";
import { formatISODate } from "../../../../base/helpers/date";
import { useService } from "../../../../base/hooks/useService";
import TableSearch from "../../../../base/components/Table/tableSearch";
import {
  useSortProvider,
  useSearchProvider,
  useLocationSource,
  useFilterProvider,
  parseSorting,
} from "../../../../base/components/Table/hooks";
import { LIMIT_OPTIONS } from "../../../../base/constants/pagination";
import Icon from "../../../../base/components/Icon";
import joinClassNames from "../../../../base/helpers/joinClassNames";
import UserTagService from "../../../../services/UserTagService";
import { QUIZ_MAP } from "../constants";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";
import Button from "../../../../base/components/Button";
import ConfirmPopup from "../../../../base/components/ConfirmPopup";
import ToasterService from "../../../../services/ToastService";

export const columns = [
  {
    Header: "#",
    width: 50,
    Cell: ({ row: { index } }) => {
      const {
        params: { offset = 0 },
      } = useLocationQuery();
      return <label className="mb-0">{index + 1 + (offset ?? 0)}</label>;
    },
  },
  {
    Header: "Variable name",
    accessor: "key",
    width: 200,
    canSort: true,
    Cell: ({ value }) => {
      const { params: { t } } = useLocationQuery();
      const { decorateText } = useHighlight(t);
      return (
        <section className="w-fit-content d-flex align-items-center">
          <span {...decorateText(value)} />
        </section>
      );
    },
  },
  {
    Header: "Value",
    accessor: "value",
    width: 150,
    Cell: ({ value }) => (
      <section className="w-fit-content d-flex align-items-center">
        {value}
      </section>
    ),
  },
  {
    Header: "Quiz type",
    accessor: "quizType",
    width: 100,
    canSort: true,
    Cell: ({ value }) => (
      <section className="w-fit-content d-flex align-items-center">
        {QUIZ_MAP[value]}
      </section>
    ),
  },

  {
    Header: "Date",
    accessor: "createdAt",
    width: 150,
    canSort: true,
    Cell: ({ value }) => (
      <section className="w-fit-content d-flex align-items-center">
        {formatISODate(value)}
      </section>
    ),
  },
  {
    Header: () => <div className="">Actions</div>,
    className: 'last-sticky-column',
    accessor: 'actions',
    width: 60,
    Cell: (item) => (
      <section className="d-flex align-items-center recommendation-icons">
        <Icon icon="edit" className="recommendation-icons__edit"
              onClick={(event) => {
                event.stopPropagation();
                item.actions.edit(item);
              }}
        />
        <Icon
          id={`delete-${item.cell?.row?.original?.id}`}
          icon="trashIcon"
          className={joinClassNames(
            "recommendation-icons__trash outline-none",
          )}
          onClick={(event) => {
            event.stopPropagation();
            item.actions.delete(item);
          }}
        />
      </section>
    ),
  }
];

export const SearchPlaceholder = () => <>No variable names found</>;

export const TableHeader = ({ searchProvider, updateEditTag }) => {
  return (
    <section className="d-flex flex-column">
      <section className="d-flex w-100 mb-3 align-items-center justify-content-between">
        <TableSearch
          className="biomarkers-search me-3"
          search={searchProvider.getValue()}
          onSearch={searchProvider.setValue}
          autoFocus={true}
          placeholder="Search variable name"
        />
        <Button
          color={BUTTON_COLORS.primary}
          type="button"
          onClick={() => updateEditTag()}
          className="mb-0"
        >
          + Add variable
        </Button>
      </section>
    </section>
  );
};

export default function TagsPopup({ isOpen, updateIsOpen, updateEditTag }) {
  /**
   * @type {UserTagService}
   */
  const userTagService = useService(UserTagService);
  /**
   * @type {ToasterService}
   */
  const toastService = useService(ToasterService);
  const { search: locationSearch } = useLocation();
  const [showDeletePopupWithId, updateShowDeletePopupWithId] = useState("");

  const {
    params: {
      t,
      limit = LIMIT_OPTIONS[0].value,
      offset = 0,
      key,
      createdAt,
      quizType,
    },
  } = useQueryString(locationSearch);

  const [searchParams, setSearchParams] = useSearchParams();
  const locationSource = useLocationSource();
  const searchProvider = useSearchProvider({
    source: locationSource,
    scope: "",
    alias: "t",
    onApplyClearScope: [""],
  });

  const generateSortProviderParams = (name) => {
    const allParams = ["key", "createdAt", "quizType"];
    return {
      source: locationSource,
      alias: name,
      scope: "",
      onApplyClearScope: allParams.filter((paramName) => paramName !== name),
    };
  };

  const clearParams = useCallback(() => {
    const allParams = ["key", "createdAt", "t", "limit", "offset", "quizType"];
    allParams.forEach((i) => {
      searchParams.delete(i);
    });
    setSearchParams(searchParams);
  }, []);

  const filterProvider = useFilterProvider({
    source: locationSource,
    scope: "",
    alias: "categoryIds",
    onApplyClearScope: ["offset"],
  });

  const nameSortProvider = useSortProvider(generateSortProviderParams("key"));
  const quizTypeSortProvider = useSortProvider(
    generateSortProviderParams("quizType")
  );
  const createdAtSortProvider = useSortProvider(
    generateSortProviderParams("createdAt")
  );

  const { id } = useParams();
  const [isLoading, { registerPromise }] = useLoading(false);
  const [records, setRecords] = useState([]);

  const onClose = () => updateIsOpen(false);

  const fitlerSearch = useCallback((i) => {
    return i.key.includes(t || '')
  }, [t])

  const fetchRecords = useCallback(() => {
    const sortObject = parseSorting({
      key,
      createdAt,
      quizType,
    });

    return userTagService
      .getUserTags(id, {
        ...sortObject,
      })
      .then(({ data }) => {
        setRecords(data);
      });
  }, [id, limit, offset, key, createdAt, quizType]);

  useEffect(() => {
    if (isOpen === true) {
      registerPromise(fetchRecords());
    }
  }, [isOpen, fetchRecords]);

  useEffect(() => {
    if (isOpen === false) {
      clearParams();
    }
  }, [isOpen, clearParams]);


  return (
    <CustomModal scrollable={true} size="xl" className="small-modal" isOpen={isOpen}>
      <ModalHeader onClose={onClose}>Latest assessment data</ModalHeader>
      <ModalBody>
        <Table
          columns={columns}
          data={records.filter(fitlerSearch)}
          loading={isLoading}
          HeaderComponent={() => TableHeader({ searchProvider, updateEditTag })}
          searchProvider={searchProvider}
          sortProviders={{
            ["key"]: nameSortProvider,
            ["createdAt"]: createdAtSortProvider,
            ["quizType"]: quizTypeSortProvider,
          }}
          commonPlaceholder={<div className="position-absolute d-flex align-items-center justify-content-center w-95 h-60">No variable names found</div>}
          placeholderForSearch={<SearchPlaceholder />}
          placeholderForSearchClassName="w-auto simple-table-spinner"
          filterProvider={filterProvider}
          withLocation
          isLimitEditable={true}
          withoutPagination={true}
          actions={{
            edit: (cell) => updateEditTag(cell?.row?.original?.id),
            delete: (cell) => updateShowDeletePopupWithId(cell?.row?.original?.id),
          }}
        />
      </ModalBody>
      {!!showDeletePopupWithId &&
        <ConfirmPopup
          isOpen={showDeletePopupWithId}
          updateIsOpen={() => updateShowDeletePopupWithId("")}
          onSubmit={() => {
            userTagService.deleteUserTag(showDeletePopupWithId);
            updateShowDeletePopupWithId("");
            toastService.success("Variable was successfully deleted");
            updateIsOpen(false);
          }}
          title="Delete"
          description="Are you sure you want to delete the chosen variable?"
          submitBtnText="Delete"
        />
      }
    </CustomModal>
  );
}
