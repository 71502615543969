// Libs
import React, { useState } from "react";

// Components, Views, Screens
import Table from "../../../../../../base/components/Table";
import { columns, ExerciseGroupCollapseRows, TableHeader } from "./components";
import { WORKOUT_SECTION_TOPIC_TYPES } from "../../../WorkoutForm/constants";


export function WorkoutDescriptionTable({ sections }) {

    const [collapsedRows, updateCollapsedRows] = useState([]);

    const formatSectionsForTable = (sections) => {

        const formattedData = [];

        const formatExerciseToRows = ({ exercise, exerciseIndex, section = {}, sectionIndex = null }) => {
            const rows = [];

            rows.push({
                id: `${sectionIndex}${exerciseIndex}`,
                index: !exerciseIndex ? sectionIndex + 1 : null,
                name: !exerciseIndex ? section.name : null,
                exerciseTitle: exercise.title,
                set: exercise.sets.length ? exercise.sets[0] : {},
                type: WORKOUT_SECTION_TOPIC_TYPES.EXERCISE
            });

            exercise.sets.slice(1).forEach((set) => {
                rows.push({
                    index: null,
                    name: null,
                    exerciseTitle: null,
                    set: set
                });
            });

            return rows;
        };

        sections.forEach((section, sectionIndex) => {
            section.exercises.forEach((exercise, exerciseIndex) => {
                if (exercise.exerciseId) {
                    formattedData.push(
                        ...formatExerciseToRows({
                            exercise,
                            exerciseIndex,
                            section,
                            sectionIndex
                        })
                    );
                } else {
                    formattedData.push({
                        id: `${sectionIndex}${exerciseIndex}`,
                        type: exercise.type,
                        index: !exerciseIndex ? sectionIndex + 1 : null,
                        name: !exerciseIndex ? section.name : null,
                        set: {
                            repetitions: exercise.repetitions,
                            rest: exercise.rest
                        },
                        exercises: exercise.exercises.reduce((acc, subExercise, subExerciseIndex) => {
                            return [
                                ...acc,
                                ...formatExerciseToRows({
                                    exercise: subExercise,
                                    exerciseIndex: subExerciseIndex
                                })];
                        }, [])
                    });
                }
            });
        });


        return formattedData;
    };


    const toggleCollapse = (id) => {
        if (collapsedRows.includes(id)) {
            updateCollapsedRows(prevState => prevState.filter(item => item !== id));
            return;
        }
        updateCollapsedRows(prevState => [...prevState, id]);
    };

    return (
        <div className="ps-2 mt-3">
            <Table
                columns={columns}
                data={formatSectionsForTable(sections)}
                withoutPagination
                tableClassName="h-auto min-h-auto overflow-x-auto"
                useCollapseRule={({ type }) => {
                    return [WORKOUT_SECTION_TOPIC_TYPES.SUPERSET, WORKOUT_SECTION_TOPIC_TYPES.CIRCUIT]
                        .includes(type?.toString());
                }}
                collapsedState={collapsedRows}
                toggleCollapse={toggleCollapse}
                CollapsedComponent={ExerciseGroupCollapseRows}
                collapsedRowClass="bg-body-secondary no-hover"
                hover={false}
            />
        </div>
    );
}