import React, { useEffect, useMemo, useState } from 'react'
import { useService } from "../../../../base/hooks/useService";
import RecommendationsService from "../../../../services/RecommendationsService";
import { useLoading } from "../../../../base/hooks/useLoading";
import { HIERARCHY_OPTIONS, selectAllRecommendations } from "./constants";
import FilterDropdown from "../../Biomarkers/CreateEdit/components/FilterDropdown";
import { keyBy } from "lodash";
import { useParams } from "react-router-dom";

export default function ChildrenRecommendationsDropdown({
                                                          label,
                                                          onChange,
                                                          value,
                                                          maxLength,
                                                          placeholder,
                                                          containerClassName,
                                                        }) {
  /**
   * @type {RecommendationsService}
   */
  const recommendationsService = useService(RecommendationsService);
  const { id: currentRecommendationId } = useParams();

  const selectedRecommendations = useMemo(() => value?.map(recommendation => {
      return {
        ...recommendation,
        id: recommendation.recommendationId,
      }
    }
  ) || [], [value]);

  const [isOpen, updateIsOpen] = useState(false);
  const [recommendations, updateRecommendations] = useState([]);
  const [isLoading, { registerPromise }] = useLoading(true);
  const [selectedChildrenRecommendations, updateSelectedChildrenRecommendations] = useState(selectedRecommendations);

  const selectedChildrenRecommendationsById = useMemo(() => keyBy(selectedChildrenRecommendations, 'id'), [selectedChildrenRecommendations]);

  const getRecommendations = () =>
    registerPromise(
      recommendationsService.getRecommendations({
        offset: 0,
        limit: 100,
        hierarchyType: HIERARCHY_OPTIONS.CHILD,
      }).then(({ data }) => {
        const recommendationsWithoutCurrentRecommendation = data.filter(({ id }) => id !== parseInt(currentRecommendationId));
        updateRecommendations(recommendationsWithoutCurrentRecommendation);
      })
    );

  useEffect(() => {
    getRecommendations()
  }, []);

  const handleCheckboxChange = (value, currentState, updateState) => {
    if(currentState.find(({ id }) => value.id === id)) {
      updateState(prevState => prevState.filter(({ id }) => id !== value.id))
      return
    }
    updateState(prevState => [...prevState, value]);
  }

  const isAllChildrenRecommendationsFiltersSelected = useMemo(() =>
    recommendations.length === selectedChildrenRecommendations.length, [selectedChildrenRecommendations, recommendations]);

  const selectAllChildrenRecommendations = (allChildrenRecommendations, currentState, updateState) => {
    if(allChildrenRecommendations.length === currentState.length) {
      updateState([]);
      return;
    }
    updateState(allChildrenRecommendations);
  }

  useEffect(() => {
    onChange(selectedChildrenRecommendations.map(({ id }) => id));
  }, [selectedChildrenRecommendations]);

  return (
    <FilterDropdown
      label={label}
      value={selectedChildrenRecommendations.map(({ title }) => title).join(", ")}
      showPlaceholder={selectedChildrenRecommendations.length === 0}
      placeholder="Select children recommendations"
      listOfCheckboxes={recommendations.map((recommendation) => {
        return {
          id: recommendation.id,
          text: recommendation.title,
          value: !!selectedChildrenRecommendationsById[recommendation.id],
          onChange: () => handleCheckboxChange(recommendation, selectedChildrenRecommendations, updateSelectedChildrenRecommendations)
        }
      })}
      selectAllCheckbox={{
        ...selectAllRecommendations, value: isAllChildrenRecommendationsFiltersSelected, onChange: () => {
          selectAllChildrenRecommendations(recommendations, selectedChildrenRecommendations, updateSelectedChildrenRecommendations)
        }
      }}
      clearOption={() => updateSelectedChildrenRecommendations([])}
    />
  )
}