import React from "react";
import { useField } from "formik";

import ExerciseItem from "../ExerciseItem";
import Icon from "../../../../../../../../../../base/components/Icon";

import {
    MAX_REPETITIONS,
    MIN_REPETITIONS,
    MIN_REST,
    WORKOUT_SECTION_TOPIC_TYPES
} from "../../../../../../constants";
import { initialExercise } from "../../../../constants";

import classes from "./ExerciseGroupItem.module.scss";
import FormikInput from "../../../../../../../../../../base/components/FormikInput";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";


const EXERCISE_GROUP_TITLE = {
    [WORKOUT_SECTION_TOPIC_TYPES.CIRCUIT]: 'Circuit',
    [WORKOUT_SECTION_TOPIC_TYPES.SUPERSET]: 'Superset'
};

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle
});

export default function ExerciseGroupItem({ exercises, name, index = 0, dragHandleProps, removeItem }) {
    const [{ value }, , { setValue }] = useField(name);

    const addExercise = () => {
        setValue({
            ...value,
            exercises: [...value.exercises, initialExercise]
        });
    };

    const removeExercise = (index) => {
        setValue({
            ...value,
            exercises: value?.exercises?.filter((_, itemIndex) => itemIndex !== index)
        });
    };

    const onDragEnd = (result) => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        const items = Array.from(value.exercises);

        const [reorderedItem] = items.splice(source.index, 1);

        items.splice(destination.index, 0, reorderedItem);

        setValue({
            ...value,
            exercises: items
        });
    };

    return <div className={classes.ExerciseGroupContainer}>
        <div className={classes.ExerciseGroupHeader}>
            <div className={classes.ExerciseGroupTitle}>
                {EXERCISE_GROUP_TITLE[value.type]}
            </div>

            <div className="d-flex align-items-center justify-content-end gap-4">

                <div
                    {...dragHandleProps}
                >
                    <Icon
                        icon="dndIcon"
                        width={24}
                        height={24}
                        className="cursor-pointer impacts-section__view"
                    />
                </div>

                <Icon
                    icon={"trashIcon"}
                    width={24}
                    height={24}
                    onClick={(event) => {
                        event.stopPropagation();
                        removeItem();
                    }}
                    className="cursor-pointer"
                />
            </div>
        </div>

        <li className={classes.ListItem}>
            <div className={classes.Index}>{index + 1}.</div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="workoutSetUpExerciseGroup">
                    {(provided) => (
                        <ol
                            type="a"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {
                                value.exercises.map((item, index, arr) => <Draggable
                                        key={index}
                                        draggableId={index.toString()}
                                        index={index}
                                        isDragDisabled={false}>

                                        {(provided, snapshot) => (<div
                                                {...provided.draggableProps}
                                                ref={provided.innerRef}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                                key={index}
                                                className="mb-3"
                                            >
                                                <ExerciseItem
                                                    item={item}
                                                    exercises={exercises}
                                                    name={`${name}.exercises[${index}]`}
                                                    removeItem={() => removeExercise(index)}
                                                    dragHandleProps={provided.dragHandleProps}
                                                    isShowDelete={arr.length > 1}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                )
                            }
                            {provided.placeholder}
                        </ol>
                    )}
                </Droppable>
            </DragDropContext>
        </li>

        <div className={classes.AdditionalFieldWrapper}>
            <div className="w-100">
                <FormikInput
                    name={`${name}.repetitions`}
                    type="number"
                    label="Quantity of repetition"
                    min={MIN_REPETITIONS}
                    max={MAX_REPETITIONS}
                />
            </div>
            <div className="w-100">
                <FormikInput
                    name={`${name}.rest`}
                    type="number"
                    label="Rest (s)"
                    placeholder="0 (optional)"
                    min={MIN_REST}
                />
            </div>
        </div>

        <div
            className="text-primary ms-4 ps-3 w-fit-content cursor-pointer"
            onClick={addExercise}
        >
            + Add exercise
        </div>

    </div>;
}