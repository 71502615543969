import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CustomModal, ModalBody, ModalHeader } from "../../../../base/components/CustomModal";
import { Tabs } from "../../Biomarkers/CreateEdit/components/ResultSummaryTabs";
import { useLoading } from "../../../../base/hooks/useLoading";
import { Spinner } from "reactstrap";
import { formatISODate } from "../../../../base/helpers/date";

const ENTER_SYMBOL = String.fromCharCode(13);
const LINE_BREAK_CHAR = '\n';

export default function FilePopup({
  isOpen,
  updateIsOpen,
  details = {},
  afterOnClose,
}) {
  const { file, statusFile, resultFile } = details;

  const [isLoading, { enable, disable }] = useLoading(true);

  const [activeTab, updateActiveTab] = useState(0)
  const [text, updateText] = useState("");

  const tabs = useMemo(() => {
    const fileTabs = [];
    if(file) fileTabs.push({ label: "New", file })
    if(statusFile) fileTabs.push({ label: "Status", file: statusFile })
    if(resultFile) fileTabs.push({ label: "Results", file: resultFile })
    return fileTabs;
  }, [file, statusFile, resultFile]);

  const onClose = () => {
    updateIsOpen(false);
    afterOnClose()
  }

  const getFileData = useCallback(() => {
    enable();
    fetch(tabs[activeTab].file.link)
      .then((response) => {
        return response.text().then((text) => {
          updateText(text.replaceAll(ENTER_SYMBOL, LINE_BREAK_CHAR))
        });
      });
    setTimeout(() => disable(), 500)
  }, [activeTab]);

  useEffect(() => {
    getFileData()
  }, [getFileData]);

  return (
    <CustomModal isOpen={isOpen} className="hl7-modal" toggle={onClose}>
      <ModalHeader onClose={onClose} className="text-dark" sectionClassName="border-none">
        View HL7 result
      </ModalHeader>
      <ModalBody>
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          updateActiveTab={updateActiveTab}
        />

        {isLoading ?
          <section className="w-100 spinner d-flex align-items-center justify-content-center">
            <Spinner color="info" />
          </section>
          :
          <section className="">
            <label>Updated: <span className="text-secondary">{formatISODate(tabs[activeTab].file?.updatedAt)}</span></label>
            <p className="file-text mb-0">
              {text}
            </p>
          </section>
        }

      </ModalBody>
    </CustomModal>
  )
}