import React, { useEffect, useState } from 'react';
import Breadcrumb from "./Common/Breadcrumb2";
import { Card, CardBody, Col, Row } from "reactstrap";
import PageSpinner from "./PageSpinner";
import Switch from "./Switch";
import { useDarkMode, useUpdateDarkMode } from "../context/darkMode";

export default function UICustomisationLayoutWithCard({
  breadcrumbs,
  loading,
  cardClassName,
  children,
  linkBack
}) {
  const isDarkMode = useDarkMode();
  const updateDarkMode = useUpdateDarkMode();
  return (
    <>
      <Row>
        <Col lg="10">
          <Breadcrumb {...breadcrumbs} linkBack={linkBack}/>
        </Col>
        <Col>
          <label className="d-flex align-items-center justify-content-end gap-3">
            Dark mode
            <Switch state={isDarkMode}
              updateState={() => {
                updateDarkMode(!isDarkMode)
              }}
            />
          </label>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody className={cardClassName}>
              {loading ? <PageSpinner/> : children}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}