import React, { useRef } from "react";
import classes from "./MobilePreview.module.scss";
import iphone from "../../../assets/images/iphone.svg"
import useScrollOnDrag from "react-scroll-ondrag";

export default function MobilePreview({ children }) {
  const containerRef = useRef(null);
  const { events } = useScrollOnDrag(containerRef);

  return (
    <div className={classes.MobilePreviewContainer} style={{background: `url(${iphone})`}}>
      <div className={classes.MobilePreviewBody} {...events} ref={containerRef}>
        {children}
      </div>
    </div>
  )
}