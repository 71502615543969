import Http from "./Http";

class ProgramsService extends Http {
    static $displayName = "ProgramsService";

    async getPrograms(params) {
        return await this.get(`/programs`, { params });
    }

    async getProgramById(id) {
        return await this.get(`/programs/${id}`);
    }

    createProgram(values) {
        return this.post(`/programs`, values);
    }

    deleteProgram(id) {
        return this.delete(`/programs/${id}`);
    }

    updateProgram(id, values) {
        return this.put(`/programs/${id}`, values);
    }

    copyProgram(id) {
        return this.post(`/programs/${id}/copy`);
    }

    changeStatus(id, newStatus) {
        return this.patch(`/programs/${id}/status`, { status: newStatus });
    }

    async getRules(programId, params) {
        return await this.get(`/programs/${programId}/rules`, { params });
    }

    getRuleById(id) {
        return this.get(`/programs/rules/${id}`);
    }

    createRule(programId, values) {
        return this.post(`/programs/${programId}/rules`, values);
    }

    updateRule(id, programId, values) {
        return this.put(`/programs/rules/${id}`, values);
    }

    deleteRule(id) {
        return this.delete(`/programs/rules/${id}`);
    }

    async getGoals(programId, params) {
        return await this.get(`/programs/${programId}/goals`, { params });
    }

    getGoalById(id) {
        return this.get(`/programs/goals/${id}`);
    }

    createGoal(programId, values) {
        return this.post(`/programs/${programId}/goals`, values);
    }

    updateGoal(id, programId, values) {
        return this.put(`/programs/goals/${id}`, values);
    }

    deleteGoal(id) {
        return this.delete(`/programs/goals/${id}`);
    }

    getFoods() {
        return this.get("/dashboard/food-intolerance/foods");
    }

    getNutrients() {
        return this.get("/nutrients");
    }
}

export default ProgramsService;
