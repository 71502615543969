
export const CONTENTS_GROUP_ROUTES = {
  BASE: "/",
  LISTS: "/lists/*",
  FORM_CONTENTS: "/form/contents",
  FORM_CATEGORIES: "/form/categories",
  CONTENTS_DETAILS: "/lists/contents/:id",
  CATEGORIES_DETAILS: "/lists/categories/:id",
  CATEGORIES_FORM: "/form/categories/*"
};

export const CONTENTS_GROUP_LINKS = {
  BASE: "/app/contents",
  LISTS: "/app/contents/lists",
  CONTENTS_LIST: "/app/contents/lists/contents",
  CATEGORIES_LIST: "/app/contents/lists/categories",
  TAGS_LIST: "/app/contents/lists/tags",
  FORM_CONTENTS: "/app/contents/form/contents",
  FORM_CATEGORIES: "/app/contents/form/categories",

};