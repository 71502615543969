import React from "react";
import { CONTENT_TYPE_API_ID, PROGRAM_PLAN_CONTENT_TYPE } from "../../../constants";
import { RecipesDropdown } from "../../../../../../base/components/Dropdowns/RecipesDropdown/RecipesDropdown";
import { WorkoutsDropdown } from "../../../../../../base/components/Dropdowns/WorkoutsDropdown/WorkoutsDropdown";
import {
    ProgramContentsDropdown
} from "../../../../../../base/components/Dropdowns/ProgramContentDropdown/ProgramContentDropdown";

export const DROPDOWN_COMPONENTS = {
    [PROGRAM_PLAN_CONTENT_TYPE.RECIPE]: RecipesDropdown,
    [PROGRAM_PLAN_CONTENT_TYPE.WORKOUT]: WorkoutsDropdown,
    [PROGRAM_PLAN_CONTENT_TYPE.ARTICLE]: (props) =>
        <ProgramContentsDropdown contentType={CONTENT_TYPE_API_ID[PROGRAM_PLAN_CONTENT_TYPE.ARTICLE]} {...props}  />,
    [PROGRAM_PLAN_CONTENT_TYPE.AUDIO]: (props) =>
        <ProgramContentsDropdown contentType={CONTENT_TYPE_API_ID[PROGRAM_PLAN_CONTENT_TYPE.AUDIO]} {...props}  />,
    [PROGRAM_PLAN_CONTENT_TYPE.VIDEO]: (props) =>
        <ProgramContentsDropdown contentType={CONTENT_TYPE_API_ID[PROGRAM_PLAN_CONTENT_TYPE.VIDEO]} {...props}  />,
}