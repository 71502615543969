import React, { useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import Checkbox from "../../../../base/components/Checkbox";
import Button from "../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";
import { useNavigate } from "react-router-dom";
import TableSearch from "../../../../base/components/Table/tableSearch";
import { BIOMARKERS_GROUP_LINKS } from "../config";
import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import { useHighlight } from "../../../../base/hooks/useHighlight";
import Icon from "../../../../base/components/Icon";
import joinClassNames from "../../../../base/helpers/joinClassNames";
import { ReactComponent as activate } from "../../../../assets/images/bx-power-off.svg";
import { ReactComponent as deactivate } from "../../../../assets/images/bx-check-circle.svg";

export const SearchPlaceholder = () => (
  <>
    No biomarkers for now.
    <br/>
    Click the “Create biomarker” option to add a new one.
  </>
)

export const TableFilter = ({ filterProvider, listOfCategories }) => {
  const [isOpen, updateIsOpen] = useState(false);
  const currentValue = filterProvider.getValue();
  const initialValue = currentValue ? (typeof currentValue === "object" ? currentValue : [currentValue]) : [];
  const [categories, updateCategories] = useState(initialValue ?? []);

  const submitFilters = () => {
    filterProvider.setValue(categories);
    updateIsOpen(false);
  }

  const handleCheckboxChange = (id) => {
    updateCategories(prevState => {
      return prevState.includes(id) ? prevState.filter(item => item !== id) : [...prevState, id];
    });
  }

  return (
    <section className="d-flex align-items-center">
      <Dropdown
        isOpen={isOpen}
        toggle={() => updateIsOpen(prevState => !prevState)}
        className="d-inline-block"
      >
        <DropdownToggle
          className=" btn btn-outline-primary d-flex align-items-center"
          tag="button"
        >
          <i className="bx bx-filter-alt me-2"/> Filter by category
        </DropdownToggle>

        <DropdownMenu className="filter-menu pb-1 px-1">

          <section className="filter-options mx-1 custom-scrollbar">
            {listOfCategories.map((category, index) => {
              return (
                <Checkbox
                  id={category.id}
                  text={category.name}
                  value={categories?.includes(category.id)}
                  onChange={() => handleCheckboxChange(category.id)}
                  key={index}
                  className="my-2 ms-1"
                />
              )
            })}
          </section>

          <section className="w-100 px-2 pb-2">
            <Button color={BUTTON_COLORS.primary} onClick={submitFilters} className="w-100"
                    disabled={!categories.length}>
              Apply
            </Button>
          </section>

        </DropdownMenu>
      </Dropdown>

      {!!filterProvider.getValue() &&
        <Button
          color={BUTTON_COLORS.transparent}
          onClick={() => {
            updateCategories([]);
            filterProvider.setValue();
          }}
          className="text-danger no-border ms-2"
        >
          Clear filter
        </Button>
      }
    </section>
  )
}

export const TableHeader = ({ searchProvider, filterProvider, categories }) => {
  const navigate = useNavigate();
  return (
    <section className="mb-3 d-flex align-items-center justify-content-between">
      <section className="d-flex align-items-center">
        <TableSearch
          className="biomarkers-search me-3"
          search={searchProvider.getValue()}
          onSearch={searchProvider.setValue}
        />
        <TableFilter filterProvider={filterProvider} listOfCategories={categories}/>
      </section>
      <Button
        type="button"
        color={BUTTON_COLORS.primary}
        onClick={() => navigate(BIOMARKERS_GROUP_LINKS.CREATE_NEW)}
      >
        <i className="mdi mdi-plus me-1"/>
        Create biomarker
      </Button>
    </section>
  )
}

export const columns = [
  {
    Header: "#",
    width: 35,
    Cell: (cellProps) => {
      const { params: { offset = 0 } } = useLocationQuery();
      return <label className="mb-0">{cellProps.row.index + 1 + (offset ?? 0)}</label>;
    },
  },
  {
    Header: "Name",
    accessor: "name",
    width: 410,
    canSort: true,
    Cell: ({ row: { original: { id, ...rest } }, value, collapsedState, toggleCollapse, useCollapseRule }) => {
      const { params } = useLocationQuery();
      const { decorateText } = useHighlight(params.search);
      const isCollapsed = collapsedState.includes(id)
      const canUseCollapse = useCollapseRule(rest);

      return (
        <section className="w-fit-content d-flex align-items-center" onClick={(event) => {
          event.stopPropagation();
          toggleCollapse(id)
        }}>
          <div {...decorateText(value)} className="text-truncate mb-0" style={{ maxWidth: "33vw" }}/>
        </section>
      );
    },
  },
  {
    Header: "Category",
    accessor: "category.name",
    id: "categoryName",
    width: 200,
    canSort: true,
    Cell: (cellProps) => {
      const { params } = useLocationQuery();
      const { decorateText } = useHighlight(params.search);
      return (
        <span {...decorateText(cellProps.value)}/>
      );
    },
  },
  {
    Header: () => <div className="align-right me-2">Actions</div>,
    accessor: "actions",
    width: 210,
    Cell: (cellProps) => {
      const isActive = cellProps.row.original.isActive;

      return (
        <section className="d-flex justify-content-between align-items-center">
          <div className={joinClassNames(
            isActive ? "active-pill" : "deactivated-pill"
          )}>
            {!isActive ? "Deactivated" : "Active"}
          </div>

          <section>
            <Icon
              icon={!isActive ? "deactivate" : "activate"}
              onClick={(event) => {
                event.stopPropagation();
                cellProps.onActivateItem(cellProps.row.original)
              }}
              className="me-4 cursor-pointer"
            />
            <Icon icon="edit" className="me-4 cursor-pointer"
                  onClick={(event) => {
                    event.stopPropagation();
                    cellProps.onEditItem(cellProps.row.original.id)
                  }}
            />
            <Icon icon="trashIcon" className="cursor-pointer"
                  onClick={(event) => {
                    event.stopPropagation();
                    cellProps.onDeleteItem(cellProps.row.original.id)
                  }}
            />
          </section>
        </section>
      );
    },
  },
];