// Libs
import React, { useEffect, useMemo, useState } from "react";
import { Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

// Components, Views, Screens
import Title from "../../Biomarkers/Details/Title";
import BaseLayoutWithCard from "../../../../base/components/BaseLayoutWithCard";
import FormikInput from "../../../../base/components/FormikInput";
import Button from "../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";
import { useQueryString } from "../../../../base/hooks/useQueryString";
import FormikReactSelect from "../../../../base/components/FormikReactSelect";
import { AllergensDropdown } from "../../../../base/components/Dropdowns/AllergensDropdown";
import { MealTypeDropdown } from "../../../../base/components/Dropdowns/MealTypeDropdown";
import { UserSegmentsDropdown } from "../../../../base/components/Dropdowns/UserSegmentsDropdown/UserSegmentsDropdown";

// Hooks, Utils, Helpers
import { useService } from "../../../../base/hooks/useService";
import MealplansService from "../../../../services/MealplansService";
import ToastService from "../../../../services/ToastService";
import { MEALPLANS_GROUP_LINKS } from "../config";
import {
    MAX_AGE,
    MAX_CALORIES,
    MAX_HEIGHT,
    MAX_WEIGHT,
    MIN_AGE,
    MIN_CALORIES,
    MIN_HEIGHT,
    MIN_WEIGHT
} from "./constants";
import { ALLERGEN_TITLES, META_NUTRITION_ALLERGENS } from "../../../../base/constants/foods";
import joinClassNames from "../../../../base/helpers/joinClassNames";
import {
    activityLevelOption,
    cookingPreferenceOption, executeFieldNames,
    initialValues,
    matchDataFieldNames,
    mealplanGoalOption,
    MultiSelectValueItemView,
    sexOptions,
    trainingIntensityOption,
    validationSchema
} from "./form";
import { map, noop } from "lodash";
import MetaNutritionApi from "../../../../services/MetaNutritionApi";
import { META_NUTRITION_SEX } from "../../HealthProgram/Nutrition/RulesGoalsList/GoalsList/CreateEditGoal/constants";
import { NUTRIENTS } from "../../Fitness/WorkoutDetails/components/WorkoutDescriptionTable/constants";
import { PreparationTimeFields } from "./components/PreparationTimeFields";
import { MealplanCalendar } from "./components/MealplanCalendar/MealplanCalendar";
import { Spinner } from "reactstrap";

export function MealplanForm() {
    /**
     * @type {MealplansService}
     */
    const mealplanService = useService(MealplansService);
    /**
     * @type {MetaNutritionApi}
     */
    const metaNutritionServices = useService(MetaNutritionApi);

    /**
     * @type {ToastService}
     */
    const toastService = useService(ToastService);
    const navigate = useNavigate();

    const [isSubmitting, updateIsSubmitting] = useState(false);
    const [isExecuting, updateIsExecuting] = useState(false);

    const [mealplan, updateMealplan] = useState(null);
    const [weekMealplan, setWeekMealplan] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [kcal, setKcal] = useState(0);

    const afterSuccess = () => {
        updateIsSubmitting(false);
        updateIsExecuting(false);
    };

    const { search: locationSearch } = useLocation();

    const {
        params: {
            editMealplanId
        }
    } = useQueryString(locationSearch);

    const programOptions = useMemo(() => {
        return programs.map((program, index) => ({
            value: index,
            label: program.fullName
        }));
    }, [programs]);


    const executeMealplan = ({
                                 intensityLevel,
                                 program,
                                 allergens,
                                 finalWeightGoal,
                                 gender,
                                 age,
                                 weight,
                                 height,
                                 mealType,
                                 calories,
                                 protein,
                                 carbs,
                                 fat,
                                 breakfastTime,
                                 snackTime,
                                 lunchTime,
                                 dinnerTime,
                                 goal,
                                 cookingPreference,
                                 activityLevel
                             }) => {
        updateIsExecuting(true);


        metaNutritionServices.getWeekPlanPlayground({
            goal,
            activityLevel,
            program: programs[program],
            allergens: allergens.map((value) => META_NUTRITION_ALLERGENS[value]),
            goalWeight: Number(finalWeightGoal),
            intensityLevel: Number(intensityLevel),
            sex: META_NUTRITION_SEX[gender],
            age: Number(age),
            weight: Number(weight),
            height: Number(height),
            mealEntries: mealType,
            kcal: Number(calories),
            carbs: Number(carbs),
            proteins: Number(protein),
            fats: Number(fat),
            breakfastTime: breakfastTime && Number(breakfastTime),
            lunchTime: lunchTime && Number(breakfastTime),
            dinnerTime: dinnerTime && Number(dinnerTime),
            snackTime: snackTime && Number(snackTime),
            complexity: cookingPreference
        })
            .then(({ data }) => {
                setWeekMealplan(data);
                afterSuccess();
            })
            .finally(() => updateIsExecuting(false));
    };

    const matchProgram = ({
                              userSegments,
                              age,
                              gender,
                              finalWeightGoal,
                              goal,
                              activityLevel,
                              intensityLevel,
                              weight,
                              height
                          }) => {
        updateIsSubmitting(true)
        const segmentIds = map(userSegments, 'id');

        mealplanService.matchProgram({ segmentIds, age: Number(age), sex: gender })
            .then(({ data }) => {
                setPrograms(data);
                afterSuccess();
                if (!data.length) {
                    toastService.error("Nutrition not found")
                }
            })
            .finally(() => updateIsSubmitting(false));

        metaNutritionServices.getDietPlanPlayground({
            goal,
            activityLevel,
            intensityLevel,
            age: Number(age),
            weight: Number(weight),
            height: Number(height),
            goalWeight: finalWeightGoal,
            sex: META_NUTRITION_SEX[gender]
        }).then((data) => {
            setKcal(data.kcal);
        });
    };


    const breadcrumbs = {
        title: "Mealplan Playground",
        breadcrumbItems: []
    };

    useEffect(() => {
        if (editMealplanId) {
            mealplanService.getMealplanById(editMealplanId)
                .then((data) => {
                    updateMealplan({
                        ...data
                    });
                });
        }
    }, [editMealplanId]);


    return <BaseLayoutWithCard breadcrumbs={breadcrumbs}>
        <Formik
            initialValues={mealplan || initialValues}
            validationSchema={validationSchema}
            validateOnBlur
            onSubmit={noop}
            enableReinitialize
        >
            {({ errors, handleSubmit, values, touched, setFieldValue }) => {
                const hasMatchProgramError = Object.keys(errors).some((key) => matchDataFieldNames.includes(key));

                const {
                    goal,
                    allergens,
                    userSegments,
                    activityLevel,
                    intensityLevel,
                    finalWeightGoal,
                    age,
                    gender,
                    height,
                    weight,
                    mealType
                } = values;

                useEffect(() => {
                    setPrograms([]);

                    executeFieldNames.forEach((name) => {
                        setFieldValue(name, initialValues[name] || undefined);
                    })

                    setWeekMealplan([])
                }, [
                    goal,
                    allergens,
                    userSegments,
                    activityLevel,
                    intensityLevel,
                    finalWeightGoal,
                    age,
                    gender,
                    height,
                    weight,
                    mealType
                ]);

                return <form className={joinClassNames("form-horizontal p-2", isSubmitting && "pointer-events-none")}
                             onSubmit={(e) => {
                                 handleSubmit(e);
                             }}>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <Title
                            title="Parameters"
                        />
                    </div>
                    <section className="w-50">
                        <FormikReactSelect
                            name="goal"
                            options={mealplanGoalOption}
                            label="Goal"
                            setFieldValue={setFieldValue}
                            containerClassName="mt-3"
                            placeholder="Choose goal for meal plan"
                        />

                        <div className="mt-3">
                            <AllergensDropdown
                                name="allergens"
                                setFieldValue={setFieldValue}
                                placeholder="Select allergens (optional)"
                                value={values.allergens}
                                label="Allergens"
                                valueItemViewComponent={({ value, onDeleteItem }) => <MultiSelectValueItemView
                                    value={value}
                                    onDeleteItem={onDeleteItem}
                                    labels={ALLERGEN_TITLES}
                                />}
                            />
                        </div>

                        <div className="mt-3">
                            <UserSegmentsDropdown
                                value={values.userSegments}
                                onChange={(value) => setFieldValue("userSegments", value)}
                                placeholder="Choose user segments"
                                label="User segments"
                                valueItemViewComponent={MultiSelectValueItemView}
                            />
                        </div>

                        <FormikReactSelect
                            name="activityLevel"
                            options={activityLevelOption}
                            label="Activity level"
                            setFieldValue={setFieldValue}
                            containerClassName="mt-3"
                            placeholder="Choose activity level"
                        />

                        <div className="d-flex align-items-center justify-content-between gap-2 mt-3">
                            <FormikReactSelect
                                name="intensityLevel"
                                options={trainingIntensityOption}
                                label="Intensity level"
                                setFieldValue={setFieldValue}
                                containerClassName="w-100"
                                placeholder="Choose intensity level"
                            />

                            <FormikInput
                                name="finalWeightGoal"
                                label="Final weight goal"
                                type="number"
                                containerClassName="w-100"
                                min={MIN_WEIGHT}
                                max={MAX_WEIGHT}
                                placeholder="kg"
                            />
                        </div>

                        <div className="d-flex align-items-start justify-content-between gap-2 mt-3">
                            <FormikReactSelect
                                name="gender"
                                options={sexOptions}
                                label="Gender"
                                setFieldValue={setFieldValue}
                                containerClassName="w-100"
                                placeholder="Choose gender"
                            />

                            <FormikInput
                                name="age"
                                label="Age"
                                type="number"
                                containerClassName="w-100"
                                min={MIN_AGE}
                                max={MAX_AGE}
                                placeholder="18"
                            />

                            <FormikInput
                                name="height"
                                label="Height (cm)"
                                type="number"
                                min={MIN_HEIGHT}
                                max={MAX_HEIGHT}
                                containerClassName="w-100"
                            />

                            <FormikInput
                                name="weight"
                                label="Weight (kg)"
                                type="number"
                                min={MIN_WEIGHT}
                                max={MAX_WEIGHT}
                                containerClassName="w-100"
                                placeholder="kg"
                            />
                        </div>

                        <div className="mt-3">
                            <MealTypeDropdown
                                name="mealType"
                                label="Meal type selection"
                                setFieldValue={setFieldValue}
                                value={values.mealType}
                                placeholder="Choose meal types to include"
                                errorMessage={touched.mealType && errors.mealType}
                            />
                        </div>

                        <div className="d-flex justify-content-end mt-5">
                            <Button
                                type={"button"}
                                onClick={() => matchProgram(values)}
                                color={BUTTON_COLORS.primary}
                                disabled={hasMatchProgramError || isSubmitting}
                                className="d-flex align-items-center"
                            >
                                {isSubmitting && <Spinner size="sm" className="me-2"/>}
                                Match program
                            </Button>
                        </div>

                        {!!programs.length && <div>
                            <Title
                                title="Program"
                            />
                            <FormikReactSelect
                                name="program"
                                options={programOptions}
                                label="Select program"
                                setFieldValue={async (name, value) => {
                                    const nutrients = programs[value].goals[0].programGoalNutrients;

                                    const goalKcal = nutrients
                                        .find(({ nutrientId }) => nutrientId === NUTRIENTS.CALORIES)
                                        .quantity || kcal;

                                    const goalCarbs = nutrients
                                        .find(({ nutrientId }) => nutrientId === NUTRIENTS.CARBS).quantity;

                                    const goalProtein = nutrients
                                        .find(({ nutrientId }) => nutrientId === NUTRIENTS.PROTEIN).quantity;

                                    const goalFat = nutrients
                                        .find(({ nutrientId }) => nutrientId === NUTRIENTS.FAT).quantity;

                                    await setFieldValue('calories', goalKcal);
                                    await setFieldValue('carbs', goalCarbs);
                                    await setFieldValue('protein', goalProtein);
                                    await setFieldValue('fat', goalFat);
                                    await setFieldValue(name, value);
                                }}
                                containerClassName="mt-3"
                                placeholder="Choose program from the list"
                            />

                            {
                                !!programs[values.program] && <div>
                                    <FormikInput
                                        name="calories"
                                        label="Target calories intake"
                                        type="number"
                                        containerClassName="w-100 mt-3"
                                        min={MIN_CALORIES}
                                    />

                                    <div className="d-flex align-items-start gap-2 mt-3">
                                        <FormikInput
                                            name="carbs"
                                            label="Carbs (%)"
                                            type="number"
                                            containerClassName="w-100"
                                        />
                                        <FormikInput
                                            name="protein"
                                            label="Protein (%)"
                                            type="number"
                                            containerClassName="w-100"
                                        />
                                        <FormikInput
                                            name="fat"
                                            label="Fat (%)"
                                            type="number"
                                            containerClassName="w-100"
                                        />
                                    </div>
                                    <div className="invalid-feedback d-block">{errors.totalSum}</div>

                                    <FormikReactSelect
                                        name="cookingPreference"
                                        options={cookingPreferenceOption}
                                        label="Choose preference"
                                        setFieldValue={setFieldValue}
                                        containerClassName="mt-3"
                                        placeholder="Choose goal for meal plan"
                                    />

                                    <PreparationTimeFields
                                        mealTypes={values.mealType}
                                        values={values}
                                        errors={errors}
                                        setFieldValue={setFieldValue}
                                    />

                                    <div className="mt-3 d-flex justify-content-end">
                                        <Button
                                            type={"button"}
                                            color={BUTTON_COLORS.primary}
                                            disabled={!!Object.keys(errors).length || isExecuting}
                                            onClick={() => executeMealplan(values)}
                                            className="d-flex align-items-center"
                                        >
                                            {isExecuting && <Spinner size="sm" className="me-2"/>}
                                            Execute
                                        </Button>
                                    </div>
                                </div>
                            }
                        </div>}
                    </section>

                    {!!weekMealplan?.length &&
                        <MealplanCalendar mealTypes={values.mealType} mealplan={weekMealplan}/>
                    }

                </form>;
            }}
        </Formik>
    </BaseLayoutWithCard>;
}