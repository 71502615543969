import { CONTENTS_GROUP_LINKS} from "../config";

export const CONTENTS_LISTS_TABS = {
    CONTENTS: "Contents",
    CATEGORIES: "Categories",
    TAGS: "Tags",
};

export const CONTENTS_LISTS_TAB_LINKS = {
    [CONTENTS_LISTS_TABS.CONTENTS]: CONTENTS_GROUP_LINKS.CONTENTS_LIST,
    [CONTENTS_LISTS_TABS.CATEGORIES]: CONTENTS_GROUP_LINKS.CATEGORIES_LIST,
    [CONTENTS_LISTS_TABS.TAGS]: CONTENTS_GROUP_LINKS.TAGS_LIST,
};
