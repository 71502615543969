import React, { useMemo } from "react";

import Button from "../../../../../../../../base/components/Button";
import Icon from "../../../../../../../../base/components/Icon";
import { BUTTON_COLORS } from "../../../../../../../../base/components/Button/appearance";
import { MAX_SECTION_NAME_LENGTH, WORKOUT_SECTION_TOPIC_TYPES } from "../../../../constants";
import ExerciseItem from "./components/ExerciseItem";
import ExerciseGroupItem from "./components/ExerciseGroupItem/ExerciseGroupItem";
import { useField } from "formik";

import classes from "./WorkoutSetUpItem.module.scss";
import { initialExercise, initialExerciseGroup } from "../../constants";
import FormikInput from "../../../../../../../../base/components/FormikInput";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";


const ADD_TOPIC_LABELS = {
    [WORKOUT_SECTION_TOPIC_TYPES.EXERCISE]: "+ Add exercise",
    [WORKOUT_SECTION_TOPIC_TYPES.SUPERSET]: "+ Add superset",
    [WORKOUT_SECTION_TOPIC_TYPES.CIRCUIT]: "+ Add circuit"
};

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    backgroundColor: "#F8F8FB",
    ...draggableStyle
});

const WorkoutSetUpItem = ({ name, exercises, dragHandleProps, deleteItem }) => {

    const [{ value }, __, { setValue }] = useField(name);

    const handleAddTopic = (type) => {
        setValue({
            ...value,
            exercises: [...value.exercises, {
                type,
                ...(type === WORKOUT_SECTION_TOPIC_TYPES.EXERCISE ? initialExercise : initialExerciseGroup)
            }]
        });
    };

    const onDragEnd = (result) => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        const items = Array.from(value.exercises);

        const [reorderedItem] = items.splice(source.index, 1);

        items.splice(destination.index, 0, reorderedItem);

        setValue({ ...value, exercises: items });
    };

    const handleRemoveItem = (index) => {
        const newItems = value.exercises.filter((_, i) => i !== index);
        setValue({ ...value, exercises: newItems });
    };

    return <div className="p-3 bg-body">
        <div className={classes.HeaderWrapper}>
            <ItemHeader
                dragHandleProps={dragHandleProps}
                name={name}
                deleteItem={deleteItem}
            />
        </div>


        <ol className="ps-0">
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="workoutSetUpExercise">
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {
                                value.exercises.map(((item, index) => {
                                    const ItemComponent = item.type === WORKOUT_SECTION_TOPIC_TYPES.EXERCISE
                                        ? ExerciseItem
                                        : ExerciseGroupItem;

                                    return <Draggable
                                        key={index}
                                        draggableId={index.toString()}
                                        index={index}
                                        isDragDisabled={false}>

                                        {(provided, snapshot) => (
                                            <div
                                                {...provided.draggableProps}
                                                ref={provided.innerRef}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <div key={index} className={classes.ItemWrapper}>
                                                    <ItemComponent
                                                        exercises={exercises}
                                                        name={`${name}.exercises[${index}]`}
                                                        index={index}
                                                        dragHandleProps={provided.dragHandleProps}
                                                        removeItem={() => handleRemoveItem(index)}
                                                    />
                                                </div>
                                            </div>)}
                                    </Draggable>;
                                }))
                            }
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </ol>

        <FooterItem
            addExercises={handleAddTopic}
            currentExercises={value.exercises}
        />
    </div>;
};

export default WorkoutSetUpItem;

function ItemHeader({ name, dragHandleProps, deleteItem }) {
    const [field, { error }, { setValue }] = useField(name);

    const { isEditTitle, name: title } = field.value;

    const changeMode = () => {
        return setValue({
            ...field.value,
            isEditTitle: !isEditTitle
        });
    };

    return isEditTitle
        ? <div className="d-flex justify-content-between pb-3">
            <div className="d-flex gap-3 align-items-center">
                <label className="text-nowrap m-0">Section title</label>
                <FormikInput name={`${name}.name`} maxLength={MAX_SECTION_NAME_LENGTH} withoutErrorText/>
            </div>
            <Button
                type="button"
                onClick={changeMode}
                color={BUTTON_COLORS.primary}
                disabled={!!error?.name}
            >
                Save
            </Button>
        </div>
        : <div className="d-flex justify-content-between pb-3">
            <label className="font-weight-semibold">{title}</label>

            <div className="d-flex gap-3">

                <Icon icon="edit"
                      width={24}
                      height={24}
                      className="me-4 cursor-pointer impacts-section__view"
                      onClick={(event) => {
                          event.stopPropagation();
                          changeMode();
                      }}
                />

                <div
                    {...dragHandleProps}
                >
                    <Icon
                        icon="dndIcon"
                        width={24}
                        height={24}
                        className="me-4 cursor-pointer impacts-section__view"
                    />
                </div>


                <Icon
                    icon={"trashIcon"}
                    width={24}
                    height={24}
                    onClick={(event) => {
                        event.stopPropagation();
                        deleteItem();
                    }}
                    className="cursor-pointer"
                />
            </div>
        </div>;
}

const FooterItem = ({ currentExercises = [], addExercises }) => {

    const currentExercisesTypes = useMemo(() => {
        return currentExercises.map(({ type }) => type);
    }, [currentExercises]);


    return <div className="d-flex gap-3 pt-3">

        {
            Object.entries(ADD_TOPIC_LABELS)
                .filter(([key]) => key === WORKOUT_SECTION_TOPIC_TYPES.EXERCISE || !currentExercisesTypes.includes(key))
                .map(([key, label], index, array) => (
                    <div key={key} className="d-flex gap-3">
                        <div
                            className="text-primary cursor-pointer"
                            onClick={() => addExercises(key)}
                        >
                            {label}
                        </div>

                        {
                            index !== (array.length - 1)
                                ? <div className="text-secondary"> or </div>
                                : null
                        }
                    </div>
                ))
        }
    </div>;
};

