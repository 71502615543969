import StoryblokClient from "storyblok-js-client";
import {
  getAttributes,
  getBannerInfo,
  getPreviewImage,
  getStoryTitle,
  getTags
} from "../groups/app/ContentPlayground/helpers";

class StoryblokService {

  Storyblok = new StoryblokClient({
    oauthToken: process.env.REACT_APP_STORYBOOK_OAUTH_TOKEN
  })

  async getStories(params) {
    const result = await this.Storyblok.get("spaces/249716/stories", {
      story_only: true,
      page: 1,
    })
    const stories = await Promise.all(result.data.stories.map(story => this.getStoryById(story.id)))
    const formatStories = this.#mapStories(stories)
    return { data: formatStories, pagination: {} }
  }

  async getStoryById(id) {
    const result = await this.Storyblok.get(`spaces/249716/stories/${id}`, {})
    return result.data
  }

  async getStoryByQuery(query) {
    const result = await this.Storyblok.get(`spaces/249716/stories}`, {filter_query: query})
    return result.data
  }

  #mapStories = (stories) => {
    return stories.map(({ story }) => {
      const attributes = getAttributes(story)
      const tags = getTags(story)
      const title = getStoryTitle(story)
      const image = getPreviewImage(story)
      const bannerInfo = getBannerInfo(story)

      return {
        name: story.name,
        attributes,
        tags,
        title,
        image,
        bannerInfo
      }
    })
  }

}

export default StoryblokService;
