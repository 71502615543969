import React from "react";
import Wrapper from "../Wrapper";

import {ReactComponent as LogoIcon} from "../../assets/image/logo.svg";
import {ReactComponent as ClockIcon} from "../../assets/image/clock.svg";

import "./index.scss"

const FitnessTitle = ({ blok }) => {
    if (!blok) {
        return null;
    }

    const imageStyle = {
        width: "44px",
        height: "44px",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#F2F7FB",
    }

    return (
        <Wrapper blok={{ ...blok }}>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                    <div className="day-marker">
                        Day {blok.day}
                    </div>
                    <div className="title">
                        {blok.title}
                    </div>
                    <div className="fitness-type-wrapper">
                        <div className="d-flex align-items-center gap-1">
                            <ClockIcon /> <span>{blok.durationInMinutes}m</span>
                        </div>
                        <div className="d-flex align-items-center gap-1">
                            <img src={blok.typeIcon.filename} className="type-icon" alt="exercise type icon" loading="lazy" />
                            <span>{blok.typeLabel}</span>
                        </div>
                    </div>
                </div>
                <div style={imageStyle}>
                    <LogoIcon style={{width: "24px", height: "24px"}} />
                </div>
            </div>
        </Wrapper>
    );
};

export default FitnessTitle;
