import React from 'react'
import { Container } from "reactstrap";
import { Route, Routes } from "react-router-dom";
import { CUSTOMER_MANAGEMENT_ROUTES } from "./config";
import UploadManually from "./UploadManually";
import UsersList from "./UsersList";
import Details from "./Details";

export default function CustomerManagement() {
  return (
    <Container fluid className="content">
      <Routes>
        <Route
          path={CUSTOMER_MANAGEMENT_ROUTES.LIST}
          element={<UsersList />}
        />
        <Route
          path={CUSTOMER_MANAGEMENT_ROUTES.UPLOAD_MANUALLY}
          element={<UploadManually />}
        />
        <Route
          path={CUSTOMER_MANAGEMENT_ROUTES.DETAILS}
          element={<Details />}
        />
      </Routes>
    </Container>
  )
}