import Http from "./Http";

class ContentsService extends Http {
    static $displayName = "ContentsService";

    getContents(params) {
        return this.get(`/admins/contents`, { params });
    }

    getContentById(id) {
        return this.get(`/admins/contents/${id}`);
    }

    createContents(values) {
        return this.post(`/admins/contents`, values);
    }

    updateContent(id, values) {
        return this.put(`/admins/contents/${id}`, values);
    }

    deleteContent(id) {
        return this.delete(`/admins/contents/${id}`);
    }

    getContentAuthor(params) {
        return this.get(`/admins/content-authors`, { params })
    }

    createContentAuthor(values) {
        return this.post(`/admins/content-authors`, values)
    }
}

export default ContentsService;
