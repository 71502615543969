import React from 'react';
import { Container } from 'reactstrap';
import { Route, Routes } from 'react-router-dom';

import { ORGANISATIONS_GROUP_ROUTES } from './config';

import OrganisationsList from "./List";

export default function Organisations() {
    return (
        <Container fluid className="content">
            <Routes>
                <Route
                    path={ORGANISATIONS_GROUP_ROUTES.LIST}
                    element={<OrganisationsList/>}
                />
            </Routes>
        </Container>
    );
}