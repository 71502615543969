// Libs
import React from "react";

// Components, Views, Screens
import FormikInput from "../../../../../base/components/FormikInput";
import Icon from "../../../../../base/components/Icon";
import classnames from "classnames";

export function SourceItem({ onDeleteItem, onDeleteLink, addLink, name, value }) {
    const disableAddLink = value?.links && !value?.links?.every(Boolean);

    return <>
        <div className="w-100 bg-body p-3">
            <div className="d-flex align-items-start gap-3 w-100">
                <FormikInput
                    name={`${name}.title`}
                    placeholder="Enter title..."
                    containerClassName="w-100"
                />
                <Icon icon="trashIcon" className="mt-2" onClick={onDeleteItem}/>
            </div>

            {!!value?.links?.length && <div className="w-100">
                <label className="mt-3">Study links:</label>
                <div className="d-flex flex-column gap-3">
                    {value.links.map((_, index) => <div key={`links-${index}`}>
                        <div className="d-flex align-items-start gap-3">
                            <div className="mt-2 index-source-links flex-grow-0 flex-shrink-0">{index + 1}.</div>
                            <FormikInput
                                name={`${name}.links.${index}`}
                                placeholder="https://"
                                containerClassName="w-100"
                            />
                            <Icon icon="trashIcon" className="mt-2" onClick={() => onDeleteLink(index)}/>
                        </div>
                    </div>)}
                </div>
            </div>}


            {value.title && <div
                onClick={() => {
                    if (disableAddLink) return;
                    addLink();
                }}
                className={classnames(
                    "w-100 mt-3 d-flex align-items-center cursor-pointer text-primary",
                    disableAddLink && "opacity-50"
                )}>
                <i className="bx bx-plus me-2"/>
                Add study link
            </div>}
        </div>
    </>;
}