import React, { useCallback, useEffect, useState } from 'react'
import joinClassNames from "../../../../base/helpers/joinClassNames";
import CustomInput from "../../../../base/components/Input";
import { MIN_LENGTH_FOR_SEARCH_REQUEST, useDebounce } from "../../../../base/hooks/useDebounce";
import { useService } from "../../../../base/hooks/useService";
import RecommendationsService from "../../../../services/RecommendationsService";
import { Dropdown, DropdownMenu, Spinner } from "reactstrap";
import { useLoading } from "../../../../base/hooks/useLoading";
import { useHighlight } from "../../../../base/hooks/useHighlight";
import { formatSearch } from "../../../../base/components/Table/tableSearch";
import { HIERARCHY_OPTIONS } from "./constants";
import { useParams } from "react-router-dom";
import OutSideClick from "../../../../base/components/OutSideClick";


export const TypeAheadOption = ({ name, search, onClick }) => {
  const { decorateText } = useHighlight(search);
  return (
    <section
      onClick={onClick}
      className={joinClassNames("cursor-pointer type-ahead-option",)}
      {...decorateText(name)}
    />
  )
}

export default function ParentRecommendationTypeAhead({
  label,
  onChange,
  onBlur,
  value,
  maxLength,
  placeholder,
  containerClassName,
}) {
  /**
   * @type {RecommendationsService}
   */
  const recommendationsService = useService(RecommendationsService);
  const { id: currentRecommendationId } = useParams();

  const [isOpen, updateIsOpen] = useState(false);
  const [search, updateSearch] = useState(value?.title);
  const [recommendations, updateRecommendations] = useState([]);
  const [isLoading, { registerPromise }] = useLoading(true);

  const [debouncedSearch] = useDebounce(search)

  const getRecommendations = useCallback(() => {
    if(!!debouncedSearch && debouncedSearch.length < MIN_LENGTH_FOR_SEARCH_REQUEST) return;
    registerPromise(
      recommendationsService.getRecommendations({
        offset: 0,
        limit: 100,
        search: debouncedSearch,
        hierarchyType: HIERARCHY_OPTIONS.PARENT,
      }).then(({ data }) => {
        const recommendationsWithoutCurrentRecommendation = data.filter(({ id }) => id !== parseInt(currentRecommendationId));
        updateRecommendations(recommendationsWithoutCurrentRecommendation);
      })
    )
  }, [debouncedSearch]);

  useEffect(() => {
    getRecommendations()
  }, [getRecommendations]);

  return (
    <OutSideClick
      className={joinClassNames("relative", containerClassName)}
      onClick={() => {
        updateIsOpen(false);
        onBlur();
      }}
      isOpen={isOpen}
    >
      <section className={joinClassNames(containerClassName)}>
        <label>{label}</label>
        <CustomInput
          type="text"
          value={search}
          onChange={(event) => {
            const formattedSearch = formatSearch(event.target.value)
            updateSearch(formattedSearch)
            if(formattedSearch.length !== 1) {
              updateIsOpen(true)
            }
          }}
          className=""
          maxLength={maxLength}
          placeholder={placeholder}
        />
        <Dropdown
          isOpen={isOpen}
          toggle={() => updateIsOpen(prevState => !prevState)}
          className="d-inline-block w-100"
        >
          <DropdownMenu className="w-100 type-ahead-menu custom-scrollbar">
            {isLoading ?
              <section className="mt-3 d-flex flex-column justify-content-center align-items-center w-100 h-100">
                <Spinner color="info"/>
              </section>
              :
              recommendations.length ?
                recommendations.map((recommendation , index) => {
                  return (
                    <TypeAheadOption
                      key={index}
                      name={recommendation.title}
                      search={search}
                      onClick={() => {
                        updateSearch(recommendation.title)
                        updateIsOpen(false)
                        onChange(recommendation);
                      }}
                    />
                  )
                })
                :
                <section className="mt-4 d-flex flex-column justify-content-center align-items-center w-100 h-100">
                  <label className="text-secondary ">
                    No matches found
                  </label>
                </section>
            }
          </DropdownMenu>
        </Dropdown>
      </section>
    </OutSideClick>
  )
}