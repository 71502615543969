import { useEffect, useState } from "react";

import ConfigService from "../../services/ConfigService";
import { useService } from "./useService";
import StorageService from "../../services/StorageService";
import { KEY_USER } from "../constants/storage";

const useConfig = (theme = 1) => {
  const [isConfigLoading, setIsConfigLoading] = useState(true);
  const [isThemeSettingsLoading, setIsThemeSettingsLoading] = useState(true);
  const [config, setConfig] = useState({});
  const [themeSettings, setThemeSettings] = useState({});
  const [servicesSettings, setServicesSettings] = useState({});
  const [isServiceSettingsLoading, setIsServicesSettingsLoading] =
    useState(true);
  /**
   * @type {ConfigService}
   */
  const configService = useService(ConfigService);
  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);

  const DEFAULT_OPTIMALLY_ME_ORG = 1;
  const { organisationId } = storage.get(KEY_USER, { organisationId: DEFAULT_OPTIMALLY_ME_ORG });

  useEffect(() => {
    configService.initConfig().then(config => {
      setConfig(config);
      setIsConfigLoading(false);
    });

    configService.initThemeSettings().then(themeSettings => {
      setThemeSettings(themeSettings[theme]);
      setIsThemeSettingsLoading(false);
    });

    configService.initServicesSettings(organisationId).then(servicesSettings => {
      setServicesSettings(servicesSettings);
      setIsServicesSettingsLoading(false);
    });
  }, []);

  return {
    isConfigLoading,
    config,
    isThemeSettingsLoading,
    themeSettings,
    isServiceSettingsLoading,
    servicesSettings,
  };
};

export default useConfig;
