
export const MIN_STRING_LENGTH = 1
export const MAX_PROGRAM_NAME_LENGTH = 100
export const MIN_USER_SEGMENTS_ITEMS_LENGTH = 1

export const TAG_NAMES_MIN_ITEMS_LENGTH = 1

export const TAG_NAMES_MAX_ITEMS_LENGTH = 50

export const MAX_PROGRAM_OVERVIEW = 2000

export const MAX_PROGRAM_HIGHLIGHT = 2000

export const MIN_LENGTH_PROGRAM_DAYS = 1

export const ALLOWED_EXTENTIONS_FILE = ["png", "jpeg", "jpg"];
export const ERROR_ALLOWED_TYPE_MESSAGE = "Invalid format. Choose a PNG, JPG, JPEG image.";