import React from 'react';
import { BULLET_LIST_TYPES_MAP } from "../CreateEdit/components/constants";
import ValueWithLabel from "./ValueWIthLabel";

export default function BulletListView({
  bulletList,
  rangeName,
}) {
  const currentRangeList = bulletList.filter(({ type }) => type === BULLET_LIST_TYPES_MAP[rangeName]);

  return (
    <section className="w-50">
      {!!currentRangeList.length && <label className="font-weight-semibold mt-3 text-secondary">Bullet list:</label>}

      {bulletList.map(({ type, content, studyLinks = [], }, index) => {
        if(type !== BULLET_LIST_TYPES_MAP[rangeName]) return null;
        return (
          <section className="bullet-list mb-2" key={index}>
            <ValueWithLabel
              label={content}
              value={""}
              className="mb-0"
            />

            {!!studyLinks.length && <label className="font-weight-semibold mt-3">Study links:</label>}

            {studyLinks.map(({ content }, studyListIndex) => {
              return (
                <section key={studyListIndex} className="d-flex align-items-center mb-2">
                  <label className="bullet-list__counter font-weight-normal me-3 mb-0">{studyListIndex + 1}.</label>
                  <label className="mb-0 text-truncate font-weight-normal">{content}</label>
                </section>
              )
            })}
          </section>
        )
      })}
    </section>
  )
}