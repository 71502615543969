import Http from "./Http";
import { DEFAULT_LIMIT_AND_OFFSET } from "./BiomarkersService";

class TemplateService extends Http {
  static $displayName = "TemplateService";

  async createTemplate(data) {
    return await this.post('/hl7/templates', data)
  }

  async getTemplateList(params = DEFAULT_LIMIT_AND_OFFSET){
    return await this.get(`/hl7/templates`, { params })
  }

  async updateTemplate(id, data) {
    return await this.put(`/hl7/templates/${id}`, data)
  }

  async deleteTemplate(id){
    return await this.delete(`/hl7/templates/${id}`)
  }

  async setTemplateAsFavourite(id, data){
    return await this.patch(`/hl7/templates/${id}`, data)
  }
}

export default TemplateService;
