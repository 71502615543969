import React from "react";
import { PROGRAM_PLAN_CONTENT_TYPE_LABELS } from "../../../constants";

import { useLocationQuery } from "../../../../../../base/hooks/useQueryString";
import { INSTANCE_API_FIELD } from "../../constants";

export const columns = [
  {
    Header: "#",
    width: 30,
    headerClassName: "ps-3",
    Cell: (cellProps) => {
      const { params: { offset = 0 } } = useLocationQuery();
      return <label style={{ width: "30px", overflow: "hidden" }}
                    className="m-0 ps-1">{cellProps.row.index + 1 + (offset ?? 0)}</label>;
    }
  },
  {
    Header: "Content type",
    accessor: "type",
    width: 100,
    Cell: ({ value }) => <div>
      {PROGRAM_PLAN_CONTENT_TYPE_LABELS[value] || "-"}
    </div>
  },
  {
    Header: "Name",
    accessor: "instanceId",
    Cell: ({ row: { original } }) => {
      const instance = original?.[INSTANCE_API_FIELD[original?.type]];

      return <span>{instance?.name || instance?.foodName || instance?.title || "-"}</span>;
    }
  },
  {
    Header: "Is rest",
    accessor: "isRest",
    Cell: ({ value }) => <span>{value ? "Yes" : "No"}</span>
  },
  {
    Header: "Is optional",
    accessor: "isOptional",
    Cell: ({ value }) => <span>{value ? "Yes" : "No"}</span>
  }
];