import React, { useEffect, useState } from "react";
import {  useNavigate, useParams } from "react-router-dom";
import { map } from "lodash";

import BaseLayoutWithCard from "../../../../../base/components/BaseLayoutWithCard";
import ValueWithLabel from "../../../../../base/components/ValueWIthLabel";
import Title from "../../../Biomarkers/Details/Title";
import Button from "../../../../../base/components/Button";

import { useService } from "../../../../../base/hooks/useService";
import { CONTENTS_GROUP_LINKS } from "../../config";
import { BUTTON_COLORS } from "../../../../../base/components/Button/appearance";
import ContentCategoriesService from "../../../../../services/ContentCategoriesService";

export function CategoryDetails() {
  /**
   * @type {ContentCategoriesService}
   */
  const contentCategoriesService = useService(ContentCategoriesService);
  const navigate = useNavigate();

  const [category, setCategory] = useState(null);

  const { id } = useParams();

  const goToEdit = () => {
    const queryParams = new URLSearchParams({ editCategoryId: id }).toString();
    navigate(`${CONTENTS_GROUP_LINKS.FORM_CATEGORIES}/?${queryParams}`);
  }

  const breadcrumbs = {
    title: "Category detailed view",
    breadcrumbItems: [
      { title: "Content", link: CONTENTS_GROUP_LINKS.CATEGORIES_LIST },
      { title: "Category detailed view" }
    ]
  };

  useEffect(() => {
    if (id) {
      contentCategoriesService.getCategoryById(id)
        .then((data) => {
          setCategory({ fullName: data.fullName, order: data.order, tagNames: map(data.categoriesTags, "tag.name") });
        });
    }
  }, [id]);

  return <BaseLayoutWithCard breadcrumbs={breadcrumbs}>
    <div className="d-flex justify-content-between align-items-center">
      <Title title="General information" />

      <Button
        color={BUTTON_COLORS.primary}
        onClick={goToEdit}
        className="d-flex align-items-center"
      >
        <i className="bx bx-edit-alt me-2"/>
        Edit category
      </Button>
    </div>

    <ValueWithLabel
      label="Full name"
      value={category?.fullName}
      className="mt-3"
    />

    <ValueWithLabel
      label="Tags"
      value={category?.tagNames?.join(", ")}
      className="mt-3"
    />

    <ValueWithLabel
      label="Order"
      value={category?.order}
      className="mt-3"
    />
  </BaseLayoutWithCard>;
}