import Http from "./Http";
import { DEFAULT_LIMIT_AND_OFFSET } from "./BiomarkersService";

class UserTagService extends Http {
    static $displayName = "UserTagService";

    async getUserTags(id, params = DEFAULT_LIMIT_AND_OFFSET) {
        return await this.get(`/admins/users/user-tags/${id}`, { params })
    }

    createUserTag(values) {
        return this.post(`/admins/user-quiz-variables/`, values);
    }

    getUserTagById(id) {
        return this.get(`/admins/user-quiz-variables/${id}`);
    }

    updateUserTag(id, values) {
        return this.put(`/admins/user-quiz-variables/${id}`, values);
    }

    deleteUserTag(id) {
        return this.delete(`/admins/user-quiz-variables/${id}`);
    }
}

export default UserTagService;