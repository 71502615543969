export const ORGANISATION_STATUSES = {
    ACTIVE: 1,
    BLOCKED: 2,
    DELETED: 3,
}

export const ORGANISATION_STATUSES_LABELS = {
    [ORGANISATION_STATUSES.ACTIVE]: 'Active',
    [ORGANISATION_STATUSES.BLOCKED]: 'Blocked',
    [ORGANISATION_STATUSES.DELETED]: 'Deleted',
}
