import React from 'react';
import StorageService from '../../services/StorageService';
import { useService } from '../hooks/useService';
import { KEY_USER } from "../constants/storage";

export default function Divider(){
  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);

  const DEFAULT_OPTIMALLY_ME_ORG = 1;
  const { organisationId } = storage.get(KEY_USER, { organisationId: DEFAULT_OPTIMALLY_ME_ORG });

  return (
    <link
          href={`${process.env.REACT_APP_BASE_URL}/system/theme/styles.css?organisationId=${organisationId}`}
          rel="stylesheet"
      />
  )
}