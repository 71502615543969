import React, { useState } from "react";
import { map, sortBy, without } from "lodash";

import MultiSelectWithCheckbox from "../MultiSelectWithCheckbox";

import { ALLERGEN_TITLES, MEAL_TYPES_LABELS } from "../../constants/foods";
import { Badge } from "reactstrap";
import Icon from "../Icon";
import classnames from "classnames";

export const DefaultMultiSelectValueItemView = ({ value }) => {
    return value.map(idx => MEAL_TYPES_LABELS[idx]).join(', ')
}

export const MealTypeDropdown = ({ value = [], name, setFieldValue, placeholder, label, valueItemViewComponent, errorMessage }) => {
    const [search, setSearch] = useState("");

    const options = sortBy(
        Object.entries(MEAL_TYPES_LABELS).map(([index, title]) => ({ title, index })),
        [item => item.title]);

    const filteredOption = search
        ? options.filter(item => item.title.toLowerCase().includes(search.toLowerCase()))
        : options;

    const isSelectAll = value?.length === Object.keys(options).length;

    const handleDeleteItem = (idx) => {
        setFieldValue(name, value.filter(item => item !== idx))
    }

    const ValueComponent = valueItemViewComponent || DefaultMultiSelectValueItemView

    return <MultiSelectWithCheckbox
        label={label}
        value={<ValueComponent value={value} onDeleteItem={handleDeleteItem} />}
        selectAllCheckbox={{
            value: isSelectAll,
            text: "Select all",
            onChange: () => setFieldValue(
                name,
                isSelectAll
                    ? []
                    : map(options, "index")
            )
        }}
        listOfCheckboxes={filteredOption.map(({ title, index }) => {
            const valueItem = value?.includes(index);

            return {
                value: valueItem,
                text: title,
                onChange: () => {
                    const newAllergens = valueItem ? without(value, index) : [...value, index];
                    setFieldValue(name, newAllergens);
                }
            };
        })}
        placeholder={placeholder}
        clearOption={() => setFieldValue(name, [])}
        withSearch
        search={search}
        onChangeSearch={setSearch}
        errorMessage={errorMessage}
        valueViewComponent={({ placeholder, value: valueView }) => {
            const isShowPlaceholder = !value?.length

            return (
            <span className={classnames(
            "ms-2 me-1 user-select-none text-truncate",
            isShowPlaceholder && "text-secondary"
            )}>
        {isShowPlaceholder ? placeholder : valueView}
    </span>
);
}}
    />;
};



