import { FITNESS_GROUP_LINKS} from "../config";

export const FITNESS_LISTS_TABS = {
    EXERCISES_LIST: "Exercises",
    WORKOUT_TYPES_LIST: "Workout types",
    WORKOUTS_LIST: "Workouts",
    HOMEPAGE_LAYOUT_LIST: "Homepage-layout",
};

export const FITNESS_LISTS_TAB_LINKS = {
    [FITNESS_LISTS_TABS.EXERCISES_LIST]: FITNESS_GROUP_LINKS.EXERCISES_LIST,
    [FITNESS_LISTS_TABS.WORKOUT_TYPES_LIST]: FITNESS_GROUP_LINKS.WORKOUT_TYPES_LIST,
    [FITNESS_LISTS_TABS.WORKOUTS_LIST]: FITNESS_GROUP_LINKS.WORKOUTS_LIST,
    [FITNESS_LISTS_TABS.HOMEPAGE_LAYOUT_LIST]: FITNESS_GROUP_LINKS.HOMEPAGE_LAYOUT_LIST,
};

