import Http from "./Http";

class WorkoutService extends Http {
    static $displayName = "WorkoutService";

    async getWorkout(params) {
        return await this.get(`/workouts`, { params });
    }

    async getWorkoutById(id) {
        return await this.get(`/workouts/${id}`);
    }

    createWorkout(values) {
        return this.post(`/workouts`, values);
    }

    updateWorkout(id, values) {
        return this.put(`/workouts/${id}`, values);
    }

    deleteWorkout(id) {
        return this.delete(`/workouts/${id}`);
    }

    async getWorkoutTypes(params) {
        return await this.get(`/workout-types`, { params });
    }

    async getWorkoutTypeById(id) {
        return await this.get(`/workout-types/${id}`);
    }

    createWorkoutTypes(values) {
        return this.post(`/workout-types`, values);
    }

    deleteWorkoutType(id) {
        return this.delete(`/workout-types/${id}`);
    }

    updateWorkoutTypes(id, values) {
        return this.put(`/workout-types/${id}`, values);
    }
}

export default WorkoutService;
