import React from "react";
import _ from "lodash"

export const TableHeader = () => {
    return <div></div>
}

export const columns = [
    {
        Header: "Content ID",
        accessor: "name",
        canSort: false,
        Cell: ({value}) => {
            return (
                <section className="d-flex align-items-center">
                    <div className="table-cell-25 text-truncate">{value}</div>
                </section>
            );
        },
    },
    {
        Header: "Title",
        accessor: "title",
        canSort: false,
        Cell: ({value}) => {
            return (
                <section>
                    <div className="table-cell-25 text-truncate">{value}</div>
                </section>
            );
        },
    },
    {
        Header: "Tags",
        accessor: "tags",
        canSort: false,
        Cell: ({value}) => {
            const tags = _.flatten(Object.values(value)).join(', ')
            return (
                <section>
                    <div>{tags}</div>
                </section>
            );
        },
    },
    {
        Header: "Attributes",
        accessor: "attributes",
        canSort: false,
        Cell: ({value}) => {
            const attributes = _.flatten(Object.values(value)).join(', ')
            return (
                <section>
                    <div>{attributes}</div>
                </section>
            );
        },
    },
];