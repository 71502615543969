import Http from "./Http";
import { DEFAULT_LIMIT_AND_OFFSET } from "./BiomarkersService";


class NotificationsService extends Http {
  static $displayName = "NotificationsService";

  async getNotifications(params = DEFAULT_LIMIT_AND_OFFSET) {
    return await this.get("/hl7/notifications", { params });
  }
}

export default NotificationsService;
