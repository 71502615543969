import React from "react";
import Wrapper from "./Wrapper";

const ImageSection = ({ blok }) => {
    if (!blok) {
        return null;
    }

    const style = {
        borderRadius: blok.borderRadius
    }

    return (
        <Wrapper blok={blok}>
            <section className="w-100">
                <img className="w-100" style={style} src={blok.image.filename} alt={blok.description} loading="lazy"/>
                <p className="text-description">{blok.description}</p>
            </section>
        </Wrapper>
    );
};

export default ImageSection;
