const URL_WITH_THUMBNAIL = 'https://copy-of-meta-nutrition-images'

export const getThumbnailImage = (path, size) => {
    if (!path.startsWith(URL_WITH_THUMBNAIL)) {
        return path
    }

    const parts = path.split("/");
    if (parts && parts.length > 0) {
        let url = "";
        for (let i = 0; i < parts.length - 1; i++) {
            url += `${parts[i]}/`;
        }
        return `${url}${size}x${size}/${parts[parts.length - 1]}`;
    }
    return undefined;
};