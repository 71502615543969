import axios from "axios";

import {
    abortControllerInterceptor,
    refreshSessionInterceptor
} from "./interceptors/refreshSessionInterceptor";
import { mapResponse } from "./interceptors/mapResponse";
import { setAuthToken } from "./interceptors/setAuthToken";


const interceptorsResponse = [
    mapResponse,
    refreshSessionInterceptor
];

const interceptorsRequest = [
    setAuthToken
];

const headers = {
    "Access-Control-Allow-Origin": "*"
};

class MetaNutritionApi {
    constructor() {
        this.instance = MetaNutritionApi.createInstance({
            baseURL: `${MetaNutritionApi.api.common}/${this.getURLScope()}`,
            headers
        });
        this.instance.interceptors.request.use(...interceptorsRequest);
        this.instance.interceptors.response.use((data) => data, abortControllerInterceptor);
        this.instance.interceptors.response.use(...interceptorsResponse);
    }

    static $displayName = "MetaNutritionApi";

    static createInstance() {
        return axios.create.apply(axios, arguments);
    }

    getURLScope() {
        return "";
    }

    #get() {
        return this.instance.get.apply(this.instance, arguments);
    }

    #patch() {
        return this.instance.patch.apply(this.instance, arguments);
    }

    #put() {
        return this.instance.put.apply(this.instance, arguments);
    }

    #post() {
        return this.instance.post.apply(this.instance, arguments);
    }

    #delete() {
        return this.instance.delete.apply(this.instance, arguments);
    }

    getIngredientGroup(
        search,
        pageNumber,
        params
    ) {
        return this.#get(`/v1/ingredientGroup`, { params: { q: search, page: pageNumber.toString(), ...params } });
    }

    getIngredientGroupCategories() {
        return this.#get(`/v1/ingredientGroup/category`);
    }

    getRecipeTag() {
        return this.#get(`/v1/recipeTags`);
    }

    getBasicFoods(params) {
        return this.#get(`v1/food/v2`, { params: { ...params, collection: 'basicFoods' } });
    }

    getRecipes(params) {
        return this.#get(`v1/food/v2`, { params: { ...params, collection: 'recipes' } });
    }

    getDietPlanPlayground(values) {
        return this.#post(`v1/diet/v7/dietplan/kcal/playground`, values);
    }

    getWeekPlanPlayground(values) {
        return this.#post(`v1/diet/v7/dietplan/week/playground`, values);
    }
}

MetaNutritionApi.api = {
    common: process.env.REACT_APP_META_NUTRITION_URL

};

MetaNutritionApi.versions = {
    v1: process.env.REACT_APP_API_VERSION
};

export default MetaNutritionApi;
