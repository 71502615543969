export const HEALTH_PROGRAM_GROUP_ROUTES = {
  BASE: "/health-program/*",
  FITNESS: "/fitness/*",
  WELLNESS: "/wellness/*",
  NUTRITION: "/nutrition/*"

};

export const HEALTH_PROGRAM_GROUP_LINKS = {
  BASE: "/app/health-program",
  FITNESS: "/app/health-program/fitness",
  NUTRITION: "/app/health-program/nutrition",
  LIST: "/app/health-program",
};