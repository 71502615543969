import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import Table from "../../../../../base/components/Table";
import ConfirmPopup from "../../../../../base/components/ConfirmPopup";
import {
  SearchPlaceholder,
  TableHeader,
  columns,
  NoContentsPlaceholder,
  TAGS_ACTIONS
} from "./components";

import { DEFAULT_TABLE_LIMIT } from "../../../../../base/constants/shared";
import {
  usePaginationProvider,
  useLocationSource,
  useSearchProvider,
  useSortProvider, useFilterProvider
} from "../../../../../base/components/Table/hooks";
import ToasterService from "../../../../../services/ToastService";
import { useService } from "../../../../../base/hooks/useService";
import { useLoading } from "../../../../../base/hooks/useLoading";
import { useQueryString } from "../../../../../base/hooks/useQueryString";
import { LIMIT_OPTIONS, MIN_SEARCH_LENGTH } from "../../../../../base/constants/pagination";
import ContentsService from "../../../../../services/ContentsService";
import { TagsCollapseRows } from "./components";
import { CONTENTS_GROUP_LINKS } from "../../config";


export default function Contents() {
  /**
   * @type {ToasterService}
   */
  const toastService = useService(ToasterService);
  /**
   * @type {ContentsService}
   */
  const contentService = useService(ContentsService);
  const [isLoading, { registerPromise }] = useLoading(false);
  const { search: locationSearch, pathname } = useLocation();
  const {
    params: {
      limit = DEFAULT_TABLE_LIMIT,
      offset = 0,
      search,
      name,
      categoryFilter,
      tagFilter
    }
  } = useQueryString(locationSearch);
  const navigate = useNavigate()

  const [contents, updateContents] = useState([]);
  const [pagination, updatePagination] = useState({});
  const [showDeletePopup, updateShowDeletePopup] = useState(null);
  const [collapsedRows, updateCollapsedRows] = useState([]);

  const toggleCollapse = (id) => {
    if (collapsedRows.includes(id)) {
      updateCollapsedRows(prevState => prevState.filter(item => item !== id));
      return;
    }
    updateCollapsedRows(prevState => [...prevState, id]);
  };

  const locationSource = useLocationSource();

  const sortKeys = ["name"];
  const getSortScope = (key) => [...sortKeys.filter(item => item !== key), "offset"];

  const nameSortProvider = useSortProvider({
    source: locationSource,
    alias: "name",
    scope: "",
    onApplyClearScope: getSortScope("name")
  });

  const searchRequest = useMemo(() => search && search.toString()?.trim()?.length >= MIN_SEARCH_LENGTH
      ? search
      : null,
    [search]);

  const paginationProvider = usePaginationProvider({
    source: locationSource,
    alias: "offset",
    scope: "",
    fallback: 0
  });

  const searchProvider = useSearchProvider({
    source: locationSource,
    scope: "",
    alias: 'search',
    onApplyClearScope: ["offset"]
  });

  const limitProvider = usePaginationProvider({
    source: locationSource,
    alias: "limit",
    scope: "",
    fallback: 10,
    onApplyClearScope: ["offset"]
  });

  const categoryFilterProvider = useFilterProvider({
    source: locationSource,
    scope: "",
    alias: 'categoryFilter',
    onApplyClearScope: ["offset"]
  });

  const tagFilterProvider = useFilterProvider({
    source: locationSource,
    scope: "",
    alias: 'tagFilter',
    onApplyClearScope: ["offset"]
  });

  const filterProvider = useMemo(() => ({
    category: categoryFilterProvider,
    tag: tagFilterProvider
  }), [categoryFilterProvider, tagFilterProvider]);

  const deleteContents = (id) => {
    contentService.deleteContent(id)
      .then(() => {
        getContents();
        toastService.success("The contents has been successfully deleted");
      });
  };

  const goToEdit = (id) => {
    const queryParams = new URLSearchParams({ editContentId: id }).toString();
    navigate(`${CONTENTS_GROUP_LINKS.FORM_CONTENTS}?${queryParams}`);
  }

  const getContents = useCallback(() => {
    const [orderBy, orderType] = Object.entries({
      name
    }).find(([_, value]) => value) || [];

    registerPromise(contentService.getContents({
      limit,
      offset,
      query: searchRequest,
      orderBy,
      orderType,
      categoryIds: categoryFilter,
      tagIds: tagFilter

    })).then(({ data, pagination }) => {
      updateContents(data);
      updatePagination(pagination);
    });
  }, [limit, offset, searchRequest, name, categoryFilter, tagFilter]);

  useEffect(() => {
    getContents();
  }, [getContents]);

  return (
    <>
      <Table
        columns={contents.length ? columns : []}
        data={contents}
        loading={isLoading}
        HeaderComponent={TableHeader}
        totalCount={pagination.totalCount}
        limit={limitProvider.getValue()}
        offset={offset}
        paginationProvider={paginationProvider}
        searchProvider={searchProvider}
        commonPlaceholder={<NoContentsPlaceholder/>}
        placeholderForSearch={<SearchPlaceholder/>}
        filterProvider={filterProvider}
        hasActiveFilters={!!categoryFilterProvider.getValue() || !!tagFilterProvider.getValue()}
        actions={{
          [TAGS_ACTIONS.EDIT]: goToEdit,
          [TAGS_ACTIONS.DELETE]: updateShowDeletePopup
        }}
        sortProviders={{
          name: nameSortProvider
        }}
        isLimitEditable
        limitProvider={limitProvider}
        limitOptions={LIMIT_OPTIONS}
        useCollapseRule={({ tags }) => !!tags?.length}
        collapsedState={collapsedRows}
        toggleCollapse={toggleCollapse}
        CollapsedComponent={TagsCollapseRows}
        isRowClick
      />

      {showDeletePopup &&
        <ConfirmPopup
          isOpen={showDeletePopup}
          updateIsOpen={updateShowDeletePopup}
          onSubmit={() => {
            deleteContents(showDeletePopup);
            updateShowDeletePopup(null);
          }}
          title="Delete content"
          description="Are you sure you want to delete the chosen content?"
          submitBtnText="Delete"
          className="upload-manually__popup"
        />
      }
    </>
  );
}
