import React from "react";
import { MEAL_TYPES_LABELS } from "../../../../../base/constants/foods";
import { PREP_TIME_INPUT_NAMES, statusOptions } from "../constants";
import FormikReactSelect from "../../../../../base/components/FormikReactSelect";
export const PreparationTimeFields = ({ mealTypes = [], values, setFieldValue, errors }) => {

    return (
        <div className="d-grid gap-3 w-100 mt-3 px-2">
            <div className="row bg-body py-3">
                <div
                    className="col font-weight-semibold text-nowrap"
                    style={{ color: "transparent" }}
                >
                    Max. preptime (min)
                </div>
                {mealTypes.map((type, index) => {
                    return (
                        <div className="col font-weight-semibold" key={index}>
                            {MEAL_TYPES_LABELS[type]}
                        </div>
                    );
                })}
            </div>

            <div className="row">
                <div className="col font-weight-semibold text-nowrap d-flex align-items-center">Max. preptime (min)</div>
                {mealTypes.map((type, index) => {
                    return (
                        <div className="col mb-3" key={index}>
                            <FormikReactSelect
                                setFieldValue={setFieldValue}
                                options={statusOptions}
                                className="custom-select"
                                placeholder=""
                                value={statusOptions.find(({ value }) => value === values[PREP_TIME_INPUT_NAMES[type]])}
                                backendError={errors?.[PREP_TIME_INPUT_NAMES[type]]}
                                containerClassName="mt-3"
                                name={PREP_TIME_INPUT_NAMES[type]}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
