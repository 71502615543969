import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";


import BaseLayoutWithCard from "../../../../base/components/BaseLayoutWithCard";
import Button from "../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";

import { useLoading } from "../../../../base/hooks/useLoading";
import { useService } from "../../../../base/hooks/useService";
import PageSpinner from "../../../../base/components/PageSpinner";
import ValueWithLabel from "./components/ValueWIthLabel";
import RichTextView from "./components/RIchTextView";
import ExercisesService from "../../../../services/ExercisesService";
import { FITNESS_GROUP_LINKS } from "../config";
import {
    EXERCISE_CONTENT_FORMAT,
    EXERCISE_CONTENT_FORMAT_LABELS,
    EXERCISE_STATUSES_LABELS,
    EXERCISE_TYPES_LABEL
} from "../ExerciseForm/constants";


export default function ExerciseDetails() {
    /**
     * @type {ExercisesService}
     */
    const exercisesService = useService(ExercisesService);

    const [exercise, setExercise] = useState(null);

    const [isLoading, { registerPromise }] = useLoading(true);
    const navigate = useNavigate();

    const { id } = useParams();
    const mapProgramRestrictions = (programRestrictions = []) => {
        return programRestrictions.map(item => item?.restrictionName);
    };

    const mapExercise = ({
                             type,
                             status,
                             file,
                             equipment = [],
                             muscles = [],
                             similarExercises = [],
                             videoUrl,
                             audioUrl,
                             ...otherValue
                         }) => {
        return {
            type: EXERCISE_TYPES_LABEL[type],
            status: EXERCISE_STATUSES_LABELS[status],
            equipment: equipment.map(({ name }) => name).join(', '),
            muscles: muscles.map(({ name }) => name).join(', '),
            similarExercises: similarExercises.map(({ title }) => title).join(', '),
            image: file?.link,
            formatUrl: videoUrl || audioUrl,
            ...otherValue
        };
    };

    const goToEdit = () => {
        const queryParams = new URLSearchParams({ editExerciseId: id }).toString();
        navigate(`${FITNESS_GROUP_LINKS.FORM_EXERCISES}?${queryParams}`);
    };

    useEffect(() => {
        if (id) {
            registerPromise(exercisesService.getExerciseById(id))
                .then((data) => {
                    setExercise(mapExercise(data));
                });
        }
    }, [id]);

    const breadcrumbs = {
        title: "Exercise detailed view",
        breadcrumbItems: [
            { title: "Fitness", link: FITNESS_GROUP_LINKS.BASE },
            { title: "Exercise detailed view" }
        ]
    };

    return <BaseLayoutWithCard breadcrumbs={breadcrumbs}>
        {isLoading
            ? <PageSpinner/>
            : <>
                <section className="d-flex align-items-start justify-content-between mb-4">
                    <label className="font-weight-semibold font-size-15">General information</label>
                    <div className="d-flex gap-3 align-items-center">
                        <Button
                            color={BUTTON_COLORS.primary}
                            onClick={goToEdit}
                            className="d-flex align-items-center"
                        >
                            <i className="bx bx-edit-alt me-2"/>
                            Edit exercise
                        </Button>
                    </div>
                </section>
                <section className="d-flex flex-column gap-3">
                    {
                        exercise?.image
                            ? <img
                                src={exercise?.image}
                                alt="exercise image"
                                width={240}
                            />
                            : null
                    }

                    <ValueWithLabel value={exercise?.title} label="Title"/>

                    <ValueWithLabel value={exercise?.type} label="Exercise type:"/>

                    <ValueWithLabel value={exercise?.equipment} label="Equipment"/>

                    <ValueWithLabel value={exercise?.muscles} label="Muscles targeted"/>

                    <ValueWithLabel value={exercise?.similarExercises} label="Similar exercises"/>

                    <ValueWithLabel value={exercise?.status} label="Status"/>

                    <ValueWithLabel value={EXERCISE_CONTENT_FORMAT_LABELS[exercise?.format]} label="Exercise content format:"/>

                    {
                        exercise?.format !== EXERCISE_CONTENT_FORMAT.TEXT &&
                        <ValueWithLabel
                            value={
                                <a
                                    href={exercise?.formatUrl.startsWith('http') ? exercise?.formatUrl : `https://${exercise?.formatUrl}`}
                                    target="_blank"
                                    rel="noreferrer">{exercise?.formatUrl}
                                </a>
                            }
                            label={exercise?.format === EXERCISE_CONTENT_FORMAT.VIDEO ? "Video URL" : "Audio URL"}
                        />
                    }
                </section>

                <section className="mt-5">
                    <label className="font-weight-semibold font-size-15 mb-2">Instruction</label>
                    <div className="d-flex flex-column gap-3">
                        <RichTextView richText={exercise?.instruction}/>
                    </div>
                </section>
            </>
        }
    </BaseLayoutWithCard>;
}