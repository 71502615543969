import React, { useEffect, useMemo, useState } from "react";

import Button from "../../../../../base/components/Button";
import TableSearch from "../../../../../base/components/Table/tableSearch";
import { useLocationQuery } from "../../../../../base/hooks/useQueryString";
import { useHighlight } from "../../../../../base/hooks/useHighlight";
import { BUTTON_COLORS } from "../../../../../base/components/Button/appearance";
import { MIN_SEARCH_LENGTH } from "../../../../../base/constants/pagination";
import Icon from "../../../../../base/components/Icon";
import { useNavigate } from "react-router-dom";
import { CONTENTS_GROUP_LINKS } from "../../config";
import joinClassNames from "../../../../../base/helpers/joinClassNames";
import { Badge, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { CONTENT_ASSIGNED_LABELS } from "../../constants";
import Checkbox from "../../../../../base/components/Checkbox";
import { exerciseStatusesOption } from "../../../Fitness/ExerciseForm/form";
import { useService } from "../../../../../base/hooks/useService";
import ContentCategoriesService from "../../../../../services/ContentCategoriesService";
import { FilterListWithInfinityScroll } from "../../../../../base/components/FilterListWithInfinityScroll";
import TagService from "../../../../../services/TagService";

export const TAGS_ACTIONS = {
  DELETE: 'delete',
  EDIT: 'edit'
};

const getArray = (itemOrArray) => {
  if (!itemOrArray) return [];
  return typeof itemOrArray === "object" ? itemOrArray : [itemOrArray];
};

const TableFilter = ({ filterProvider }) => {
  /**
   * @type {ContentCategoriesService}
   */
  const contentCategoriesService = useService(ContentCategoriesService);
  /**
   * @type {TagService}
   */
  const tagService = useService(TagService);

  const { tag, category } = filterProvider;

  const [isOpen, updateIsOpen] = useState(false);
  const [categories, updateCategories] = useState(getArray(category.getValue()));
  const [tagFilter, updateTagFilter] = useState(getArray(tag.getValue()));

  const submitFilters = () => {
    category.setValue(categories);
    tag.setValue(tagFilter);
    updateIsOpen(false);
  };

  return (
    <section className="d-flex align-items-center">
      <Dropdown
        isOpen={isOpen}
        toggle={() => updateIsOpen(prevState => !prevState)}
        className="d-inline-block"
      >
        <DropdownToggle
          className=" btn btn-outline-primary d-flex align-items-center"
          tag="button"
        >
          <i className="bx bx-filter-alt me-2"/> Add filter
        </DropdownToggle>

        <DropdownMenu className="filter-menu p-2 pt-3 rec-filter">

          <section className="d-flex justify-content-between">
            <FilterListWithInfinityScroll
              label="BY CATEGORY"
              filterState={categories}
              updateFilterState={updateCategories}
              getData={(params) => contentCategoriesService.getContentCategories(params)}
              mapOptions={(categories) => categories.map(({ id, fullName }) => ({ id, label: fullName }))}
            />

            <FilterListWithInfinityScroll
              label="BY TAG"
              filterState={tagFilter}
              updateFilterState={updateTagFilter}
              getData={(params) => tagService.getTags(params)}
              mapOptions={(tags) => tags.map(({ id, name }) => ({ id, label: name }))}
            />
          </section>

          <section className="w-100 px-2 pb-2 mt-3">
            <Button color={BUTTON_COLORS.primary} onClick={submitFilters} className="w-100">
              Apply
            </Button>
          </section>
        </DropdownMenu>
      </Dropdown>

      {(!!category.getValue() || !!tag.getValue()) &&
        <Button
          color={BUTTON_COLORS.transparent}
          onClick={() => {
            updateCategories([]);
            updateTagFilter([]);
            category.setValue();
            tag.setValue();
          }}
          className="text-danger no-border ms-2"
        >
          Clear filter
        </Button>
      }
    </section>
  );
};

export const SearchPlaceholder = () => (
  <>
    No matching content.
  </>
);

export const NoContentsPlaceholder = () => (
  <div className="text-center">
    No contents for now.<br/>
    Click the “Create content” option to add a new one.
  </div>
);

export const TableHeader = ({ searchProvider, filterProvider }) => {
  const navigate = useNavigate();
  const goToCreate = () => {
    navigate(CONTENTS_GROUP_LINKS.FORM_CONTENTS);
  };

  return (
    <section className="d-flex w-100 pt-1 my-3 align-items-center justify-content-between">
      <div className="d-flex align-items-center gap-3">
        <TableSearch
          className="biomarkers-search"
          search={searchProvider?.getValue()}
          onSearch={searchProvider.setValue}
          placeholder="Search content..."
        />

        <TableFilter filterProvider={filterProvider} />
      </div>

      <Button color={BUTTON_COLORS.primary} onClick={goToCreate} className="d-flex align-items-center">
        <i className="bx bx-plus me-2"/>
        Create content
      </Button>
    </section>
  );
};

export const TagsCollapseRows = ({ row }) => {
  const { tags } = row;

  return <tr>
    <td className="bg-white"/>
    <td className="bg-white" colSpan={5}>
      <div className="d-flex flex-wrap gap-2">
        {
          tags?.map(({ tag, wellnessId }) => <Badge
              key={`${tag.id}-${wellnessId}`}
              color="light"
              pill
              className="d-flex font-weight-normal py-2 px-3 w-fit-content align-items-center bg-transparent border-1 border-secondary font-size-13 border"
            >
              {tag.name}
            </Badge>
          )}
      </div>
    </td>
    <td className="bg-white"/>
  </tr>;
};

export const columns = [
  {
    Header: "#",
    width: 50,
    className: "bg-white",
    Cell: (cellProps) => {
      const { params: { offset = 0 } } = useLocationQuery();
      return <label style={{ width: "50px" }}
                    className="mb-0 text-nowrap">{cellProps.row.index + 1 + (offset ?? 0)}</label>;
    }
  },

  {
    Header: "Name",
    accessor: "title",
    className: "bg-white",
    canSort: true,
    width: 200,
    Cell: ({ value, row: { original }, collapsedState, toggleCollapse, useCollapseRule }) => {
      const { params } = useLocationQuery();
      const searchRequest = useMemo(
        () => params.search && params.search.toString()?.trim()?.length >= MIN_SEARCH_LENGTH ? params.search : null,
        [params.search]);
      const { decorateText } = useHighlight(searchRequest);
      const isCollapsed = collapsedState.includes(original.id);
      const canUseCollapse = useCollapseRule(original);

      return (
        <section
          style={{ width: "200px", overflow: "hidden" }}
          className="d-flex align-items-center"
          onClick={(event) => {
            event.stopPropagation();
            if (!canUseCollapse) return;

            toggleCollapse(original.id);
          }}
        >
          <div {...decorateText(value)} className="text-truncate mb-0"/>

          {canUseCollapse &&
            <Icon icon="arrowDown"
                  className={joinClassNames("table-expand-icon", isCollapsed ? "rotate-180" : "rotate-360")}/>
          }
        </section>
      );
    }
  },
  {
    Header: "Content assigned",
    accessor: "contentAssignedType",
    className: "bg-white",
    width: 140,
    canSort: true,
    Cell: ({ value }) => {
      return (
        <section>
          <div>{CONTENT_ASSIGNED_LABELS[value]}</div>
        </section>
      );
    }
  },
  {
    Header: "Category",
    accessor: "category",
    className: "bg-white",
    width: 100,
    canSort: true,
    Cell: ({ value }) => {
      return (
        <section>
          <div>{value.fullName}</div>
        </section>
      );
    }
  },
  {
    Header: "Subscription",
    accessor: "subscriptionPlan",
    className: "bg-white",
    Cell: ({ value }) => {
      return (
        <section>
          <div>{value?.adaptyProductName ? value?.adaptyProductName : '-'}</div>
        </section>
      );
    }
  },
  {
    Header: "Order",
    accessor: "order",
    className: "bg-white",
    canSort: true,
    Cell: ({ value }) => {
      return (
        <section>
          <div>{value}</div>
        </section>
      );
    }
  },
  {
    Header: "Actions",
    className: "bg-white",
    Cell: ({
      row: {
        original: { id }
      },
      actions
    }) => {

      return <section
        className="d-flex gap-1 align-items-center"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Icon icon="edit"
              width={20}
              height={20}
              className="me-4 cursor-pointer impacts-section__view"
              onClick={(event) => {
                event.stopPropagation();
                actions[TAGS_ACTIONS.EDIT](id);
              }}
        />

        <Icon
          icon={"trashIcon"}
          width={20}
          height={20}
          onClick={(event) => {
            event.stopPropagation();
            actions[TAGS_ACTIONS.DELETE](id);
          }}
          className="me-4 cursor-pointer"
        />
      </section>;
    }
  }
];

