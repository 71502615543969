// Libs
import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { ModalFooter, Spinner } from "reactstrap";

// Components, Views, Screens
import ValueWithLabel from "../../../../../../base/components/ValueWIthLabel";

// Hooks, Utils, Helpers
import { useService } from "../../../../../../base/hooks/useService";
import { useModal } from "../../../../../../base/hooks/useModal";
import { useLoading } from "../../../../../../base/hooks/useLoading";

import TagService from "../../../../../../services/TagService";
import { map } from "lodash";
import joinClassNames from "../../../../../../base/helpers/joinClassNames";

export function TagDetailsModal({ isOpen, close, tagId }) {
  /**
   * @type {TagService}
   */
  const tagService = useService(TagService);

  const { Modal } = useModal();
  const [isLoading, { registerPromise }] = useLoading(true);

  const [tag, setTag] = useState(null);

  useEffect(() => {
    if (tagId) {
      registerPromise(tagService.getTagById(tagId))
        .then((data) => {
          setTag(data);
        });
    }
  }, [tagId]);

  return (
    <Modal isOpen={isOpen} toggle={() => close()} size="md">
      <Modal.Header onClose={() => close()}>
        Tag
      </Modal.Header>

      {
        isLoading
          ? <div className="d-flex align-items-center justify-content-center py-5"><Spinner/></div>
          : <div>
            <div className="modal-header">
              <ValueWithLabel value={tag?.name} label="Name"/>
            </div>
            <Modal.Body>
              <section className="d-flex flex-column">
                <label className="font-weight-semibold mb-1">Related content</label>
                <label className="font-weight-normal mb-0 text-wrap word-break">
                  {tag?.contentNames?.join(", ") || "-"}
                </label>
              </section>

              <section className="d-flex flex-column mt-3">
                <label className="font-weight-semibold mb-1">Related categories</label>
                <label className="font-weight-normal mb-0 text-wrap word-break">
                  {tag?.categoryNames?.join(", ") || "-"}
                </label>
              </section>
            </Modal.Body>

          </div>
      }

    </Modal>);
}