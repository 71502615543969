import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";

import Title from "../../Biomarkers/Details/Title";
import BaseLayoutWithCard from "../../../../base/components/BaseLayoutWithCard";
import FormikInput from "../../../../base/components/FormikInput";
import Button from "../../../../base/components/Button";
import FormikReactSelect from "../../../../base/components/FormikReactSelect";
import RichTextEditor from "../../../../base/components/RichTextEditor";
import { FormAvatar } from "../../../../base/components/Dropzone/Dropzone";

import ToastService from "../../../../services/ToastService";

import { useService } from "../../../../base/hooks/useService";
import { useQueryString } from "../../../../base/hooks/useQueryString";
import useUploadImages from "../../../../base/components/Dropzone/useUploadImages";
import joinClassNames from "../../../../base/helpers/joinClassNames";
import { FITNESS_GROUP_LINKS } from "../config";
import {
    EXERCISE_CONTENT_FORMAT,
    EXERCISE_CONTENT_FORMAT_LABELS,
    MAX_INSTRUCTIONS_LENGTH,
    MAX_TITLE_LENGTH
} from "./constants";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";
import { PROGRAMS_FILE_TYPE } from "../../../../base/constants/shared";

import { initialValues, validateFile, validationSchema, exerciseStatusesOption, exerciseTypeOptions } from "./form";
import ExercisesService from "../../../../services/ExercisesService";
import RadioButton from "../../../../base/components/RadioButton";
import { EquipmentsDropdown } from "../../../../base/components/Dropdowns/EquipmentsDropdown/EquipmentsDropdown";
import { MusclesDropdown } from "../../../../base/components/Dropdowns/MusclesDropdown";
import { map } from "lodash";
import ExerciseMultiSelectDropdown from "../../../../base/components/Dropdowns/ExerciseDropdown/ExerciseMultiSelect";
import { CROP_IMAGE_RATIO_16_9 } from "../../../../base/constants/colorsAndSIzes";
import { MAX_SIZE_MB, MAX_WIDTH_OR_HEIGHT, USE_WEB_WORKER } from "../../../../base/constants/image";
import imageCompression from "browser-image-compression";

export function ExerciseForm() {
    /**
     * @type {ExercisesService}
     */
    const exercisesService = useService(ExercisesService);
    /**
     * @type {ToastService}
     */
    const toastService = useService(ToastService);
    const uploadImage = useUploadImages();
    const { search: locationSearch } = useLocation();
    const navigate = useNavigate();

    const [isSubmitting, updateIsSubmitting] = useState(false);
    const [exercise, setExercise] = useState(null);

    const {
        params: {
            editExerciseId
        }
    } = useQueryString(locationSearch);

    const afterSuccess = () => {
        toastService.success("Exercise has been successfully saved");
        navigate(FITNESS_GROUP_LINKS.EXERCISES_LIST);
        updateIsSubmitting(false);
    };

    const apiFunction = (exercise) => {
        if (editExerciseId) {
            return exercisesService.updateExercises(editExerciseId, exercise);
        }

        return exercisesService.createExercises(exercise);
    };

    const mapFormValuesToExercise = ({
                                         muscles,
                                         exerciseStatus,
                                         format,
                                         contentUrl,
                                         equipments,
                                         similarExercises,
                                         instruction,
                                         videoFile,
                                         ...otherValues
                                     }) => {
        return {
            ...otherValues,
            format,
            similarExerciseIds: map(similarExercises, 'id'),
            equipmentIds: map(equipments, 'id'),
            muscleIds: muscles,
            status: exerciseStatus,
            instruction: instruction || null,
            [EXERCISE_CONTENT_FORMAT.VIDEO === format ? 'videoUrl' : 'audioUrl']: contentUrl || null
        };
    };

    const createExercise = ({ file, ...otherValues }) => {
        updateIsSubmitting(true);

        const fileId = file?.[0]?.id;

        if (file?.[0]?.file && !file?.[0]?.id) {
          const options = {
            maxSizeMB: MAX_SIZE_MB,
            maxWidthOrHeight: MAX_WIDTH_OR_HEIGHT,
            useWebWorker: USE_WEB_WORKER,
          }
          imageCompression(file[0].file, options).then((compressedFile) => {
            return uploadImage(compressedFile, PROGRAMS_FILE_TYPE).then(({ file }) => {
              return apiFunction(mapFormValuesToExercise({
                ...otherValues,
                fileId: file.id
              }));
            });
          }).then(afterSuccess).finally(() => updateIsSubmitting(false));

          return;
        }

        apiFunction(mapFormValuesToExercise({
            ...otherValues,
            fileId
        })).then(afterSuccess).finally(() => updateIsSubmitting(false));
    };

    const mapExerciseToForm = ({
                                   muscles,
                                   equipment = [],
                                   status,
                                   updatedAt,
                                   id,
                                   createdAt,
                                   isDeleted,
                                   file,
                                   videoUrl,
                                   audioUrl,
                                   ...otherValues
                               }) => {
        return {
            muscles: map(muscles, "id"),
            equipments: equipment.map(({ id, name }) => ({ id, name })),
            exerciseStatus: status,
            file: file
                ? [{
                    ...(file || {}),
                    preview: file?.link,
                    file: { size: file?.bytes ?? 0 },
                    cropped: true
                }]
                : [],
            contentUrl: videoUrl || audioUrl,
            ...otherValues
        };
    };

    useEffect(() => {
        if (editExerciseId) {
            exercisesService.getExerciseById(editExerciseId)
                .then((data) => {
                    setExercise(mapExerciseToForm(data));
                });
        }
    }, [editExerciseId]);

    const breadcrumbs = {
        title: editExerciseId ? "Edit exercise" : "Create exercise",
        breadcrumbItems: [
            { title: "Fitness", link: FITNESS_GROUP_LINKS.BASE },
            { title: editExerciseId ? "Edit exercise" : "Create exercise" }
        ]
    };

    return <BaseLayoutWithCard breadcrumbs={breadcrumbs}>
        <Formik
            initialValues={exercise || initialValues}
            validationSchema={validationSchema}
            validateOnBlur
            onSubmit={createExercise}
            enableReinitialize
        >
            {({ errors, handleSubmit, values, setFieldValue }) => {

                return <form className={joinClassNames("form-horizontal p-2", isSubmitting && "pointer-events-none")}
                             onSubmit={handleSubmit}>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <Title
                            title="General information"
                        />
                    </div>
                    <section className="w-50">
                        <FormAvatar
                          validateImage={validateFile}
                          cropperSetting={CROP_IMAGE_RATIO_16_9}
                        />

                        <FormikInput
                            placeholder="Enter title (required)"
                            name="title"
                            maxLength={MAX_TITLE_LENGTH}
                            label="Title"
                            containerClassName="mt-3"
                        />

                        <FormikReactSelect
                            name="type"
                            options={exerciseTypeOptions}
                            label="Exercise type"
                            setFieldValue={setFieldValue}
                            containerClassName="mt-3"
                            placeholder="Select exercise type (required)"
                        />

                        <div className="mt-3">
                            <EquipmentsDropdown
                                value={values.equipments}
                                onChange={(value) => setFieldValue("equipments", value)}
                                placeholder="Select equipments"
                                label="Equipment"
                            />
                        </div>

                        <div className="mt-3">
                            <MusclesDropdown
                                name="muscles"
                                value={values.muscles}
                                setFieldValue={setFieldValue}
                                placeholder="Select muscles targeted (required)"
                                label="Muscles targeted"
                                errorMessage={errors.muscles}
                                withoutErrorText
                            />
                        </div>

                        <div className="mt-3">
                            <label>Similar exercises</label>
                            <ExerciseMultiSelectDropdown
                                value={values.similarExercises}
                                onChange={(value) => setFieldValue("similarExercises", value)}
                                placeholder="Select similar exercises"
                                label="Similar exercises"
                                error={errors.similarExercises}
                            />
                        </div>

                        <FormikReactSelect
                            name="exerciseStatus"
                            options={exerciseStatusesOption}
                            label="Status"
                            setFieldValue={setFieldValue}
                            containerClassName="mt-3"
                            placeholder="Select status (required)"
                        />

                        <div className="mt-3">
                            <label>Exercise content format:</label>
                            <div className="d-flex gap-3">
                                {Object.values(EXERCISE_CONTENT_FORMAT).map((format) => <RadioButton
                                    key={format}
                                    label={EXERCISE_CONTENT_FORMAT_LABELS[format]}
                                    onChange={() => {
                                        setFieldValue("contentUrl", "");
                                        setFieldValue("format", format);
                                    }}
                                    checked={values.format === format}
                                    name="format"
                                />)}
                            </div>
                        </div>
                    </section>

                    {
                        EXERCISE_CONTENT_FORMAT.TEXT === values.format
                            ? null
                            : <FormikInput
                                placeholder="Please enter a url"
                                name="contentUrl"
                                label={EXERCISE_CONTENT_FORMAT.VIDEO === values.format ? "Video URL" : "Audio URL"}
                                containerClassName="mt-3"
                            />
                    }

                    <RichTextEditor
                        value={values.instruction || initialValues.instruction}
                        onChange={(text) => {
                            setFieldValue('instruction', text);
                        }}
                        maxLength={MAX_INSTRUCTIONS_LENGTH}
                        name="instruction"
                        label="Instruction"
                        placeholder="Enter instructions for the exercise..."
                    />

                    <div className="d-flex justify-content-end mt-5">
                        {<Button
                            color={BUTTON_COLORS.primary}
                            type="submit"
                            disabled={!!Object.keys(errors).length || isSubmitting}
                        >
                            Save exercise
                        </Button>}
                    </div>
                </form>;
            }}
        </Formik>
    </BaseLayoutWithCard>;
}