import React, { useCallback, useEffect, useState } from 'react';
import joinClassNames from "../../../../base/helpers/joinClassNames";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Input,
  Label,
  Spinner,
  UncontrolledAccordion
} from "reactstrap";
import { DropZoneCard } from "../../../../base/components/Dropzone";
import {
  ALLOWED_LOGO_FILE_EXTENSION, ASSETS_FILE_TYPE,
  BYTES_IN_KILOBYTE, ERROR_ALLOWED_LOGO_FILE_TYPE_MESSAGE, ERROR_LOGO_FILE_SIZE_MESSAGE,
  KILOBYTES_IN_MEGABYTE,
  LOGO_FILE_MASK,
  MAX_LOGO_FILE_SIZE
} from "../../../../base/constants/shared";
import Icon from "../../../../base/components/Icon";
import { Formik } from "formik";
import { validateEmptyFile, validateFileSize, validateFileType } from "../../../../validation/fileUploadAndProcessing";
import ToasterService from "../../../../services/ToastService";
import { useService } from "../../../../base/hooks/useService";
import FormikColorPicker from "../../../../base/components/FormikColorPicker";
import * as _ from "lodash";
import ConfigService, { ThemeTypes } from "../../../../services/ConfigService";
import FormikInput from "../../../../base/components/FormikInput";
import Button from "../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";
import yup from "../../../../validation/yup";
import { MAX_RULE_NAME_LENGTH, MIN_STRING_LENGTH } from "../../HealthProgram/Nutrition/CreateEditRule/constants";
import useUploadImages from "../../../../base/components/Dropzone/useUploadImages";
import {
  BiomarkerShapeTypes,
  FormikShapeSelect
} from "../../../../base/components/FormikReactSelect";
import { useDarkMode } from "../../../../base/context/darkMode";

export const validationSchema = yup.object().shape({
  loginText: yup.string()
    .trim()
    .min(MIN_STRING_LENGTH)
    .max(MAX_RULE_NAME_LENGTH)
    .required(),
});

const shapeSelectOptions = [
  {
    label: "Triangle",
    value: BiomarkerShapeTypes.triangle
  },
  {
    label: "Hexagon",
    value: BiomarkerShapeTypes.hexagon
  },
  {
    label: "Circle",
    value: BiomarkerShapeTypes.circle
  }
]

export default function HomePageLayout() {
  const [initialValues, setInitialValues] = useState({});
  const [isSubmitting, updateIsSubmitting] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, updateIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState('1');
  const [apiConfig, setApiConfig] = useState({});
  const [apiSettings, setApiSettings] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const uploadImage = useUploadImages();
  const isDarkMode = useDarkMode();
  const themeType = isDarkMode ? ThemeTypes.dark : ThemeTypes.white;

  /**
   * @type {ConfigService}
   */
  const configService = useService(ConfigService);
  /**
   * @type {ToasterService}
   */
  const toastService = useService(ToasterService);

  const fetchData = () => {
    return Promise.all([
      configService.fetchCustomerConfig(),
      configService.fetchThemeSettings()
    ]);
  }

  useEffect(() => {
    fetchData().then(([rawConfig, rawThemeSettings]) => {
      const configFields = ["id", "variable", "value"];
      const config = _.chain(rawConfig)
        .get("data")
        .map(item => _.pick(item, configFields))
        .keyBy("variable")
        .value();

      setApiConfig(config);

      const themeFields = ["id", "propertyName", "fileId", "value", "file", "page", "themeType"];
      const themeSettings = _.chain(rawThemeSettings)
        .get("data")
        .filter({ themeType:  themeType })
        .map(item => _.pick(item, themeFields))
        .keyBy("propertyName")
        .value();

      setApiSettings(themeSettings);
      setSelectedFile({
        ...themeSettings.homepageBackgroundImage
      });
      updateIsLoading(false);

      setInitialValues((prev)  => ({
        ...prev,
        homepageBackgroundImage: themeSettings.homepageBackgroundImage.value,
        bloodHeatmapButtonText: themeSettings.bloodHeatmapButtonText.value,
        BLOOD_HEATMAP_LINK: config.BLOOD_HEATMAP_LINK.value,
        skinHeatmapButtonText: themeSettings.skinHeatmapButtonText.value,
        SKIN_HEATMAP_LINK: config.SKIN_HEATMAP_LINK.value,
        dnamHeatmapButtonText: themeSettings.dnamHeatmapButtonText.value,
        DNAM_HEATMAP_LINK: config.DNAM_HEATMAP_LINK.value,
        heatmapBackgroundColor: themeSettings.heatmapBackgroundColor.value,
        avatarInitials: themeSettings.avatarInitials.value,
        opacity: themeSettings.opacity.value,
        criticalShape: themeSettings.criticalShape.value,
        criticalColor: themeSettings.criticalColor.value,
        badShape: themeSettings.badShape.value,
        badColor: themeSettings.badColor.value,
        borderlineShape: themeSettings.borderlineShape.value,
        borderlineColor: themeSettings.borderlineColor.value,
        optimalShape: themeSettings.optimalShape.value,
        optimalColor: themeSettings.optimalColor.value,
        nonTestedShape: themeSettings.nonTestedShape.value,
        nonTestedColor: themeSettings.nonTestedColor.value,
        chartsTagsColorOne: themeSettings.chartsTagsColorOne.value,
        chartsTagsColorTwo: themeSettings.chartsTagsColorTwo.value,
        chartsTagsColorThree: themeSettings.chartsTagsColorThree.value,
        chartsTagsColorFour: themeSettings.chartsTagsColorFour.value,
        tagColor: themeSettings.tagColor.value,
        loginText: themeSettings.loginText.value,
      }));
    });
  }, [configService, isDarkMode]);

  const handleAcceptedFile = useCallback(async (file) => {
    setErrorMessage("");

    try {
      validateFileType(file, ALLOWED_LOGO_FILE_EXTENSION, ERROR_ALLOWED_LOGO_FILE_TYPE_MESSAGE);
      validateFileSize(file, MAX_LOGO_FILE_SIZE, ERROR_LOGO_FILE_SIZE_MESSAGE);
      validateEmptyFile(file);
    } catch ({ message }) {
      toastService.error(message)
      setErrorMessage(message);
      return;
    }

    setSelectedFile((prev) => {
      return {
        ...prev,
        value: URL.createObjectURL(file),
        fileData: file,
        file: {
          name: file.name,
          bytes: file.size
        }
      }});

  }, [setErrorMessage]);

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === REACT_DROPZONE_INVALID_FILE_TYPE_ERROR) {
            toastService.error(ERROR_ALLOWED_LOGO_FILE_TYPE_MESSAGE);
          }
        });
      });

      if (!acceptedFiles.length) return;

      acceptedFiles.forEach((file) => handleAcceptedFile(file));

    },
    [handleAcceptedFile]
  );

  const deleteLogo = () => {
    setSelectedFile(null);
  }

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const submitSettings = async (values) => {
    updateIsSubmitting(true);
    try {
      const configToSubmit = _.pick(apiConfig, _.keys(values));
      const settingsToSubmit = _.pick(apiSettings, _.keys(values));

      _.map(settingsToSubmit, (setting) => {
        setting.value = values[setting.propertyName];
        return setting;
      });

      _.map(configToSubmit, (config) => {
        config.value = values[config.variable];
      });

      if (!selectedFile.id) {
        const uploadedFile = await uploadImage(selectedFile.fileData, ASSETS_FILE_TYPE); // uploadedFile.file.id
        settingsToSubmit.homepageBackgroundImage = {
          ...settingsToSubmit.homepageBackgroundImage,
          fileId: uploadedFile.file.id
        };
        setSelectedFile((prev) => ({
          ...prev,
          id: uploadedFile.file.id
        }));
      }

      settingsToSubmit.homepageBackgroundImage = _.omit(settingsToSubmit.homepageBackgroundImage, ['value', 'file']);

      const updatedSettings = await configService.updateThemeSettings(_.values(settingsToSubmit));
      const updatedConfig = await configService.updateCustomerConfig(_.values(configToSubmit));

      toastService.success("Settings was successfully uploaded");
    } catch (err) {
      console.error(err);
      toastService.error(err)
      setErrorMessage(err);
    } finally {
      updateIsSubmitting(false);
    }
  };

  return (
    <div className="sideform-wrapper">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur
        onSubmit={submitSettings}
        enableReinitialize
      >
        {({ errors, handleSubmit, values, setFieldValue }) =>
          <form className={joinClassNames("form-horizontal", isSubmitting && "pointer-events-none")}
                onSubmit={handleSubmit}>
            <UncontrolledAccordion defaultOpen={'1'} toggle={toggle} stayOpen={true}
                                   className="ui-customisation-accordion">
              <AccordionItem>
                <AccordionHeader targetId="1">
                  Background image
                </AccordionHeader>
                <AccordionBody accordionId="1">
                  <p className="accordion-description">This background image will be used at every step of sign up and log in</p>
                  {isLoading &&
                    <section className="w-100 py-5 spinner d-flex align-items-center justify-content-center">
                      <Spinner color="info"/>
                    </section>
                  }
                  {!selectedFile && !isLoading &&
                    <DropZoneCard
                    onDrop={onDrop}
                  errorMessage={errorMessage}
                      isDropContainer={true}
                      className={""}
                      fileMask={LOGO_FILE_MASK}
                    >
                      <section className="upload-container--section">
                        <p className="upload-container--section__cloud-upload-desc">Upload logo</p>
                        <p className="upload-container--section__desc mb-0">Drop a PNG, JPG or JPEG file.</p>
                        <p
                          className="upload-container--section__desc">Max {MAX_LOGO_FILE_SIZE / BYTES_IN_KILOBYTE / KILOBYTES_IN_MEGABYTE} Mb</p>
                      </section>
                    </DropZoneCard>
                  }
                  {selectedFile && !isLoading && !isUploading &&
                    <div>
                      <div
                        className="d-flex align-items-center justify-content-between p-4 selected-file-container formik-file-input">
                        <div className="d-flex align-items-center">
                          <img src={selectedFile.value} alt="Logo" className="formik-file-input-logo"/>
                          <div className="d-flex flex-column justify-content-between px-4">
                            <span
                              className="selected-file-container__selected-file-name mb-1">{selectedFile.file.name}</span>
                            <span className="font-semibold">
                                {(selectedFile.file.bytes / BYTES_IN_KILOBYTE).toFixed(2)} KB
                              </span>
                          </div>
                        </div>
                        <Icon icon="trashIcon" className="cursor-pointer ms-3" onClick={deleteLogo}/>
                      </div>
                    </div>
                  }
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="2">Button text & link</AccordionHeader>
                <AccordionBody accordionId="2">
                  <section className="input-subgroup my-4 pb-4">
                    <span className="font-weight-semibold">Blood heatmap</span>
                    <FormikInput
                      label="Button text"
                      placeholder="Take a new test"
                      name="bloodHeatmapButtonText"
                      maxLength={255}
                      containerClassName="mt-3"
                    />
                    <FormikInput
                      label="Link"
                      placeholder="Provide a link for your test"
                      name="BLOOD_HEATMAP_LINK"
                      maxLength={255}
                      containerClassName="mt-3"
                    />
                  </section>

                  <section className="input-subgroup my-4 pb-4">
                    <span className="font-weight-semibold">Skin heatmap</span>
                    <FormikInput
                      label="Button text"
                      placeholder="Take a photo"
                      name="skinHeatmapButtonText"
                      maxLength={255}
                      containerClassName="mt-3"
                    />
                    <FormikInput
                      label="Link"
                      placeholder="Provide a link for your test"
                      name="SKIN_HEATMAP_LINK"
                      maxLength={255}
                      containerClassName="mt-3"
                    />
                  </section>

                  <section className="input-subgroup mt-4 border-0">
                    <span className="font-weight-semibold">DNAm heatmap</span>
                    <FormikInput
                      label="Button text"
                      placeholder="Take a photo"
                      name="dnamHeatmapButtonText"
                      maxLength={255}
                      containerClassName="mt-3"
                    />
                    <FormikInput
                      label="Link"
                      placeholder="Provide a link for your test"
                      name="DNAM_HEATMAP_LINK"
                      maxLength={255}
                      containerClassName="mt-3"
                    />
                  </section>


                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="3">Color & Shapes</AccordionHeader>
                <AccordionBody accordionId="3">
                  <p className="accordion-description">Changes in colors selection will effect colors on biomarker
                    details page and all elements related to biomarkers on the platform.</p>

                  <section className="input-subgroup my-4 pb-4">
                    <span className="font-weight-semibold">Heatmap background color</span>
                    <FormikColorPicker
                      id="heatmapBackgroundColor"
                      label="Color"
                      name="heatmapBackgroundColor"
                      maxLength={255}
                      containerClassName="d-flex mt-3 flex-row gap-3 align-center"
                      display="inline"
                    />
                  </section>

                  <section className="input-subgroup my-4 pb-4">
                    <span className="font-weight-semibold">Avatar initials</span>
                    <FormikColorPicker
                      id="heatmapBackgroundColor"
                      label="Color"
                      name="avatarInitials"
                      maxLength={255}
                      containerClassName="d-flex mt-3 flex-row gap-3 align-center"
                      display="inline"
                    />
                  </section>

                  <section className="input-subgroup my-4 pb-4">
                    <span className="font-weight-semibold">Default colors opacity</span>
                    <p className="accordion-description mt-2">Colors with opacity would be displayed when elements are
                      not active</p>
                    <div className="range-container p-relative">
                      <div className="labels">
                        <span className="min-label">0</span>
                        <span className="max-label">100</span>
                      </div>
                      <span className="range-tooltip" style={{
                        'left': `calc(${(values.opacity * 100)}% - 9px)`
                      }}>{values.opacity * 100}</span>
                      <FormikInput
                        id="opacity"
                        name="opacity"
                        type="range"
                        min={0}
                        max={1}
                        step={0.1}
                        style={{ 'background': `linear-gradient(to right, #556EE6 ${values.opacity * 100}%, #CED4DA 0px` }}
                      />
                    </div>
                  </section>

                  <section className="input-subgroup my-4 pb-4">
                    <span className="font-weight-semibold">Critical</span>

                    <FormikShapeSelect
                      label="Category"
                      name="criticalShape"
                      placeholder="Select shape"
                      containerClassName="d-flex gap-3 mt-3"
                      setFieldValue={setFieldValue}
                      options={shapeSelectOptions}
                      color={values.criticalColor}
                    />

                    <FormikColorPicker
                      id="heatmapBackgroundColor"
                      label="Active color"
                      name="criticalColor"
                      maxLength={255}
                      containerClassName="d-flex mt-3 flex-row gap-3 align-center"
                      display="inline"
                    />
                  </section>

                  <section className="input-subgroup my-4 pb-4">
                    <span className="font-weight-semibold">Bad</span>

                    <FormikShapeSelect
                      label="Category"
                      name="badShape"
                      placeholder="Select shape"
                      containerClassName="d-flex gap-3 mt-3"
                      setFieldValue={setFieldValue}
                      options={shapeSelectOptions}
                      color={values.badColor}
                    />

                    <FormikColorPicker
                      id="heatmapBackgroundColor"
                      label="Active color"
                      name="badColor"
                      maxLength={255}
                      containerClassName="d-flex mt-3 flex-row gap-3 align-center"
                      display="inline"
                    />
                  </section>

                  <section className="input-subgroup my-4 pb-4">
                    <span className="font-weight-semibold">Borderline</span>

                    <FormikShapeSelect
                      label="Category"
                      name="borderlineShape"
                      placeholder="Select shape"
                      containerClassName="d-flex gap-3 mt-3"
                      setFieldValue={setFieldValue}
                      options={shapeSelectOptions}
                      color={values.borderlineColor}
                    />

                    <FormikColorPicker
                      id="heatmapBackgroundColor"
                      label="Active color"
                      name="borderlineColor"
                      maxLength={255}
                      containerClassName="d-flex mt-3 flex-row gap-3 align-center"
                      display="inline"
                    />
                  </section>

                  <section className="input-subgroup my-4 pb-4">
                    <span className="font-weight-semibold">Optimal</span>

                    <FormikShapeSelect
                      label="Category"
                      name="optimalShape"
                      placeholder="Select shape"
                      containerClassName="d-flex gap-3 mt-3"
                      setFieldValue={setFieldValue}
                      options={shapeSelectOptions}
                      color={values.optimalColor}
                    />

                    <FormikColorPicker
                      id="heatmapBackgroundColor"
                      label="Active color"
                      name="optimalColor"
                      maxLength={255}
                      containerClassName="d-flex mt-3 flex-row gap-3 align-center"
                      display="inline"
                    />
                  </section>

                  <section className="input-subgroup my-4 pb-4">
                    <span className="font-weight-semibold">Non-tested</span>

                    <FormikShapeSelect
                      label="Category"
                      name="nonTestedShape"
                      placeholder="Select shape"
                      containerClassName="d-flex gap-3 mt-3"
                      setFieldValue={setFieldValue}
                      options={shapeSelectOptions}
                      color={values.nonTestedColor}
                    />

                    <FormikColorPicker
                      id="heatmapBackgroundColor"
                      label="Active color"
                      name="nonTestedColor"
                      maxLength={255}
                      containerClassName="d-flex mt-3 flex-row gap-3 align-center"
                      display="inline"
                    />
                  </section>
                </AccordionBody>
              </AccordionItem>

              <AccordionItem>
                <AccordionHeader targetId="4">Charts & Tags</AccordionHeader>
                <AccordionBody accordionId="4">
                  <FormikColorPicker
                    label="Color 1"
                    name="chartsTagsColorOne"
                    maxLength={255}
                    containerClassName="d-flex mt-3 flex-row gap-3 align-center"
                    display="inline"
                  />
                  <FormikColorPicker
                    label="Color 2"
                    name="chartsTagsColorTwo"
                    maxLength={255}
                    containerClassName="d-flex mt-3 flex-row gap-3 align-center"
                    display="inline"
                  />
                  <FormikColorPicker
                    label="Color 3"
                    name="chartsTagsColorThree"
                    maxLength={255}
                    containerClassName="d-flex mt-3 flex-row gap-3 align-center"
                    display="inline"
                  />
                  <FormikColorPicker
                    label="Color 4"
                    name="chartsTagsColorFour"
                    maxLength={255}
                    containerClassName="d-flex mt-3 flex-row gap-3 align-center"
                    display="inline"
                  />
                </AccordionBody>
              </AccordionItem>

              <AccordionItem>
                <AccordionHeader targetId="5">Recommendation cards</AccordionHeader>
                <AccordionBody accordionId="5">
                  <FormikColorPicker
                    label="Tag color"
                    name="tagColor"
                    maxLength={255}
                    containerClassName="mt-3 gap-3"
                    previewType="tag"
                  />
                </AccordionBody>
              </AccordionItem>
            </UncontrolledAccordion>
            <div className="d-flex justify-content-end mt-5 gap-3">
              <Button
                color={BUTTON_COLORS.primary}
                type="submit"
                disabled={!!Object.keys(errors).length || isSubmitting}
              >
                Save changes
              </Button>
            </div>
          </form>
        }
      </Formik>
    </div>
  );
}