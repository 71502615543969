
import yup from "../../../../../../validation/yup";
import { WORKOUT_TYPE_STATUSES_LABELS } from "../../../constants";
import {
    MIN_STRING_LENGTH
} from "../../../../HealthProgram/Nutrition/CreateEditProgram/constants";
import { capitalize } from "lodash";
import { MAX_FULL_NAME } from "./constants";


export const initialValues = {
    fullName: '',
    status: null,
};

export const validationSchema = yup.object().shape({
    fullName: yup
        .string()
        .trim()
        .min(MIN_STRING_LENGTH)
        .max(MAX_FULL_NAME)
        .required(),
    status: yup.string().required(),
});

export const workTypeStatusOptions = Object.entries(WORKOUT_TYPE_STATUSES_LABELS).map(([key, value]) => ({
    value: key,
    id: key,
    label: capitalize(value)
}))

