import { SERVICE_BLOK_FIELDS } from "../../../base/constants/storyblok";

export const getAttributes = (story) => {
    const componentAttribute = story.content?.body?.find((item) => {
        return item.component === "attributes"
    })

    if (!componentAttribute) return {}

    return Object.keys(componentAttribute).reduce((acc, key) => {
        if (!["title", ...SERVICE_BLOK_FIELDS].includes(key)) {
            acc[key] = componentAttribute[key]
        }

        return acc
    }, {})
}

export const getTags = (story) => {
    const tagsComponent = story.content?.body?.find((item) => {
        return item.component === "tags"
    })

    if (!tagsComponent) return {}

    return Object.keys(tagsComponent).reduce((acc, key) => {
        if (!(SERVICE_BLOK_FIELDS.includes(key))) {
            acc[key] = tagsComponent[key]
        }

        return acc
    }, {})
}

export const getStoryTitle = (story) => {
    const componentAttribute = story.content?.body?.find((item) => item.component === "attributes")
    return componentAttribute?.title
}

export const getPreviewImage = (story) => {
    const componentImage = story.content?.body?.find((item) => item.component === "image-section")
    return componentImage?.image?.filename
}

export const getBannerInfo = (story) => {
    const componentBanner = story.content?.body?.find((item) => item.component === "banner")

    return {
        logo: componentBanner?.logo?.filename,
        text: componentBanner?.text,
        date: componentBanner?.date
    }
}