import { MEDICATION_TYPE, SUPPLEMENT_TYPE } from "../Interactions/constants";

export const getInteractionOutput = (interactions) => {
  const currentSupplements = [], currentMedications = [];

  const interactionsOutput = interactions.map((interaction = {}) => {
    if (interaction.type === SUPPLEMENT_TYPE) {
      currentSupplements.push(interaction);
      return {
        ...interaction,
        order: currentSupplements.length,
      }
    }

    if (interaction.type === MEDICATION_TYPE) {
      currentMedications.push(interaction)
      return {
        ...interaction,
        order: currentMedications.length,
      }
    }
  })

  return { interactionsOutput, currentSupplements, currentMedications }
}