
export const FITNESS_GROUP_ROUTES = {
  BASE: "/*",
  LISTS: "/",
  FORM: "/form",
  DETAILS: "/:id",
};

export const FITNESS_GROUP_LINKS = {
  BASE: "/app/health-program/fitness",
  LISTS: "/app/health-program/fitness",
  FORM: "/app/health-program/fitness/form",
};