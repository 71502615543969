import React, { useEffect, useState } from 'react';
import { Nav, NavItem, NavLink } from "reactstrap";
import classNames from "classnames";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import RulesList from "./RulesList";
import BaseLayoutWithCard from "../../../../../base/components/BaseLayoutWithCard";
import GoalsList from "./GoalsList";

import { LIST_TABS } from "./constants";
import { useQueryString } from "../../../../../base/hooks/useQueryString";
import { PROGRAMS_GROUP_LINKS } from "../config";
import { useService } from "../../../../../base/hooks/useService";
import ProgramsService from "../../../../../services/ProgramsService";
import { useLoading } from "../../../../../base/hooks/useLoading";

export default function RuleGoalList() {
    /**
     * @type {ProgramsService}
     */
    const programsService = useService(ProgramsService);

    const [isLoading, {registerPromise}] = useLoading(true);
    const [programs, setPrograms] = useState(null);

    const { search: locationSearch, pathname } = useLocation();

    const {
        params: {
            activeTab,
        }
    } = useQueryString(locationSearch);

    const {programId} = useParams()

    const tabComponents = {
        [LIST_TABS.RULES]: <RulesList/>,
        [LIST_TABS.GOALS]: <GoalsList/>
    };

    let navigate = useNavigate();

    const updateActiveTab = (tab) => {
        const queryParams = new URLSearchParams({ activeTab: tab }).toString();
        navigate(`${PROGRAMS_GROUP_LINKS.BASE}/${programId}/rule-goal?${queryParams}`)
    }

    const breadcrumbs = {
        title: programs?.fullName,
        breadcrumbItems: [
            { title: "Nutrition", link: PROGRAMS_GROUP_LINKS.BASE },
            { title: "Program detailed view", link: `${PROGRAMS_GROUP_LINKS.BASE}/${programId}` },
            { title: "Rules & Nutritional goals" },
        ]
    };

    useEffect(() => {
        if (activeTab) return

        updateActiveTab(LIST_TABS.RULES)
    }, [activeTab])

    useEffect(() => {
        if (programId) {
            registerPromise(programsService.getProgramById(programId))
                .then((data) => {
                    setPrograms(data)
                    const currentSearchParams = new URLSearchParams(locationSearch);

                    currentSearchParams.set('isPredefined', data.isPredefined);

                    navigate(`${pathname}?${currentSearchParams.toString()}`, { replace: true });
                });
        }
    }, [programId])

    return (
        <BaseLayoutWithCard breadcrumbs={breadcrumbs}>
            <Nav tabs className="nav-tabs-custom mt-1">
                {Object.values(LIST_TABS).map((label, index) => (
                    <NavItem key={`navItem${index}`}>
                        <NavLink
                            className={classNames(
                                {
                                    active: activeTab === label
                                },
                                "cursor-pointer py-1 mx-1"
                            )}
                            onClick={() => updateActiveTab(label)}
                        >
                            <span>{label}</span>
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>

            {tabComponents[activeTab]}
        </BaseLayoutWithCard>
    );
}
