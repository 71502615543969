import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { debounce, difference, map } from "lodash";
import { Dropdown, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem, Spinner } from "reactstrap";
import classnames from "classnames";

import classes from "./IngredientGroupByCategoryDropdown.module.scss";
import Checkbox from "../../Checkbox";
import TableSearch from "../../Table/tableSearch";
import { toggleSelectItem } from "../../../helpers/toggleSelectItem";
import { useHighlight } from "../../../hooks/useHighlight";
import { fetchDataWithChunks } from "../../../helpers/api";
import Swiper from "../../Swiper/Swiper";
import { useService } from "../../../hooks/useService";
import MetaNutritionApi from "../../../../services/MetaNutritionApi";
import { useLoading } from "../../../hooks/useLoading";



const ALL_FOODS_TAB_KEY = "all";
const MIN_SEARCH_LENGTH = 3;
const MAX_LIMIT = 1000;
const ID_KEY = "_id"

const IngredientGroupByCategoryDropdown = ({ onChange, value = [], placeholder }) => {
    /**
     * @type {MetaNutritionApi}
     */
    const metaNutritionApi = useService(MetaNutritionApi);
    const [isOpen, updateIsOpen] = useState(false);
    const [ingredientGroups, setIngredientGroups] = useState([]);
    const [slides, setSlides] = useState([]);
    const [activeTabs, setActiveTabs] = useState(ALL_FOODS_TAB_KEY);
    const [search, setSearch] = useState("");
    const [prevSearchRequest, setPrevSearchRequest] = useState("");
    const [hasNextPage, setHasNextPage] = useState(true);
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, { registerPromise }] = useLoading(true);
    const lastElementRef = useRef(null);

    const valueIds = map(value, ID_KEY);
    const ingredientGroupIds = map(ingredientGroups, ID_KEY);
    const showPlaceholder = !value.length;
    const valueTitles = useMemo(() => {
        return map(value, 'name');
    }, [value]);

    const isSelectedAll = useMemo(() => {
        return !(difference(ingredientGroupIds, valueIds).length);
    }, [valueIds, ingredientGroupIds]);

    const searchRequest = useMemo(() => {
        return search && search.toString().trim().length >= MIN_SEARCH_LENGTH ? search : '';
    }, [search]);

    const { decorateText } = useHighlight(searchRequest);

    const selectAllItems = (newItems) => {
        const currentIds = map(newItems, ID_KEY);

        if (isSelectedAll) {
            onChange(value.filter(({ _id }) => !currentIds.includes(_id)));
        } else {
            const unselectedItems = newItems.filter(({ _id }) => {
                return !valueIds.includes(_id);
            });

            onChange([...value, ...unselectedItems]);
        }
    };

    const handleSelectAllValues = () => {
        const loadCallback = (limit, page) => {
            const params = {
                limit,
                categoryId: activeTabs === ALL_FOODS_TAB_KEY ? null : activeTabs
            };
            return metaNutritionApi.getIngredientGroup(searchRequest, page, params);
        };

        if (!hasNextPage) {
            selectAllItems(ingredientGroups);
        } else {
            registerPromise(fetchDataWithChunks({
                totalCount,
                limit: MAX_LIMIT,
                fetchCallback: loadCallback,
                resultCallback: (result) => result.data
            })).then((data) => {
                setIngredientGroups(data);
                setHasNextPage(false);

                selectAllItems(data);
            });
        }
    };

    const handleClear = () => {
        onChange([]);
    };

    const loadIngredientGroups = useCallback(() => {
        const params = {
            limit: MAX_LIMIT,
            categoryId: activeTabs === ALL_FOODS_TAB_KEY ? null : activeTabs
        };

        setHasNextPage(false);

        registerPromise(metaNutritionApi.getIngredientGroup(searchRequest, 1, params))
            .then(({ data, meta }) => {
                setIngredientGroups(data);
                setHasNextPage(meta.pages > page);
                setPage(1)
                setTotalCount(meta?.total);
            });
    }, [searchRequest, activeTabs]);

    const loadNextPage = () => {
        if (isLoading || !hasNextPage) return;

        const params = {
            limit: MAX_LIMIT,
            categoryId: activeTabs === ALL_FOODS_TAB_KEY ? null : activeTabs
        };

        const nextPage = page + 1

        registerPromise(metaNutritionApi.getIngredientGroup(searchRequest, nextPage, params))
            .then(({ data, meta }) => {
                setIngredientGroups((prev) => [...prev, ...data]);
                setHasNextPage(meta.pages > page);
                setPage(nextPage)
                setTotalCount(meta?.total);
            });
    }

    const debounceSetNextPage = debounce(loadNextPage, 300);

    const debounceLoadIngredientGroups = useMemo(() => debounce(loadIngredientGroups, 600), [loadIngredientGroups])

    useEffect(() => {
        debounceLoadIngredientGroups();
    }, [debounceLoadIngredientGroups]);

    useEffect(() => {
        metaNutritionApi.getIngredientGroupCategories()
            .then(({ data }) => {
                setSlides([
                    {
                        id: ALL_FOODS_TAB_KEY,
                        name: "All"
                    },
                    ...data.map((item) => {
                    return {
                        id: item.categoryId,
                        name: item.categoryName
                    };
                })]);
            });
    }, []);

    useEffect(() => {
        if (!lastElementRef.current) return;

        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                debounceSetNextPage();
            }
        });

        observer.observe(lastElementRef.current);

        return () => {
            observer.disconnect();
        };
    }, [debounceSetNextPage, lastElementRef.current]);

    return (
        <section className="d-flex align-items-center w-100">
            <Dropdown
                isOpen={isOpen}
                toggle={() => updateIsOpen(prevState => !prevState)}
                className="d-inline-block filter-dropdown cursor-pointer result-filter w-100"
                direction="down"
            >
                <DropdownToggle
                    className={classnames('filter-toggle w-100', { 'with-border': isOpen })}
                    tag="section"
                >
                    <span
                        className={classnames('ms-2 me-1 pointer-events-none user-select-none text-truncate', { 'text-secondary': showPlaceholder })}>
                        {showPlaceholder
                            ? placeholder || 'Select ingredient group'
                            : valueTitles.join(', ')}
                    </span>
                    <i className={classnames('mdi mdi-chevron-down pointer-events-none user-select-none', { 'mdi-rotate-180': isOpen })} />
                </DropdownToggle>
                <DropdownMenu className="filter-menu pb-1 px-1 w-100 top-50" flip={false}>
                    <TableSearch onSearch={setSearch} search={search} className="my-2" />

                    <Swiper
                        containerClassName="my-2"
                        slidesPerView="auto"
                        spaceBetween={0}
                        slideClassName="w-fit-content"
                        slides={slides.map((item) => {
                            return <button
                                type="button"
                                key={item.id}
                                onClick={() => {
                                    if (item.id !== activeTabs) {
                                        setActiveTabs(item.id)
                                    }

                                }}
                                className={classnames(
                                    classes.Slide,
                                    item.id === activeTabs ? classes.Active : ""
                                )}
                            >
                                {item.name}
                            </button>;
                        })}
                    />

                    <section>
                        <ListGroup className={classes.ListGroup}>
                            <ListGroupItem
                                className={classnames("d-flex bg-transparent justify-content-between", classes.ServiceItem)}>
                                <Checkbox
                                    name="selectAllValues"
                                    text="Select all"
                                    value={isSelectedAll}
                                    onChange={handleSelectAllValues}
                                />
                                <button
                                    type="button"
                                    className="text-danger border-0 bg-transparent"
                                    onClick={handleClear}
                                >
                                    Clear
                                </button>
                            </ListGroupItem>

                            <div className={classnames(classes.ItemsWrapper, "custom-scrollbar")}>
                                {ingredientGroups.map((item) => (
                                    <ListGroupItem className="bg-transparent border-0" key={item}>
                                        <Checkbox
                                            name={`ingredientGroup-${item[ID_KEY]}`}
                                            text={<div {...decorateText(item.name)} />}
                                            value={valueIds.includes(item[ID_KEY])}
                                            onChange={() => toggleSelectItem(item, value, onChange, ID_KEY)}
                                        />
                                    </ListGroupItem>
                                ))}

                                <div ref={lastElementRef} className="d-flex justify-content-center"/>
                                {isLoading && <div className="d-flex my-2 justify-content-center">
                                    <Spinner size="sm" color="primary"/>
                                </div>}
                            </div>
                        </ListGroup>
                    </section>
                </DropdownMenu>
            </Dropdown>
        </section>
    );
};

export default IngredientGroupByCategoryDropdown;
