import React from 'react';
import { Nav, NavItem, NavLink } from "reactstrap";
import classNames from "classnames";
import { CONTENTS_LISTS_TAB_LINKS, CONTENTS_LISTS_TABS } from "./constants";
import BaseLayoutWithCard from "../../../../base/components/BaseLayoutWithCard";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { CONTENTS_LISTS_ROUTES } from "./config";
import { CONTENTS_GROUP_LINKS } from "../config";
import Tags from "./Tags";
import Categories from "./Categories";
import Contents from "./Contents";

const breadcrumbs = {
  title: "Content", breadcrumbItems: []
};

export default function Lists() {
  let navigate = useNavigate();

  const location = useLocation();

  return (
    <BaseLayoutWithCard breadcrumbs={breadcrumbs}>
      <Nav tabs className="nav-tabs-custom mt-1">
        {Object.values(CONTENTS_LISTS_TABS).map((label, index) => (
          <NavItem key={`navItem${index}`}>
            <NavLink
              className={classNames(
                {
                  active: location.pathname === CONTENTS_LISTS_TAB_LINKS[label]
                },
                "cursor-pointer py-1 mx-1"
              )}
              onClick={() => navigate(CONTENTS_LISTS_TAB_LINKS[label])}
            >
              <span>{label}</span>
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      <Routes>
        <Route
          path={CONTENTS_LISTS_ROUTES.TAGS}
          element={<Tags/>}
        />

        <Route
          path={CONTENTS_LISTS_ROUTES.CATEGORIES}
          element={<Categories/>}
        />

        <Route
          path={CONTENTS_LISTS_ROUTES.CONTENTS}
          element={<Contents/>}
        />

        <Route path={"/"} element={<Navigate to={CONTENTS_GROUP_LINKS.CONTENTS_LIST}/>}/>
      </Routes>
    </BaseLayoutWithCard>
  );
}
