import React from "react";
import "./index.scss";
import Wrapper from "../Wrapper";

const Exercise = ({ blok }) => {
    if (!blok) {
        return null;
    }

    function formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');

        return `${formattedMinutes}:${formattedSeconds}`;
    }

    return (
        <Wrapper blok={{ ...blok }}>
            <div className="exercise">
                <div className="title">{blok.title}</div>
                <div className="media">
                    {blok.image?.filename && !blok.video?.filename &&
                        <div className="media-container">
                            <img className="image" src={blok.image.filename} alt={blok.description} loading="lazy"/>
                        </div>
                    }
                    {blok.video?.filename &&
                        <div className="media-container">
                            <video
                                controls
                                playsInline
                                poster={blok.image.filename}
                                className="video">
                                <source src={blok.video?.filename} type="video/mp4"/>
                                Your browser does not support the video tag.
                            </video>
                            <p className="text-description">{blok.videoTitle}</p>
                        </div>
                    }
                </div>
                <div className="instruction">
                    <div>
                        {blok.sets} sets
                    </div>
                    {Boolean(blok.reps) && <>
                        <div className="border"/>
                        <div>
                            {blok.reps} reps
                        </div>
                    </>}
                </div>
                {blok.restInSeconds && <div className="rest">
                    Rest {formatTime(blok.restInSeconds)}
                </div>}
            </div>
        </Wrapper>
    );
};

export default Exercise;
