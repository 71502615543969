export const CUSTOMER_RESULTS_CATEGORIES = {
    BLOOD_CATEGORY: 1,
    DNA_CATEGORY: 2,
    FOOD_INTOLERANCE_CATEGORY: 3
}

export const CATEGORY_LABELS = {
    [CUSTOMER_RESULTS_CATEGORIES.BLOOD_CATEGORY]: "Blood",
    [CUSTOMER_RESULTS_CATEGORIES.DNA_CATEGORY]: "DNA",
    [CUSTOMER_RESULTS_CATEGORIES.FOOD_INTOLERANCE_CATEGORY]: "Food Intolerance",
}