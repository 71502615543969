import React, { useEffect, useState } from 'react';
import {  Nav, NavItem, NavLink } from "reactstrap";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import ProgramsList from "./ProgramsList";
import BaseLayoutWithCard from "../../../../../base/components/BaseLayoutWithCard";

import { PROGRAM_LIST_TABS } from "./constants";

const breadcrumbs = {
    title: "Wellness Programs", breadcrumbItems: []
};

export default function FitnessProgramsList() {

    const [activeTab, updateActiveTab] = useState(PROGRAM_LIST_TABS.PROGRAMS);

    const tabComponents = {
        [PROGRAM_LIST_TABS.PROGRAMS]: <ProgramsList key={PROGRAM_LIST_TABS.PROGRAMS} isPredefined={false} />,
        [PROGRAM_LIST_TABS.PREDEFINED]: <ProgramsList key={PROGRAM_LIST_TABS.PREDEFINED} isPredefined={true} />
    }

    let navigate = useNavigate();

    const clearQueryParams = () => {
        navigate(window.location.pathname, { replace: true });
    };

    useEffect(() => {
        clearQueryParams()
    }, [activeTab]);

    return (
            <BaseLayoutWithCard breadcrumbs={breadcrumbs} >
                            <Nav tabs className="nav-tabs-custom mt-1">
                                {Object.values(PROGRAM_LIST_TABS).map((label, index) => (
                                    <NavItem key={`navItem${index}`}>
                                        <NavLink
                                            className={classNames(
                                                {
                                                    active: activeTab === label
                                                },
                                                "cursor-pointer py-1 mx-1"
                                            )}
                                            onClick={() => updateActiveTab(label)}
                                        >
                                            <span>{label}</span>
                                        </NavLink>
                                    </NavItem>
                                ))}
                            </Nav>

                            {tabComponents[activeTab]}
            </BaseLayoutWithCard>
    );
}
