import React, { useState } from "react";
import Wrapper from "../Wrapper";
import {
    FacebookShareButton,
} from "react-share";
import { ReactComponent as InstagramIcon } from "../../assets/image/instagram.svg";
import { ReactComponent as TikTokIcon } from "../../assets/image/tik-tok.svg";
import { ReactComponent as FacebookIcon } from "../../assets/image/facebook.svg";
import { ReactComponent as OtherLinkIcon } from "../../assets/image/link.svg";
import { useModal } from "../../../../hooks/useModal";
import { Input, InputGroup, InputGroupText } from "reactstrap";

const ShareBlock = ({ blok }) => {
    const {open, isOpen, close, getModalProps, Modal} = useModal()
    const [isCopied, setIsCopied] = useState(false)

    if (!blok) {
        return null;
    }

    const shareUrl = window.location.href;

    const handleClickCopyHref = () => {
        navigator.clipboard.writeText(shareUrl);
        setIsCopied(true)
    }

    const handleCloseShareModal = () => {
        setIsCopied(false)
        close()
    }

    return (
        <Wrapper blok={{ ...blok, width: "content-width" }}>
            <div>
                <p className="text-description text-center mb-1 font-size-16">Share</p>
                <div className="d-flex align-items-center gap-3">
                    <div className="social-item instagram" onClick={open}>
                        <InstagramIcon/>
                    </div>
                    <div className="social-item tiktok" onClick={open}>
                        <TikTokIcon/>
                    </div>
                    <FacebookShareButton url={shareUrl} quote={blok.title}>
                        <div className="social-item facebook">
                            <FacebookIcon/>
                        </div>
                    </FacebookShareButton>
                    <div className="social-item other" onClick={open}>
                        <OtherLinkIcon/>
                    </div>
                </div>
            </div>

            <Modal {...getModalProps()} >
                <Modal.Header onClose={handleCloseShareModal}>
                    Share link
                </Modal.Header>
                <Modal.Body>
                    <InputGroup>
                        <Input value={shareUrl} disabled />
                        <InputGroupText className="cursor-pointer" onClick={handleClickCopyHref}>
                            {isCopied ? "Copied" : "Copy"}
                        </InputGroupText>
                    </InputGroup>
                </Modal.Body>
            </Modal>
        </Wrapper>
    );
};

export default ShareBlock;
