import React, { useState, useCallback, useEffect } from 'react';
import { CustomModal, ModalActions, ModalHeader } from "../../../../base/components/CustomModal";
import Button from "../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";
import { useService } from "../../../../base/hooks/useService";
import { Formik } from 'formik';
import { validationSchema } from "./formValidation";
import FormikInput from "../../../../base/components/FormikInput";
import FormikReactSelect from "../../../../base/components/FormikReactSelect";
import { QUIZ_TYPES_OPTIONS } from "../constants";
import UserTagService from "../../../../services/UserTagService";
import { useParams } from "react-router-dom";
import ToasterService from "../../../../services/ToastService";

const INITIAL_VALUES = {
  key: "",
  value: "",
  quizType: ""
};

export default function EditTagPopup({
  isOpen,
  onClose,
  selectedTagId,
}) {
  /**
   * @type {UserTagService}
   */
  const userTagService = useService(UserTagService);
  /**
   * @type {ToasterService}
   */
  const toastService = useService(ToasterService);

  const { id: userId } = useParams();

  const isEdit = !!selectedTagId;
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);
  const [tagType, setTagType] = useState("text");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchUserTag = async () => {
    return userTagService.getUserTagById(selectedTagId);
  };

  useEffect(() => {
    setIsSubmitting(false);
    setInitialValues(INITIAL_VALUES);

    if(!selectedTagId) return;
    fetchUserTag(selectedTagId).then((data) => {
      setInitialValues({
        key: data.key,
        value: data.value,
        quizType: data.quizType
      });
      setTagType(data.type);
    })
  }, [selectedTagId]);


  const onSubmit = async (data) => {
    setIsSubmitting(true);
    if (isEdit) {
      await userTagService.updateUserTag(selectedTagId, data);
    } else {
      data.userId = userId;
      await userTagService.createUserTag(data);
    }

    toastService.success(`User variable was successfully ${isEdit ? "updated" : "created"}`);

    onClose();
  }

  return (
    <CustomModal isOpen={isOpen} className="filter-modal">
      <ModalHeader onClose={onClose}>
        {isEdit ? "Edit variable" : "Add variable"}
      </ModalHeader>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(tagType)}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ errors, handleSubmit, values, setFieldValue }) => {
          return (
            <form onSubmit={handleSubmit}>
              <section className="px-3">
                <FormikInput
                  label="Full name"
                  placeholder="Full name"
                  name="key"
                  maxLength={255}
                  containerClassName="mt-3"
                />

                <FormikInput
                  label="Value"
                  placeholder="Value"
                  name="value"
                  maxLength={255}
                  containerClassName="mt-3"
                />

                <FormikReactSelect
                  name="quizType"
                  options={QUIZ_TYPES_OPTIONS}
                  value={QUIZ_TYPES_OPTIONS.find(option => option.value === values.quizType)}
                  placeholder="Select quiz type"
                  label="Quiz type"
                  setFieldValue={setFieldValue}
                  containerClassName="mt-3 mb-5"
                />
              </section>

              <ModalActions>
                <Button color={BUTTON_COLORS.primaryOutline} onClick={onClose} className="mb-0">
                  Cancel
                </Button>
                <Button
                  color={BUTTON_COLORS.primary}
                  type="submit"
                  className="mb-0"
                  disabled={
                    isSubmitting || !!Object.keys(errors).length
                  }
                >
                  {isEdit ? "Save changes" : "Create"}
                </Button>
              </ModalActions>
            </form>
          )
        }}
      </Formik>
    </CustomModal>
  )
}