import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from "react-router-dom";

import Table from "../../../../../base/components/Table";
import ConfirmPopup from "../../../../../base/components/ConfirmPopup";
import {
    SearchPlaceholder,
    TableHeader,
    columns,
    NoTagsPlaceholder,
    TAGS_ACTIONS
} from "./components";

import { DEFAULT_TABLE_LIMIT } from "../../../../../base/constants/shared";
import {
    usePaginationProvider,
    useLocationSource,
    useSearchProvider,
    useSortProvider
} from "../../../../../base/components/Table/hooks";
import ToasterService from "../../../../../services/ToastService";
import { useService } from "../../../../../base/hooks/useService";
import { useLoading } from "../../../../../base/hooks/useLoading";
import { useQueryString } from "../../../../../base/hooks/useQueryString";
import { LIMIT_OPTIONS, MIN_SEARCH_LENGTH } from "../../../../../base/constants/pagination";
import { TagModalForm } from "./TagForm";
import TagService from "../../../../../services/TagService";
import { TagDetailsModal } from "./TagDetails";

export default function Tags() {
    /**
     * @type {ToasterService}
     */
    const toastService = useService(ToasterService);
    /**
     * @type {TagService}
     */
    const tagService = useService(TagService);
    const [showFormPopup, updateShowFormPopup] = useState(false);
    const [isLoading, { registerPromise }] = useLoading(false);
    const { search: locationSearch, pathname } = useLocation();
    const {
        params: {
            limit = DEFAULT_TABLE_LIMIT,
            offset = 0,
            search,
            name,
        }
    } = useQueryString(locationSearch);

    const [tags, updateTags] = useState([]);
    const [pagination, updatePagination] = useState({});
    const [showDeletePopup, updateShowDeletePopup] = useState(null);
    const [showDetailsPopup, updateShowDetailsPopup] = useState(null);
    const [editTagId, updateTagId] = useState(null)

    const locationSource = useLocationSource();

    const sortKeys = ["name"];
    const getSortScope = (key) => [...sortKeys.filter(item => item !== key), "offset"];

    const nameSortProvider = useSortProvider({
        source: locationSource,
        alias: "name",
        scope: "",
        onApplyClearScope: getSortScope("name")
    });

    const searchRequest = useMemo(() => search && search.toString()?.trim()?.length >= MIN_SEARCH_LENGTH
        ? search
        : null,
        [search])

    const paginationProvider = usePaginationProvider({
        source: locationSource,
        alias: "offset",
        scope: "",
        fallback: 0
    });

    const searchProvider = useSearchProvider({
        source: locationSource,
        scope: "",
        alias: 'search',
        onApplyClearScope: ["offset"]
    });

    const limitProvider = usePaginationProvider({
        source: locationSource,
        alias: "limit",
        scope: "",
        fallback: 10,
        onApplyClearScope: ["offset"]
    });

    const handleClickCreate = () => {
        updateShowFormPopup(true)
    }

    const deleteTags = (id) => {
        tagService.deleteTag(id).then(() => {
            getTags()
            toastService.success("The tags has been successfully deleted")
        })
    }

    const getTags = useCallback(() => {
        const [orderBy, orderType] = Object.entries({
            name
        }).find(([_, value]) => value) || [];

        registerPromise(tagService.getTags({
            limit,
            offset,
            query: searchRequest,
            orderBy,
            orderType,
        })).then(({ data, pagination }) => {
            updateTags(data);
            updatePagination(pagination);
        });
    }, [limit, offset, searchRequest, name]);

    useEffect(() => {
        getTags();
    }, [getTags]);

    return (
        <>
            <Table
                columns={tags.length ? columns : []}
                data={tags}
                loading={isLoading}
                HeaderComponent={TableHeader}
                totalCount={pagination.totalCount}
                limit={limitProvider.getValue()}
                offset={offset}
                paginationProvider={paginationProvider}
                searchProvider={searchProvider}
                commonPlaceholder={<NoTagsPlaceholder/>}
                placeholderForSearch={<SearchPlaceholder/>}
                actions={{
                    [TAGS_ACTIONS.EDIT]: (id) => {
                        updateShowFormPopup(true)
                        updateTagId(id)
                    },
                    [TAGS_ACTIONS.DELETE]: updateShowDeletePopup,
                    [TAGS_ACTIONS.OPEN_DETAILS]: updateShowDetailsPopup
                }}
                sortProviders={{
                    name: nameSortProvider,
                }}
                isLimitEditable
                limitProvider={limitProvider}
                limitOptions={LIMIT_OPTIONS}
                headerActions={{ handleClickCreate }}
            />
            {showDeletePopup &&
                <ConfirmPopup
                    isOpen={showDeletePopup}
                    updateIsOpen={updateShowDeletePopup}
                    onSubmit={() => {
                        deleteTags(showDeletePopup)
                        updateShowDeletePopup(null);
                    }}
                    title="Delete tag"
                    description="Are you sure you want to delete the chosen tag?"
                    submitBtnText="Delete"
                    className="upload-manually__popup"
                />
            }
            {showFormPopup && <TagModalForm
                isOpen={showFormPopup}
                editTagId={editTagId}
                close={() => {
                    updateShowFormPopup(false)
                    updateTagId(null)
                    getTags()
                }}
            />}

            {showDetailsPopup && <TagDetailsModal
              isOpen={showDetailsPopup}
              tagId={showDetailsPopup}
              close={() => {
                  updateShowDetailsPopup(false)
              }}
            />}
        </>
    );
}
