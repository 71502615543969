import React from "react";

const SingleAttributes = ({ label, value }) => {
    if (!value) return null

    return (
        <div
            key={label}>
            {label}: <span className="text-info">{value}</span>
        </div>
    );
};

export default SingleAttributes;
