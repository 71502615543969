import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import Table from "../../../../../base/components/Table";
import ConfirmPopup from "../../../../../base/components/ConfirmPopup";
import {
    SearchPlaceholder,
    TableHeader,
    columns,
    NoCategoriesPlaceholder,
    CATEGORIES_ACTIONS, TagsCollapseRows
} from "./components";

import { DEFAULT_TABLE_LIMIT } from "../../../../../base/constants/shared";
import {
    usePaginationProvider,
    useLocationSource,
    useSearchProvider,
    useSortProvider
} from "../../../../../base/components/Table/hooks";
import ToasterService from "../../../../../services/ToastService";
import { useService } from "../../../../../base/hooks/useService";
import { useLoading } from "../../../../../base/hooks/useLoading";
import { useQueryString } from "../../../../../base/hooks/useQueryString";
import { LIMIT_OPTIONS, MIN_SEARCH_LENGTH } from "../../../../../base/constants/pagination";
import CategoryService from "../../../../../services/ContentCategoriesService";
import { FITNESS_GROUP_LINKS } from "../../../Fitness/config";
import { CONTENTS_GROUP_LINKS } from "../../config";

export default function Categories() {
    /**
     * @type {ToasterService}
     */
    const toastService = useService(ToasterService);
    /**
     * @type {ContentCategoriesService}
     */
    const categoryService = useService(CategoryService);
    const [showFormPopup, updateShowFormPopup] = useState(false);
    const [isLoading, { registerPromise }] = useLoading(false);
    const { search: locationSearch, pathname } = useLocation();
    const {
        params: {
            limit = DEFAULT_TABLE_LIMIT,
            offset = 0,
            search,
            name,
        }
    } = useQueryString(locationSearch);

    const [categories, updateCategories] = useState([]);
    const [pagination, updatePagination] = useState({});
    const [showDeletePopup, updateShowDeletePopup] = useState(null);
    const [collapsedRows, updateCollapsedRows] = useState([]);

    const locationSource = useLocationSource();
    const navigate = useNavigate()

    const toggleCollapse = (id) => {
        if(collapsedRows.includes(id)) {
            updateCollapsedRows(prevState => prevState.filter(item => item !== id))
            return
        }
        updateCollapsedRows(prevState => [...prevState, id]);
    }

    const sortKeys = ["name"];
    const getSortScope = (key) => [...sortKeys.filter(item => item !== key), "offset"];

    const nameSortProvider = useSortProvider({
        source: locationSource,
        alias: "name",
        scope: "",
        onApplyClearScope: getSortScope("name")
    });

    const searchRequest = useMemo(() => search && search.toString()?.trim()?.length >= MIN_SEARCH_LENGTH
        ? search
        : null,
        [search])

    const paginationProvider = usePaginationProvider({
        source: locationSource,
        alias: "offset",
        scope: "",
        fallback: 0
    });

    const searchProvider = useSearchProvider({
        source: locationSource,
        scope: "",
        alias: 'search',
        onApplyClearScope: ["offset"]
    });

    const limitProvider = usePaginationProvider({
        source: locationSource,
        alias: "limit",
        scope: "",
        fallback: 10,
        onApplyClearScope: ["offset"]
    });

    const handleClickCreate = () => {
        updateShowFormPopup(true)
    }

    const deleteCategories = (id) => {
        categoryService.deleteCategory(id).then(() => {
            getCategories()
            toastService.success("The categories has been successfully deleted")
        })
    }

    const goToEdit = (id) => {
        const queryParams = new URLSearchParams({ editCategoryId: id }).toString();
        navigate(`${CONTENTS_GROUP_LINKS.FORM_CATEGORIES}?${queryParams}`);
    }

    const getCategories = useCallback(() => {
        const [orderBy, orderType] = Object.entries({
            name
        }).find(([_, value]) => value) || [];

        registerPromise(categoryService.getContentCategories({
            limit,
            offset,
            query: searchRequest,
            orderBy,
            orderType,
        })).then(({ data, pagination }) => {
            updateCategories(data);
            updatePagination(pagination);
        });
    }, [limit, offset, searchRequest, name]);

    useEffect(() => {
        getCategories();
    }, [getCategories]);

    return (
        <>
            <Table
                columns={categories.length ? columns : []}
                data={categories}
                loading={isLoading}
                HeaderComponent={TableHeader}
                totalCount={pagination.totalCount}
                limit={limitProvider.getValue()}
                offset={offset}
                paginationProvider={paginationProvider}
                searchProvider={searchProvider}
                commonPlaceholder={<NoCategoriesPlaceholder/>}
                placeholderForSearch={<SearchPlaceholder/>}
                actions={{
                    [CATEGORIES_ACTIONS.EDIT]: goToEdit,
                    [CATEGORIES_ACTIONS.DELETE]: updateShowDeletePopup,
                }}
                sortProviders={{
                    name: nameSortProvider,
                }}
                isLimitEditable
                limitProvider={limitProvider}
                limitOptions={LIMIT_OPTIONS}
                headerActions={{ handleClickCreate }}
                useCollapseRule={({categoriesTags}) => !!categoriesTags?.length}
                collapsedState={collapsedRows}
                toggleCollapse={toggleCollapse}
                CollapsedComponent={TagsCollapseRows}
                isRowClick
            />
            {showDeletePopup &&
                <ConfirmPopup
                    isOpen={showDeletePopup}
                    updateIsOpen={updateShowDeletePopup}
                    onSubmit={() => {
                        deleteCategories(showDeletePopup)
                        updateShowDeletePopup(null);
                    }}
                    title="Delete category"
                    description="Are you sure you want to delete the chosen category?"
                    submitBtnText="Delete"
                    className="upload-manually__popup"
                />
            }
        </>
    );
}
