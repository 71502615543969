import Http from "./Http";

class ExercisesService extends Http {
    static $displayName = "ExercisesService";

    async getExercises(params) {
        return await this.get(`/exercises`, { params });
    }

    async getSimpleExercises() {
        return await this.get(`/exercises/simple`);
    }

    async getExerciseById(id) {
        return await this.get(`/exercises/${id}`);
    }

    createExercises(values) {
        return this.post(`/exercises`, values);
    }

    deleteExercise(id) {
        return this.delete(`/exercises/${id}`);
    }

    updateExercises(id, values) {
        return this.put(`/exercises/${id}`, values);
    }

    getEquipments() {
        return this.get('/equipments')
    }

    getMuscles() {
        return this.get('/exercises/muscles')
    }
}

export default ExercisesService;
