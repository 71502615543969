import Http from "./Http";

class HomePageLayoutService extends Http {
  static $displayName = "HomePageLayoutService";

  getHomepageSections(params) {
    return this.get(`/admin-homepage-sections`, { params })
  }

  getPreviewHomepageSections(params) {
    return this.get(`/users/homepage-sections`, { params })
  }

  changeHomepageSectionOrder(id, { oldPosition, newPosition }) {
    return this.patch(`/admin-homepage-sections/${id}`, { oldPosition, newPosition })
  }

  changeHomepageSectionStatus(id, isActive) {
    return this.patch(`/admin-homepage-sections/${id}/status`, { isActive })
  }

  createSection(values) {
    return this.post(`/admin-homepage-sections`, values)
  }

  updateSection(id, values) {
    return this.put(`/admin-homepage-sections/${id}`, values)
  }

  getSectionById(id) {
    return this.get(`/admin-homepage-sections/${id}`)
  }

  deleteHomepageSection(id) {
    return this.delete(`/admin-homepage-sections/${id}`)
  }

  getHomepageSectionFilters(type, params) {
    return this.get(`/admin-homepage-sections/search-filter-categories/${type}`, { params })
  }
}

export default HomePageLayoutService;
