import React, { useCallback, useEffect, useState } from "react";
import {
  CustomModal,
  ModalBody,
  ModalHeader
} from "../../../../base/components/CustomModal";
import Table from "../../../../base/components/Table";
import { useService } from "../../../../base/hooks/useService";
import UserResultsService from "../../../../services/UserResults";
import { useLoading } from "../../../../base/hooks/useLoading";
import ToasterService from "../../../../services/ToastService";

const formatDate = (s = "") => s.split("T")[0].split("-").reverse().join("/");

export default function DNACsvViewer({ updateIsOpen, details }) {
  /**
   * @type {UserResultsService}
   */
  const userResultsService = useService(UserResultsService);
  /**
   * @type {ToasterService}
   */
  const toastService = useService(ToasterService);
  
  const [data, setData] = useState([]);
  const [extra, setExtra] = useState();
  
  const [isLoading, { registerPromise }] = useLoading(false);
  
  const onClose = () => {
    updateIsOpen(false);
    setData([])
  };
  
  const getData = useCallback((id) => {
    registerPromise(userResultsService.getCsvData(id))
      .then((data) => {
        setData(data.data);
        setExtra(data.extra);
      })
      .catch(() => {
        onClose();
      });
  }, [details]);
  
  useEffect(() => {
    if (details) {
      getData(details.id);
    }
  }, [details]);
  
  return (
    <CustomModal scrollable={false} isOpen={details} className="csvViewerModal foodIntoleranceModal" toggle={onClose}>
      <ModalHeader
        onClose={onClose}
        sectionClassName="border-none"
      >
        <p className="text-dark mb-1">View CSV Result</p>
        <div className="d-flex">
          <p className="uploaded-tag mb-0 me-1">Uploaded:</p>
          <p className="uploaded-val mb-0">{formatDate(extra?.updatedAt)}</p>
        </div>
      </ModalHeader>
      <ModalBody className="h-82 d-flex flex-column ">
        
        <Table
          tableClassName={"dna-table custom-scrollbar"}
          columns={[
            {
              Header: "#",
              width: 10,
              className: "text-center align-middle",
              Cell: (cellProps) => {
                return <label className="mb-0">{cellProps.row.index + 1}</label>;
              }
            },
            ...(data.length ? Object.keys(data[0]).map(key => ({
              Header: key,
              accessor: key,
              className: "",
              Cell: (cellProps) => {
                return <div className="div-cell" title={cellProps.cell.value}>{cellProps.cell.value || '-'}</div>
              }
            })) : [{
              Header: "Results",
              accessor: "Results"
            }])
          ]}
          data={data}
          loading={isLoading}
          commonPlaceholder="No results for now."
          placeholderForSearch={"No results for now."}
          withoutPagination={true}
          headerClassName="position-sticky top-0"
        />
      
      </ModalBody>
    </CustomModal>
  );
}
