import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLoading } from "../../../../base/hooks/useLoading";
import { useLocation } from "react-router-dom";
import { useService } from "../../../../base/hooks/useService";
import {
  parseSorting,
  useFilterProvider,
  useLocationSource,
  usePaginationProvider,
  useSearchProvider, useSortProvider,
} from "../../../../base/components/Table/hooks";
import { useDebounce } from "../../../../base/hooks/useDebounce";
import { DEFAULT_DEBOUNCE_DELAY, DEFAULT_TABLE_LIMIT, EMPTY_ARRAY } from "../../../../base/constants/shared";
import { useQueryString } from "../../../../base/hooks/useQueryString";
import Table from "../../../../base/components/Table";
import { SearchPlaceholder, TableHeader } from "./components";
import { columns } from './tableStructure';
import BaseLayoutWithCard from "../../../../base/components/BaseLayoutWithCard";
import RecommendationsService from "../../../../services/RecommendationsService";
import { activityOptions, activityValues } from "../CreateEdit/constants";
import ToasterService from "../../../../services/ToastService";

const breadcrumbs = {
  title: "Recommendations", breadcrumbItems: []
}

export default function RecommendationsList() {
  /**
   * @type {RecommendationsService}
   */
  const recommendationsService = useService(RecommendationsService);
  /**
   * @type {ToasterService}
   */
  const toastService = useService(ToasterService);

  const [recommendations, updateRecommendations] = useState([]);
  const [recommendationsPagination, updateRecommendationsPagination] = useState({ totalCount: 0 });
  const { search: locationSearch } = useLocation();
  const {
    params: {
      limit = DEFAULT_TABLE_LIMIT,
      offset = 0,
      search,
      title,
      category,
      categories = EMPTY_ARRAY,
      isArchived = EMPTY_ARRAY
    }
  } = useQueryString(locationSearch);

  const [isLoading, { registerPromise }] = useLoading(true);
  const locationSource = useLocationSource();

  const paginationProvider = usePaginationProvider({
    source: locationSource,
    alias: "offset",
    scope: "",
    fallback: offset
  });

  const [debouncedSearch] = useDebounce(search, DEFAULT_DEBOUNCE_DELAY, () => paginationProvider.setValue(0));

  const generateSortProviderParams = (name) => {
    const allParams = ["title", "category", "offset"];
    return {
      source: locationSource,
      alias: name,
      scope: "",
      onApplyClearScope: allParams.filter(paramName => paramName !== name)
    }
  }

  const titleSortProvider = useSortProvider(generateSortProviderParams("title"));
  const categorySortProvider = useSortProvider(generateSortProviderParams("category"));

  const searchProvider = useSearchProvider({
    source: locationSource,
    scope: "",
    alias: 'search',
    onApplyClearScope: [""]
  })

  const categoryFilterProvider = useFilterProvider({
    source: locationSource,
    scope: "",
    alias: 'categories',
    onApplyClearScope: ["offset"]
  })

  const statusFilterProvider = useFilterProvider({
    source: locationSource,
    scope: "",
    alias: 'isArchived',
    onApplyClearScope: ["offset"]
  })

  const getActivityFilterOption = () => {
    if (isArchived?.length === activityOptions.length) {
      return;
    }
    return activityValues[isArchived];
  }

  const getRecommendations = useCallback(() => {
    const sortObject = parseSorting({ title, category });
    registerPromise(recommendationsService.getRecommendations({
        limit,
        offset,
        search: debouncedSearch,
        category: categories,
        isArchived: getActivityFilterOption(isArchived),
        ...sortObject
      })
        .then(({ data, pagination }) => {
          updateRecommendations(data);
          updateRecommendationsPagination(pagination)
        })
    )
  }, [offset, limit, debouncedSearch, title, category, categories, isArchived]);

  const handleArchive = (id, isArchived) => {
    recommendationsService.archiveRecommendation(id, { isArchived: !isArchived }).then(() => {
      getRecommendations();
      toastService.success(`Recommendation has been successfully ${isArchived ? "unarchived" : "archived"}`)
    })
  }

  const handleDelete = (id) => {
    recommendationsService.deleteRecommendation(id).then(() => {
      getRecommendations();
    })
  }

  useEffect(() => {
    getRecommendations();
  }, [getRecommendations])

  const sortProviders = useMemo(() => ({
    title: titleSortProvider,
    category: categorySortProvider,
  }), [titleSortProvider, categorySortProvider]);

  const filterProvider = useMemo(() => ({
    category: categoryFilterProvider,
    status: statusFilterProvider
  }), [categoryFilterProvider, statusFilterProvider]);

  return (
    <BaseLayoutWithCard breadcrumbs={breadcrumbs}>
      <Table
        columns={columns}
        data={recommendations}
        isAddNewBiomarker
        loading={isLoading}
        HeaderComponent={TableHeader}
        totalCount={recommendationsPagination.totalCount}
        limit={DEFAULT_TABLE_LIMIT}
        offset={offset}
        sortProviders={sortProviders}
        paginationProvider={paginationProvider}
        onArchive={handleArchive}
        onDelete={handleDelete}
        onEdit={() => {
        }}
        searchProvider={searchProvider}
        commonPlaceholder="No recommendations found"
        placeholderForSearch={<SearchPlaceholder/>}
        filterProvider={filterProvider}
        hasActiveFilters={!!categoryFilterProvider.getValue() || !!statusFilterProvider.getValue()}
        isRowClick
      />
    </BaseLayoutWithCard>
  )
}