export const MAX_ALSO_KNOW_AS_NAMES = 1;
export const MEDICATION_TYPE = 1;
export const SUPPLEMENT_TYPE = 2;

export const LABELS_DUE_TO_TYPE = {
  [MEDICATION_TYPE]: {
    name: "Medication",
  },
  [SUPPLEMENT_TYPE]: {
    name: "Supplement"
  }
}