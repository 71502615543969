import React, { useState } from 'react';
import { Carousel as RCarousel, CarouselControl, CarouselItem } from 'reactstrap';
import { CAROUSEL_ITEM_COMPONENTS_BY_SECTION_TYPE } from "./config";
import Icon from "../../../Icon";
import classes from "./SectionCarousel.module.scss";
import classnames from "classnames";

function SectionsCarousel({ type, quantityPerLine = 1, items = [] }) {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const SlideComponent = CAROUSEL_ITEM_COMPONENTS_BY_SECTION_TYPE[type] || Placeholder;

    const chunkedItems = _.chunk(items, quantityPerLine);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === chunkedItems.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? chunkedItems.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = chunkedItems.map((items, index) => {
        return (
            <CarouselItem
                className={classes.CarouselItem}
                tag="div"
                key={index}
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
            >
                <div className="d-flex h-100 w-100 gap-2">
                    {
                        items.map((item) => <div
                            key={item.id}
                            className="h-100"
                            style={{ flex: `0 0 ${100 / quantityPerLine}%` }}
                        >
                            <SlideComponent item={item}/>
                        </div>)
                    }
                </div>

            </CarouselItem>
        );
    });

    return (
        <div>
            <RCarousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                interval={false}
            >
                <div className={classes.CustomIndicators}>
                    {chunkedItems.map((_, idx) => (
                        <span
                            key={idx}
                            className={`${classes.IndicatorDot} ${activeIndex === idx ? classes.Active : ''}`}
                            onClick={() => goToIndex(idx)}
                        />
                    ))}
                </div>

                {slides.length ? slides : [<Placeholder key={'placeholder'}/>]}

                <CarouselControl
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={previous}
                />
                <CarouselControl
                    direction="next"
                    directionText="Next"
                    onClickHandler={next}
                />
            </RCarousel>
        </div>
    );
}

export default SectionsCarousel;

export const Placeholder = () => {
    return <div className={classnames(classes.CarouselItem, classes.Placeholder)}>
        <Icon icon="logoPlaceholder"/>
    </div>;
};