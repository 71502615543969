import React, { useState } from 'react'
import { CustomModal, ModalBody, ModalHeader } from "../../../../base/components/CustomModal";
import { ModalFooter } from "reactstrap";
import Button from "../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";
import CustomInput from "../../../../base/components/Input";
import { MAX_RULE_NAME_LENGTH } from "../../Biomarkers/CreateEdit/constants";
import RadioButton from "../../../../base/components/RadioButton";
import { formatSearch } from "../../../../base/components/Table/tableSearch";

export default function TemplatePopup({
  isOpen,
  updateIsOpen,
  initialTemplateName = "",
  isInitialPrivate = true,
  afterOnClose = () => {},
  onSubmit,
}) {
  const [isPrivate, updateIsPrivate] = useState(isInitialPrivate);
  const [templateName, updateTemplateName] = useState(initialTemplateName);

  const onClose = () => {
    updateIsOpen(false);
    afterOnClose()
  }

  return (
    <CustomModal isOpen={isOpen} className="template-modal" toggle={onClose}>
      <ModalHeader onClose={onClose} className="text-dark" sectionClassName="border-none">
        Save as template
      </ModalHeader>
      <ModalBody>
        <section>
          <label className="">Add a title to the new template:</label>
          <CustomInput
            value={templateName}
            onChange={(event) => updateTemplateName(formatSearch(event.target.value).trimStart())}
            placeholder="Enter title"
            maxLength={MAX_RULE_NAME_LENGTH}
          />
        </section>

        <section className="mt-3">
          <label>Template type:</label>
          <section className="d-flex align-items-center">
            <RadioButton
              checked={isPrivate}
              onChange={() => updateIsPrivate(prevState => !prevState)}
              containerClassName=""
              label="Private"
            />

            <RadioButton
              checked={!isPrivate}
              onChange={() => updateIsPrivate(prevState => !prevState)}
              containerClassName="ms-4"
              label="Public"
            />
          </section>
        </section>
      </ModalBody>
      <ModalFooter>
        <Button
          color={BUTTON_COLORS.primaryOutline}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color={BUTTON_COLORS.primary}
          onClick={() => {
            onSubmit({ templateName, isPrivate })
            onClose();
          }}
          disabled={!templateName.length}
        >
          Save
        </Button>
      </ModalFooter>
    </CustomModal>
  )
}