import React from 'react';
import PlusButtonWithTooltip from "../../../../../base/components/PlusButtonWithTooltip";
import { BULLET_LIST_TYPES_MAP } from "./constants";
import InputWithDelete from "../../../../../base/components/InputWithDeleteIcon";
import { MAX_BIOMARKERS_NAME_LENGTH } from "../constants";
import StudyLink from "./StudyLink";
import { getIn, useFormikContext } from 'formik';

const MAX_BULLET_LIST_ITEMS = 15;
export const MAX_STUDY_LINKS = 4;

export default function BulletList({
  bulletList,
  setFieldValue,
  keyForEdit,
  rangeName,
  id
}) {
  const { errors } = useFormikContext();

  const currentRangeList = bulletList.filter(({ type }) => type === BULLET_LIST_TYPES_MAP[rangeName]);
  const isDisabled = currentRangeList.length >= MAX_BULLET_LIST_ITEMS;

  return (
    <section className="w-50">
      {!!currentRangeList.length && <label className="font-weight-semibold mt-3">Bullet list:</label>}

      {bulletList.map(({ type, content, studyLinks = [], }, index) => {
        if(type !== BULLET_LIST_TYPES_MAP[rangeName]) return null;
        const keyForBulletListItem = `${keyForEdit}[${index}]`

        const error = getIn(errors, keyForBulletListItem);
        const errorText = error?.content;

        return (
          <section className="bullet-list mb-2" key={index}>
            <InputWithDelete
              value={content}
              onChange={(value) => {
                setFieldValue(`${keyForBulletListItem}.content`, value)
              }}
              max
              placeholder="Enter title..."
              onDelete={() => {
                setFieldValue(keyForEdit, bulletList.filter((_, listIndex) => index !== listIndex))
              }}
              maxLength={MAX_BIOMARKERS_NAME_LENGTH}
              errorClassName={errorText? "is-invalid" : ""}
              errorText={errorText}
              isBulletListTitle
            />

            {!!studyLinks.length && <label className="font-weight-semibold mt-3">Study links:</label>}

            {studyLinks.map((link, studyListIndex) => {
              const keyForBulletListItemLinks = `${keyForBulletListItem}.studyLinks[${studyListIndex}]`;
              return (
                <StudyLink
                  key={studyListIndex}
                  studyListIndex={studyListIndex}
                  onChange={(value) => {
                    setFieldValue(keyForBulletListItemLinks, value)
                  }}
                  value={link}
                  onDelete={() => {
                    setFieldValue(`${keyForBulletListItem}.studyLinks`, studyLinks.filter((_, listIndex) => studyListIndex !== listIndex))
                  }}
                />
              )
            })}
            <PlusButtonWithTooltip
              id={`add${id}${rangeName}studyLink${index}`}
              disabled={studyLinks.length >= MAX_STUDY_LINKS || studyLinks.some(text => !text)}
              onClick={() => {
                setFieldValue(`${keyForBulletListItem}.studyLinks`, [...(studyLinks ?? []), ""])
              }}
              tooltipText="Max. 4 study links can be added"
              buttonText="Add study link"
              containerClassName="mt-1"
              buttonClassName="ps-0"
              showTooltip={studyLinks.length >= MAX_STUDY_LINKS}
            />
          </section>
        )
      })}

      <PlusButtonWithTooltip
        disabled={isDisabled || currentRangeList.some(({ content }) => !content)}
        id={`add${id}${rangeName}BulletList`}
        onClick={() => {
          setFieldValue(keyForEdit, [...bulletList, { content: "", type: BULLET_LIST_TYPES_MAP[rangeName] }])
        }}
        buttonText={currentRangeList.length ? "Add row" : "Add bullet list"}
        containerClassName="mt-1"
        buttonClassName="ps-0"
        showTooltip={isDisabled}
        tooltipText="Only 15 rows can be added."
      />
    </section>
  )
}
