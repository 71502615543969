import React, { useEffect, useState } from "react";
import Wrapper from "../Wrapper";
import StoryblokService from "../../../../../services/StoryblokService";
import RelatedArticle from "./components/RelatedArticle";
import "./index.scss"

const RelatedArticles = ({ blok }) => {
    if (!blok) {
        return null;
    }

    const [articles, setArticles] = useState([])

    const storyblokService = new StoryblokService()

    const filterQuery = {

    }

    useEffect(() => {
        storyblokService.getStories(filterQuery)
            .then((result => {
                const filteredData = result.data.reduce((acc, item) => {
                    if (item.title && acc.length < 3) {
                        acc.push(item)
                    }

                    return acc
                }, [])

                setArticles(filteredData)
            }))
    }, [])


    return (
        <Wrapper blok={{ ...blok }}>
            <div className="d-flex flex-column gap-3">
                <h3 className="font-size-16 text-start">Related Articles</h3>

                {articles.map((article, index) => <RelatedArticle key={index} article={article} />)}
            </div>
        </Wrapper>
    );
};

export default RelatedArticles;
