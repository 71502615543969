import React, { useMemo, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem } from "reactstrap";
import classnames from "classnames";
import Swiper from "../../Swiper/Swiper";
import classes from "./ContentByTypeDropdown.module.scss";
import TableSearch from "../../Table/tableSearch";
import { useHighlight } from "../../../hooks/useHighlight";
import * as _ from "lodash";

const ALL_TAB_KEY = "all";
const MIN_SEARCH_LENGTH = 3;
const ContentTypes = {
    ARTICLE: 1,
    AUDIO: 2,
    VIDEO: 3,
}
const ContentTypesLabels = {
    [ContentTypes.ARTICLE]: "Article",
    [ContentTypes.AUDIO]: "Audio",
    [ContentTypes.VIDEO]: "Video",
}

const ContentByTypeDropdown = ({ onChange, value, placeholder, content, error, label, disabled }) => {
    const [isOpen, updateIsOpen] = useState(false);
    const [activeTabs, setActiveTabs] = useState(ALL_TAB_KEY);
    const [search, setSearch] = useState("");

    const contentByType = _.chain(content).get('data').groupBy('contentType').mapValues((value, key) => {
        return {
            contents: value,
            id: key,
            name: ContentTypesLabels[key]
        }}).values().value();

    const { decorateText } = useHighlight(search && search.length >= MIN_SEARCH_LENGTH ? search : null);

    const mappedContents = useMemo(() => {
        const allContentsTab = {
            contents: content.data,
            id: 'all',
            name: 'All'
        };

        return [allContentsTab, ...contentByType];
    }, [content]);

    const filterContents = useMemo(() => {
            const lowerCaseSearchAvailableIngredients = search?.toLowerCase();

            return search && search.length >= MIN_SEARCH_LENGTH
                ? mappedContents.map(content => {
                    const filteredContent = content.contents.filter((item) => item.title?.toLowerCase().includes(lowerCaseSearchAvailableIngredients));

                    return {
                        ...content,
                        content: filteredContent
                    }
                })
                : mappedContents;
        },
        [search, mappedContents]);


    const slides = filterContents.filter(item => !!item?.contents?.length)

    const selectedContents = useMemo(() => {
        return filterContents.find(item => item.id === activeTabs)?.contents;
    }, [filterContents, activeTabs]);

    const showPlaceholder = !value?.contentId;

    return (
        <section className={`align-items-center w-100 ${label ? '' : 'd-flex'}`}>
            {label ? (
              <label>{label}</label>
            ) : null}
            <Dropdown
                isOpen={isOpen}
                toggle={() => updateIsOpen(prevState => !prevState)}
                className="d-inline-block filter-dropdown cursor-pointer result-filter min-w-1-5 w-100 w-100"
                direction="down"
                disabled={disabled}
            >
                <DropdownToggle
                    className={classnames('filter-toggle w-100 ', { 'with-border': isOpen, 'is-invalid': !!error })}
                    tag="section"
                >
                    <span className={
                        classnames(
                            'ms-2 me-1 pointer-events-none user-select-none text-truncate',
                            { 'text-secondary': showPlaceholder || disabled },
                            { 'cursor-default': disabled }
                        )}
                    >
                        {showPlaceholder ? placeholder : value?.name }
                    </span>
                    <i className={classnames('mdi mdi-chevron-down pointer-events-none user-select-none', { 'mdi-rotate-180': isOpen })}/>
                </DropdownToggle>
                <DropdownMenu className="filter-menu pb-1 px-1 w-100 top-50" flip={false}>
                    <TableSearch onSearch={setSearch} placeholder="Search" search={search} className="my-2"/>

                    <Swiper
                        containerClassName="my-2"
                        slidesPerView="auto"
                        spaceBetween={0}
                        slideClassName="w-fit-content"
                        slides={slides.map((item) => {
                            return <button
                                type="button"
                                key={item.id}
                                onClick={(() => setActiveTabs(item.id))}
                                className={classnames(
                                    "text-nowrap",
                                    classes.Slide,
                                    item.id === activeTabs ? classes.Active : "",
                                )}
                            >
                                {item.name}
                            </button>;
                        })}
                    />

                    <section>
                        <ListGroup>
                            <div className={classnames(classes.ItemsWrapper, "custom-scrollbar")}>
                                <div>
                                    {selectedContents?.length
                                        ? selectedContents.map((item) => {
                                            return (
                                                <ListGroupItem
                                                    className="bg-transparent border-0"
                                                    key={item.id}
                                                    onClick={() => {
                                                        onChange(item);
                                                        updateIsOpen(false);
                                                    }}>
                                                    <div {...decorateText(item.title)} />
                                                </ListGroupItem>
                                            );
                                        })
                                        : <div className="text-secondary py-3 w-100 text-center">No result</div>
                                    }
                                </div>
                            </div>
                        </ListGroup>
                    </section>
                </DropdownMenu>
            </Dropdown>
        </section>
    );
};
export default ContentByTypeDropdown;
