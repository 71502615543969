import React from 'react'
import { Card, Container } from "reactstrap";
import Breadcrumb from "../../../base/components/Common/Breadcrumb2";
import StoryblokClient from 'storyblok-js-client'
import Table from "../../../base/components/Table";
import { useTable } from "../../../base/hooks/useTable";
import { columns, TableHeader } from "./tableStructure";
import { useLocationSource, usePaginationProvider } from "../../../base/components/Table/hooks";
import { getAttributes, getStoryTitle, getTags } from "./helpers";

const breadcrumbs = {
    title: "Content Editor", breadcrumbItems: []
}

const Storyblok = new StoryblokClient({
    oauthToken: 'N9trgm5JVWDQJM2JT8IupAtt-211239-ambBREhXHcRqxGK8Gz18'
})


export default function ContentPlayground() {

    const [loading, pagination, data, handleTableChange] = useTable({
        getNewDataService: getStories
    })

    const mapStories = (stories) => {
        return stories.map(({ story }) => {
            const attributes = getAttributes(story)
            const tags = getTags(story)
            const title = getStoryTitle(story)

            return {
                name: story.name,
                attributes,
                tags,
                title,
            }
        })
    }

    async function getStories(params) {
        const result = await Storyblok.get("spaces/249716/stories", {
            story_only: true,
            page: 1,
        })

        const stories = await Promise.all(result.data.stories.map(story => getStoryById(story.id)))
        const formatStories = mapStories(stories)
        return { data: formatStories, pagination: {} }
    }

    async function getStoryById(id) {
        const result = await Storyblok.get(`spaces/249716/stories/${id}`, {})
        return result.data
    }

    async function getStoryByQuery(query) {
        const result = await Storyblok.get(`spaces/249716/stories}`, {filter_query: query})
        return result.data
    }

    const locationSource = useLocationSource();

    const paginationProvider = usePaginationProvider({
        source: locationSource,
        alias: "offset",
        scope: "",
        fallback: 0
    });

    return (
        <Container fluid className="content">
            <Breadcrumb {...breadcrumbs} />
            <Card className="w-100 p-2">
                    <Table
                        data={data || []}
                        columns={columns}
                        hasActiveFilters={false}
                        HeaderComponent={TableHeader}
                        withoutPagination
                        paginationProvider={paginationProvider}
                        commonPlaceholder="No contents found"
                        loading={loading}
                        handleTableChange={handleTableChange}
                    />
            </Card>
        </Container>
    )
}