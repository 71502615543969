import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import Table from "../../../../../../base/components/Table";
import ConfirmPopup from "../../../../../../base/components/ConfirmPopup";
import {
    SearchPlaceholder,
    TableHeader,
    columns,
    NoProgramPlaceholder,
    PROGRAMS_ACTIONS,
    TagsCollapseRows
} from "./components";

import {
    usePaginationProvider,
    useLocationSource,
    useSearchProvider,
    useSortProvider, useFilterProvider
} from "../../../../../../base/components/Table/hooks";
import { useDebounce } from "../../../../../../base/hooks/useDebounce";
import { useService } from "../../../../../../base/hooks/useService";
import { useLoading } from "../../../../../../base/hooks/useLoading";
import { useQueryString } from "../../../../../../base/hooks/useQueryString";
import ToasterService from "../../../../../../services/ToastService";

import { WELLNESS_GROUP_LINKS } from "../../config";
import { DELETED_STATUS_ID, PROGRAM_STATUSES } from "../constants";
import { DEFAULT_DEBOUNCE_DELAY } from "../../../../../../base/constants/shared";
import { WELLNESS_TYPE } from "../../../constants";
import WellnessService from "../../../../../../services/WellnessService";
import { LIMIT_OPTIONS, MIN_SEARCH_LENGTH } from "../../../../../../base/constants/pagination";


export default function ProgramsList({ isPredefined }) {
    /**
     * @type {WellnessService}
     */
    const wellnessService = useService(WellnessService);
    /**
     * @type {ToasterService}
     */
    const toastService = useService(ToasterService);

    const [isLoading, { registerPromise }] = useLoading(false);
    const { search: locationSearch, pathname } = useLocation();
    const [collapsedRows, updateCollapsedRows] = useState([]);
    const {
        params: {
            limit = LIMIT_OPTIONS[0].value,
            offset = 0,
            search,
            name,
            coachName,
            status,
            wellnessProgramTypes,
            statusFilter,
            programTypeFilter,
        }
    } = useQueryString(locationSearch);

    const searchRequest = useMemo(() => {
        return search && search.toString().trim().length >= MIN_SEARCH_LENGTH ? search : '';
    }, [search]);

    const [programs, updatePrograms] = useState([]);
    const [programsPagination, updateProgramsPagination] = useState({});
    const [showDeletePopup, updateShowDeletePopup] = useState(null);
    const [showChangeStatusPopup, updateShowChangeStatusPopup] = useState(null);

    const navigate = useNavigate();

    const locationSource = useLocationSource();

    const paginationProvider = usePaginationProvider({
        source: locationSource,
        alias: "offset",
        scope: "",
        fallback: 0
    });

    const limitProvider = usePaginationProvider({
        source: locationSource,
        alias: "limit",
        scope: "",
        fallback: LIMIT_OPTIONS[0].value,
        onApplyClearScope: ["offset"]
    });

    const [debouncedSearch] = useDebounce(searchRequest, DEFAULT_DEBOUNCE_DELAY, () => paginationProvider.setValue(0));

    const searchProvider = useSearchProvider({
        source: locationSource,
        scope: "",
        alias: 'search',
        onApplyClearScope: [""]
    });

    const sortKeys = ["name", "coachName", "status", "wellnessProgramTypes"];
    const getSortScope = (key) => [...sortKeys.filter(item => item !== key), "offset"];

    const nameSortProvider = useSortProvider({
        source: locationSource,
        alias: "name",
        scope: "",
        onApplyClearScope: getSortScope("name")
    });

    const coachSortProvider = useSortProvider({
        source: locationSource,
        alias: "coachName",
        scope: "",
        onApplyClearScope: getSortScope("coachName")
    });

    const wellnessProgramTypesAtSortProvider = useSortProvider({
        source: locationSource,
        alias: "wellnessProgramTypes",
        scope: "",
        onApplyClearScope: getSortScope("wellnessProgramTypes")
    });

    const statusSortProvider = useSortProvider({
        source: locationSource,
        alias: "status",
        scope: "",
        onApplyClearScope: getSortScope("status")
    });

    const programTypeFilterProvider = useFilterProvider({
        source: locationSource,
        scope: "",
        alias: 'programTypeFilter',
        onApplyClearScope: ["offset"]
    });

    const statusFilterProvider = useFilterProvider({
        source: locationSource,
        scope: "",
        alias: 'statusFilter',
        onApplyClearScope: ["offset"]
    });

    const filterProvider = useMemo(() => ({
        programType: programTypeFilterProvider,
        status: statusFilterProvider
    }), [programTypeFilterProvider, statusFilterProvider]);

    const getPrograms = useCallback(() => {
        const [orderBy, orderType] = Object.entries({
            name,
            coachName,
            status,
            wellnessProgramTypes,
        }).find(([_, value]) => value) || [];

        registerPromise(wellnessService.getPrograms({
            wellnessType: WELLNESS_TYPE.WELLNESS,
            isPredefined: !!isPredefined,
            limit,
            offset,
            query: debouncedSearch,
            orderBy,
            orderType,
            status: statusFilter,
            wellnessProgramTypeIds: programTypeFilter,
        }).then(({ data, pagination }) => {
                updatePrograms(data);
                updateProgramsPagination(pagination);
            }));
    }, [limit, offset, debouncedSearch, name, coachName, status, wellnessProgramTypes, statusFilter, programTypeFilter]);

    const handleClickDeleteProgram = (program) => {
        updateShowDeletePopup(program);
    };

    const goToEdit = (program) => {
        const queryParams = new URLSearchParams({ editProgramId: program.id }).toString();
        navigate(`${WELLNESS_GROUP_LINKS.FORM}?${queryParams}`)
    };

    const goToCopy = (program) => {
        wellnessService.copyProgram(program.id)
            .then((data) => {
                const queryParams = new URLSearchParams({ editProgramId: data.id }).toString();
                navigate(`${WELLNESS_GROUP_LINKS.FORM}?${queryParams}`)
            })
    };

    const deleteProgram = (program) => {
        registerPromise(wellnessService.deleteProgram(program.id))
            .then(() => {
                getPrograms()
                toastService.success("The wellness program has been successfully deleted")
            });
    };

    const changeStatus = (id, newStatus = PROGRAM_STATUSES.ACTIVE) => {
        registerPromise(wellnessService.changeStatus(id, newStatus))
            .then(() => {
                getPrograms()
                toastService.success("The wellness program status has been successfully updated")
            });
    }

    const toggleCollapse = (id) => {
        if (collapsedRows.includes(id)) {
            updateCollapsedRows(prevState => prevState.filter(item => item !== id))
            return
        }
        updateCollapsedRows(prevState => [...prevState, id]);
    }

    useEffect(() => {
        getPrograms();
    }, [getPrograms]);

    return (
        <>
            <Table
                columns={programs.length ? columns : []}
                data={programs}
                loading={isLoading}
                HeaderComponent={TableHeader}
                totalCount={programsPagination.totalCount}
                limit={limitProvider.getValue()}
                limitProvider={limitProvider}
                offset={offset}
                paginationProvider={paginationProvider}
                searchProvider={searchProvider}
                commonPlaceholder={<NoProgramPlaceholder/>}
                placeholderForSearch={<SearchPlaceholder/>}
                collapsedState={collapsedRows}
                toggleCollapse={toggleCollapse}
                filterProvider={filterProvider}
                isRowClick
                hasActiveFilters={!!programTypeFilterProvider.getValue() || !!statusFilterProvider.getValue()}
                sortProviders={{
                    name: nameSortProvider,
                    coach: coachSortProvider,
                    status: statusSortProvider,
                    wellnessProgramType: wellnessProgramTypesAtSortProvider
                }}
                CollapsedComponent={TagsCollapseRows}
                actions={{
                    [PROGRAMS_ACTIONS.DELETE]: handleClickDeleteProgram,
                    [PROGRAMS_ACTIONS.EDIT]: goToEdit,
                    [PROGRAMS_ACTIONS.COPY]: goToCopy,
                    [PROGRAMS_ACTIONS.ACTIVATE]: ({id}) => updateShowChangeStatusPopup({ id, status: PROGRAM_STATUSES.ACTIVE }),
                    [PROGRAMS_ACTIONS.DEACTIVATE]: ({id}) => updateShowChangeStatusPopup({ id, status: PROGRAM_STATUSES.INACTIVE }),
                }}
                isLimitEditable={true}
                limitOptions={LIMIT_OPTIONS}
            />

            {showChangeStatusPopup &&
              <ConfirmPopup
                isOpen={showChangeStatusPopup}
                updateIsOpen={updateShowChangeStatusPopup}
                onSubmit={() => {
                    changeStatus(showChangeStatusPopup.id, showChangeStatusPopup.status);
                    updateShowChangeStatusPopup(null);
                }}
                title={`${showChangeStatusPopup.status === PROGRAM_STATUSES.ACTIVE ? 'Activate' : 'Deactivate'} program`}
                description={`Are you sure you want to ${showChangeStatusPopup.status === PROGRAM_STATUSES.ACTIVE ? 'activate' : 'deactivate'} the program?`}
                submitBtnText={showChangeStatusPopup.status === PROGRAM_STATUSES.ACTIVE ? 'Activate' : 'Deactivate'}
                className="upload-manually__popup"
              />
            }

            {showDeletePopup &&
                <ConfirmPopup
                    isOpen={showDeletePopup}
                    updateIsOpen={updateShowDeletePopup}
                    onSubmit={() => {
                        deleteProgram(showDeletePopup);
                        updateShowDeletePopup(null);
                    }}
                    title="Delete program"
                    description="Are you sure you want to delete the chosen program?"
                    submitBtnText="Delete"
                    className="upload-manually__popup"
                />
            }
        </>
    );
}
