import React from "react";
import classes from "../WorkoutSlideItem/WorkoutSlideItem.module.scss";
import { Badge } from "reactstrap";
import { HEALTH_PROGRAM_GROUP_LINKS } from "../../../../../../../groups/app/HealthProgram/config";

export const ProgramSlideItem = ({ item }) => {
    const goToDetails = () => {
        const url = `${HEALTH_PROGRAM_GROUP_LINKS.FITNESS}/${item.id}`;
        window.open(url, '_blank');
    };

    return (
        <div className="h-100">
            <div
                className={classes.Container} style={{ backgroundImage: `url(${item?.file?.link})`}}
                onClick={goToDetails}
            >
                <div className="p-2 d-flex flex-column justify-content-between h-100">
                    <div>
                        {item.durationInMs && <Badge
                            pill
                            className="d-flex text-black font-weight-normal py-1 px-2 w-fit-content align-items-center bg-white  font-size-9"
                        >
                            { Math.floor(item.durationInMs / MS_IN_MIN) } min
                        </Badge>}
                    </div>

                    <div className="font-size-10 text-white">
                        <div>
                            { item.name }
                        </div>
                        <div className="font-size-9 d-flex gap-1 align-items-center w-100" style={{ height: "20px" }}>
                            {item.coach.file?.link && <div>
                                <img src={item.coach.file?.link} className="rounded-circle" width={20} height={20} alt={"coach"} />
                            </div>}
                            <div>{ item.coach.name }</div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    )
}