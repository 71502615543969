// Libs
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem, Spinner } from "reactstrap";
import { debounce, noop } from "lodash";
import classnames from "classnames";

// Components, Views, Screens

// Hooks, Utils, Helpers
import { useService } from "../../../hooks/useService";
import { useLoading } from "../../../hooks/useLoading";

// Styles, assets
import classes from "./ContentsDropdown.module.scss";
import ContentsService from "../../../../services/ContentsService";
import { useDebounce } from "../../../hooks/useDebounce";

const DEFAULT_LIMIT = 100;
const MIN_SEARCH_LENGTH = 3;


export const ContentDropdown = ({ onChange, value, placeholder, label, error, handleBlur = noop }) => {
  /**
   * @type {ContentsService}
   */
  const contentService = useService(ContentsService);

  const [isOpen, updateIsOpen] = useState(false);
  const [contents, setContents] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isLoading, { registerPromise }] = useLoading(true);
  const lastElementRef = useRef(null);
  const [search, setSearch] = useState("");

  const [debouncedSearch] = useDebounce(search);

  const showPlaceholder = !value?.id;

  const searchRequest = useMemo(() => {
      return search && search.toString()?.trim()?.length >= MIN_SEARCH_LENGTH ? search : undefined;
    },
    [search]);

  const mapContentTypesToDropdown = (data = []) => {
    return data.map((item) => ({ id: item.id, name: item.title }));
  };

  const setNextPage = () => {
    if (isLoading || !hasNextPage) return;
    setPage(page + 1);
  };

  const loadContents = () => {
    const params = { limit: DEFAULT_LIMIT, offset: 0, query: searchRequest };

    registerPromise(contentService.getContents(params))
      .then((result => {
        setContents(mapContentTypesToDropdown(result.data));
        setHasNextPage(result?.pagination?.nextOffset < result?.pagination?.totalCount);
        setPage(1);
      }));
  };

  const loadMoreContents = () => {
    if (isLoading || !hasNextPage) return;

    const nextOffset = (page) * DEFAULT_LIMIT;

    const params = { limit: DEFAULT_LIMIT, offset: nextOffset, query: searchRequest };

    registerPromise(contentService.getContents(params))
      .then((result => {
        setContents([...contents, ...mapContentTypesToDropdown(result.data)]);
        setHasNextPage(result?.pagination?.nextOffset < result?.pagination?.totalCount);
        setPage(page + 1);
      }));
  };

  useEffect(() => {
    if (!lastElementRef.current) return;

    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        loadMoreContents();
      }
    });

    observer.observe(lastElementRef.current);

    return () => {
      observer.disconnect();
    };
  }, [loadMoreContents, lastElementRef.current]);

  useEffect(() => {
    loadContents();
  }, [debouncedSearch]);

  useEffect(() => {
    setSearch(value?.name)
  }, [value]);

  return (<>
      {!!label && <label>{label}</label>}
      <section className="d-flex align-items-center w-100">
        <Dropdown
          isOpen={isOpen}
          toggle={() => {
            if (isOpen) {
              handleBlur();
            }
            setSearch(value?.name)
            updateIsOpen(prevState => !prevState);
          }}
          className="d-inline-block filter-dropdown cursor-pointer w-100"
          direction="down"
        >
          <DropdownToggle
            className={classnames('filter-toggle max-w-100 pe-0',
              {
                'with-border': isOpen,
                'is-invalid': !!error
              }
            )}
            tag="section"
          >
            <div
              className={classnames('me-1 w-95 user-select-none text-truncate', { 'text-secondary': showPlaceholder })}>
              <input
                className="w-100 ps-2 ms-1 border-0 no-outline"
                value={search}
                onChange={(event) => {
                  updateIsOpen(true)
                  setSearch(event.target.value)
                }}
                placeholder={placeholder || "Select content"}
              />
            </div>
            <i className={classnames('mdi mdi-chevron-down pointer-events-none user-select-none me-2',
              { 'mdi-rotate-180': isOpen })}/>
          </DropdownToggle>

          <DropdownMenu className="filter-menu pb-1 px-1 w-100 top-50" flip={false}>

            <section>
              <ListGroup>
                <div className={classnames(classes.ItemsWrapper, "custom-scrollbar")}>
                  {contents.map((item, index) => (
                    <ListGroupItem className="bg-transparent border-0 p-0" key={item.id}>
                      <div
                        className={classnames(classes.Item, value?.id === item.id && classes.Active)}
                        onClick={() => {
                          onChange(item);
                          setSearch(item?.name)
                          updateIsOpen(false);
                        }}
                      >
                        {item.name}
                      </div>
                      {
                        index === contents.length - 1
                          ? <div
                            ref={lastElementRef}
                            className="d-flex justify-content-center">
                          </div>
                          : null
                      }
                    </ListGroupItem>
                  ))}
                  {isLoading && <div className="d-flex my-2 justify-content-center">
                    <Spinner size="sm" color="primary"/>
                  </div>}
                </div>
              </ListGroup>
            </section>
          </DropdownMenu>
        </Dropdown>
      </section>
    </>
  );
};

