export const ALLERGENS = {
    // NEW
    GLUTEN: 10,
    NUTS: 16,
    MUSTARD: 15,
    CAFFEINE: 3,
    SALICYLATES: 19,
    AMINES: 1,
    SULPHITES: 23,
    FRUCTOSE: 9,
    YEAST: 26,
    ASPARTAME: 2,
    MSG: 13, // Monosodium Glutamate
    MUSHROOMS: 14,
    CELERY: 4,
    LUPIN: 12,
    CORN: 5,
    KIWI: 11,

    // OLD
    DAIRY: 6, // Lactose
    EGG: 7,
    FISH: 8,
    PEANUT: 17,
    PORK: 18,
    SESAME: 20,
    SHELLFISH: 21,
    SOY: 22,
    TREENUT: 24,
    WHEAT: 25,
};

export const META_NUTRITION_ALLERGENS = {
    [ALLERGENS.GLUTEN]: 'gluten',
    [ALLERGENS.NUTS]: 'nuts',
    [ALLERGENS.MUSTARD]: 'mustard',
    [ALLERGENS.CAFFEINE]: 'caffeine',
    [ALLERGENS.SALICYLATES]: 'salicylates',
    [ALLERGENS.AMINES]: 'amines',
    [ALLERGENS.SULPHITES]: 'sulphites',
    [ALLERGENS.FRUCTOSE]: 'fructose',
    [ALLERGENS.YEAST]: 'yeast',
    [ALLERGENS.ASPARTAME]: 'aspartame',
    [ALLERGENS.MSG]: 'MSG', // Monosodium Glutamate
    [ALLERGENS.MUSHROOMS]: 'mushrooms',
    [ALLERGENS.CELERY]: 'celery',
    [ALLERGENS.LUPIN]: 'lupin',
    [ALLERGENS.CORN]: 'corn',
    [ALLERGENS.KIWI]: 'kiwi',

    // OLD
    [ALLERGENS.DAIRY]: 'dairy', // Lactose
    [ALLERGENS.EGG]: 'egg',
    [ALLERGENS.FISH]: 'fish',
    [ALLERGENS.PEANUT]: 'peanut',
    [ALLERGENS.PORK]: 'pork',
    [ALLERGENS.SESAME]: 'sesame',
    [ALLERGENS.SHELLFISH]: 'shellfish',
    [ALLERGENS.SOY]: 'soy',
    [ALLERGENS.TREENUT]: 'treenut',
    [ALLERGENS.WHEAT]: 'wheat'
};



export const ALLERGEN_TITLES = {
    [ALLERGENS.GLUTEN]: 'Gluten',
    [ALLERGENS.NUTS]: 'Nuts',
    [ALLERGENS.MUSTARD]: 'Mustard',
    [ALLERGENS.CAFFEINE]: 'Caffeine',
    [ALLERGENS.SALICYLATES]: 'Salicylates',
    [ALLERGENS.AMINES]: 'Amines',
    [ALLERGENS.SULPHITES]: 'Sulphites',
    [ALLERGENS.FRUCTOSE]: 'Fructose',
    [ALLERGENS.YEAST]: 'Yeast',
    [ALLERGENS.ASPARTAME]: 'Aspartame',
    [ALLERGENS.MSG]: 'MSG', // Monosodium Glutamate
    [ALLERGENS.MUSHROOMS]: 'Mushrooms',
    [ALLERGENS.CELERY]: 'Celery',
    [ALLERGENS.LUPIN]: 'Lupin',
    [ALLERGENS.CORN]: 'Corn',
    [ALLERGENS.KIWI]: 'Kiwi',

    // OLD
    [ALLERGENS.DAIRY]: 'Dairy', // Lactose
    [ALLERGENS.EGG]: 'Egg',
    [ALLERGENS.FISH]: 'Fish',
    [ALLERGENS.PEANUT]: 'Peanut',
    [ALLERGENS.PORK]: 'Pork',
    [ALLERGENS.SESAME]: 'Sesame',
    [ALLERGENS.SHELLFISH]: 'Shellfish',
    [ALLERGENS.SOY]: 'Soy',
    [ALLERGENS.TREENUT]: 'Tree Nut',
    [ALLERGENS.WHEAT]: 'Wheat'
};

export const CLASSIFICATION = [
    "All",
    "Spices and Herbs",
    "Miscellaneous",
    "Cereal Grains and Pasta",
    "Vegetables and Vegetable Products",
    "Alcoholic Beverages",
    "Beverages",
    "Fruits and Fruit Juices",
    "Breakfast Cereals",
    "Nut and Seed Products",
    "Legumes and Legume Products",
    "Finfish and Shellfish Products",
    "Beef Products",
    "Dairy and Egg Products",
    "Fats and Oils",
    "Pork Products",
    "Poultry Products",
    "Snacks",
    "Soups, Sauces, and Gravies",
    "Lamb, Veal, and Game Products",
    "Meals, Entrees, and Side Dishes"
];

export const MEAL_TYPES = {
    BREAKFAST: 'breakfast',
    LUNCH: 'lunch',
    DINNER: 'dinner',
    SNACK: 'snack'
}

export const MEAL_TYPES_LABELS = {
    [MEAL_TYPES.BREAKFAST]: 'Breakfast',
    [MEAL_TYPES.LUNCH]: 'Lunch',
    [MEAL_TYPES.DINNER]: 'Dinner',
    [MEAL_TYPES.SNACK]: 'Snack'
}

export const RECIPE_STATUS = {
    notVerified: 1, // Not Verified
    descriptionNeedsToBeUpdated: 2, // Description needs to be updated
    ingredientsNeedToBeUpdated: 3, // Ingridients need to be updated
    imageToReview: 4, // Image to review
    toDelete: 5, // To delete
    readyToUse: 6, // Ready to use
};


