
export const MIN_STRING_LENGTH = 1

export const MIN_VALUE = 0

export const MAX_PERCENTAGE_VALUE = 100

export const MIN_AGE_RANGE = 18

export const MAX_AGE_RANGE = 99

export const MIN_DIFF_AGE_RANGE = 1

export const SEX = {
    MALE: 1,
    FEMALE: 2
}

export const META_NUTRITION_SEX = {
    [SEX.MALE]: "male",
    [SEX.FEMALE]: "female"
}

export const SEX_LABELS = {
    [SEX.MALE]: "Male",
    [SEX.FEMALE]: "Female"
}

export const UNIT = {
    KCAL: 'kcal',
    GRAM: 'g',
    MG: 'mg',
    ML: 'ml',
    UG: 'µg',
    PERCENTAGE: '%'
}

export const UNIT_VALUES = {
    [UNIT.GRAM]: 1,
    [UNIT.PERCENTAGE]: 2
}

export const SPECIAL_NUTRIENT_IDS = [2, 5, 3]

export const NUTRIENT_UNITS_BY_ID = {
    1: UNIT.KCAL,
    2: UNIT.PERCENTAGE,
    3: UNIT.PERCENTAGE,
    4: UNIT.GRAM,
    5: UNIT.PERCENTAGE,
    6: UNIT.MG,
    7: UNIT.GRAM,
    8: UNIT.PERCENTAGE,
    9: UNIT.PERCENTAGE,
    10: UNIT.PERCENTAGE,
    11: UNIT.MG,
    12: UNIT.UG,
    13: UNIT.MG,
    14: UNIT.MG,
    15: UNIT.ML,
    16: UNIT.MG
}

