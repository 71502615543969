import Http from "./Http";
import { DEFAULT_LIMIT_AND_OFFSET } from "./BiomarkersService";

class UserSegmentsService extends Http {
  static $displayName = "UserSegmentsService";

  async getUserSegmentsList(id, params = DEFAULT_LIMIT_AND_OFFSET) {
    return await this.get(`/user-segments/user/${id}`, { params })
  }

  async downloadUserSegmentsCSV(id) {
    return await this.get(`/user-segments/user/${id}/file`);
  }
}

export default UserSegmentsService;
