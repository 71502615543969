import { REC_RANGES_TO_APPLY_BY_ID } from "./constants";
import { checkOnDefined } from "../fromValidation";

export const applyGrouping = (
  groups,
  groupName,
  fullGroupOptions,
  newGroups,
  setFieldValue,
  keyForRecommendations,
  recommendations,
  idForGroup,
  keyForResultSummary,
  resultSummary,
  currentName
) => {
  fullGroupOptions.forEach(({ id }) => {
    const wasInGroup = groups[groupName]?.includes(id)
    const isInGroup = newGroups.includes(id);
    const isGroupExists = groups[groupName]?.length;
    const isGroupWillExists = newGroups.length

    const groupFormField = REC_RANGES_TO_APPLY_BY_ID[id].name;
    const firstItemInGroup = REC_RANGES_TO_APPLY_BY_ID[groups[groupName]?.[0]];

    const keyForFirstGroupItemRecommendations = firstItemInGroup.name;

    if(wasInGroup && isInGroup) return;
    if(!wasInGroup && !isInGroup) return;

    if(!wasInGroup && isInGroup) {
      if(isGroupExists) {
        setFieldValue(
          `${keyForRecommendations}.${groupFormField}`,
          recommendations[keyForFirstGroupItemRecommendations]
        )

        setFieldValue(
          `${keyForResultSummary}.${groupFormField}`,
          resultSummary[keyForFirstGroupItemRecommendations]
        )
        return;
      }

      if(id === idForGroup) {
        return;
      }

      setFieldValue(
        `${keyForRecommendations}.${groupFormField}`,
        recommendations[currentName]
      )

      setFieldValue(
        `${keyForResultSummary}.${groupFormField}`,
        resultSummary[currentName]
      )
      return;
    }

    if(wasInGroup && !isInGroup) {
      if(isGroupWillExists) {
        setFieldValue(
          `${keyForRecommendations}.${groupFormField}`,
          []
        )
        setFieldValue(
          `${keyForResultSummary}.${groupFormField}`,
          ""
        )
        return;
      }

      if(id !== idForGroup) {
        setFieldValue(
          `${keyForRecommendations}.${groupFormField}`,
          []
        )
        setFieldValue(
          `${keyForResultSummary}.${groupFormField}`,
          ""
        )
      }

    }
  })
}

export const getGroupData = ({ group, groupName, idForGroup, canBeGroupedWith, ranges, label }) => {
  const isGroupedItem = group?.includes(idForGroup);

  const groupOptions = canBeGroupedWith.filter(({ id }) => {
    const rangeToCheck = REC_RANGES_TO_APPLY_BY_ID[id].rangesToApply;
    return rangeToCheck.some(rangeKey => checkOnDefined(ranges[rangeKey]))
  })

  const withGroupOption = !!groupName && !!groupOptions.length;

  const isNotFirstGroupItem = isGroupedItem && group?.indexOf(idForGroup) !== 0;

  if(isNotFirstGroupItem) return { emptyComponent: true };

  const groupItems = group?.map(id => REC_RANGES_TO_APPLY_BY_ID[id])

  let displayedLabel = label;

  if(isGroupedItem) {
    displayedLabel = groupItems.reduce((prevValue, currValue) => prevValue + (prevValue.length ? " / " : "") + currValue.label, "");
  }

  const fullGroupOptions = [...groupOptions, { id: idForGroup }];

  return {
    displayedLabel,
    isGroupedItem,
    groupOptions,
    withGroupOption,
    fullGroupOptions
  }
}

export const applyChangesToAllGroup = (
  group,
  idForGroup,
  setFieldValue,
  fieldKey,
  newValue
) => {
  group.forEach((id) => {
    if(id !== idForGroup) {
      setFieldValue(`${fieldKey}.${REC_RANGES_TO_APPLY_BY_ID[id].name}`, newValue)
    }
  })
}