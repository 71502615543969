import React from "react";
import Wrapper from "../Wrapper";
import { startCase } from "lodash"
import { SERVICE_BLOK_FIELDS } from "../../../../constants/storyblok";
import ArrayAttribute from "./components/ArrayAttribute";
import SingleAttribute from "./components/SingleAttribute";

const Attributes = ({ blok }) => {
    if (!blok) {
        return null;
    }

    const ignoreFields = [ "title", ...SERVICE_BLOK_FIELDS ]

    const getAttribute = (label, value) => {
        if (Array.isArray(value)) {
            return <ArrayAttribute label={label} value={value} />
        } else if (typeof value === 'string' || typeof value === 'number') {
            return <SingleAttribute label={label} value={value} />;
        }

        return null
    }

    return (
        <Wrapper blok={{ ...blok, width: "content-width" }}>
            <div>
                <h1>{blok.title}</h1>
                {
                    Object.keys(blok)
                        .filter((key => !ignoreFields.includes(key)))
                        .map(key => getAttribute(startCase(key), blok[key]))
                }
            </div>
        </Wrapper>
    );
};

export default Attributes;
