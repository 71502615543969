import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useField } from 'formik';
import { FormGroup } from 'reactstrap';
import { ChromePicker } from 'react-color';

const FormikColorPicker = ({ label, containerClassName, description = "", display = "", previewType = "tile", ...props }) => {
  const [field, { error, touched }, { setValue, setTouched }] = useField(props);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const handleChangeComplete = (color) => {
    setTouched(true);
    setValue(color.hex);
  };

  return (
    <FormGroup className={containerClassName}>
      <div className={classNames("d-flex", display === "inline" ? "w-20 align-items-center flex-row" : "flex-column" )}>
        {label && (
          <label htmlFor={props.name}
                 className={classNames("m-0", {'text-danger': (error && touched), "mb-2": display !== "inline"})}
          >
            {label}</label>
        )}
        {description && <p className="font-size-12 text-secondary mt-0 mb-2">{description}</p>}
      </div>
      <div className="d-flex gap-3 flex-grow-1">
        <div className="flex-grow-1">
          <input className='form-control' {...field} {...props} />
        </div>
        <div className="flex-grow-0 d-flex align-items-center pl-0">
          <div className="p-relative">
            {previewType === "tile"
              ? <div className="color-picker-thumbnail" style={{backgroundColor: field.value}} onClick={() => setShowColorPicker(true)}></div>
              : <div className="color-picker-tag" style={{backgroundColor: field.value}} onClick={() => setShowColorPicker(true)}>Tag</div>
            }
            {showColorPicker ?
              <div className="color-picker-popover">
                <div className="color-picker-cover" onClick={() => setShowColorPicker(false)}/>
                <ChromePicker
                  className="color-picker"
                  color={field.value}
                  onChangeComplete={handleChangeComplete}
                />
              </div>
              : null}
          </div>
        </div>
      </div>
    </FormGroup>
  )
};

FormikColorPicker.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  containerClassName: PropTypes.string,
  display: PropTypes.string,
  previewType: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element),
}

export default FormikColorPicker;