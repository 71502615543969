import React, { useEffect, useState } from 'react';
import UICustomisationLayoutWithCard from "../../../../base/components/UICustomisationLayoutWithCard";
import { Col } from "reactstrap";
import { Formik } from "formik";
import { useService } from "../../../../base/hooks/useService";
import ConfigService from "../../../../services/ConfigService";
import FormikInput from "../../../../base/components/FormikInput";
import Button from "../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";
import joinClassNames from "../../../../base/helpers/joinClassNames";
import yup from "../../../../validation/yup";
import * as _ from "lodash";
import ToasterService from "../../../../services/ToastService";
import { SystemTypes } from "../../../../services/ConfigService";

export const validationSchema = yup.object().shape({
  WEFITTER_SECRET: yup.string()
    .trim()
    .min(10)
    .max(100)
    .required(),
  TYPEFORM_PERSONAL_TOKEN: yup.string()
    .trim()
    .min(10)
    .max(100)
    .required(),
  DECISION_RULES_SOLVER_API_KEY: yup.string()
    .trim()
    .min(10)
    .max(100)
    .required(),
  KLAVIYO_API_KEY: yup.string()
    .trim()
    .min(10)
    .max(100)
    .required(),
});

export default function ThirdPartyServices() {

  const breadcrumbs = {
    title: "Third Party Services", breadcrumbItems: []
  };
  /**
   * @type {ConfigService}
   */
  const configService = useService(ConfigService);

  const [initialValues, setInitialValues] = useState({});
  const [isSubmitting, updateIsSubmitting] = useState(false);
  const [apiConfig, setApiConfig] = useState({});
  /**
   * @type {ToasterService}
   */
  const toastService = useService(ToasterService);

  useEffect(() => {
    configService.fetchCustomerConfig(SystemTypes.backend).then((rawConfig) => {
    const configFields = ["id", "variable", "value"];
        const config = _.chain(rawConfig)
          .get("data")
          .map(item => _.pick(item, configFields))
          .keyBy("variable")
          .value();

        setApiConfig(config);

        setInitialValues((prev)  => ({
          ...prev,
          WEFITTER_SECRET: config.WEFITTER_SECRET.value,
          TYPEFORM_PERSONAL_TOKEN: config.TYPEFORM_PERSONAL_TOKEN.value,
          DECISION_RULES_SOLVER_API_KEY: config.DECISION_RULES_SOLVER_API_KEY.value,
          KLAVIYO_API_KEY: config.KLAVIYO_API_KEY.value,
        }));
      });
  }, [configService]);

  const [errorMessage, setErrorMessage] = useState("");

  const submitSettings = async (values) => {
    updateIsSubmitting(true);
    try {
      const configToSubmit = _.pick(apiConfig, _.keys(values));

      _.map(configToSubmit, (config) => {
        config.value = values[config.variable];
      });

      const updatedConfig = await configService.updateCustomerConfig(_.values(configToSubmit));

      toastService.success("Settings was successfully uploaded");
    } catch (err) {
      console.error(err);
      toastService.error(err)
      setErrorMessage(err);
    } finally {
      updateIsSubmitting(false);
    }
  };

  return (
    <UICustomisationLayoutWithCard breadcrumbs={breadcrumbs} cardClassName="ui-customisation">
      <Col lg={6} className="sideform-wrapper">
        <h2 className="sideform-header">API Keys</h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur
          onSubmit={submitSettings}
          enableReinitialize
        >
          {({ errors, handleSubmit, values, setFieldValue }) =>
            <form className={joinClassNames("form-horizontal p-0", isSubmitting && "pointer-events-none")}
                  onSubmit={handleSubmit}>
              <FormikInput
                label="Wefitter"
                placeholder="Enter API key"
                name="WEFITTER_SECRET"
                maxLength={255}
                containerClassName="mt-3"
              />
              <FormikInput
                label="Typeform"
                placeholder="Enter API key"
                name="TYPEFORM_PERSONAL_TOKEN"
                maxLength={255}
                containerClassName="mt-3"
              />
              <FormikInput
                label="Decision Rules"
                placeholder="Enter API key"
                name="DECISION_RULES_SOLVER_API_KEY"
                maxLength={255}
                containerClassName="mt-3"
              />
              <FormikInput
                label="Klaviyo"
                placeholder="Enter API key"
                name="KLAVIYO_API_KEY"
                maxLength={255}
                containerClassName="mt-3"
              />
              <div className="d-flex justify-content-end mt-5 gap-3">
                <Button
                  color={BUTTON_COLORS.primary}
                  type="submit"
                  disabled={!!Object.keys(errors).length || isSubmitting}
                >
                  Save changes
                </Button>
              </div>
            </form>
          }
        </Formik>
      </Col>
    </UICustomisationLayoutWithCard>
  );
}
