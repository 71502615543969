
export const FITNESS_GROUP_ROUTES = {
  BASE: "/",
  LISTS: "/lists/*",
  FORM_EXERCISES: "/form/exercises",
  FORM_WORKOUTS: "/form/workouts",
  FORM_HOMEPAGE_SECTION: "/form/homepage",
  EXERCISE_DETAILS: "/lists/exercises/:id",
  WORKOUT_DETAILS: "/lists/workouts/:id",
};

export const FITNESS_GROUP_LINKS = {
  BASE: "/app/fitness",
  LIST: "/app/fitness/lists",
  EXERCISES_LIST: "/app/fitness/lists/exercises",
  WORKOUT_TYPES_LIST: "/app/fitness/lists/workout-types",
  WORKOUTS_LIST: "/app/fitness/lists/workouts",
  HOMEPAGE_LAYOUT_LIST: "/app/fitness/lists/homepage-layout",
  FORM_EXERCISES: "/app/fitness/form/exercises",
  FORM_WORKOUTS: "/app/fitness/form/workouts",
  FORM_HOMEPAGE_SECTION: "/app/fitness/form/homepage",

};