import React from "react";
import { storyblokEditable } from "@storyblok/react";
import Wrapper from "./Wrapper";

function VideoSection({ blok }) {
    if (!blok?.video) {
        return null;
    }

    return <Wrapper blok={blok}>
        <video controls className="w-100">
            <source src={blok.video.filename} type="video/mp4"/>
            Your browser does not support the video tag.
        </video>
    </Wrapper>
}

export default VideoSection