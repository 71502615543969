import React, { useMemo, useState } from 'react';
import ReactSwitch from "../../../../../base/components/Switch";
import { DNAm_RECOMMENDATION_SECTIONS, RECOMMENDATION_SECTIONS, SKIN_RECOMMENDATION_SECTIONS } from "./constants";
import RecommendationSection from "./RecommendationSection";
import { checkOnDefined } from "../fromValidation";
import { applyGrouping, getGroupData, applyChangesToAllGroup } from "./helpers";

export default function Recommendations({
  recommendations = {},
  setFieldValue,
  keyForRecommendations,
  ranges,
  groups,
  keyForGroups,
  keyForResultSummary,
  resultSummary,
  isSkinCategory,
  isDNAmCategory,
}) {
  const [isAvailable, updateIsAvailable] = useState(true);

  const toggleAvailability = () => {
    updateIsAvailable(prevState => !prevState);
  }

  const changeRecommendationsOrder = (fromIndex, toIndex, name, isGroupedItem, groupName, idForGroup) => {
    if(!recommendations[name].length) return;
    const newRecommendations = [...recommendations[name]];
    const [draggedItem] = newRecommendations.splice(fromIndex, 1);
    newRecommendations.splice(toIndex, 0, draggedItem);
    setFieldValue(`${keyForRecommendations}.${name}`, newRecommendations)

    if(isGroupedItem) {
      applyChangesToAllGroup(
        groups[groupName],
        idForGroup,
        setFieldValue,
        keyForRecommendations,
        newRecommendations
      )
    }
  }

  const recommendationsArray = useMemo(() => {
    if(isSkinCategory) return SKIN_RECOMMENDATION_SECTIONS;
    if(isDNAmCategory) return DNAm_RECOMMENDATION_SECTIONS;
    return RECOMMENDATION_SECTIONS;
  }, [isSkinCategory, isDNAmCategory]);

  return (
    <section className="form-recommendations mt-5 w-75">
      <section className="w-100 d-flex justify-content-between align-items-center mb-2">
        <div className="d-flex flex-column">
          <label className="mb-1 font-weight-semibold">Recommendations</label>
          <span className="text-secondary">
            {isDNAmCategory && "Select recommendations for DNAm age biomarker."}
            {isSkinCategory && "Select recommendations for skin biomarker."}
            {!isSkinCategory && !isDNAmCategory && "Select a recommendation for each biomarker range level."}
          </span>
        </div>
        <ReactSwitch
          state={isAvailable}
          updateState={toggleAvailability}
        />
      </section>

      {!!isAvailable && recommendationsArray.map(({
        name,
        label,
        rangesToApply,
        idForGroup,
        groupName,
        canBeGroupedWith = [],
      }, index) => {

        if(rangesToApply.every(rangeKey => !checkOnDefined(ranges[rangeKey]))) {
          if(recommendations[name]?.length) {
            setFieldValue(`${keyForRecommendations}.${name}`, [])
          }
          if(resultSummary[name]) {
            setFieldValue(`${keyForResultSummary}.${name}`, undefined)
          }
          return null;
        }

        const {
          displayedLabel,
          isGroupedItem,
          groupOptions,
          withGroupOption,
          fullGroupOptions,
          emptyComponent
        } = getGroupData({
          group: groups[groupName],
          groupName,
          idForGroup,
          canBeGroupedWith,
          ranges,
          label
        })

        if(emptyComponent) {
          return null
        }

        return (
          <RecommendationSection
            key={index}
            isSkinCategory={isSkinCategory}
            isDNAmCategory={isDNAmCategory}
            label={displayedLabel}
            defaultLabel={label}
            name={name}
            recommendations={recommendations[name]}
            addNewRecommendation={() => {
              const recommendationsArray = recommendations[name] ?? [];
              setFieldValue(
                `${keyForRecommendations}.${name}`,
                [...recommendationsArray, { id: undefined }]
              )
            }}
            changeRecommendationsOrder={(fromIndex, toIndex, name) => {
              changeRecommendationsOrder(fromIndex, toIndex, name, isGroupedItem, groupName, idForGroup)
            }}
            deleteRecommendation={(deletedId, currentRecommendationIndex) => {
              const newRecommendations = recommendations[name].filter((_, index) => index !== currentRecommendationIndex)
              if(!newRecommendations.length) {
                newRecommendations.push({ id: undefined })
              }
              setFieldValue(`${keyForRecommendations}.${name}`, newRecommendations)
              if(isGroupedItem) {
                applyChangesToAllGroup(
                  groups[groupName],
                  idForGroup,
                  setFieldValue,
                  keyForRecommendations,
                  newRecommendations
                )
              }
            }}
            onSelect={(item, index) => {
              setFieldValue(`${keyForRecommendations}.${name}[${index}]`, item)
              if(isGroupedItem) {
                applyChangesToAllGroup(
                  groups[groupName],
                  idForGroup,
                  setFieldValue,
                  keyForRecommendations,
                  [...recommendations[name], item]
                )
              }
            }}
            idForGroup={idForGroup}
            currentGroup={groups[groupName]}
            keyForGroupChange={`${keyForGroups}.${groupName}`}
            canBeGroupedWith={groupOptions}
            groupName={groupName}
            setFieldValue={setFieldValue}
            withGroupOption={withGroupOption}
            onGroup={(idOfGroup, newGroups) => {
              applyGrouping(
                groups,
                groupName,
                fullGroupOptions,
                newGroups,
                setFieldValue,
                keyForRecommendations,
                recommendations,
                idForGroup,
                keyForResultSummary,
                resultSummary,
                name,
              )
            }}
          />
        )
      })}
    </section>
  )
}