import React, { useCallback, useEffect, useState } from 'react';
import joinClassNames from "../../../../base/helpers/joinClassNames";
import { AccordionBody, AccordionHeader, AccordionItem, Col, Spinner, UncontrolledAccordion } from "reactstrap";
import { DropZoneCard } from "../../../../base/components/Dropzone";
import {
  ALLOWED_LOGO_FILE_EXTENSION, ASSETS_FILE_TYPE,
  BYTES_IN_KILOBYTE, ERROR_ALLOWED_LOGO_FILE_TYPE_MESSAGE, ERROR_LOGO_FILE_SIZE_MESSAGE,
  KILOBYTES_IN_MEGABYTE,
  LOGO_FILE_MASK,
  MAX_LOGO_FILE_SIZE
} from "../../../../base/constants/shared";
import Icon from "../../../../base/components/Icon";
import { Formik } from "formik";
import { validateEmptyFile, validateFileSize, validateFileType } from "../../../../validation/fileUploadAndProcessing";
import ToasterService from "../../../../services/ToastService";
import { useService } from "../../../../base/hooks/useService";
import FormikColorPicker from "../../../../base/components/FormikColorPicker";
import * as _ from "lodash";
import ConfigService, { ThemeTypes } from "../../../../services/ConfigService";
import FormikInput from "../../../../base/components/FormikInput";
import Button from "../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";
import yup from "../../../../validation/yup";
import { MAX_RULE_NAME_LENGTH, MIN_STRING_LENGTH } from "../../HealthProgram/Nutrition/CreateEditRule/constants";
import useUploadImages from "../../../../base/components/Dropzone/useUploadImages";
import { useDarkMode } from "../../../../base/context/darkMode";

export default function DatePicker() {
  const [initialValues, setInitialValues] = useState({});
  const [isSubmitting, updateIsSubmitting] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, updateIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState('1');
  const [apiSettings, setApiSettings] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const uploadImage = useUploadImages();
  const isDarkMode = useDarkMode();
  const themeType = isDarkMode ? ThemeTypes.dark : ThemeTypes.white;

  /**
   * @type {ConfigService}
   */
  const configService = useService(ConfigService);
  /**
   * @type {ToasterService}
   */
  const toastService = useService(ToasterService);

  useEffect(() => {
    configService.fetchThemeSettings().then((rawThemeSettings) => {
      const themeFields = ["id", "propertyName", "fileId", "value", "file", "page", "themeType"];
      const themeSettings = _.chain(rawThemeSettings)
        .get("data")
        .filter({ themeType:  themeType })
        .map(item => _.pick(item, themeFields))
        .keyBy("propertyName")
        .value();

      setApiSettings(themeSettings);
      updateIsLoading(false);

      setInitialValues((prev)  => ({
        ...prev,
        datePickerBackgroundColor: themeSettings.datePickerBackgroundColor.value,
        datePickerStartEndColor: themeSettings.datePickerStartEndColor.value,
        datePickerSelectedDatesColor: themeSettings.datePickerSelectedDatesColor.value,
        datePickerHoveredDatesColor: themeSettings.datePickerHoveredDatesColor.value,
      }));
    });
  }, [configService, isDarkMode]);

  const submitSettings = async (values) => {
    updateIsSubmitting(true);
    try {
      const settingsToSubmit = _.pick(apiSettings, _.keys(values));

      _.map(settingsToSubmit, (setting) => {
        setting.value = values[setting.propertyName];
        return setting;
      });

      const updatedSettings = await configService.updateThemeSettings(_.values(settingsToSubmit));

      toastService.success("Settings was successfully uploaded");
    } catch (err) {
      console.error(err);
      toastService.error(err)
      setErrorMessage(err);
    } finally {
      updateIsSubmitting(false);
    }
  };

  return (
    <Col className="sideform-wrapper">
      <h2 className="sideform-header">Colors</h2>
      <Formik
        initialValues={initialValues}
        validateOnBlur
        onSubmit={submitSettings}
        enableReinitialize
      >
        {({ errors, handleSubmit, values, setFieldValue }) =>
          <form className={joinClassNames("form-horizontal", isSubmitting && "pointer-events-none")}
                onSubmit={handleSubmit}>
                  <FormikColorPicker
                    label="Background color"
                    placeholder=""
                    name="datePickerBackgroundColor"
                    maxLength={255}
                    containerClassName="mt-3 mb-4"
                  />
                  <FormikColorPicker
                    label="Start/end color"
                    placeholder=""
                    name="datePickerStartEndColor"
                    maxLength={255}
                    containerClassName="mt-3 mb-4"
                  />
                  <FormikColorPicker
                    label="Selected dates color"
                    placeholder=""
                    name="datePickerSelectedDatesColor"
                    maxLength={255}
                    containerClassName="mt-3 mb-4"
                  />
                  <FormikColorPicker
                    label="Hovered dates color"
                    placeholder=""
                    name="datePickerHoveredDatesColor"
                    maxLength={255}
                    containerClassName="mt-3 mb-4"
                  />

            <div className="d-flex justify-content-end mt-5 gap-3">
              <Button
                color={BUTTON_COLORS.primary}
                type="submit"
                disabled={!!Object.keys(errors).length || isSubmitting}
              >
                Save changes
              </Button>
            </div>
          </form>
        }
      </Formik>
    </Col>
  );
}