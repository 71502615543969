import yup from "../../../../validation/yup";

import { INVALID_PASSWORD_MESSAGE, USER_PASSWORD, USER_PASSWORD_WITH_PATTERN } from "../../../../validation/user";

export const validationSchema = yup.object().shape({
  password: USER_PASSWORD_WITH_PATTERN.required(INVALID_PASSWORD_MESSAGE),
  repeatPassword: USER_PASSWORD.required(INVALID_PASSWORD_MESSAGE)
    .test("passwords-match", "Passwords do not match", function (value) {
      return this.parent.password === value;
    }),
});

export const initialValues = {
  password: "",
  repeatPassword: "",
};
