import React from "react";
import { Badge } from "reactstrap";
import { capitalize } from "lodash"


const Tag = ({ value, size = "lg" }) => {

    const sizeStyles = {
        sm: "p-1 font-size-10",
        lg: "p-2 font-size-14"
    }

    return (
        <Badge
            pill
            color="info"
            className={`me-1 ${sizeStyles[size]}`}>
            {capitalize(value)}
        </Badge>
    );
};

export default Tag;
