import yup from "../../../../../validation/yup";
import {
    ALLOWED_EXTENTIONS_FILE,
    MAX_PROGRAM_NAME_LENGTH,
    MIN_STRING_LENGTH,
    ERROR_ALLOWED_TYPE_MESSAGE,
    ENERGY_EQUATION_LABEL, ENERGY_EQUATION, MIN_USER_SEGMENTS_ITEMS_LENGTH,
    MAX_WHY_SYSTEM_CAN_RECOMMEND_DESCRIPTION,
    HTML_TAGS_REGEX
} from "./constants";
import {
    ERROR_SIZE_MESSAGE,
    isAllowedExtension,
    MAX_FILE_SIZE
} from "../../../../../base/components/Dropzone";

export const initialValues = {
    fullName: "",
    author: "",
    energyEquation: null,
    status: 1,
    restrictions: [],
    shortDescription: '',
    longDescription: '',
    file: [],
    userSegments: [],
    allergens: [],
    whyRecommendedDescription: ""
}

export const validationSchema = yup.object().shape({
    fullName: yup.string()
        .trim()
        .min(MIN_STRING_LENGTH)
        .max(MAX_PROGRAM_NAME_LENGTH)
        .required(),

    author: yup.string()
        .trim()
        .min(MIN_STRING_LENGTH)
        .max(MAX_PROGRAM_NAME_LENGTH)
        .required(),
    energyEquation: yup.number().required(),
    userSegments: yup.array().min(MIN_USER_SEGMENTS_ITEMS_LENGTH, 'User segments is required'),
    whyRecommendedDescription: yup
      .string()
      .test('raw-text-check', `Maximum ${MAX_WHY_SYSTEM_CAN_RECOMMEND_DESCRIPTION} characters allowed`, function(value) {
          const rawString = value?.replace(HTML_TAGS_REGEX, '');
          return rawString?.length < MAX_WHY_SYSTEM_CAN_RECOMMEND_DESCRIPTION;
      })
});

export const validateFile = (file) => {
    if (file?.size > MAX_FILE_SIZE) {
        return ERROR_SIZE_MESSAGE;
    }

    if (!isAllowedExtension(file, ALLOWED_EXTENTIONS_FILE)) {
        return ERROR_ALLOWED_TYPE_MESSAGE;
    }

    return null;
};

export const energyEquationOptions = Object.values(ENERGY_EQUATION).map((value) => ({
    value,
    id: value,
    label: ENERGY_EQUATION_LABEL[value]
}))