import React, { useMemo, useState } from 'react'
import get from "lodash.get";
import { Tabs } from "../CreateEdit/components/ResultSummaryTabs";
import { transformGroupsToTabs } from "../CreateEdit/helpers/transformModels";
import RichTextView from "./RIchTextView";
import BulletListView from "./BulletListView";
import { isEmptyString } from '../../../../base/helpers/string';

export default function RichTextWithTabs({
  ranges,
  valuesObject,
  groups,
  isCustomTabs,
  allTabsArray,
  allTabsMap,
  className,
  label,
  withBulletList,
}) {
  const [activeTab, updateActiveTab] = useState(0);
  const { low = [], high = [] } = groups;

  const tabs = useMemo(() => {
    return transformGroupsToTabs({
      isCustomTabs,
      allTabsArray,
      ranges,
      low,
      high,
      groups,
      allTabsMap,
    })
  }, [ranges, allTabsArray, allTabsMap, groups, isCustomTabs])

  const { name = "", secondKey = "" } = tabs[activeTab] ?? {};

  const getFieldContent = (firstValue, secondValue ) => {
    if(!isEmptyString(firstValue)) {
      return firstValue;
    }

    if(!isEmptyString(secondValue)) {
      return secondValue;
    }

    return "-"
  }

  return (
    <section className={className}>
      {!!tabs.length && name &&
        <section>
          <RichTextView
            containerClassName="mt-3"
            label={label}
            richText={getFieldContent(valuesObject[name], valuesObject[secondKey])}
            Tabs={<Tabs
              tabs={tabs}
              activeTab={activeTab}
              updateActiveTab={updateActiveTab}
              className="mb-1"
            />}
          />

          {withBulletList &&
            <BulletListView
              bulletList={get(valuesObject, `bulletList`, [])}
              rangeName={name}
            />
          }
        </section>
      }
    </section>
  )
}
