import React from "react";
import { storyblokEditable } from "@storyblok/react";
import Wrapper from "./Wrapper";

export default function GoogleDriveVideo({ blok }) {
    if (!blok?.link) {
        return null;
    }

    return <Wrapper blok={blok}>
        <div className="video-container">
            <iframe
                width="100%"
                height="100%"
                src={blok?.link}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        </div>
    </Wrapper>

}