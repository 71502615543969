import React from 'react'
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumb from "../../../../base/components/Common/Breadcrumb2";
import BiomarkerForm from "./form";
import { BIOMARKERS_GROUP_LINKS } from "../config";

const breadcrumbs = {
  title: "Create biomarker",
  breadcrumbItems: [
    { title: "Biomarkers", link: BIOMARKERS_GROUP_LINKS.BASE },
    { title: "Create biomarker"}
  ]
}

export default function CreateNewBiomarker(){

  return (
    <>
      <Breadcrumb {...breadcrumbs} />
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <BiomarkerForm />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}