import React from "react";
import { useStoryblok, StoryblokComponent } from "@storyblok/react";
import "./index.scss"
import PageSpinner from "../../base/components/PageSpinner";

function Article() {

    let slug =
        window.location.pathname === "/"
            ? "home"
            : window.location.pathname.replace("/articles", "");

    const story = useStoryblok(slug, { version: "draft" });

    if (!story || !story.content) {
        return <div><PageSpinner/></div>;
    }

    return <StoryblokComponent blok={story.content}/>;
}

export default Article;
