import React from "react";
import Switch from "../../../../../../../base/components/Switch";
import { useField } from "formik";

import FormikInput from "../../../../../../../base/components/FormikInput";

import yup from "../../../../../../../validation/yup";
import {
    MAX_PERCENTAGE_VALUE,
    MIN_VALUE,
    NUTRIENT_UNITS_BY_ID, SEX, SEX_LABELS,
    SPECIAL_NUTRIENT_IDS,
    UNIT,
} from "./constants";
import { useLocationQuery } from "../../../../../../../base/hooks/useQueryString";
import { useHighlight } from "../../../../../../../base/hooks/useHighlight";

export const initialValues = {
    sex: null,
    ageMinValue: 18,
    ageMaxValue: 99,
    nutrients: []
};

export const validationSchema = yup.object().shape({
    sex: yup.string().required(),
    nutrients: yup.array().of(
        yup.object().shape({
            quantity: yup.number().when('nutrientId', {
                is: (value) => NUTRIENT_UNITS_BY_ID[value] === UNIT.PERCENTAGE,
                then: yup.number()
                    .max(MAX_PERCENTAGE_VALUE, "Must be less than or equal to 100")
                    .min(MIN_VALUE, "Must be greater than or equal to 0"),
                otherwise: yup.number()
            }),
        })
    ).test('total-percent', 'Total percent must equal 100', function (nutrients) {
        const totalPercent = nutrients.reduce((total, { quantity, nutrientId }) => {
            return SPECIAL_NUTRIENT_IDS.includes(nutrientId) ? total + quantity : total;
        }, 0);

        if (totalPercent !== 100) {
            return this.createError({
                path: `totalCountPercent`,
                message: 'Total percent must equal 100',
            });
        }

        return true;
    })
});

export const sexOptions = Object.values(SEX).map((key) => ({ label: SEX_LABELS[key], value: key }));

export const columnsNutrients = [
    {
        Header: "Nutrient",
        accessor: "name",
        width: 200,
        canSort: true,
        Cell: ({ value }) => {
            const { params } = useLocationQuery();
            const { decorateText } = useHighlight(params.searchNutrients);

            return (
                <section className="w-fit-content d-flex align-items-center">
                    <div {...decorateText(value)} className="text-truncate mb-0" style={{ maxWidth: "33vw" }}/>
                </section>
            );
        }
    },
    {
        Header: "Enable",
        accessor: "isEnabled",
        width: 200,
        canSort: true,
        Cell: ({ row: { original: { index } } }) => {
            const [field, _, { setValue }] =
                useField(`nutrients.${index}`);

            return (
                <section className="w-fit-content d-flex align-items-center">
                    {
                        !SPECIAL_NUTRIENT_IDS.includes(field.value.nutrientId)
                            ? <Switch
                                state={field.value.isEnabled}
                                updateState={() => setValue({
                                    ...field.value,
                                    quantity: 0,
                                    isEnabled: !field.value.isEnabled
                                })}
                            />
                            : "On"
                    }
                </section>
            );
        }
    },
    {
        Header: "Quantity",
        accessor: "quantity",
        width: 200,
        canSort: true,
        Cell: ({ row: { original: { index } } }) => {
            const [field] =
                useField(`nutrients.${index}`);

            const isPercentageUnit = field.value?.unit === UNIT.PERCENTAGE;
            const isDisabled = !field?.value?.isEnabled && !SPECIAL_NUTRIENT_IDS.includes(field.value.nutrientId);

            return (
                <section className="w-fit-content d-flex align-items-center">
                    <FormikInput
                        name={`nutrients.${index}.quantity`}
                        type="number"
                        min={MIN_VALUE}
                        max={isPercentageUnit ? MAX_PERCENTAGE_VALUE : null}
                        disabled={isDisabled}
                    />
                </section>
            );
        }
    },
    {
        Header: "Unit",
        accessor: "unit",
        width: 200,
        canSort: true,
        Cell: ({ row: { original: { index } } }) => {
            const [field] =
                useField(`nutrients.${index}`);

            return (
                <section className="w-100 d-flex align-items-center">
                    {
                        NUTRIENT_UNITS_BY_ID[field.value.nutrientId]
                    }
                </section>
            );
        }
    }
];


