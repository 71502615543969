import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Table from "../../../../../../base/components/Table";
import { DEFAULT_DEBOUNCE_DELAY, DEFAULT_TABLE_LIMIT } from "../../../../../../base/constants/shared";
import {
    usePaginationProvider,
    useLocationSource, useSearchProvider, useFilterProvider, useDateRangeProvider, useSortProvider
} from "../../../../../../base/components/Table/hooks";
import { useDebounce } from "../../../../../../base/hooks/useDebounce";
import { SearchPlaceholder, TableHeader, columns, NoGoalPlaceholder, GOALS_ACTION } from "./tableComponents";
import ToasterService from "../../../../../../services/ToastService";
import { useService } from "../../../../../../base/hooks/useService";
import ProgramsService from "../../../../../../services/ProgramsService";
import { useLoading } from "../../../../../../base/hooks/useLoading";
import { useQueryString } from "../../../../../../base/hooks/useQueryString";
import ConfirmPopup from "../../../../../../base/components/ConfirmPopup";
import { PROGRAMS_GROUP_LINKS } from "../../config";
import { CreateEditGoal } from "./CreateEditGoal";

export default function GoalsList() {
    /**
     * @type {ProgramsService}
     */
    const programsService = useService(ProgramsService);
    /**
     * @type {ToasterService}
     */
    const toastService = useService(ToasterService);

    const [isLoading, { registerPromise }] = useLoading(true);
    const { search: locationSearch } = useLocation();
    const {programId} = useParams()

    const {
        params: {
            limit = DEFAULT_TABLE_LIMIT,
            offset = 0,
            search,
            fullName,
            author,
            status,
            createdAt,
        }
    } = useQueryString(locationSearch);

    const [goals, updateGoals] = useState([]);
    const [goalsPagination, updateGoalsPagination] = useState({});
    const [showDeletePopup, updateShowDeletePopup] = useState(null);
    const [showFormPopup, updateShowFormPopup] = useState(false);
    const [editGoalId, setEditGoalId] = useState(null);
    const [copyGoalId, setCopyGoalId] = useState(null);
    const [showDeleteProgramPopup, updateShowDeleteProgramPopup] = useState(null);


    const locationSource = useLocationSource();
    const navigate = useNavigate();

    const paginationProvider = usePaginationProvider({
        source: locationSource,
        alias: "offset",
        scope: "",
        fallback: 0
    });

    const [debouncedSearch] = useDebounce(search, DEFAULT_DEBOUNCE_DELAY, () => paginationProvider.setValue(0));

    const generateFilterProvidersParams = (names = []) => {
        return {
            source: locationSource,
            alias: names,
            scope: "",
            onApplyClearScope: ["offset"]
        };
    };

    const searchProvider = useSearchProvider({
        source: locationSource,
        scope: "",
        alias: 'search',
        onApplyClearScope: ["offset"]
    });

    const filterProvider = useFilterProvider({
        source: locationSource,
        scope: "",
        alias: 'status',
        onApplyClearScope: ["offset"]
    });

    const sortKeys = ["fullName", "author", "createdAt", "status"];
    const getSortScope = (key) => [...sortKeys.filter(item => item !== key), "offset"];

    const nameSortProvider = useSortProvider({
        source: locationSource,
        alias: "fullName",
        scope: "",
        onApplyClearScope: getSortScope("fullName")
    });

    const authorSortProvider = useSortProvider({
        source: locationSource,
        alias: "author",
        scope: "",
        onApplyClearScope: getSortScope("author")
    });

    const createdAtSortProvider = useSortProvider({
        source: locationSource,
        alias: "createdAt",
        scope: "",
        onApplyClearScope: getSortScope("createdAt")
    });

    const statusSortProvider = useSortProvider({
        source: locationSource,
        alias: "status",
        scope: "",
        onApplyClearScope: getSortScope("status")
    });

    const dateRangeProviders = {
        createdAt: useDateRangeProvider(generateFilterProvidersParams(["createdAtStartDate", "createdAtEndDate"]))
    };

    const getGoals = useCallback(() => {
        const [orderBy, orderType] = Object.entries({
            fullName,
            author,
            status,
            createdAt
        }).find(([_, value]) => value) || [];

        registerPromise(programsService.getGoals(programId, {
            limit,
            offset,
            orderBy,
            orderType,
            query: search,
        }).then(({ data, pagination }) => {
                updateGoals(data);
                updateGoalsPagination(pagination);
            }));
    }, [limit, offset, fullName, author, status, createdAt, search]);

    const deleteGoal = (id) => {
      registerPromise(programsService.deleteGoal(id))
          .then(() => {
              getGoals()
              toastService.success("The goal has been successfully deleted")
          })
    }

    const handleClickDeleteProgram = () => {
        updateShowDeleteProgramPopup(programId);
    };

    const handleClickDelete = (id) => {
        updateShowDeletePopup(id);
    };

    const goToEditPrograms = () => {
        const queryParams = new URLSearchParams({ editProgramId: programId }).toString();
        navigate(`${PROGRAMS_GROUP_LINKS.CREATE_EDIT}?${queryParams}`);
    };

    const goToCopy = (id) => {
        const queryParams = new URLSearchParams({ copyGoalId: id }).toString();
        navigate(`${PROGRAMS_GROUP_LINKS.LIST}/${programId}/goal-form?${queryParams}`);
    };

    const goToEdit = (id) => {
        setEditGoalId(id)
        updateShowFormPopup(true)
    };

    useEffect(() => {
        getGoals();
    }, [getGoals]);

    return (
        <>
          <Table
            columns={goals.length ? columns : []}
            data={goals}
            loading={isLoading}
            HeaderComponent={TableHeader}
            totalCount={goalsPagination.totalCount}
            limit={DEFAULT_TABLE_LIMIT}
            offset={offset}
            paginationProvider={paginationProvider}
            searchProvider={searchProvider}
            commonPlaceholder={<NoGoalPlaceholder/>}
            placeholderForSearch={<SearchPlaceholder/>}
            actions={{
                [GOALS_ACTION.COPY]: goToCopy,
                [GOALS_ACTION.EDIT]: goToEdit,
                [GOALS_ACTION.DELETE]: handleClickDelete
            }}
            sortProviders={{}}
            headerActions={{
                goToCreate: () => updateShowFormPopup(true),
                goToEditPrograms,
                deleteProgram: handleClickDeleteProgram
            }}
        />
          {showDeletePopup &&
              <ConfirmPopup
                  isOpen={showDeletePopup}
                  updateIsOpen={updateShowDeletePopup}
                  onSubmit={() => {
                    deleteGoal(showDeletePopup)
                    updateShowDeletePopup(null)
                  }}
                  title="Delete goal"
                  description="Are you sure you want to delete the chosen goal?"
                  submitBtnText="Delete"
                  className="upload-manually__popup"
              />
          }

            {showFormPopup && <CreateEditGoal
                isOpen={showFormPopup}
                editGoalId={editGoalId}
                close={() => {
                    updateShowFormPopup(false)
                    setEditGoalId(null)
                    setCopyGoalId(null)
                    getGoals()
                }}
            />}
          </>
    );
}
