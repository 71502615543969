import React from "react";
import { MEAL_TYPES, MEAL_TYPES_LABELS } from "../../../../../../base/constants/foods";
import Title from "../../../../Biomarkers/Details/Title";
import { RecipeCard } from "./components/RecipeCard";
import { TotalNutrientInfo } from "./components/TotalNutrientInfo";

const DAYS = {
    MONDAY: 0,
    TUESDAY: 1,
    WEDNESDAY: 2,
    THURSDAY: 3,
    FRIDAY: 4,
    SATURDAY: 5,
    SUNDAY: 6
};

const DAY_LABELS = {
    [DAYS.MONDAY]: "Mo",
    [DAYS.TUESDAY]: "Tu",
    [DAYS.WEDNESDAY]: "Wed",
    [DAYS.THURSDAY]: "Thu",
    [DAYS.FRIDAY]: "Fri",
    [DAYS.SATURDAY]: "Sat",
    [DAYS.SUNDAY]: "Sun"
};

const ORDERING_MEAL_TYPES = [
    MEAL_TYPES.BREAKFAST,
    MEAL_TYPES.LUNCH,
    MEAL_TYPES.DINNER,
    MEAL_TYPES.SNACK
];

export const MealplanCalendar = ({ mealplan, mealTypes }) => {

    const visibleMealTypes = ORDERING_MEAL_TYPES.filter((type) => mealTypes.includes(type));

    const columnsCount = Object.keys(DAYS).length;

    return (
        <div className="mt-4 w-100">
            <Title title="Meal plan" className="mb-3"/>

            <div
                className="d-grid max-w-100"
                style={{
                    gridTemplateColumns: `77px repeat(${columnsCount}, minmax(0, 1fr))`
                }}
            >
                {/* Header */}
                <div/>
                {Object.values(DAY_LABELS).map((label, index) => (
                    <div
                        key={label}
                        className="d-flex align-items-center justify-content-center flex-column gap-2 mb-2"
                    >
                        <div>
                            {label}
                        </div>
                        <div className="font-weight-500">
                            Day {index + 1}
                        </div>
                    </div>))}

                {/* Cards */}
                {
                    visibleMealTypes.map((mealType) => {
                        return (
                            <>
                                <div key={mealType} className="p-2">
                                    <div
                                        className="bg-body w-100 h-100 align-items-center d-flex justify-content-center font-weight-500">
                                        <div className="bx-rotate-270">
                                            {MEAL_TYPES_LABELS[mealType]}
                                        </div>
                                    </div>
                                </div>

                                {Object.values(DAYS).map((day) => {
                                    const currentItem = mealplan?.[day]?.meals?.find((item) => item.mealTime === mealType);

                                    return <div key={`${mealType}-${day}`}>
                                        <RecipeCard recipe={currentItem || {}}/>
                                    </div>;
                                })}

                            </>
                        );
                    })
                }

                {/* Total row */}

                <div/>

                { Object.values(DAYS).map((day, index) => {
                    return <TotalNutrientInfo key={`total-${index}`} mealPlanByDay={mealplan?.[day]?.meals} />
                }) }
            </div>
        </div>
    );
};
