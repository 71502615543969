import React from 'react';
import { getInteractionOutput } from "../CreateEdit/helpers/interactions";
import { LABELS_DUE_TO_TYPE } from "../CreateEdit/Interactions/constants";
import RangeSlider from "../../../../base/components/RangeSlider";
import ValueWithLabel from "./ValueWIthLabel";
import RichTextView from "./RIchTextView";
import Divider from "../../../../base/components/Divider";

export default function InteractionsView({
  interactions = [],
}) {
  const { interactionsOutput } = getInteractionOutput(interactions);

  return (
    <section className="">
      {interactionsOutput.map(({ type, id, name, order, alsoKnowAs, effects, impact }, index) => {
        const { name: typeName = "" } = LABELS_DUE_TO_TYPE[type] ?? {};
        return (
          <React.Fragment key={id}>
            <section className="">
              <label className="d-flex align-items-center font-weight-semibold">
                {typeName} #{order}
              </label>

              <ValueWithLabel
                value={name}
                label={typeName}
                className="mt-3"
              />

              {alsoKnowAs &&
                <ValueWithLabel
                  value={alsoKnowAs}
                  label="Also known as"
                  className="mt-3"
                />
              }

              <RangeSlider
                value={impact ?? 0}
                onChange={() => {
                }}
                disabled
                label="Impact"
                containerClassName="mt-3 w-50"
                labelClassName="font-weight-semibold"
              />

              {!!effects &&
                <RichTextView
                  label={`${typeName} effects`}
                  richText={effects}
                  containerClassName="w-100 mt-3"
                />
              }
            </section>
            {index < interactionsOutput.length - 1 &&
              <Divider className="my-4"/>
            }
          </React.Fragment>
        )
      })}
    </section>
  )
}