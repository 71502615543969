import { CONDITION_OPERATORS, CONDITION_TYPES } from "../../../constants/conditions";


export const OPERATIONS_BY_TYPES = {
    [CONDITION_TYPES.TERM]: [
        CONDITION_OPERATORS.EQUAL,
        CONDITION_OPERATORS.IS_EMPTY,
        CONDITION_OPERATORS.STARTS_WITH,
        CONDITION_OPERATORS.ENDS_WITH,
        CONDITION_OPERATORS.CONTAINS
    ],
    [CONDITION_TYPES.BOOLEAN]: [
        CONDITION_OPERATORS.EQUAL,
        CONDITION_OPERATORS.IS_EMPTY
    ],
    [CONDITION_TYPES.NUMBER]: [
        CONDITION_OPERATORS.EQUAL,
        CONDITION_OPERATORS.IS_EMPTY,
        CONDITION_OPERATORS.GREATER,
        CONDITION_OPERATORS.LESS,
        CONDITION_OPERATORS.BETWEEN,
    ],
    [CONDITION_TYPES.NUMBER_RANGE]: [
        CONDITION_OPERATORS.EQUAL,
        CONDITION_OPERATORS.IS_EMPTY,
        CONDITION_OPERATORS.MIN_BETWEEN,
        CONDITION_OPERATORS.MAX_BETWEEN
    ]
};

export const OPERATION_ICONS = {
    [CONDITION_OPERATORS.IS_EMPTY]: "notEqual",
    [CONDITION_OPERATORS.GREATER]: "greater",
    [CONDITION_OPERATORS.LESS]: "less",
    [CONDITION_OPERATORS.EQUAL]: "equal",
    [CONDITION_OPERATORS.BETWEEN]: "between",
    [CONDITION_OPERATORS.MIN_BETWEEN]: "minBetween",
    [CONDITION_OPERATORS.MAX_BETWEEN]: "maxBetween",
    [CONDITION_OPERATORS.STARTS_WITH]: "startsWith",
    [CONDITION_OPERATORS.ENDS_WITH]: "endsWith",
    [CONDITION_OPERATORS.CONTAINS]: "contains"
};

export const OPERATION_TITLES = {
    [CONDITION_OPERATORS.IS_EMPTY]: "Is empty",
    [CONDITION_OPERATORS.GREATER]: "Greater",
    [CONDITION_OPERATORS.LESS]: "Less",
    [CONDITION_OPERATORS.EQUAL]: "Equal",
    [CONDITION_OPERATORS.BETWEEN]: "Between",
    [CONDITION_OPERATORS.MIN_BETWEEN]: "Min between",
    [CONDITION_OPERATORS.MAX_BETWEEN]: "Max between",
    [CONDITION_OPERATORS.STARTS_WITH]: "Starts with",
    [CONDITION_OPERATORS.ENDS_WITH]: "Ends with",
    [CONDITION_OPERATORS.CONTAINS]: "Contains"
};




