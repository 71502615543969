export const QUIZ_MAP_BY_TYPE = {
  Skin: 1,
  Assessment: 2,
};

export const QUIZ_MAP = {
  [QUIZ_MAP_BY_TYPE.Skin]: "Skin",
  [QUIZ_MAP_BY_TYPE.Assessment]: "Assessment",
};

export const QUIZ_TYPES_OPTIONS = [
  { value: QUIZ_MAP_BY_TYPE.Skin, label: QUIZ_MAP[QUIZ_MAP_BY_TYPE.Skin] },
  { value: QUIZ_MAP_BY_TYPE.Assessment, label: QUIZ_MAP[QUIZ_MAP_BY_TYPE.Assessment] },
];

export const ALLOWED_QUIZ_KEY_REGEX = /^[a-z\d]+(?:_+[a-z\d]+)*$/;
export const ALLOWED_QUIZ_KEY_MESSAGE = 'Full name must contain only lowercase letters and underscores';