import React from "react";
import Tag from "../../Tags/components/Tag";
import { DATE_FORMAT_STORYBLOK, formatDate } from "../../../../../helpers/date";
import { DEFAULT_DATE_OUTPUT_FORMAT } from "../../../../../constants/date";
import {ReactComponent as LogoIcon} from "../../../assets/image/logo.svg";
import { Badge } from "reactstrap";

const RelatedArticles = ({ article }) => {
    if (!article) {
        return null;
    }

    const getTag = (value) => {
        if (Array.isArray(value)) {
            return value.map(text => <Tag size="sm" key={text} value={text}/>);
        } else if (typeof value === 'string' || typeof value === 'number') {
            return <Tag key={value} size="sm" value={value}/>;
        }

        return null
    }

    const date = formatDate(article.bannerInfo.date, DATE_FORMAT_STORYBLOK, DEFAULT_DATE_OUTPUT_FORMAT)

    const imageStyle = {
        width: "24px",
        height: "24px",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "lightgray"
    }

    return (
        <div className="related-article">
            <img src={article.image} className="related-article__image" alt="preview image" loading="lazy"/>
            <div className="related-article__info">
                <div className="d-flex justify-content-between mb-2">
                    <span className="text-description">{date}</span>
                    <div>{Object.values(article.tags).map(value => getTag(value))}</div>
                </div>
                <div className="related-article__title">
                    {article.title}
                </div>
            </div>
            <div className="related-article__banner">
                <div style={imageStyle}>
                    <LogoIcon />
                </div>
                <span>By Optimally Me team</span>
            </div>
        </div>
    );
};

export default RelatedArticles;
