import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";

import Title from "../../Biomarkers/Details/Title";
import BaseLayoutWithCard from "../../../../base/components/BaseLayoutWithCard";
import FormikInput from "../../../../base/components/FormikInput";
import Button from "../../../../base/components/Button";
import FormikReactSelect from "../../../../base/components/FormikReactSelect";
import { FormAvatar } from "../../../../base/components/Dropzone/Dropzone";
import TimeInput from "../../../../base/components/TimeInput";
import WorkoutSetUp from "./components/WorkoutSetUp";
import RadioButton from "../../../../base/components/RadioButton";
import { WorkoutTypeDropdown } from "../../../../base/components/Dropdowns/WorkoutTypeDropdown/WorkoutTypeDropdown";
import { UserSegmentsDropdown } from "../../../../base/components/Dropdowns/UserSegmentsDropdown/UserSegmentsDropdown";

import ToastService from "../../../../services/ToastService";

import { useService } from "../../../../base/hooks/useService";
import { useQueryString } from "../../../../base/hooks/useQueryString";
import useUploadImages from "../../../../base/components/Dropzone/useUploadImages";
import joinClassNames from "../../../../base/helpers/joinClassNames";
import { FITNESS_GROUP_LINKS } from "../config";
import {
    MAX_NAME_LENGTH, MAX_OVERVIEW,
    WORKOUT_CONTENT_FORMAT,
    WORKOUT_CONTENT_FORMAT_LABELS,
    WORKOUT_DESCRIPTION_TYPE,
    WORKOUT_DESCRIPTION_TYPE_LABELS, WORKOUT_SECTION_TOPIC_TYPES
} from "./constants";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";
import { PROGRAMS_FILE_TYPE } from "../../../../base/constants/shared";
import {
    DropZonePlaceholder,
    initialValues,
    mapFormValuesToWorkout, mapWorkoutToForm,
    validateFile,
    validationSchema,
    workoutLevelOptions,
    workoutStatusesOption
} from "./form";
import WorkoutsService from "../../../../services/WorkoutService";
import RichTextEditor from "../../../../base/components/RichTextEditor";
import { CROP_IMAGE_RATIO_16_9 } from "../../../../base/constants/colorsAndSIzes";
import imageCompression from "browser-image-compression";
import { MAX_SIZE_MB, MAX_WIDTH_OR_HEIGHT, USE_WEB_WORKER } from "../../../../base/constants/image";
import SubscriptionPlansService from "../../../../services/SubscriptionPlansService";

export function WorkoutForm() {
    /**
     * @type {WorkoutsService}
     */
    const workoutsService = useService(WorkoutsService);
    /**
     * @type {ToastService}
     */
    const toastService = useService(ToastService);
    /**
     * @type {SubscriptionPlansService}
     */
    const subscriptionPlansService = useService(SubscriptionPlansService);

    const uploadImage = useUploadImages();
    const { search: locationSearch } = useLocation();
    const navigate = useNavigate();
    const initSubscription = { value: null, label: "No subscription" };

    const [isSubmitting, updateIsSubmitting] = useState(false);
    const [workout, setWorkout] = useState(null);
    const [subscriptionPlans, setSubscriptionPlans] = useState([initSubscription]);

    const {
        params: {
            editWorkoutId
        }
    } = useQueryString(locationSearch);

    const afterSuccess = () => {
        toastService.success("Workout has been successfully saved");
        navigate(FITNESS_GROUP_LINKS.WORKOUTS_LIST);
        updateIsSubmitting(false);
    };

    const apiFunction = (workout) => {
        if (editWorkoutId) {
            return workoutsService.updateWorkout(editWorkoutId, workout);
        }

        return workoutsService.createWorkout(workout);
    };

    const createWorkout = ({ file, ...otherValues }) => {
        updateIsSubmitting(true);

        const fileId = file?.[0]?.id;

        if (file?.[0]?.file && !file?.[0]?.id) {
            const options = {
                maxSizeMB: MAX_SIZE_MB,
                maxWidthOrHeight: MAX_WIDTH_OR_HEIGHT,
                useWebWorker: USE_WEB_WORKER,
            }
            imageCompression(file[0].file, options).then((compressedFile) => {
                return uploadImage(compressedFile, PROGRAMS_FILE_TYPE).then(({ file }) => {
                    return apiFunction(mapFormValuesToWorkout({
                        ...otherValues,
                        fileId: file.id
                    }));
                });
            }).then(afterSuccess).finally(() => updateIsSubmitting(false));

            return;
        }

        apiFunction(mapFormValuesToWorkout({
            ...otherValues,
            fileId
        })).then(afterSuccess).finally(() => updateIsSubmitting(false));
    };

    useEffect(() => {
        if (editWorkoutId) {
            workoutsService.getWorkoutById(editWorkoutId)
                .then((data) => {
                    setWorkout(mapWorkoutToForm(data));
                });
        }
    }, [editWorkoutId]);

    useEffect(() => {
        subscriptionPlansService.getSubscriptionPlans()
          .then(({ data }) => {
              setSubscriptionPlans([
                  initSubscription,
                  ...data.map(({ id, adaptyProductName }) => ({ value: id, label: adaptyProductName }))
              ])
          });
    }, []);

    const breadcrumbs = {
        title: editWorkoutId ? "Edit workout" : "Create workout",
        breadcrumbItems: [
            { title: "Fitness", link: FITNESS_GROUP_LINKS.WORKOUTS_LIST },
            { title: editWorkoutId ? "Edit workout" : "Create workout" }
        ]
    };

    return <BaseLayoutWithCard breadcrumbs={breadcrumbs}>
        <Formik
            initialValues={workout || initialValues}
            validationSchema={validationSchema}
            validateOnBlur
            onSubmit={createWorkout}
            enableReinitialize
        >
            {({ errors, handleSubmit, values, setFieldValue, setFieldTouched, touched }) => {
                return <form className={joinClassNames("form-horizontal p-2", isSubmitting && "pointer-events-none")}
                             onSubmit={handleSubmit}>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <Title
                            title="General information"
                        />
                    </div>
                    <section className="w-50">
                        <FormAvatar
                            validateImage={validateFile}
                            dropZonePlaceholder={<DropZonePlaceholder />}
                            dropZoneTitle="Upload an image"
                            cropperSetting={CROP_IMAGE_RATIO_16_9}
                        />

                        <FormikInput
                            placeholder="Enter name (required)"
                            name="name"
                            maxLength={MAX_NAME_LENGTH}
                            label="Name"
                            containerClassName="mt-3"
                        />

                        <FormikReactSelect
                            name="level"
                            options={workoutLevelOptions}
                            label="Workout level"
                            setFieldValue={setFieldValue}
                            containerClassName="mt-3"
                            placeholder="Select workout level (required)"
                        />

                        <div className="mt-3">
                            <WorkoutTypeDropdown
                                value={values.typeId}
                                onChange={(value) => setFieldValue('typeId', value)}
                                placeholder="Select workout type (required)"
                                label="Workout type"
                                error={touched.typeId && errors.typeId}
                                handleBlur={() => setFieldTouched('typeId', true)}
                            />
                        </div>

                        <div className="mt-3">
                            <UserSegmentsDropdown
                                value={values.userSegments}
                                onChange={(value) => setFieldValue("userSegments", value)}
                                placeholder="Select user segment (optional)"
                                label="User segment"
                            />
                        </div>

                        <FormikReactSelect
                            name="status"
                            options={workoutStatusesOption}
                            label="Status"
                            setFieldValue={setFieldValue}
                            containerClassName="mt-3"
                            placeholder="Select status (required)"
                        />

                        <TimeInput
                            placeholder="00h 00m 00s (optional)"
                            name="duration"
                            label="Duration (min)"
                            containerClassName="mt-3"
                            value={values.duration}
                            handleChange={(value) => setFieldValue('duration', value)}
                        />

                        <FormikReactSelect
                          name="subscriptionPlanId"
                          options={subscriptionPlans}
                          label="Subscription type"
                          setFieldValue={setFieldValue}
                          containerClassName="mt-3"
                          placeholder="Select subscription type (required)"
                          withError
                        />

                        <div className="mt-3">
                            <label>Workout content:</label>
                            <div className="d-flex gap-3">
                                {Object.values(WORKOUT_CONTENT_FORMAT).map((format) => <RadioButton
                                    key={format}
                                    label={WORKOUT_CONTENT_FORMAT_LABELS[format]}
                                    onChange={() => {
                                        setFieldValue("content", format);
                                    }}
                                    checked={values.content === format}
                                    name="content"
                                />)}
                            </div>
                        </div>
                    </section>

                    <div className="d-flex justify-content-between align-items-center my-4">
                        <Title
                            title="Workout description (required)"
                        />
                    </div>

                    <div className="mt-3">
                        <label>Select type:</label>
                        <div className="d-flex gap-3">
                            {Object.values(WORKOUT_DESCRIPTION_TYPE).map((format) => <RadioButton
                                key={format}
                                label={WORKOUT_DESCRIPTION_TYPE_LABELS[format]}
                                onChange={() => {
                                    setFieldValue("sections", []);
                                    setFieldValue("videoUrl", null);
                                    setFieldValue("descriptionType", format);
                                }}
                                checked={values.descriptionType === format}
                                name="descriptionType"
                            />)}
                        </div>
                    </div>

                    <div className="mt-3">
                        {
                            values.descriptionType === WORKOUT_DESCRIPTION_TYPE.WORKOUT_SET_UP
                                ? <WorkoutSetUp
                                    name="sections"
                                />
                                : <FormikInput
                                    containerClassName="w-50"
                                    name="videoUrl"
                                    label="Video URL"
                                    placeholder="Please enter a url (Required)"
                                />
                        }
                    </div>

                    <RichTextEditor
                        value={values.overview || initialValues.overview}
                        onChange={(text) => {
                            setFieldValue('overview', text);
                        }}
                        maxLength={MAX_OVERVIEW}
                        name="overview"
                        label="Workout overview"
                        placeholder="Enter the overview for the workout..."
                    />

                    <RichTextEditor
                      value={values.whyRecommendedDescription || initialValues.whyRecommendedDescription}
                      onChange={(text) => {
                          setFieldValue('whyRecommendedDescription', text);
                      }}
                      className="mt-5"
                      name="whyRecommendedDescription"
                      label="Describe why system can recommend this workout"
                      placeholder="Enter why system can recommend this workout..."
                    />

                    <div className="d-flex justify-content-end mt-5">
                        {<Button
                            color={BUTTON_COLORS.primary}
                            type="submit"
                            disabled={!!Object.keys(errors).length || isSubmitting}
                        >
                            Save workout
                        </Button>}
                    </div>
                </form>;
            }}
        </Formik>
    </BaseLayoutWithCard>;
}