import React from "react";
import { Card, CardBody, Container } from "reactstrap";
import Breadcrumb from "../../../base/components/Common/Breadcrumb2";
import ReportsTable from "./components/ReportsTable";
import { KEY_USER } from "../../../base/constants/storage";
import { USER_ROLES } from "../../../base/constants/shared";
import { useNavigate } from "react-router-dom";
import { CUSTOMER_MANAGEMENT_LINKS } from "../CustomerManagement/config";
import StorageService from "../../../services/StorageService";
import { useService } from "../../../base/hooks/useService";

const breadcrumbs = {
  title: "Dashboard", breadcrumbItems: []
}

export default function Dashboard() {
  const navigate = useNavigate();
  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);

  const user = storage.get(KEY_USER, { firstName: "", lastName: "" });

  if (user.role === USER_ROLES.practitioner) {
    navigate({
      pathname: CUSTOMER_MANAGEMENT_LINKS.LIST,
    })
  }

  return (
    <Container fluid className="content">
      <Breadcrumb {...breadcrumbs} />
      <Card>
        <CardBody>
          <ReportsTable />
        </CardBody>
      </Card>
    </Container>
  );
}