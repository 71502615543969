// Libs
import React, { useEffect, useRef, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem, Spinner } from "reactstrap";
import classnames from "classnames";
import { noop } from "lodash";

// Components, Views, Screens
import Icon from "../../../../../../../base/components/Icon";
import { FormAvatar } from "../../../../../../../base/components/Dropzone/Dropzone";

// Hooks, Utils, Helpers
import { useService } from "../../../../../../../base/hooks/useService";
import { useLoading } from "../../../../../../../base/hooks/useLoading";
import { validateFile } from "../../form";
import CoachService from "../../../../../../../services/CoachService";

// Styles, assets
import classes from "./CoachDropdown.module.scss";


const DEFAULT_LIMIT = 100;


export const CoachDropdown = ({ onChange, value, placeholder, label, error, handleBlur = noop, name = "coach" }) => {
    /**
     * @type {CoachService}
     */
    const coachService = useService(CoachService);

    const [isOpen, updateIsOpen] = useState(false);
    const [coaches, setCoaches] = useState([]);
    const [page, setPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [isLoading, { registerPromise }] = useLoading(true);
    const lastElementRef = useRef(null);

    const showPlaceholder = !value?.id;

    const mapCoachesToDropdownOption = (data = []) => {
        return data.map((item) => ({ id: item.id, name: item.name, file: item.file }));
    };

    const formatFile = (file) => {
        return file
            ? [{
                ...file,
                preview: file?.link,
                file: { size: file?.bytes ?? 0 },
                cropped: true
            }]
            : []
    }

    const handleChange = (e) => {
        updateIsOpen(true);
        onChange({ name: e.target.value });
    };

    const loadCoaches = () => {
        const params = { limit: DEFAULT_LIMIT, offset: 0, query: value?.name || undefined };

        setHasNextPage(false)
        setPage(1)

        registerPromise(coachService.getCoaches(params))
            .then((result => {
                setCoaches(mapCoachesToDropdownOption(result.data));
                setHasNextPage(result?.pagination?.nextOffset < result?.pagination?.totalCount);
            }));
    }

    const loadMoreCoaches = () => {
        if (isLoading || !hasNextPage) return
        const nextOffset = page * DEFAULT_LIMIT;

        const params = { limit: DEFAULT_LIMIT, offset: nextOffset, query: value?.name || undefined };

        registerPromise(coachService.getCoaches(params))
            .then((result => {
                setCoaches([...coaches, ...mapCoachesToDropdownOption(result.data)]);
                setHasNextPage(result?.pagination?.nextOffset < result?.pagination?.totalCount);
                setPage(page + 1)
            }));
    }

    useEffect(() => {
        if (!lastElementRef.current) return;

        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                loadMoreCoaches();
            }
        });

        observer.observe(lastElementRef.current);

        return () => {
            observer.disconnect();
        };
    }, [loadMoreCoaches, lastElementRef.current]);

    useEffect(() => {
        loadCoaches();
    }, [value?.name]);

    return (<>
            {!!label && <label>{label}</label>}
            <section className="d-flex gap-2 w-100">
                <Dropdown
                    isOpen={isOpen}
                    toggle={() => {
                        if (isOpen) {
                            handleBlur();
                        }
                        updateIsOpen(prevState => !prevState);
                    }}
                    className="d-inline-block filter-dropdown cursor-pointer result-filter w-100"
                    direction="down"
                >
                    <DropdownToggle
                        className={classnames('filter-toggle max-w-100 pe-0',
                            {
                                'with-border': isOpen,
                                'is-invalid': !!error
                            }
                        )}
                        tag="section"
                    >
                <div className={classnames('ms-2 me-1 w-95 user-select-none text-truncate', { 'text-secondary': showPlaceholder })}>
            <input
                className="w-100 ps-2 ms-1 border-0 no-outline"
                value={value?.name || ''}
                onChange={handleChange}
                placeholder={placeholder || "Select coach"}
            />
                </div>
                        <i className={classnames('mdi mdi-chevron-down pointer-events-none user-select-none me-2', { 'mdi-rotate-180': isOpen })}/>
                    </DropdownToggle>

                    <DropdownMenu className="filter-menu pb-1 px-1 w-100 top-50" flip={false}>
                        <section>
                            <ListGroup>
                                <div className={classnames(classes.ItemsWrapper, "custom-scrollbar")}>
                                    {coaches.map((item, index) => (
                                        <ListGroupItem className="bg-transparent border-0 p-0" key={item.id}>
                                            <div
                                                className={classnames(classes.Item, value?.id === item.id && classes.Active)}
                                                onClick={() => {
                                                    onChange({
                                                        ...item,
                                                        file: formatFile(item.file)
                                                    });
                                                    updateIsOpen(false);
                                                }}
                                            >
                                                {item.name}
                                            </div>
                                            {
                                                index === coaches.length - 1
                                                    ? <div
                                                        ref={lastElementRef}
                                                        className="d-flex justify-content-center">
                                                    </div>
                                                    : null
                                            }
                                        </ListGroupItem>
                                    ))}
                                    {isLoading && <div className="d-flex my-2 justify-content-center">
                                        <Spinner size="sm" color="primary"/>
                                    </div>}
                                </div>
                            </ListGroup>
                        </section>
                    </DropdownMenu>
                </Dropdown>

                <div>
                    <ImageField name={name}/>
                </div>
            </section>
            {error &&
                <span className="invalid-feedback d-block position-relative" style={{ bottom: '15px' }}>{error}</span>
            }
        </>
    );
};

const FieldImagePreviewComponent = ({
                                   selectedImages = [],
                                   onEditImage,
                                   onDelete
                               }) => {
    return <div>
        {
            selectedImages.map(image => {
                const { preview = "" } = image;
                return <div key={preview} className="field-image-preview mb-3">
                    <img src={preview} alt="coachUploadedImage"/>

                    <div onClick={onDelete} className="field-image-preview__delete-button">
                        <Icon icon="close"/>
                    </div>
                </div>;
            })
        }
    </div>;
};

const DropzoneComponent = () => {
    return <div className="coach-dropdown-dropzone">
        <Icon icon="user"/>
    </div>;
};

function ImageField({ name }) {
    return <FormAvatar
        validateImage={validateFile}
        imagePreviewComponent={FieldImagePreviewComponent}
        dropzoneContainerComponent={DropzoneComponent}
        fieldName={`${name}.file`}
        dropzoneClassName="border-0"
    />;
}



