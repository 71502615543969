import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Dropdown, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem, Spinner } from "reactstrap";
import classnames from "classnames";
import TableSearch from "../../Table/tableSearch";
import Checkbox from "../../Checkbox";
import { useHighlight } from "../../../hooks/useHighlight";
import { toggleSelectItem } from "../../../helpers/toggleSelectItem";
import { useService } from "../../../hooks/useService";
import classes from "./SectionFiltersDropdown.module.scss";
import Swiper from "../../Swiper/Swiper";
import HomePageLayoutService from "../../../../services/HomePageLayoutService";
import { FILTER_GROUPS, HOMEPAGE_SECTIONS_TYPE } from "../../../../groups/app/Fitness/constants";
import HealthProgramService from "../../../../services/HealthProgramService";
import { WELLNESS_TYPE } from "../../../../groups/app/HealthProgram/constants";
import * as _ from "lodash";

const MIN_SEARCH_LENGTH = 3;

const getSearchRequest = (search) => search && search.toString()?.trim()?.length >= MIN_SEARCH_LENGTH ? search : '';

const SectionFiltersDropdown = ({
                                  onChange,
                                  value = [],
                                  placeholder,
                                  error,
                                  label,
                                  type,
                                  disabled
                                }) => {

  /**
   * @type {HomePageLayoutService}
   */
  const homePageLayoutService = useService(HomePageLayoutService);
  /**
   * @type {HealthProgramService}
   */
  const healthProgramService = useService(HealthProgramService);

  const [isOpen, updateIsOpen] = useState(false);
  const [activeTabs, setActiveTabs] = useState({
    [HOMEPAGE_SECTIONS_TYPE.WORKOUT]: null,
    [HOMEPAGE_SECTIONS_TYPE.PROGRAM]: null,
  });
  const [search, setSearch] = useState({
    [HOMEPAGE_SECTIONS_TYPE.WORKOUT]: '',
    [HOMEPAGE_SECTIONS_TYPE.PROGRAM]: '',
  });
  const [filters, setFilters] = useState({
    [HOMEPAGE_SECTIONS_TYPE.WORKOUT]: null,
    [HOMEPAGE_SECTIONS_TYPE.PROGRAM]: null,
  });
  const [programTypeByTitle, setProgramTypeByTitle] = useState({
    data: []
  });
  const [allowedCategories, setAllowedCategories] = useState([{}]);
  const [isLoading, setIsLoading] = useState(false);

  const filterTabs = useMemo(() => {
    return Object.keys(filters?.[type] || {})
  }, [filters?.[type]])

  const searchRequest = useMemo(() => {
      return {
        [HOMEPAGE_SECTIONS_TYPE.WORKOUT]: getSearchRequest(search[HOMEPAGE_SECTIONS_TYPE.WORKOUT]),
        [HOMEPAGE_SECTIONS_TYPE.PROGRAM]: getSearchRequest(search[HOMEPAGE_SECTIONS_TYPE.PROGRAM]),
      };
    }, [search[HOMEPAGE_SECTIONS_TYPE.WORKOUT], search[HOMEPAGE_SECTIONS_TYPE.PROGRAM]]
  );

  const { decorateText } = useHighlight(searchRequest[type]);

  const filtersMapping = {
    [HOMEPAGE_SECTIONS_TYPE.PROGRAM]: {
      source: FILTER_GROUPS.PROGRAM_TYPES,
      target: FILTER_GROUPS.CATEGORIES
    }
  }

  const valueIds = useMemo(() => value.map(item => item.id), [value]);

  const mapData = (data = []) => {
    const value = {
      All: data.reduce((acc, item) => [...acc, ...item.filters], [])
    }

    return data.reduce((acc, item) => {
      acc[item.name] = item.filters

      return acc
    }, value)
  }

  const loadData = () => {
    if (!type) return
    setIsLoading(true);

    const params = { query: searchRequest[type] };
    Promise.all([
      homePageLayoutService.getHomepageSectionFilters(type, params),
      healthProgramService.getWellnessTypeAndSubcategories(WELLNESS_TYPE.FITNESS)
    ]).then(([homepageSectionFilters, wellnessTypesAndSubcategories]) => {
      setProgramTypeByTitle(_.chain(wellnessTypesAndSubcategories)
        .get('data')
        .keyBy('title')
        .mapValues(
          type => _.map(type.wellnessProgramSubcategories, 'title')
        ).value()
      );
      setFilters({ ...filters, [type]: mapData(homepageSectionFilters.data) });
    })
    .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    loadData();
  }, [searchRequest[HOMEPAGE_SECTIONS_TYPE.WORKOUT], searchRequest[HOMEPAGE_SECTIONS_TYPE.PROGRAM]]);

  useEffect(() => {
    if (filters[type]) return
    loadData();
  }, [type]);

  useEffect(() => {
    if(!value.length) return;
    const selectedPrograms = _.filter(value, { categoryId: filtersMapping[HOMEPAGE_SECTIONS_TYPE.PROGRAM].source }).map(item => item.name);
    const allowedCategories = [{}];
    for (const selectedProgram of selectedPrograms) {
      allowedCategories.push(...programTypeByTitle[selectedProgram]);
    }
    setAllowedCategories(allowedCategories);
  }, [value]);

  useEffect(() => {

    if (activeTabs[type] || !filters[type]) return

    setActiveTabs(prev => ({
      ...prev,
      [type]: Object.keys(filters[type])[0]
    }))
  }, [activeTabs[type], filters[type]]);

  const showPlaceholder = !value.length;

  return (
    <>
      {!!label && <label>{label}</label>}

      <section className="d-flex align-items-center w-100">

        <Dropdown
          isOpen={isOpen}
          toggle={() => updateIsOpen(prevState => !prevState)}
          className="d-inline-block filter-dropdown cursor-pointer result-filter min-w-1-5 w-100"
          direction="down"
          disabled={disabled}
        >
          <DropdownToggle
            className={classnames('filter-toggle w-100', { 'with-border': isOpen, 'is-invalid': !!error })}
            tag="section"
          >
                    <span className={classnames('ms-2 me-1 pointer-events-none user-select-none text-truncate',
                      { 'text-secondary': showPlaceholder })}>
                        {
                          showPlaceholder
                            ? placeholder
                            : value.map(item => item.name).join(', ')
                        }
                    </span>
            <i className={classnames('mdi mdi-chevron-down pointer-events-none user-select-none',
              { 'mdi-rotate-180': isOpen })}/>
          </DropdownToggle>
          <DropdownMenu className="filter-menu pb-1 px-1 w-100 top-50" flip={false}>
            <TableSearch
              onSearch={(newSearch) => setSearch(prev => ({
                ...prev,
                [type]: newSearch
              }))}
              placeholder="Search"
              search={search[type]}
              className="my-2"
            />

            <Swiper
              containerClassName="my-2"
              slidesPerView="auto"
              spaceBetween={0}
              slideClassName="w-fit-content"
              slides={filterTabs.map((item) => {
                return <button
                  type="button"
                  key={item}
                  onClick={() => {
                    setActiveTabs({ ...activeTabs, [type]: item });
                  }}
                  className={classnames(
                    "text-nowrap",
                    classes.Slide,
                    item === activeTabs[type] ? classes.Active : ""
                  )}
                >
                  {item}
                </button>;
              })}
            />

            <section>
              <ListGroup>
                <div className={classnames(classes.ItemsWrapper, "custom-scrollbar")}>
                  {filters?.[type]?.[activeTabs[type]]?.length
                    ? filters?.[type]?.[activeTabs[type]].map((item) => {
                        const itemTitle = item.name || item.fullName;
                        if (item.categoryId === filtersMapping[type]?.target) {
                          if(!allowedCategories.includes(itemTitle)) return;
                        }
                        return (
                          <ListGroupItem className="bg-transparent border-0" key={item.id}>
                            <Checkbox
                              name="filters"
                              text={<div {...decorateText(itemTitle)} />}
                              value={valueIds.includes(item.id)}
                              onChange={
                                () => toggleSelectItem(item, value, onChange)
                              }
                            />
                          </ListGroupItem>
                        );
                      }
                    )
                    : <div className="text-secondary py-3 w-100 text-center">{!isLoading && "No result"}</div>
                  }
                  {
                    isLoading && <div className="d-flex my-2 justify-content-center">
                      <Spinner size="sm" color="primary"/>
                    </div>
                  }
                </div>
              </ListGroup>
            </section>
          </DropdownMenu>
        </Dropdown>
      </section>
      {error &&
        <span className="invalid-feedback d-block">{error}</span>
      }
    </>
  );
};

export default SectionFiltersDropdown;
