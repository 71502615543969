export const CONTENT_ASSIGNED = {
  GENERAL: 1,
  PROGRAMS: 2,
  SEGMENT: 3
}

export const CONTENT_ASSIGNED_LABELS = {
  [CONTENT_ASSIGNED.GENERAL]: "General content",
  [CONTENT_ASSIGNED.PROGRAMS]: "Programs",
  [CONTENT_ASSIGNED.SEGMENT]: "User segments"
}

export const CONTENT_TYPE = {
  VIDEO: 3,
  AUDIO: 2,
  ARTICLE: 1
}

export const CONTENT_TYPE_LABELS = {
  [CONTENT_TYPE.VIDEO]: "Video",
  [CONTENT_TYPE.AUDIO]: "Audio",
  [CONTENT_TYPE.ARTICLE]: "Article"
}

export const TAG_NAMES_MIN_ITEMS_LENGTH = 1

export const TAG_NAMES_MAX_ITEMS_LENGTH = 50
