import React from 'react'
import { Card, CardBody, Col, Row } from "reactstrap";
import Breadcrumb from "../../../../base/components/Common/Breadcrumb2";
import BiomarkerForm from "./form";
import { BIOMARKERS_GROUP_LINKS } from "../config";
import { useParams } from "react-router-dom";

const breadcrumbs = {
  title: "Edit biomarker",
  breadcrumbItems: [
    { title: "Biomarkers", link: BIOMARKERS_GROUP_LINKS.BASE },
    { title: "Edit biomarker" }
  ]
}

export default function EditBiomarker() {
  const { id } = useParams();
  return (
    <>
      <Breadcrumb {...breadcrumbs}/>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <BiomarkerForm id={id} isEdit/>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}