import React, { useCallback, useEffect, useState } from 'react';
import joinClassNames from "../../../../base/helpers/joinClassNames";
import { Col, Spinner } from "reactstrap";
import { DropZoneCard } from "../../../../base/components/Dropzone";
import {
  ALLOWED_LOGO_FILE_EXTENSION, ASSETS_FILE_TYPE,
  BYTES_IN_KILOBYTE, ERROR_ALLOWED_LOGO_FILE_TYPE_MESSAGE, ERROR_LOGO_FILE_SIZE_MESSAGE,
  KILOBYTES_IN_MEGABYTE,
  LOGO_FILE_MASK,
  MAX_LOGO_FILE_SIZE, REACT_DROPZONE_INVALID_FILE_TYPE_ERROR
} from "../../../../base/constants/shared";
import Icon from "../../../../base/components/Icon";
import { Formik } from "formik";
import { validateEmptyFile, validateFileSize, validateFileType } from "../../../../validation/fileUploadAndProcessing";
import ToasterService from "../../../../services/ToastService";
import { useService } from "../../../../base/hooks/useService";
import * as _ from "lodash";
import ConfigService, { ThemeTypes } from "../../../../services/ConfigService";
import Button from "../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";
import useUploadImages from "../../../../base/components/Dropzone/useUploadImages";
import { useDarkMode } from "../../../../base/context/darkMode";

export default function BloodBiomarker() {
  const [initialValues, setInitialValues] = useState({});
  const [isSubmitting, updateIsSubmitting] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, updateIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [apiSettings, setApiSettings] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const uploadImage = useUploadImages();
  const isDarkMode = useDarkMode();
  const themeType = isDarkMode ? ThemeTypes.dark : ThemeTypes.white;

  /**
   * @type {ConfigService}
   */
  const configService = useService(ConfigService);
  /**
   * @type {ToasterService}
   */
  const toastService = useService(ToasterService);

  useEffect(() => {
    configService.fetchThemeSettings().then((rawThemeSettings) => {
      const themeFields = ["id", "propertyName", "fileId", "value", "file", "page", "themeType"];
      const themeSettings = _.chain(rawThemeSettings)
        .get("data")
        .filter({ themeType:  themeType })
        .map(item => _.pick(item, themeFields))
        .keyBy("propertyName")
        .value();

      setApiSettings(themeSettings);
      setSelectedFile({
        ...themeSettings.studyIcon
      });
      updateIsLoading(false);

      setInitialValues((prev)  => ({
        ...prev,
        studyIcon: themeSettings.studyIcon.value,
      }));
    });
  }, [configService, isDarkMode]);

  const handleAcceptedFile = useCallback(async (file) => {
    setErrorMessage("");

    try {
      validateFileType(file, ALLOWED_LOGO_FILE_EXTENSION, ERROR_ALLOWED_LOGO_FILE_TYPE_MESSAGE);
      validateFileSize(file, MAX_LOGO_FILE_SIZE, ERROR_LOGO_FILE_SIZE_MESSAGE);
      validateEmptyFile(file);
    } catch ({ message }) {
      toastService.error(message)
      setErrorMessage(message);
      return;
    }

    setSelectedFile((prev) => {
      return {
        ...prev,
        value: URL.createObjectURL(file),
        fileData: file,
        file: {
          name: file.name,
          bytes: file.size
        }
      }});

  }, [setErrorMessage]);

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === REACT_DROPZONE_INVALID_FILE_TYPE_ERROR) {
            toastService.error(ERROR_ALLOWED_LOGO_FILE_TYPE_MESSAGE);
          }
        });
      });

      if (!acceptedFiles.length) return;

      acceptedFiles.forEach((file) => handleAcceptedFile(file));

    },
    [handleAcceptedFile]
  );

  const deleteLogo = () => {
    setSelectedFile(null);
  }

  const submitSettings = async (values) => {
    updateIsSubmitting(true);
    try {
      const settingsToSubmit = _.pick(apiSettings, _.keys(values));

      _.map(settingsToSubmit, (setting) => {
        setting.value = values[setting.propertyName];
        return setting;
      });

      if (!selectedFile.id) {
        const uploadedFile = await uploadImage(selectedFile.fileData, ASSETS_FILE_TYPE); // uploadedFile.file.id
        settingsToSubmit.studyIcon = {
          ...settingsToSubmit.studyIcon,
          fileId: uploadedFile.file.id
        };
        setSelectedFile((prev) => ({
          ...prev,
          id: uploadedFile.file.id
        }));
      }

      settingsToSubmit.studyIcon = _.omit(settingsToSubmit.studyIcon, ['value', 'file']);

      const updatedSettings = await configService.updateThemeSettings(_.values(settingsToSubmit));

      toastService.success("Settings was successfully uploaded");
    } catch (err) {
      console.error(err);
      toastService.error(err)
      setErrorMessage(err);
    } finally {
      updateIsSubmitting(false);
    }
  };

  return (
    <Col className="sideform-wrapper">
      <h2 className="sideform-header">Study icon</h2>
      <Formik
        initialValues={initialValues}
        validateOnBlur
        onSubmit={submitSettings}
        enableReinitialize
      >
        {({ errors, handleSubmit, values, setFieldValue }) =>
          <form className={joinClassNames("form-horizontal", isSubmitting && "pointer-events-none")}
                onSubmit={handleSubmit}>
                  {isLoading &&
                    <section className="w-100 py-5 spinner d-flex align-items-center justify-content-center">
                      <Spinner color="info"/>
                    </section>
                  }
                  {!selectedFile && !isLoading &&
                    <DropZoneCard
                      onDrop={onDrop}
                      errorMessage={errorMessage}
                      isDropContainer={true}
                      className={""}
                      fileMask={LOGO_FILE_MASK}
                    >
                      <section className="upload-container--section">
                        <p className="upload-container--section__cloud-upload-desc">Upload logo</p>
                        <p className="upload-container--section__desc mb-0">Drop a PNG, JPG or JPEG file.</p>
                        <p
                          className="upload-container--section__desc">Max {MAX_LOGO_FILE_SIZE / BYTES_IN_KILOBYTE / KILOBYTES_IN_MEGABYTE} Mb</p>
                      </section>
                    </DropZoneCard>
                  }
                  {selectedFile && !isLoading && !isUploading &&
                    <div>
                      <div
                        className="d-flex align-items-center justify-content-between p-4 selected-file-container formik-file-input">
                        <div className="d-flex align-items-center">
                          <img src={selectedFile.value} alt="Logo" className="formik-file-input-logo"/>
                          <div className="d-flex flex-column justify-content-between px-4">
                            <span
                              className="selected-file-container__selected-file-name mb-1">{selectedFile.file.name}</span>
                            <span className="font-semibold">
                                {(selectedFile.file.bytes / BYTES_IN_KILOBYTE).toFixed(2)} KB
                              </span>
                          </div>
                        </div>
                        <Icon icon="trashIcon" className="cursor-pointer ms-3" onClick={deleteLogo}/>
                      </div>
                    </div>
                  }

            <div className="d-flex justify-content-end mt-5 gap-3">
              <Button
                color={BUTTON_COLORS.primary}
                type="submit"
                disabled={!!Object.keys(errors).length || isSubmitting}
              >
                Save changes
              </Button>
            </div>
          </form>
        }
      </Formik>
    </Col>
  );
}