import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem
} from "reactstrap";

const ACCORDION_ITEM_ID = "1";

export function RelatedContentBlock({ relatedContents = [] }) {
  const [open, setOpen] = useState([ACCORDION_ITEM_ID]);
  const toggle = () => {
    setOpen(open.length ? [] : [ACCORDION_ITEM_ID]);
  };

  return <div className="bg-body">
    <Accordion flush open={open} toggle={toggle}>
      <AccordionItem
        id={ACCORDION_ITEM_ID}
      >
        <AccordionHeader
          className="bg-body-accordion-header border-0"
          targetId={ACCORDION_ITEM_ID}>
          <span className="text-dark font-weight-semibold">Related content</span>
        </AccordionHeader>

        <AccordionBody accordionId={ACCORDION_ITEM_ID}>
          {
            relatedContents.map((content, index) => (
                <div key={content} className="d-flex align-items-center mt-2 gap-1">
                  <div style={{ width: "20px" }}>{index + 1}.</div>
                  <div>{content}</div>
                </div>
              )
            )
          }
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  </div>;
}