import React from 'react';
import joinClassNames from "../../../../base/helpers/joinClassNames";
import * as sanitizeHtml from "sanitize-html";

const generateOutput = (html) => {
  return { dangerouslySetInnerHTML: { __html: sanitizeHtml(html) } }
};

export default function RichTextView({
  label,
  richText,
  containerClassName,
  Tabs
}){
  return (
    <section className={joinClassNames("d-flex flex-column", containerClassName)}>
      <label className="font-weight-semibold mb-1">{label}</label>
      {Tabs && Tabs}
      <p
        {...generateOutput(richText )}
        className="rich-text-p-tag text-secondary mb-0"/>
    </section>
  )
}