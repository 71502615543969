export const UI_CUSTOMISATION_ROUTES = {
  GENERAL: "/general",
  UI_SETTINGS: "/ui-settings/*",
  EMAILS: `/emails`,
  THIRD_PARTY_SERVICES: `/third-party-services`,
};

export const UI_SETTINGS_TABS = {
  SIGN_UP: "Sign up",
  LOG_IN: "Log in",
  HOMEPAGE_LAYOUT: "Homepage layout",
  BLOOD_BIOMARKER: "Blood Biomarker",
  DATE_PICKER: "Date picker"
}