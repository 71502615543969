import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";

import Title from "../../Biomarkers/Details/Title";
import BaseLayoutWithCard from "../../../../base/components/BaseLayoutWithCard";
import FormikInput from "../../../../base/components/FormikInput";
import Button from "../../../../base/components/Button";
import FormikReactSelect from "../../../../base/components/FormikReactSelect";

import HomePageLayoutService from "../../../../services/HomePageLayoutService";
import ToastService from "../../../../services/ToastService";
import { useService } from "../../../../base/hooks/useService";
import { useQueryString } from "../../../../base/hooks/useQueryString";
import joinClassNames from "../../../../base/helpers/joinClassNames";
import { FITNESS_GROUP_LINKS } from "../config";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";
import {
    initialValues, sectionTypeOptions,
    validationSchema
} from "./form";
import { sexOptions } from "../../HealthProgram/Nutrition/RulesGoalsList/GoalsList/CreateEditGoal/form";
import SectionFiltersDropdown
    from "../../../../base/components/Dropdowns/SectionFiltersDropdown/SectionFiltersDropdown";
import { WORKOUT_CONTENT_FORMAT, WORKOUT_CONTENT_FORMAT_LABELS } from "../WorkoutForm/constants";
import RadioButton from "../../../../base/components/RadioButton";
import { QUANTITY_TO_DISPLAY_PER_LINE } from "../constants";
import { map } from "lodash";
import MobilePreview from "../../../../base/components/MobilePreview/MobilePreview";


export function SectionForm() {
    /**
     * @type {HomePageLayoutService}
     */
    const homePageLayoutService = useService(HomePageLayoutService);
    /**
     * @type {ToastService}
     */
    const toastService = useService(ToastService);
    const { search: locationSearch } = useLocation();
    const navigate = useNavigate();

    const [isSubmitting, updateIsSubmitting] = useState(false);
    const [section, setSection] = useState(null);

    const {
        params: {
            editSectionId
        }
    } = useQueryString(locationSearch);

    const afterSuccess = () => {
        toastService.success("Section has been successfully saved");
        navigate(FITNESS_GROUP_LINKS.HOMEPAGE_LAYOUT_LIST);
        updateIsSubmitting(false);
    };

    const apiFunction = (section) => {
        if (editSectionId) {
            return homePageLayoutService.updateSection(editSectionId, section);
        }

        return homePageLayoutService.createSection(section);
    };

    const mapFormValuesToSection = ({ filters, ...otherValues }) => {
        return {
            filtersIds: map(filters, "id"),
            ...otherValues
        };
    };

    const mapSectionToForm = ({
                                  createdAt,
                                  updatedAt,
                                  type,
                                  id,
                                  position,
                                  isPredefined,
                                  isActive,
                                  workouts,
                                  fitnessPrograms,
                                  ...otherValues
                              }) => {

        return {
            ...otherValues,
            type: type.toString()

        };
    };

    const createSection = (values) => {
        updateIsSubmitting(true);

        apiFunction(mapFormValuesToSection(values))
            .then(afterSuccess).finally(() => updateIsSubmitting(false));
    };

    useEffect(() => {
        if (editSectionId) {
            homePageLayoutService.getSectionById(editSectionId)
                .then((data) => {
                    setSection(mapSectionToForm(data));
                });
        }
    }, [editSectionId]);


    const breadcrumbs = {
        title: editSectionId ? "Edit section" : "Add section",
        breadcrumbItems: [
            { title: "Fitness", link: FITNESS_GROUP_LINKS.HOMEPAGE_LAYOUT_LIST },
            { title: editSectionId ? "Edit section" : "Add section" }
        ]
    };

    return <BaseLayoutWithCard breadcrumbs={breadcrumbs}>
        <div className="w-100 d-flex">
            <div className="w-50">
                <Formik
                    initialValues={section || initialValues}
                    validationSchema={validationSchema}
                    validateOnBlur
                    onSubmit={createSection}
                    enableReinitialize
                >
                    {({ errors, handleSubmit, values, setFieldValue, setFieldTouched, touched }) => {
                        return <form
                            className={joinClassNames("form-horizontal p-2", isSubmitting && "pointer-events-none")}
                            onSubmit={handleSubmit}>
                            <div>
                                <div className="d-flex justify-content-between align-items-center mb-4">
                                    <Title
                                        title="General information"
                                    />
                                </div>
                                <section className="w-100">
                                    <FormikInput
                                        placeholder="Enter section title (required)"
                                        name="title"
                                        label="Section title"
                                        containerClassName="mt-3"
                                    />

                                    <FormikReactSelect
                                        options={sectionTypeOptions}
                                        name="type"
                                        setFieldValue={(...params) => {
                                            setFieldValue("filters", []);
                                            setFieldValue(...params);
                                        }
                                        }
                                        label="Type"
                                        containerClassName="mt-3"
                                        placeholder="Select type (required)"
                                        withError
                                    />

                                    <div className="mt-3">
                                        <SectionFiltersDropdown
                                            value={values.filters}
                                            onChange={value => setFieldValue("filters", value)}
                                            label="Filters"
                                            placeholder="Select filters (optional)"
                                            disabled={!values.type}
                                            type={values.type}
                                        />
                                    </div>

                                    <div className="mt-3">
                                        <label>Quantity to display per line</label>
                                        <div className="d-flex gap-3">
                                            {Object.values(QUANTITY_TO_DISPLAY_PER_LINE).map((quantity) => <RadioButton
                                                key={quantity}
                                                label={quantity}
                                                onChange={() => {
                                                    setFieldValue("quantityPerLine", quantity);
                                                }}
                                                checked={values.quantityPerLine === quantity}
                                                name="quantityPerLine"
                                            />)}
                                        </div>
                                    </div>

                                    <FormikInput
                                        placeholder="Enter quantity of items (required)"
                                        name="itemsQuantity"
                                        label="Quantity of items"
                                        containerClassName="mt-3"
                                        type="number"
                                    />

                                    <div className="d-flex justify-content-end mt-5">
                                        <Button
                                            color={BUTTON_COLORS.primary}
                                            type="submit"
                                            disabled={!!Object.keys(errors).length || isSubmitting}
                                        >
                                            Save section
                                        </Button>
                                    </div>
                                </section>
                            </div>
                        </form>;
                    }}
                </Formik>
            </div>
        </div>

    </BaseLayoutWithCard>;
}