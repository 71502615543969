export const MAX_RANGE_LENGTH = 10;

export const selectAllSex = {
  text: "Select both", id: "AllSex", className: ""
}

export const selectAllAge = {
  text: "Select all", id: "AllAge", className: ""
}

export const selectAllEthnicity = {
  text: "Select all", id: "AllEthnicity", className: ""
}

export const selectAllOther = {
  text: "Select all", id: "AllOther", className: ""
}

export const BULLET_LIST_TYPES_MAP = {
  low: 1,
  high: 2
}

export const GROUP_ITEM_TYPES_MAP = {
  low: 1,
  high: 2
}

export const GROUP_ITEM_TYPES_ARRAY = [null, "low", "high"]