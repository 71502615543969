import React, { useEffect } from 'react'
import RulesDropdown from "./RuleDropdown";
import get from "lodash.get";
import Button from "../../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../../base/components/Button/appearance";
import { FormikControlledInput } from "../../../../../base/components/FormikInput";
import { BLOOD_RULE_ID, DNAm_RULE_ID, MAX_RULE_NAME_LENGTH, SKIN_RULE_ID } from "../constants";
import { generateMapFromArray } from "../../../Recommendations/CreateEdit/constants";
import { useService } from "../../../../../base/hooks/useService";
import BiomarkersService, { DEFAULT_LIMIT_AND_OFFSET } from "../../../../../services/BiomarkersService";

export default function Rule({
  handleSelectRule,
  values,
  updateIsOpenDeleteModel,
  availableRules,
  updateAvailableRules,
  updateUsedRuleNames,
  availableRuleName,
  setFieldValue,
  updateAvailableRuleName,
  usedRuleNames,
  isRuleNameExists,
  isSkinCategory,
  isDNAmCategory,
}) {
  /**
   * @type {BiomarkersService}
   */
  const biomarkersService = useService(BiomarkersService);

  const getRuleType = () => {
    if(isDNAmCategory){
      return DNAm_RULE_ID;
    }
    if(isSkinCategory){
      return SKIN_RULE_ID;
    }
    return BLOOD_RULE_ID;
  }

  useEffect(() => {
    biomarkersService.getRules({
      ruleType: getRuleType(),
      ...DEFAULT_LIMIT_AND_OFFSET,
    }).then((rulesResponse) => {
      updateAvailableRules(rulesResponse.data);
      updateUsedRuleNames(generateMapFromArray(rulesResponse.data, "name", "name"))
    })
  }, [isSkinCategory, isDNAmCategory]);

  return (
    <>
      <section className="w-75 d-flex justify-content-between align-items-center">
        <RulesDropdown
          isRule
          label="Rule"
          value={get(values, "ruleName", "")}
          onChange={handleSelectRule}
          optionWithDelete
          onDeleteOption={(value) => {
            updateIsOpenDeleteModel(value);
          }}
          noOptionsText="No rules found"
          options={availableRules}
          withDelete={values.ruleName && (!availableRuleName)}
          onDelete={() => {
            updateIsOpenDeleteModel(values.ruleId);
          }}
          withReset={values.ruleName}
          onReset={() => {
            updateAvailableRuleName(false);
            if(!values.ruleId) {
              setFieldValue("ruleName", "")
              return;
            }
            setFieldValue("ruleName", undefined)
            setFieldValue("ruleId", undefined);
            setFieldValue("filters", []);
          }}
          containerClassName="rule-form-section w-100"
          selectedFilterIndex={values.ruleId}
        />
        <Button
          className="ms-3 align-self-end rule-form-add-new"
          color={BUTTON_COLORS.primary}
          onClick={() => updateAvailableRuleName(true)}
          disabled={availableRuleName}
        >
          + Add new rule
        </Button>
      </section>

      {availableRuleName &&
        <>
          <FormikControlledInput
            maxLength={MAX_RULE_NAME_LENGTH}
            name="ruleName"
            onChange={(value) => {
              setFieldValue("ruleName", value);
            }}
            placeholder="Add rule name"
            label="Add rule name"
            containerClassName="w-75 mt-3"
            id="rule-name"
            backendError={usedRuleNames[values.ruleName]}
          />
          {isRuleNameExists && <span className="font-size-10 text-danger">
                  Such rule name has already been exist. If you do not change the name it will not be created
                </span>}
        </>
      }
    </>
  )
}