import React, { useState } from "react";
import { useLocationQuery } from "../../../../../../base/hooks/useQueryString";
import { useHighlight } from "../../../../../../base/hooks/useHighlight";
import Icon from "../../../../../../base/components/Icon";
import TableSearch from "../../../../../../base/components/Table/tableSearch";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import Checkbox from "../../../../../../base/components/Checkbox";
import Button from "../../../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../../../base/components/Button/appearance";
import {
    RULE_ACTION_LABELS,
    RULES_STATUSES,
    RULES_ACTIONS,
    RULE_STATUSES_LABELS,
    RULE_ACTION_ICONS
} from "../constants";

export const RULES_TABLE_ACTIONS = {
    EDIT: 'edit',
    DELETE: 'delete'
};

const getArray = (itemOrArray) => {
    if (!itemOrArray) return [];
    return typeof itemOrArray === "object" ? itemOrArray : [itemOrArray]
}

export const TableFilter = ({ filterProvider }) => {
    const [isOpen, updateIsOpen] = useState(false);
    const { statusFilter: status, actionsFilter: action } = filterProvider;

    const [actionFilter, updateAction] = useState(getArray(action.getValue()));
    const [statusFilter, updateStatusFilter] = useState(getArray(status.getValue()));

    const submitFilters = () => {
        action.setValue(actionFilter);
        status.setValue(statusFilter);
        updateIsOpen(false);
    };

    const handleCheckboxChange = (id, setState) => {
        setState(prevState => {
            return prevState.includes(id) ? prevState.filter(item => item !== id) : [...prevState, id];
        });
    }

    return (
        <section className="d-flex align-items-center">
            <Dropdown
                isOpen={isOpen}
                toggle={() => updateIsOpen(prevState => !prevState)}
                className="d-inline-block"
            >
                <DropdownToggle
                    className="text-nowrap btn btn-outline-primary d-flex align-items-center"
                    tag="button"
                >
                    <i className="bx bx-filter-alt me-2"/> Add filter
                </DropdownToggle>

                <DropdownMenu className="filter-menu pb-1 px-1">
                    <div className="d-flex">
                        <section className="filter-options mx-1 custom-scrollbar">
                            <label className="text-secondary font-size-10 mb-1 font-weight-normal">BY ACTION</label>
                            {Object.values(RULES_ACTIONS).map((action, index) => {
                                return (
                                    <Checkbox
                                        id={status}
                                        text={RULE_ACTION_LABELS[action]}
                                        value={actionFilter?.includes(action)}
                                        onChange={() => handleCheckboxChange(action, updateAction)}
                                        key={index}
                                        className="my-2 ms-1"
                                    />
                                );
                            })}
                        </section>
                        <section className="filter-options mx-1 custom-scrollbar">
                            <label className="text-secondary font-size-10 mb-1 font-weight-normal">BY STATUS</label>
                            {Object.values(RULES_STATUSES).map((status, index) => {
                                return (
                                    <Checkbox
                                        id={status}
                                        text={RULE_STATUSES_LABELS[status]}
                                        value={statusFilter?.includes(status)}
                                        onChange={() => handleCheckboxChange(status, updateStatusFilter)}
                                        key={index}
                                        className="my-2 ms-1"
                                    />
                                );
                            })}
                        </section>
                    </div>


                    <section className="w-100 px-2 pb-2">
                        <Button
                            color={BUTTON_COLORS.primary}
                            onClick={submitFilters}
                            className="w-100"
                        >
                            Apply
                        </Button>
                    </section>

                </DropdownMenu>
            </Dropdown>

            {(!!status?.getValue() || !!action.getValue()) &&
                <Button
                    color={BUTTON_COLORS.transparent}
                    onClick={() => {
                        updateStatusFilter([]);
                        updateAction([]);
                        status?.setValue();
                        action?.setValue()
                    }}
                    className="text-danger no-border ms-2"
                >
                    Clear filter
                </Button>
            }
        </section>
    );
};

export const SearchPlaceholder = () => (
    <>
        No rules found.
    </>
);

export const NoRulesPlaceholder = () => (
    <div className="text-center">
        No rules for now.<br/>
        Click the “Add rule” option to add a new one.
    </div>
);
export const TableHeader = ({ searchProvider, filterProvider, headerActions }) => {

    return (
        <section className="d-flex w-100 pt-1 my-3 align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-4">
                <TableSearch
                    className="biomarkers-search"
                    search={searchProvider?.getValue()}
                    onSearch={searchProvider.setValue}
                    placeholder="Search rules..."
                />

                <TableFilter filterProvider={filterProvider}/>


            </div>
            <div className="d-flex gap-3">
                <Button
                    color={BUTTON_COLORS.primary}
                    className="text-nowrap d-flex align-items-center gap-2"
                    onClick={headerActions.goToCreate}
                >
                    <i className="bx bx-plus" /> Add rule
                </Button>
                <Button
                    color={BUTTON_COLORS.primaryOutline}
                    className="text-nowrap d-flex align-items-center gap-2"
                    onClick={headerActions.goToEditPrograms}
                >
                    <Icon icon="edit" className="button-icon"  /> Edit program
                </Button>
                <Button
                    color={BUTTON_COLORS.dangerOutline}
                    className="text-nowrap d-flex align-items-center gap-2"
                    onClick={headerActions.deleteProgram}
                >
                    <Icon icon="trashIcon" className="button-icon" /> Delete program
                </Button>
            </div>
        </section>
    );
};

export const columns = [
    {
        Header: "#",
        width: 35,
        Cell: (cellProps) => {
            const { params: { offset = 0 } } = useLocationQuery();
            return <label className="mb-0">{cellProps.row.index + 1 + (offset ?? 0)}</label>;
        }
    },
    {
        Header: "Name",
        accessor: "fullName",
        width: 200,
        canSort: true,
        Cell: ({ value }) => {
            const { params } = useLocationQuery();
            const { decorateText } = useHighlight(params.search);

            return (
                <section className="w-fit-content d-flex align-items-center">
                    <div {...decorateText(value)} className="text-truncate mb-0" style={{ maxWidth: "33vw" }}/>
                </section>
            );
        }
    },
    {
        Header: "Actions",
        accessor: "actionType",
        width: 200,
        Cell: ({ value }) => <div className="d-flex align-items-center gap-2">
            <Icon icon={RULE_ACTION_ICONS[value]} /> <span>{RULE_ACTION_LABELS[value]}</span>
        </div>
    },
    {
        Header: "User segment",
        accessor: "programRuleSegments",
        width: 500,
        canSort: true,
        Cell: ({ value }) => {

            return <div>
                {(value?.data || []).map(({segment}) => segment?.fullName).join(', ')}
            </div>
        }
    },
    {
        Header: "Actions",
        width: 50,
        Cell: ({
                   row: {
                       original: { id }
                   },
                   actions
               }) => {

            return <section className="d-flex gap-3 align-items-center">
                <Icon icon="edit" width={20} height={20} className="me-4 cursor-pointer"
                      onClick={(event) => {
                          event.stopPropagation();
                          actions[RULES_TABLE_ACTIONS.EDIT](id);
                      }}
                />
                <Icon icon="trashIcon" width={20} height={20} className="me-4 cursor-pointer"
                      onClick={(event) => {
                          event.stopPropagation();
                          actions[RULES_TABLE_ACTIONS.DELETE](id);
                      }}
                />
            </section>;
        }
    }
];