export const ATTRIBUTE_TYPES = {
    TERM: 1,
    BOOLEAN: 2,
    NUMBER: 3,
    NUMBER_RANGE: 4
}

export const ATTRIBUTE_TYPE_LABELS = {
    [ATTRIBUTE_TYPES.TERM]: "Term",
    [ATTRIBUTE_TYPES.BOOLEAN]: "True or false",
    [ATTRIBUTE_TYPES.NUMBER]: "Number",
    [ATTRIBUTE_TYPES.NUMBER_RANGE]: "Number range"
}
