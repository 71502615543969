
export const MIN_STRING_LENGTH = 1
export const MAX_NAME_LENGTH = 100
export const MAX_SECTION_NAME_LENGTH = 100

export const MAX_REPETITIONS = 300

export const MIN_REPETITIONS = 0

export const MIN_REST = 0

export const MAX_OVERVIEW = 1000

export const ALLOWED_EXTENTIONS_FILE = ["png", "jpeg", "jpg"];
export const ERROR_ALLOWED_TYPE_MESSAGE = "Invalid format. Choose a PNG, JPG, JPEG image.";

export const ALLOWED_SYMBOLS_REGEX = /^[a-zA-Z0-9' _]*$/;
export const ALLOWED_SYMBOLS_SECTION_NAME_REGEX = /^[a-zA-Z0-9-_ ]*$/

export const ALLOWED_SYMBOLS_MESSAGE = 'Text must contain only letters, numbers, spaces, apostrophes, and underscores';

export const WORKOUT_LEVEL = {
    BEGINNER: 1,
    INTERMEDIATE: 2,
    ADVANCED: 3,
}

export const WORKOUT_LEVEL_LABEL = {
    [WORKOUT_LEVEL.BEGINNER]: "Beginner",
    [WORKOUT_LEVEL.INTERMEDIATE]: "Intermediate",
    [WORKOUT_LEVEL.ADVANCED]: "Advanced"
}

export const WORKOUT_STATUSES = {
    ACTIVE: 1,
    INACTIVE: 2
}

export const WORKOUT_STATUSES_LABELS = {
    [WORKOUT_STATUSES.ACTIVE]: "Active",
    [WORKOUT_STATUSES.INACTIVE]: "Inactive"
}

export const WORKOUT_CONTENT_FORMAT = {
    BASIC_WORKOUT: 1,
    PRO_WORKOUT: 2,
}

export const WORKOUT_CONTENT_FORMAT_LABELS = {
    [WORKOUT_CONTENT_FORMAT.BASIC_WORKOUT]: "Basic workout",
    [WORKOUT_CONTENT_FORMAT.PRO_WORKOUT]: "Pro workout"
}

export const WORKOUT_DESCRIPTION_TYPE = {
    WORKOUT_SET_UP: 1,
    WORKOUT_VIDEO: 2,
}

export const WORKOUT_DESCRIPTION_TYPE_LABELS = {
    [WORKOUT_DESCRIPTION_TYPE.WORKOUT_SET_UP]: "Workout set-up",
    [WORKOUT_DESCRIPTION_TYPE.WORKOUT_VIDEO]: "Workout video"
}

export const WORKOUT_SECTION_TOPIC_TYPES = {
    EXERCISE: '0',
    SUPERSET: '1',
    CIRCUIT: '2'
};

export const WORKOUT_SECTION_TOPIC_TYPES_LABELS = {
    [WORKOUT_SECTION_TOPIC_TYPES.SUPERSET]: 'Superset',
    [WORKOUT_SECTION_TOPIC_TYPES.CIRCUIT]: 'Circuit',
    [WORKOUT_SECTION_TOPIC_TYPES.EXERCISE]: 'Exercise'
};

export const WORKOUT_INTENSITY = {
    LOW: '1',
    MEDIUM: '2',
    HARD: '3'
};

export const WORKOUT_INTENSITY_LABEL = {
    [WORKOUT_INTENSITY.LOW]: 'Low',
    [WORKOUT_INTENSITY.MEDIUM]: 'Medium',
    [WORKOUT_INTENSITY.HARD]: 'Hard'
};
