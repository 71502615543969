import Http from "./Http";

class RoboGPService extends Http {
  static $displayName = "RoboGPService";

  getNotification(params) {
    return this.get("/robogp/notifications", { params });
  }

  hideNotification() {
    return this.put("/robogp/notifications");
  }

  getReportsList() {
    return this.get("/robogp/");
  }

  getReportItem(id, params) {
    return this.get(`/robogp/${id}`, { params });
  }
}

export default RoboGPService;
