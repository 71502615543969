
export const MIN_STRING_LENGTH = 1
export const MAX_PROGRAM_NAME_LENGTH = 100

export const MAX_PROGRAM_SHORT_DESCRIPTION = 255
export const MAX_PROGRAM_LONG_DESCRIPTION = 2000

export const HTML_TAGS_REGEX = /<\/?[^>]+(>|$)/g;

export const MAX_WHY_SYSTEM_CAN_RECOMMEND_DESCRIPTION = 300;

export const MIN_USER_SEGMENTS_ITEMS_LENGTH = 1

export const ALLOWED_EXTENTIONS_FILE = ["png", "jpeg", "jpg"];
export const ERROR_ALLOWED_TYPE_MESSAGE = "Invalid format. Choose a PNG, JPG, JPEG image.";

export const ENERGY_EQUATION = {
    HARIS_BENEDICT: 1,
    ONCOLOGY: 2
}

export const ENERGY_EQUATION_LABEL = {
    [ENERGY_EQUATION.HARIS_BENEDICT]: "Harris Benedict",
    [ENERGY_EQUATION.ONCOLOGY]: "Oncology"
}