import React from 'react';
import { Container } from 'reactstrap';
import { Route, Routes } from 'react-router-dom';

import List from "./List";
import { FitnessProgramForm, WellnessProgramForm } from "./Form";

import { WELLNESS_GROUP_ROUTES } from './config';
import FitnessProgramDetails from "./Details";

export default function Wellness() {

    return (
        <Container fluid className="content">
            <Routes>
                <Route
                    path={WELLNESS_GROUP_ROUTES.LISTS}
                    element={<List/>}
                />

                <Route
                    path={WELLNESS_GROUP_ROUTES.FORM}
                    element={<WellnessProgramForm/>}
                />

              <Route
                path={WELLNESS_GROUP_ROUTES.DETAILS}
                element={<FitnessProgramDetails/>}
              />

            </Routes>
        </Container>
    );
}