
export const APP_GROUP_ROUTES = {
    BASE: '/app/*',
    DASHBOARD: `/dashboard/*`,
    BIOMARKERS: `/biomarkers/*`,
    FOOD_INTOLERANCE: `/food-intolerance/*`,
    RECOMMENDATIONS: `/recommendations/*`,
    CUSTOMER_MANAGEMENT: `/customer-management/*`,
    CUSTOMER_RESULTS: `/customer-results/*`,
    CONTENT_PLAYGROUND: '/content-playground/*',
    FOOD_AND_DIET: '/food-and-diet/*',
    PROGRAMS: '/programs/*',
    USER_SEGMENTS: '/user-segments/*',
    FITNESS: '/fitness/*',
    HEALTH_PROGRAM: '/health-program/*',
    MEALPLAN: '/mealplan/*',
    UI_CUSTOMISATION: '/ui-customisation/*',
    ORGANISATIONS: '/organisations',
    CONTENTS: '/contents/*'
};

export const APP_GROUP_LINKS = {
    BASE: '/app',
    DASHBOARD: `/app/dashboard`,
    BIOMARKERS: `/app/biomarkers`,
    RECOMMENDATIONS: `/app/recommendations`,
    CUSTOMER_MANAGEMENT: `/app/customer-management`,
    CUSTOMER_RESULTS: `/app/customer-results`,
    CONTENT_PLAYGROUND: '/app/content-playground',
    PROGRAMS: '/app/programs',
    FITNESS: '/app/fitness',
    MEALPLAN: '/app/mealplan',
    UI_CUSTOMISATION: '/app/ui-customisation',
    HEALTH_PROGRAM: '/app/health-program/',
    ORGANISATIONS: '/app/organisations',
    CONTENTS: '/app/contents'
};
