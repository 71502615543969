import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { CUSTOMER_MANAGEMENT_LINKS } from "../config";
import BaseLayoutWithCard from "../../../../base/components/BaseLayoutWithCard";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useService } from "../../../../base/hooks/useService";
import UsersService from "../../../../services/UsersService";
import { useLoading } from "../../../../base/hooks/useLoading";
import Avatar from "../../../../base/components/Avatar";
import { Formik } from "formik";
import { validationSchema } from "./formValidation";
import { FormHeader, UserResult } from "./components";
import BiomarkersService, { DEFAULT_LIMIT_AND_OFFSET } from "../../../../services/BiomarkersService";
import PlusButtonWithTooltip from "../../../../base/components/PlusButtonWithTooltip";
import Button from "../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";
import ConfirmPopup from "../../../../base/components/ConfirmPopup";
import ToasterService from "../../../../services/ToastService";
import { DEFAULT_DATE_FORMAT_FOR_BACKEND, formatJSDate } from "../../../../base/helpers/date";
import { deleteItemFromObject } from "../../../../base/helpers/object";
import { useDebounce } from "../../../../base/hooks/useDebounce";
import { useQueryString } from "../../../../base/hooks/useQueryString";
import { BiomarkerCategoriesByRule, BiomarkerTypes } from "../../Biomarkers/CreateEdit/constants";

const getBiomarkerError = (name) => `${name} result has already been recorded for the Customer. You can not upload more than one result for each biomarker per customer on the same day. `

const breadcrumbs = {
  title: "Results page",
  breadcrumbItems: [{ title: "Customer management", link: CUSTOMER_MANAGEMENT_LINKS.BASE }, { title: "Results page " }]
}

export default function UploadManually() {
  /**
   * @type {UsersService}
   */
  const usersService = useService(UsersService);
  /**
   * @type {BiomarkersService}
   */
  const biomarkersService = useService(BiomarkersService);
  /**
   * @type {ToasterService}
   */
  const toastService = useService(ToasterService);

  const [isLoading, { registerPromise }] = useLoading(true);
  const [isSearchLoading, { registerPromise: registerPromiseSearch }] = useLoading(true);

  const { id } = useParams();
  const navigate = useNavigate();

  const [showDeletePopup, updateShowDeletePopup] = useState(false);
  const [showCancelPopup, updateShowCancelPopup] = useState(false);
  const [showConfirmPopup, updateShowConfirmPopup] = useState(false);
  const [userResults, updateUserResults] = useState([]);
  const [unitOptions, updateUnitOptions] = useState([]);
  const [user, updateUser] = useState({});
  const [isSaving, updateIsSaving] = useState(false);
  const [labName, setLabName] = useState("");

  const { search: locationSearch } = useLocation();
  const {
    params: {
      pdfFileId
    }
  } = useQueryString(locationSearch);
  const saveResults = ({ date, results }, setStatus) => {
    updateIsSaving(true);
    const dateToSend = formatJSDate(date, DEFAULT_DATE_FORMAT_FOR_BACKEND);
    const values = results.map((item) => {
      return {
        biomarkerId: item.biomarker.id,
        value: parseFloat(item.value),
        date: dateToSend,
        unitId: item.unit.id
      }
    })

    const payload = {
      results: values
    }

    if (pdfFileId) {
      payload.pdfFileId = pdfFileId;
      payload.resultAt = dateToSend;
      payload.lab = labName;
    }

    usersService.createUserResults(id, payload).then(() => {
      toastService.success("Results were successfully uploaded to the customer profile.")
      navigate(CUSTOMER_MANAGEMENT_LINKS.LIST);
    }).catch(() => {
      const currentDateResults = userResults.filter(({ date: resultDate }) => resultDate === dateToSend)

      const errors = results.reduce((prevValue, { biomarker: { id, name } }) => {
        if (currentDateResults.some(({ biomarkerId }) => biomarkerId === id)) {
          toastService.error(getBiomarkerError(name))
          return {
            ...prevValue,
            [id]: true
          }
        }
        return prevValue;
      }, {});

      setStatus(errors)
    }).finally(() => {
      updateIsSaving(false);
    })
  }

  const getUsersData = useCallback(() => {
    registerPromise(Promise.all([
      usersService.getUsersResults(id),
      biomarkersService.getUnits(),
      usersService.getUserById(id),
    ])
      .then(([
        { data },
        unitsResponse,
        userData = {}
      ]) => {
        updateUserResults(data);
        updateUnitOptions(unitsResponse.units.map(({ id, unit }) => ({ id, value: unit, label: unit })))
        updateUser(userData)
      }))
  }, [id]);

  useEffect(() => {
    getUsersData();
  }, [getUsersData])


  const [biomarkers, updateBiomarkers] = useState([]);

  const [searchInSelect, updateSearchInSelect] = useState("")
  const [debouncedSearchInSelect] = useDebounce(searchInSelect);

  const getBiomarkers = useCallback(() => {
    registerPromiseSearch(biomarkersService.getBiomarkers({
      ...DEFAULT_LIMIT_AND_OFFSET,
      search: debouncedSearchInSelect,
      onlyActive: true,
    }).then(({ data }) => {
      const onlyBloodBiomarkers = data.filter(biomarker => BiomarkerCategoriesByRule[BiomarkerTypes.bloodRule].includes(biomarker.category.name));
      updateBiomarkers(onlyBloodBiomarkers);
    }));
  }, [debouncedSearchInSelect]);

  useEffect(() => {
    getBiomarkers()
  }, [getBiomarkers]);

  return (
    <BaseLayoutWithCard breadcrumbs={breadcrumbs} loading={isLoading} cardClassName="upload-manually__container">
      <section className="d-flex align-items-center">
        <Avatar avatar={user.avatar} firstName={user.firstName} className="upload-manually__avatar"/>
        <label className="mb-0 ms-3">{user.firstName} {user.lastName}</label>
      </section>

      <Formik
        initialValues={{ results: [{}], date: new Date() }}
        validationSchema={validationSchema}
        validateOnBlur
        initialStatus={{}}
        onSubmit={() => {
          updateShowConfirmPopup(true)
        }}
      >
        {({
          errors,
          handleSubmit,
          values,
          setFieldValue,
          setFieldTouched,
          status,
          setStatus,
        }) => {

          const isAllSelected = !!values.results?.length && values.results?.every(({ selected }) => selected);

          const initialDisabled = !!Object.keys(errors).length || (!!values.results.length && !values.results[0]?.biomarker);

          const selectOptions = useMemo(() => {
            if (!values.results.length) return biomarkers;
        
            const resultsIds = values.results.reduce((prevValue, { biomarker }) => {
              if(!biomarker) return prevValue;
              return {
                ...prevValue,
                [biomarker.id]: true,
              }
            }, {})

            return biomarkers.filter(({ id }) => !resultsIds[id]);
          }, [biomarkers, values.results])

          return (
            <form className="form-horizontal" onSubmit={handleSubmit}>

              {showDeletePopup &&
                <ConfirmPopup
                  isOpen={showDeletePopup}
                  updateIsOpen={updateShowDeletePopup}
                  onSubmit={() => {
                    setFieldValue("results", values.results.filter(({ selected, biomarkerId }) => {
                      if (selected) {
                        setStatus(deleteItemFromObject(status, biomarkerId))
                      }
                      return !selected
                    }))
                  }}
                  title="Delete results"
                  description="Are you sure you want to delete the chosen biomarker(s)?"
                  submitBtnText="Delete"
                  className="upload-manually__popup"
                />
              }

              {showCancelPopup &&
                <ConfirmPopup
                  isOpen={showCancelPopup}
                  updateIsOpen={updateShowCancelPopup}
                  onSubmit={() => {
                    navigate(CUSTOMER_MANAGEMENT_LINKS.LIST)
                  }}
                  title="Cancel"
                  description="Are you sure you want to cancel adding the results?"
                  submitBtnText="Confirm"
                  className="upload-manually__popup"
                />
              }

              {showConfirmPopup &&
                <ConfirmPopup
                  isOpen={showConfirmPopup}
                  updateIsOpen={updateShowConfirmPopup}
                  onSubmit={() => {
                    saveResults(values, setStatus)
                  }}
                  title="Confirmation"
                  description="Are you sure you want to confirm entered results? Please be sure that entered data is correct."
                  submitBtnText="Confirm"
                  className="upload-manually__popup"
                />
              }

              <FormHeader
                handleSelectAll={() => {
                  setFieldValue("results", values.results.map((item) => ({ ...item, selected: !isAllSelected })))
                }}
                dateOnChange={(date) => setFieldValue("date", date)}
                date={values.date}
                isAllSelected={isAllSelected}
                isAnySelected={values.results.some(({ selected }) => selected)}
                onDelete={() => {
                  updateShowDeletePopup(true)
                }}
                pdfFileId={pdfFileId}
                setLabName={setLabName}
              />

              {values.results.map((result = {}, index) => {
                return (
                  <UserResult
                    {...result}
                    key={index}
                    index={index}
                    setFieldValue={setFieldValue}
                    keyForResult={`results[${index}]`}
                    unitOptions={unitOptions}
                    options={selectOptions}
                    setFieldTouched={setFieldTouched}
                    status={status}
                    setStatus={setStatus}
                    searchInSelect={searchInSelect}
                    onUpdateSearchInSelect={updateSearchInSelect}
                    isLoading={isSearchLoading}
                  />
                )
              })}

              <section className="w-100 d-flex align-items-center justify-content-between mt-3">
                <PlusButtonWithTooltip
                  id="AddMore"
                  disabled={initialDisabled}
                  onClick={() => {
                    setFieldValue("results", [...values.results, {}])
                  }}
                  buttonText="Add 1 more result"
                  showTooltip={false}
                />

                <section className="d-flex align-items-center">
                  <Button
                    color={BUTTON_COLORS.primaryOutline}
                    disabled={isSaving}
                    onClick={() => updateShowCancelPopup(true)}
                  >
                    Cancel
                  </Button>
                  <Button
                    color={BUTTON_COLORS.primary}
                    className="ms-3"
                    disabled={isLoading || isSaving || initialDisabled || !values.results.length}
                    type="submit"
                  >
                    Analyse
                  </Button>
                </section>
              </section>
            </form>
          )
        }}
      </Formik>
    </BaseLayoutWithCard>
  )
}