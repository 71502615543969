import React from "react";
import { useNavigate } from "react-router-dom";
import { CardBody, Card } from "reactstrap";
import Logo from '../../components/Logo';
import AuthHeader from '../../components/Header';
import AuthCardContainer from '../../components/AuthCardContainer';
import { AUTH_GROUP_LINKS } from "../../config";

const Verification = () => {
  const navigate = useNavigate();
  return (
    <AuthCardContainer metaText="Verification">
      <Card className="overflow-hidden">
        <AuthHeader headerText="Expired link" className="auth-header"/>
        <CardBody className="pt-0 text-center">
          <Logo/>

          <p className="mb-0 font-weight-500 mt-4">
            The link you are following is expired.
          </p>
          <p className="font-weight-500">
            Try to request a new one.
          </p>
          <button
            className="btn btn-primary w-100"
            onClick={() => navigate(AUTH_GROUP_LINKS.LINK_FORGOT_PASSWORD)}
          >
            Forgot password
          </button>
        </CardBody>
      </Card>
    </AuthCardContainer>
  )
};

export default Verification;
