import TableSearch from "../../../../base/components/Table/tableSearch";
import React, { useEffect, useMemo, useState } from "react";
import Button from "../../../../base/components/Button";
import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import { useHighlight } from "../../../../base/hooks/useHighlight";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import Checkbox from "../../../../base/components/Checkbox";
import { ORGANISATION_STATUSES, ORGANISATION_STATUSES_LABELS } from "./constants";
import { DateTime } from "luxon";
import { formatISODate, transformJsDateToIso } from "../../../../base/helpers/date";
import { DateRangePicker } from "../../../../base/components/DateRangePicker";
import joinClassNames from "../../../../base/helpers/joinClassNames";
import Icon from "../../../../base/components/Icon";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";


export const SearchPlaceholder = () => (
    <>
        No organisations found
    </>
);

export const NoOrganisationPlaceholder = () => (
    <div className="text-center">
        No organisations for now.<br/>
    </div>
);

export const TableFilterByStatus = ({ filterProvider }) => {
    const [isOpen, updateIsOpen] = useState(false);
    const currentValue = useMemo(() => filterProvider?.getValue(), [filterProvider?.getValue()]);

    const initialValue = useMemo(() => {
        return currentValue ? (typeof currentValue === "object" ? currentValue : [currentValue]) : [];
    }, [currentValue])

    const [statuses, updateStatuses] = useState(initialValue ?? []);

    const submitFilters = () => {
        filterProvider.setValue(statuses);
        updateIsOpen(false);
    };

    const handleCheckboxChange = (id) => {
        updateStatuses(prevState => {
            return prevState.includes(id) ? prevState.filter(item => item !== id) : [...prevState, id];
        });
    };

    useEffect(() => {
        updateStatuses(initialValue)
    }, [initialValue]);

    return (
        <section className="d-flex align-items-center">
            <Dropdown
                isOpen={isOpen}
                toggle={() => updateIsOpen(prevState => !prevState)}
                className="d-inline-block"
            >
                <DropdownToggle
                    className="text-nowrap btn btn-outline-primary d-flex align-items-center"
                    tag="button"
                >
                    <i className="bx bx-filter-alt me-2"/> Filter by status
                </DropdownToggle>

                <DropdownMenu className="filter-menu pb-1 px-1">

                    <section className="filter-options mx-1 custom-scrollbar">
                        {Object.values(ORGANISATION_STATUSES).map((status, index) => {
                            return (
                                <Checkbox
                                    id={status}
                                    text={ORGANISATION_STATUSES_LABELS[status]}
                                    value={statuses?.includes(status)}
                                    onChange={() => handleCheckboxChange(status)}
                                    key={index}
                                    className="my-2 ms-1"
                                />
                            );
                        })}
                    </section>

                    <section className="w-100 px-2 pb-2">
                        <Button
                            color={BUTTON_COLORS.primary}
                            onClick={submitFilters}
                            className="w-100"
                            disabled={!statuses.length}>
                            Apply
                        </Button>
                    </section>

                </DropdownMenu>
            </Dropdown>
        </section>
    );
};

export const ThreeDotDropdown = ({ isOpen, updateIsOpen, options, onSelect }) => {
    return (
        <Dropdown isOpen={isOpen} toggle={() => updateIsOpen(prevState => !prevState)}
                  className="d-inline-block filter-dropdown ms-2">
            <DropdownToggle
                className={joinClassNames('btn btn-transparent no-border no-outline w-fit-content p-0', isOpen && '')}
                tag="button">
                <Icon icon="threeDots" className=""/>
            </DropdownToggle>
            <DropdownMenu end className="three-dot-dropdown w-150 ">
                {options.map(({ label, icon, type, id, disabled, className }) => {
                    return (
                        <DropdownItem disabled={disabled === true}
                                      className={classnames("three-dot-dropdown__item w-100", className || '')} key={id}
                                      onClick={() => onSelect(type)}>
                            <Icon icon={icon} className="me-2 three-dot-dropdown__icon"/>
                            <p className="mb-0">{label}</p>
                        </DropdownItem>
                    );
                })}
            </DropdownMenu>
        </Dropdown>
    );
};


export const TableHeader = ({ searchProvider, filterProvider, dateRangeProviders = {} }) => {
    const navigate = useNavigate();

    const goToCreate = () => {
        // navigate(ORGANISATION_GROUP_LINKS.CREATE_EDIT);
    };
    const hasFilter = !!filterProvider?.getValue();

    return (
        <section className="d-flex w-100 pt-1 mb-3 align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-4">
                <TableSearch
                    className="biomarkers-search"
                    search={searchProvider?.getValue()}
                    onSearch={searchProvider.setValue}
                    placeholder="Search organisation name or domain"
                />
            </div>
            <div className="d-flex gap-4">
                <TableFilterByStatus filterProvider={filterProvider}/>
                {hasFilter &&
                    <div className="d-flex align-items-center gap-3 me-4">
                        <Button
                          color={BUTTON_COLORS.transparent}
                          onClick={() => {
                              filterProvider?.setValue();
                          }}
                          className="text-secondary no-border pe-0 text-nowrap my-0 py-0"
                        >
                            Clear
                        </Button>
                    </div>
                }
            </div>
        </section>
    );
};

export const columns = [
    {
        Header: "#",
        width: 35,
        Cell: (cellProps) => {
            const { params: { offset = 0 } } = useLocationQuery();
            return <label className="mb-0">{cellProps.row.index + 1 + (offset ?? 0)}</label>;
        }
    },
    {
        Header: "Name",
        accessor: "name",
        canSort: true,
        Cell: ({ value }) => {
            const { params } = useLocationQuery();
            const { decorateText } = useHighlight(params.search);

            return (
                <section className="w-fit-content d-flex align-items-center">
                    <div {...decorateText(value)} className="text-truncate mb-0" style={{ maxWidth: "33vw" }}/>
                </section>
            );
        }
    },
    {
        Header: "Domain",
        accessor: "domain",
        canSort: true,
        Cell: ({ value }) => <span>{value}</span>
    },
    {
        Header: "Active",
        accessor: "status",
        width: 100,
        canSort: true,
        Cell: ({ value }) => <div className={joinClassNames(
            value === ORGANISATION_STATUSES.ACTIVE ? "active-pill" : "inactivated-pill",
            "w-fit-content"
        )}>
            {ORGANISATION_STATUSES_LABELS[value]}
        </div>
    },
    {
        Header: "Actions",
        width: 60,
        Cell: ({
                   row: {
                       original: { id, status, ...rest }
                   },
                   actions
               }) => {


            return <div onClick={(event) => {
                event.stopPropagation();
            }}>

            </div>;
        }
    }
];