import React, { useState } from 'react';
import { getThumbnailImage } from "../../../../helpers";

export const RecipeCard = ({ recipe }) => {
    const [imageError, setImageError] = useState(false);
    const food = recipe.foods?.[0];

    const handleError = () => {
        setImageError(true);
    };

    const calories = Math.round(food?.nutritionalInfo?.kcal?.value || 0);
    const numberOfServings = food?.servingSize?.numberOfServings || 0;
    const servingUnit = food?.servingSize?.unit || 'serving';

    if (!food) {
        return <Placeholder />
    }

    let thumbUrl = getThumbnailImage(food.imageUrl, 300)

    return (
        <div className="w-100 meal-plan-card">
            {!imageError && thumbUrl ? (
                <img
                    className="food-image"
                    src={thumbUrl}
                    alt={`photo ${food.foodName}`}
                    onError={handleError}
                />
            ) : (
                <div className="bg-body food-image d-flex align-items-center justify-content-center font-weight-semibold text-secondary">
                    No photo
                </div>
            )}

            <div className="food-title">
                {food.foodName}
            </div>

            <div className="food-info">
                <div>
                    {`${numberOfServings} ${servingUnit}`}
                </div>

                <div>
                    {calories} kcal
                </div>
            </div>
        </div>
    );
};

const Placeholder = () => {
    return <div className="w-100 meal-plan-card d-flex align-items-center justify-content-center font-weight-500 text-xxl-center">
        N/A
    </div>
}