import React from "react";
import { Section } from "../conponents/Section";

export const HomepagePreview = ({ sections }) => {

    return <div className="bg-white px-1">
        {
            sections.map((section) => {
                return <div key={sections.id} className="mb-4">
                    <Section section={section} />
                </div>;
            })
        }
    </div>;
};