import React, { useState } from "react";
import { CardBody, Card } from "reactstrap";
import { useNavigate } from "react-router-dom";
import FormikInput from "../../../../base/components/FormikInput";
import { Formik } from "formik";
import AuthCardContainer from "../../components/AuthCardContainer";
import Logo from "../../components/Logo";
import AuthHeader from "../../components/Header";
import { useService } from "../../../../base/hooks/useService";
import AuthService from "../../../../services/AuthService";
import { useLoading } from "../../../../base/hooks/useLoading";
import StorageService from "../../../../services/StorageService";
import { KEY_USER } from "../../../../base/constants/storage";
import Button from "../../../../base/components/Button/index";
import { BUTTON_COLORS, BUTTON_STYLES } from "../../../../base/components/Button/appearance";
import ToasterService from "../../../../services/ToastService";
import { initialValues, LENGTH_OF_EMAIL_CODE, validationSchema } from "./form";
import { ONLY_NUMBERS_PATTERN } from "../../../../base/constants/patterns";
import Countdown from "../../../../base/components/Countdown";
import { AUTH_GROUP_LINKS } from "../../config";

export const COLUMN_WIDTH_FOR_BIG_TEXT = 10;

export const MFA_METHODS = {
  mobile: 1,
  email: 2
}

export const EMAIL_CODE_ERRORS = [
  "CODE_INVALID",
  "CODE_IS_EXPIRED",
];

const MfaVerification = () => {
  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);
  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);
  /**
   * @type {ToasterService}
   */
  const toastService = useService(ToasterService);

  const navigate = useNavigate();

  const [isLoading, { registerPromise }] = useLoading();
  const [requestError, setRequestError] = useState(false);
  const [isTimerActive, updateIsTimerActive] = useState(true);

  const userData = storage.get(KEY_USER, {})

  const onSubmit = (code) => {
    registerPromise(
      authService.confirmMfaVerification(code)
        .then(() => {
          navigate(AUTH_GROUP_LINKS.EXTERNAL.AFTER_LOGIN)
        })
        .catch((error) => {
          if(EMAIL_CODE_ERRORS.includes(error.errorCode)) {
            setRequestError(true)
            updateIsTimerActive(false)
            return
          }
          toastService.error(error.message)
        })
    )
  }

  const resendCode = () => {
    updateIsTimerActive(true);
    registerPromise(
      authService.resendMfaCode({
        authenticationMethod: MFA_METHODS.email
      })
    )
  }

  return (
    <AuthCardContainer metaText="Login">
      <Card className="overflow-hidden">
        <AuthHeader
          headerText="Multi-Factor Authentication"
          className="auth-header"
          columnWidth={COLUMN_WIDTH_FOR_BIG_TEXT}
        />

        <CardBody className="pt-0">
          <Logo/>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnBlur
            validateOnMount
            onSubmit={onSubmit}
          >
            {({ errors, handleSubmit }) => (
              <form className="form-horizontal p-2" onSubmit={handleSubmit}>

                <p className="text-secondary mb-0">
                  Please, enter a code that was sent to your email
                </p>

                <p className="font-weight-semibold">
                  {userData.email}
                </p>

                <FormikInput
                  id="code"
                  name="code"
                  label="Enter code"
                  containerClassName="relative"
                  placeholder="000000"
                  backendError={requestError}
                  backendErrorText="Please enter a valid code"
                  type="text"
                  format={(value = "") => value.slice(0, LENGTH_OF_EMAIL_CODE).replace(ONLY_NUMBERS_PATTERN, "")}
                  maxLength={LENGTH_OF_EMAIL_CODE}
                  afterOnChange={() => {
                    setRequestError(false)
                  }}
                />

                <Button
                  color={BUTTON_COLORS.primary}
                  type="submit"
                  block={BUTTON_STYLES.block}
                  loading={isLoading}
                  className="mt-3"
                  disabled={isLoading || !!Object.keys(errors).length || requestError}
                >
                  Confirm
                </Button>

                <Button
                  color={BUTTON_COLORS.primaryOutline}
                  type="button"
                  block={BUTTON_STYLES.block}
                  className="mt-2"
                  onClick={() => {
                    storage.clear();
                    navigate(AUTH_GROUP_LINKS.LINK_LOGIN)
                  }}
                >
                  Cancel
                </Button>

                <div className="mt-4 text-center">
                  <label
                    className="text-secondary mb-0 font-weight-normal"
                  >
                    Resend a code in <Countdown isTimerActive={isTimerActive} updateIsTimerActive={updateIsTimerActive}/>.
                  </label>
                  <Button
                    className="ml-2"
                    color={BUTTON_COLORS.link}
                    type="button"
                    disabled={isLoading || isTimerActive}
                    onClick={resendCode}
                  >
                    Resend
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </AuthCardContainer>
  );
};

export default MfaVerification;
