import Http from "./Http";

class DNAmAgeService extends Http {
  static $displayName = "DNAmAgeService";

  async sendUploadedFileId(fileId) {
    return await this.post(`/admins/dna-age/files`, { fileId });
  }
}

export default DNAmAgeService;
