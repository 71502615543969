import yup from "../../../../validation/yup";
import {
    ALLOWED_EXTENTIONS_FILE,
    ALLOWED_SYMBOLS_REGEX,
    MIN_STRING_LENGTH,
    ERROR_ALLOWED_TYPE_MESSAGE,
    EXERCISE_TYPES_LABEL,
    MAX_TITLE_LENGTH,
    ALLOWED_SYMBOLS_MESSAGE,
    EXERCISE_STATUSES,
    EXERCISE_STATUSES_LABELS,
    EXERCISE_CONTENT_FORMAT,
    EXERCISE_TYPES,
    ALLOWED_SYMBOLS_REGEX_INSTRUCTION, ALLOWED_SYMBOLS_MESSAGE_INSTRUCTION
} from "./constants";
import {
    ERROR_SIZE_MESSAGE,
    isAllowedExtension,
    MAX_FILE_SIZE
} from "../../../../base/components/Dropzone";
import { URL_PATTERN, VIMEO_VIDEO_PATTERN } from "../../../../base/constants/patterns";

export const initialValues = {
    title: "",
    exerciseStatus: null,
    file: [],
    instruction: "",
    format: EXERCISE_CONTENT_FORMAT.TEXT,
    similarExercises: [],
    muscles: []
};

const urlValidator = yup
    .string()
    .matches(
        URL_PATTERN,
        'Invalid url'
    )
    .required();

const videoUrlValidator = yup
    .string()
    .matches(
        VIMEO_VIDEO_PATTERN,
        'Invalid url'
    )
    .required();

export const validationSchema = yup.object().shape({
    title: yup.string()
        .trim()
        .min(MIN_STRING_LENGTH)
        .max(MAX_TITLE_LENGTH)
        .matches(ALLOWED_SYMBOLS_REGEX, ALLOWED_SYMBOLS_MESSAGE)
        .required(),
    type: yup.string().required(),
    exerciseStatus: yup.string().required(),
    contentUrl: yup.string().when('format', {
        is: EXERCISE_CONTENT_FORMAT.VIDEO,
        then: videoUrlValidator,
    }).when('format', {
        is: EXERCISE_CONTENT_FORMAT.AUDIO,
        then: urlValidator,
    }).when('format', {
        is: EXERCISE_CONTENT_FORMAT.TEXT,
        then: yup.string().nullable(),
        otherwise: yup.string().nullable(),
    }),
    similarExercises: yup.array(),
    instruction: yup.string(),
    muscles: yup
        .array()
        .of(yup.string())
        .min(1, 'Muscles is required')
});

export const validateFile = (file) => {
    if (file?.size > MAX_FILE_SIZE) {
        return ERROR_SIZE_MESSAGE;
    }

    if (!isAllowedExtension(file, ALLOWED_EXTENTIONS_FILE)) {
        return ERROR_ALLOWED_TYPE_MESSAGE;
    }

    return null;
};

export const exerciseTypeOptions = Object.values(EXERCISE_TYPES).map((value) => ({
    value,
    id: value,
    label: EXERCISE_TYPES_LABEL[value]
}));

export const exerciseStatusesOption = Object.values(EXERCISE_STATUSES).map((value) => ({
    id: value,
    value,
    label: EXERCISE_STATUSES_LABELS[value]
}));