import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import Table from "../../../../../base/components/Table";
import ConfirmPopup from "../../../../../base/components/ConfirmPopup";
import Button from "../../../../../base/components/Button";
import { SearchPlaceholder, columns, NoHomepageSectionPlaceholder, HOMEPAGE_SECTIONS_ACTIONS } from "./components";

import {
    usePaginationProvider,
    useLocationSource,
    useSearchProvider, useSortProvider, useFilterProvider
} from "../../../../../base/components/Table/hooks";
import { useService } from "../../../../../base/hooks/useService";
import { useLoading } from "../../../../../base/hooks/useLoading";
import { useQueryString } from "../../../../../base/hooks/useQueryString";
import ToasterService from "../../../../../services/ToastService";
import HomePageLayoutService from "../../../../../services/HomePageLayoutService";
import { MIN_SEARCH_LENGTH } from "../../../../../base/constants/pagination";
import { BUTTON_COLORS } from "../../../../../base/components/Button/appearance";
import { FITNESS_GROUP_LINKS } from "../../config";
import { DEFAULT_TABLE_LIMIT } from "../../../../../base/constants/shared";
import Title from "../../../Biomarkers/Details/Title";
import MobilePreview from "../../../../../base/components/MobilePreview/MobilePreview";
import { HomepagePreview } from "../../../../../base/components/MobilePreview/previews/HomepagePreview";
import { CONTENTS_GROUP_LINKS } from "../../../Content/config";


export default function Homepage() {

    /**
     * @type {ToasterService}
     */
    const toastService = useService(ToasterService);
    /**
     * @type {HomePageLayoutService}
     */
    const homePageLayoutService = useService(HomePageLayoutService);
    const [isLoading, { registerPromise }] = useLoading(false);
    const { search: locationSearch } = useLocation();
    const navigate = useNavigate();
    const {
        params: {
            name,
            status
        }
    } = useQueryString(locationSearch);

    const [homepageSections, updateHomepageSections] = useState([]);
    const [sectionsPreview, updatePreviewSections] = useState([]);
    const [showDeletePopup, updateShowDeletePopup] = useState(null);

    const locationSource = useLocationSource();

    const sortKeys = ["name", "status"];
    const getSortScope = (key) => [...sortKeys.filter(item => item !== key), "offset"];

    const nameSortProvider = useSortProvider({
        source: locationSource,
        alias: "name",
        scope: "",
        onApplyClearScope: getSortScope("name")
    });

    const statusSortProvider = useSortProvider({
        source: locationSource,
        alias: "status",
        scope: "",
        onApplyClearScope: getSortScope("status")
    });

    const goToCreate = () => {
        navigate(FITNESS_GROUP_LINKS.FORM_HOMEPAGE_SECTION);
    };

    const goToEdit = (id) => {
        const queryParams = new URLSearchParams({ editSectionId: id }).toString();
        navigate(`${FITNESS_GROUP_LINKS.FORM_HOMEPAGE_SECTION}/?${queryParams}`);
    }

    const handleChangeSectionOrder = (result) => {
        if (!result.destination) {
            return;
        }

        const selectedItem = homepageSections[result.source.index];

        const items = Array.from(homepageSections);

        const [reorderedItem] = items.splice(result.source.index, 1);

        items.splice(result.destination.index, 0, reorderedItem);

        updateHomepageSections(items);

        homePageLayoutService.changeHomepageSectionOrder(selectedItem.id, {
            oldPosition: selectedItem.position,
            newPosition: result.destination.index + 1
        })
            .finally(() => {
                getHomepageSections()
                getPreviewHomepageSections();
            });
    };

    const getHomepageSections = useCallback(() => {
        const [orderBy, orderType] = Object.entries({
            name,
            status
        }).find(([_, value]) => value) || [];

        return homePageLayoutService.getHomepageSections({
            orderBy,
            orderType
        }).then(({ data }) => {
            updateHomepageSections(data);
        });
    }, [name, status]);

    const getPreviewHomepageSections = useCallback(() => {
        return homePageLayoutService.getPreviewHomepageSections()
            .then(({ data }) => {
                updatePreviewSections(data);
            });
    }, []);

    const deleteHomepageSection = (id) => {
        homePageLayoutService.deleteHomepageSection(id)
            .then(() => {
                getHomepageSections();
                getPreviewHomepageSections();
                toastService.success("The section has been successfully deleted");
            });
    };

    const changeHomepageSectionStatus = ({ id, isActive }) => {
        homePageLayoutService.changeHomepageSectionStatus(id, !isActive)
            .then(() => {
                getHomepageSections();
                getPreviewHomepageSections();
            });
    };


    useEffect(() => {
        registerPromise(getHomepageSections());
    }, [getHomepageSections]);

    useEffect(() => {
        getPreviewHomepageSections();
    }, []);

    return (
        <div className="w-100">
            <div className="d-flex pt-3 justify-content-end">
                <Button
                    color={BUTTON_COLORS.primary}
                    onClick={() => goToCreate()}
                >
                    <i className="bx bx-plus me-2"/>
                    Add section
                </Button>
            </div>
            <div className="d-flex justify-content-between">
                <section className="w-50 pe-4 border-end border-1">
                    <Table
                        columns={homepageSections.length ? columns : []}
                        data={homepageSections}
                        commonPlaceholder={<NoHomepageSectionPlaceholder/>}
                        placeholderForSearch={<SearchPlaceholder/>}
                        loading={isLoading}
                        actions={{
                            [HOMEPAGE_SECTIONS_ACTIONS.EDIT]: goToEdit,
                            [HOMEPAGE_SECTIONS_ACTIONS.CHANGE_STATUS]: changeHomepageSectionStatus,
                            [HOMEPAGE_SECTIONS_ACTIONS.DELETE]: updateShowDeletePopup
                        }}
                        sortProviders={{
                            name: nameSortProvider,
                            status: statusSortProvider
                        }}
                        withoutPagination
                        handleOnDragEnd={handleChangeSectionOrder}
                    />
                    {showDeletePopup &&
                        <ConfirmPopup
                            isOpen={showDeletePopup}
                            updateIsOpen={updateShowDeletePopup}
                            onSubmit={() => {
                                deleteHomepageSection(showDeletePopup);
                                updateShowDeletePopup(null);
                            }}
                            title="Delete section"
                            description="Are you sure you want to delete the chosen section?"
                            submitBtnText="Delete"
                            className="upload-manually__popup"
                        />
                    }
                </section>

                <section className="w-50 ps-4">
                    <Title
                        title="Preview"
                    />

                    <div className="w-100 d-flex align-items-center justify-content-center">
                        <MobilePreview>
                            <HomepagePreview sections={sectionsPreview}/>
                        </MobilePreview>
                    </div>
                </section>
            </div>
        </div>
    );
}
