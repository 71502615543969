import React, { useMemo, useState } from "react";
import { CustomModal, ModalActions, ModalBody, ModalHeader } from "../../../../../base/components/CustomModal";
import Button from "../../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../../base/components/Button/appearance";
import FilterDropdown from "./FilterDropdown";
import {
  selectAllAge,
  selectAllEthnicity,
  selectAllOther,
  selectAllSex
} from "./constants";
import isDeepEqual from 'lodash.isequal';

export default function FilterPopup({
  isOpen,
  updateIsOpen,
  submitFilters,
  isEditModal,
  currentFilters,
  handleEditFilters,
  allFiltersMap,
  previousFilters,
}) {
  const onClose = () => updateIsOpen(false);

  const { allSexTypes, allAgeTypes, allEthnicityTypes, allOtherFeatureTypes } = allFiltersMap;

  const alreadyCreatedFulfilledFilter = !!previousFilters?.find(({
    sexes = [],
    ages = [],
    ethnicities = [],
    otherFeatures = []
  }) => {
    return sexes.length === allSexTypes.length &&
      ages.length === allAgeTypes.length &&
      ethnicities.length === allEthnicityTypes.length &&
      !otherFeatures.length;
  })

  const [sexes, updateSexes] = useState(
    isEditModal ? currentFilters.sexes : allSexTypes
  );
  const [ages, updateAges] = useState(
    isEditModal ? currentFilters.ages : allAgeTypes
  );
  const [ethnicities, updateEthnicities] = useState(
    isEditModal ? currentFilters.ethnicities : allEthnicityTypes
  );
  const [otherFeatures, updateOtherFeatures] = useState(
    isEditModal ? currentFilters.otherFeatures : []
  );

  const isInitialDisabled = isEditModal &&
    isDeepEqual(sexes, currentFilters.sexes) &&
    isDeepEqual(ages, currentFilters.ages) &&
    isDeepEqual(ethnicities, currentFilters.ethnicities) &&
    isDeepEqual(otherFeatures, currentFilters.otherFeatures)

  const isAllSexFiltersSelected = useMemo(() =>
    sexes.length === allSexTypes.length, [sexes]);

  const isAllAgeFiltersSelected = useMemo(() =>
    ages.length === allAgeTypes.length, [ages]);

  const isAllEthnicityFiltersSelected = useMemo(() =>
    ethnicities.length === allEthnicityTypes.length, [ethnicities]);

  const isAllOtherFiltersSelected = useMemo(() =>
    otherFeatures.length === allOtherFeatureTypes.length, [otherFeatures]);

  const isDisabledByFulfilledFilter = !isEditModal &&
    alreadyCreatedFulfilledFilter &&
    isAllSexFiltersSelected &&
    isAllAgeFiltersSelected &&
    isAllEthnicityFiltersSelected &&
    !otherFeatures.length;

  const selectAllFiltersCategory = (allFiltersMap, currentState, updateState) => {
    if(allFiltersMap.length === currentState.length) {
      updateState([]);
      return;
    }
    updateState(allFiltersMap);
  }

  const handleCheckboxChange = (value, currentState, updateState) => {
    if(currentState.find(({ key }) => value.key === key)) {
      updateState(prevState => prevState.filter(({ key }) => key !== value.key))
      return
    }
    updateState(prevState => [...prevState, value]);
  }

  const getDisplayedValue = (allFiltersMap, currentState, isOther = false) => {
    if(!currentState.length) return isOther ? "Select" : "Select (required)";

    if(currentState.length === allFiltersMap.length) {
      return "All";
    }

    if(currentState.length === 1) {
      return currentState[0].key;
    }

    return `${currentState.length} selected`;
  }

  return (
    <CustomModal isOpen={isOpen} className="filter-modal">
      <ModalHeader onClose={onClose}>
        {isEditModal ? "Edit filter" : "Create filter"}
      </ModalHeader>
      <ModalBody>
        <p className="w-90">Create filter by sex, age and ethnicity to enter biomarker specific value for this
          group.</p>

        <section className="d-flex flex-wrap justify-content-between">

          <FilterDropdown
            label="Sex"
            value={getDisplayedValue(allSexTypes, sexes)}
            listOfCheckboxes={allSexTypes.map((checkbox) => {
              return {
                id: checkbox.key,
                text: checkbox.key,
                value: !!sexes.find((({ key }) => key === checkbox.key)),
                onChange: () => handleCheckboxChange(checkbox, sexes, updateSexes)
              }
            })}
            selectAllCheckbox={{
              ...selectAllSex, value: isAllSexFiltersSelected, onChange: () => {
                selectAllFiltersCategory(allSexTypes, sexes, updateSexes)
              }
            }}
            clearOption={() => updateSexes([])}
          />

          <FilterDropdown
            label="Age"
            value={getDisplayedValue(allAgeTypes, ages)}
            listOfCheckboxes={allAgeTypes.map((checkbox) => {
              return {
                id: checkbox.key,
                text: checkbox.key,
                value: !!ages.find((({ key }) => key === checkbox.key)),
                onChange: () => handleCheckboxChange(checkbox, ages, updateAges)
              }
            })}
            selectAllCheckbox={{
              ...selectAllAge, value: isAllAgeFiltersSelected, onChange: () => {
                selectAllFiltersCategory(allAgeTypes, ages, updateAges)
              }
            }}
            clearOption={() => updateAges([])}
          />

          <FilterDropdown
            label="Ethnicity"
            value={getDisplayedValue(allEthnicityTypes, ethnicities)}
            listOfCheckboxes={allEthnicityTypes.map((checkbox) => {
              return {
                id: checkbox.key,
                text: checkbox.key,
                value: !!ethnicities.find((({ key }) => key === checkbox.key)),
                onChange: () => handleCheckboxChange(checkbox, ethnicities, updateEthnicities)
              }
            })}
            selectAllCheckbox={{
              ...selectAllEthnicity,
              value: isAllEthnicityFiltersSelected,
              onChange: () => {
                selectAllFiltersCategory(allEthnicityTypes, ethnicities, updateEthnicities)
              }
            }}
            clearOption={() => updateEthnicities([])}
          />

          <FilterDropdown
            label="Other features"
            value={getDisplayedValue(allOtherFeatureTypes, otherFeatures, true)}
            listOfCheckboxes={allOtherFeatureTypes.map((checkbox) => {
              return {
                id: checkbox.key,
                text: checkbox.key,
                value: !!otherFeatures.find((({ key }) => key === checkbox.key)),
                onChange: () => handleCheckboxChange(checkbox, otherFeatures, updateOtherFeatures)
              }
            })}
            selectAllCheckbox={{
              ...selectAllOther,
              value: isAllOtherFiltersSelected,
              onChange: () => {
                selectAllFiltersCategory(allOtherFeatureTypes, otherFeatures, updateOtherFeatures)
              }
            }}
            clearOption={() => updateOtherFeatures([])}
          />
        </section>
      </ModalBody>

      <ModalActions>
        <Button color={BUTTON_COLORS.primaryOutline} onClick={onClose} className="mb-0">
          Cancel
        </Button>
        <Button
          color={BUTTON_COLORS.primary}
          onClick={() => {
            if(isEditModal) {
              handleEditFilters({
                sexes, ages, ethnicities, otherFeatures
              })
              onClose();
              return;
            }
            submitFilters({
              sexes, ages, ethnicities, otherFeatures, overrideSwitchStatus: false, floorSwitchStatus: false
            })
            onClose();
          }}
          className="mb-0"
          disabled={!sexes.length ||
            !ethnicities.length ||
            !ages.length ||
            isInitialDisabled ||
            isDisabledByFulfilledFilter
          }
        >
          {isEditModal ? "Save changes" : "Create filter"}
        </Button>
      </ModalActions>
    </CustomModal>
  )
}