import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";


import BaseLayoutWithCard from "../../../../../base/components/BaseLayoutWithCard";
import Button from "../../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../../base/components/Button/appearance";

import { useLoading } from "../../../../../base/hooks/useLoading";
import { useService } from "../../../../../base/hooks/useService";
import PageSpinner from "../../../../../base/components/PageSpinner";
import ValueWithLabel from "../../../../../base/components/ValueWIthLabel";
import RichTextView from "../../../../../base/components//RIchTextView";
import { FITNESS_GROUP_LINKS } from "../config";

import WellnessService from "../../../../../services/WellnessService";
import { PROGRAM_LEVEL_LABEL, PROGRAM_STATUSES_LABELS } from "../../constants";
import { map } from "lodash";
import { WeeklyProgramPlan } from "./WeeklyProgramPlan";
import Icon from "../../../../../base/components/Icon";


export default function FitnessProgramDetails() {
  /**
   * @type {WellnessService}
   */
  const wellnessService = useService(WellnessService);

  const [program, setExercise] = useState(null);

  const [isLoading, { registerPromise }] = useLoading(true);
  const navigate = useNavigate();

  const { id } = useParams();

  const mapExercise = ({
    file,
    coach,
    ...otherValue
  }) => {
    return {
      image: file?.link,
      coachImage: coach?.file?.link,
      coachName: coach?.name,
      ...otherValue
    };
  };

  const goToEdit = () => {
    const queryParams = new URLSearchParams({ editProgramId: id }).toString();
    navigate(`${FITNESS_GROUP_LINKS.FORM}?${queryParams}`);
  };

  const goToCopy = () => {
    wellnessService.copyProgram(id)
      .then((data) => {
        const queryParams = new URLSearchParams({ editProgramId: data.id }).toString();
        navigate(`${FITNESS_GROUP_LINKS.FORM}?${queryParams}`);
      });
  };

  useEffect(() => {
    if (id) {
      registerPromise(wellnessService.getProgramById(id))
        .then((data) => {
          setExercise(mapExercise(data));
        });
    }
  }, [id]);

  const breadcrumbs = {
    title: "Fitness program detailed view",
    breadcrumbItems: [
      { title: "Fitness", link: FITNESS_GROUP_LINKS.BASE },
      { title: "Fitness program detailed view" }
    ]
  };

  return <BaseLayoutWithCard breadcrumbs={breadcrumbs}>
    {isLoading
      ? <PageSpinner/>
      : <>
        <section className="d-flex align-items-start justify-content-between mb-4">
          <label className="font-weight-semibold font-size-15">General information</label>
          <div className="d-flex gap-3 align-items-center">
            {
              !program.isPredefined
                ? <Button
                  color={BUTTON_COLORS.primary}
                  onClick={goToEdit}
                  className="d-flex align-items-center"
                >
                  <i className="bx bx-edit-alt me-2"/>
                  Edit program
                </Button>
                : <Button
                  color={BUTTON_COLORS.primary}
                  onClick={goToCopy}
                  className="d-flex align-items-center"
                >
                  <i className="bx bx-edit-alt me-2"/>
                  Copy program
                </Button>
            }
          </div>
        </section>
        <section className="d-flex flex-column gap-3">
          <ValueWithLabel value={program?.wellnessProgramType?.title} label="Program type"/>

          <ValueWithLabel value={program?.wellnessProgramSubcategory?.title} label="Subcategory"/>


          {
            program?.image
              ? <img
                src={program?.image}
                alt="program image"
                width={240}
              />
              : null
          }

          <ValueWithLabel value={program?.name} label="Name"/>

          <div className="d-flex align-items-center gap-5">
            <ValueWithLabel value={program?.coachName} label="Coach"/>
            {
              program?.image
                ? <img
                  src={program?.coachImage}
                  alt="coach image"
                  width={36}
                  className="rounded-2 ms-5"
                />
                : null
            }
          </div>

          <ValueWithLabel value={PROGRAM_LEVEL_LABEL[program?.programLevel]} label="Program level"/>

          <ValueWithLabel value={map(program?.segments, 'segment.fullName').join(', ')} label="User segments"/>

          <ValueWithLabel value={map(program?.tags, 'tag.name').join(', ')} label="Tags"/>

          <ValueWithLabel value={PROGRAM_STATUSES_LABELS[program?.status]} label="Status"/>
        </section>

        <section className="mt-5">
          <label className="font-weight-semibold font-size-15 mb-2">Program overview</label>
          <div className="d-flex flex-column gap-3">
            <RichTextView richText={program?.overview}/>
          </div>
        </section>

        <section className="mt-5">
          <label className="font-weight-semibold font-size-15 mb-2">Program highlights</label>
          <div className="d-flex flex-column gap-3">
            <RichTextView richText={program?.highlights}/>
          </div>
        </section>

        <WeeklyProgramPlan days={program?.wellnessProgramDays}/>

        {
          program?.whyRecommendedDescription ?
            <section className="mt-5">
              <label className="font-weight-semibold font-size-15 mb-2">Describe why system can recommend this program</label>
              <div className="d-flex flex-column gap-3">
                <RichTextView richText={program?.whyRecommendedDescription}/>
              </div>
            </section>
            : null
        }
      </>
    }
  </BaseLayoutWithCard>;
}