import Http from "./Http";

class SubscriptionPlansService extends Http {
    static $displayName = "SubscriptionPlansService";

    getSubscriptionPlans(params) {
        return this.get(`/subscription-plans`, { params });
    }
}

export default SubscriptionPlansService;
