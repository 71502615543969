
export const MIN_STRING_LENGTH = 1
export const MAX_RULE_NAME_LENGTH = 50

export const MIN_PRIORITY = 0
export const MAX_PRIORITY = 10

export const MIN_ORDER = 0
export const MAX_ORDER = 1000

export const RULE_TYPE = {
    INGREDIENT_GROUP: 1,
    RECIPE_TAG: 2
}

export const MAX_RULE_PUBLIC_COMMENT_LENGTH = 255

export const MAX_RULE_SIMPLE_EXAMPLES_LENGTH = 255

export const MAX_SOURCE_TITLE_LENGTH = 50

export const ALLOWED_SYMBOLS_REGEX = /^[a-zA-Z0-9 _-]*$/;
export const ALLOWED_SYMBOLS_MESSAGE = 'Text must contain only letters, numbers, spaces, dashes, and underscores';

