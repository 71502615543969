import React from "react";
import { renderRichText } from "@storyblok/react";
import Wrapper from "./Wrapper";

const TextSection = ({ blok }) => {
    if (!blok) {
        return null;
    }

    return (
        <Wrapper blok={blok}>
            <article className="mb-2">
                <h3 className="mb-3">{blok.title}</h3>
                <div>
                    {blok.description && (
                        <div dangerouslySetInnerHTML={{ __html: renderRichText(blok.description) }}/>
                    )}
                </div>
            </article>
        </Wrapper>
    );
};

export default TextSection;
