import React from 'react';
import { Container } from 'reactstrap';
import { Route, Routes } from 'react-router-dom';

import { MEALPLANS_GROUP_ROUTES } from './config';

import { MealplanForm } from "./Form";

export default function Mealplan() {
    return (
        <Container fluid className="content">
            <Routes>
                <Route
                    path={MEALPLANS_GROUP_ROUTES.LIST}
                    element={<MealplanForm/>}
                />
            </Routes>
        </Container>
    );
}