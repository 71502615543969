import yup from "../../../../validation/yup";

export const validationSchema = yup.object().shape({
  date: yup.string().required(),
  results: yup.array().of(yup.object().shape({
    biomarker: yup.object().required(),
    value: yup.string().required(),
    unit: yup.object().required(),
    selected: yup.boolean(),
  })),
});