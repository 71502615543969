import React, { useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle, UncontrolledTooltip } from "reactstrap";
import joinClassNames from "../../../../../base/helpers/joinClassNames";
import Icon from "../../../../../base/components/Icon";

export default function RulesDropdown({
  value,
  label,
  onChange,
  options = [],
  withDelete,
  onDelete,
  withEdit,
  onEdit,
  withReset,
  onReset,
  containerClassName,
  noOptionsText = "",
  optionWithDelete,
  onDeleteOption,
  selectedFilterIndex,
  isRule,
}) {
  const [isOpen, updateIsOpen] = useState(false);
  return (
    <section className={joinClassNames("filter-dropdown mb-3 rules-dropdown", containerClassName)}>
      <label className="rules-dropdown__heading">{label}</label>
      <Dropdown
        isOpen={isOpen}
        toggle={() => updateIsOpen(prevState => !prevState)}
        className="d-inline-block"
      >
        <DropdownToggle
          className={joinClassNames("filter-toggle", isOpen && "with-border")}
          tag="section"
          id={label}
        >
          <section className="rules-dropdown--value">

            <span className="ms-2 me-1 text-truncate rules-dropdown--value__span">{value}</span>

            <section className="me-1 d-flex align-items-center">
              {withEdit &&
                <Icon icon="edit"
                      onClick={(event) => {
                        event.stopPropagation();
                        onEdit(value)
                      }}
                      className="rules-dropdown--value__edit mx-1 me-2 cursor-pointer "
                />
              }
              {withDelete &&
                <Icon icon="trashIcon"
                      onClick={(event) => {
                        event.stopPropagation();
                        onDelete(value)
                      }}
                      className={joinClassNames("rules-dropdown--value__trash mx-2 cursor-pointer", !withReset && "me-3")}
                />
              }

              {withReset &&
                <Icon icon="close"
                      onClick={(event) => {
                        event.stopPropagation();
                        onReset(value)
                      }}
                      className="rules-dropdown--value__reset  cursor-pointer mx-1 me-3"
                />
              }

              <i className={joinClassNames("mdi mdi-chevron-down", isOpen && "mdi-rotate-180")}/>

            </section>

            <UncontrolledTooltip
              popperClassName={joinClassNames(
                "tooltip-full-filters-name",
                (isOpen || !value) && "visibility-hidden"
              )}
              placement="bottom"
              target={label}
            >
              {value}
            </UncontrolledTooltip>
          </section>
        </DropdownToggle>

        <DropdownMenu className="rules-menu pb-0">
          <section className="rules-menu__options">
            {options.map((item, index) => {
              return (
                <section
                  key={index}
                  className={
                    joinClassNames(
                      "rules-menu--option text-truncate d-flex justify-content-between align-items-center",
                      (isRule ? item.id === selectedFilterIndex : index === selectedFilterIndex) && "active",
                    )
                  }
                  onClick={() => {
                    onChange(item, index)
                    updateIsOpen(false)
                  }}
                >
                  <span className="text-truncate">{item.value ?? item.name ?? ""}</span>
                  {optionWithDelete &&
                    <Icon icon="trashIcon"
                          onClick={(event) => {
                            event.stopPropagation();
                            onDeleteOption(item.id)
                          }}
                          className={joinClassNames("rules-dropdown--value__trash cursor-pointer")}
                    />
                  }
                </section>
              )
            })}
          </section>

          {!options.length &&
            <section className="w-100 text-center my-5 text-secondary">
              <label className="mb-0">{noOptionsText}</label>
            </section>
          }
        </DropdownMenu>
      </Dropdown>

    </section>
  )
}