
export const WELLNESS_GROUP_ROUTES = {
  BASE: "/*",
  LISTS: "/",
  FORM: "/form",
  DETAILS: "/:id",
};

export const WELLNESS_GROUP_LINKS = {
  BASE: "/app/health-program/wellness",
  LISTS: "/app/health-program/wellness",
  FORM: "/app/health-program/wellness/form",
};