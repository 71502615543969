import JoditEditor from "jodit-react";
import React, { useMemo, useRef, useState } from "react";

export default function JoditEditorComponent({ value, onChange, placeholder, setIsTemplatesValid }) {
  const editor = useRef(null);

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: placeholder || "",
    }),
    [placeholder]
  );

  const detectTags = (text) => {
    let tags = [];
    const regex = /\$\{[a-zA-Z0-9\_\-\.]+\}/g;
    const found = text.match(regex);
    if (found) {
      found.forEach((tag) => {
        if (!tags.includes(tag)) {
          tags.push(tag);
        }
      });
    }
    return tags;
  }

  let initialTags = useMemo(() => detectTags(value), [value]);
  const [tagsMismatch, setTagsMismatch] = useState(false);
  const [newTags, setNewTags] = useState([]);

  const handleEditorDataChange = (newContent) => {
    const newTags = detectTags(newContent);
    const isTemplatesNotEqual = !_.isEqual(initialTags, newTags);
    setTagsMismatch(isTemplatesNotEqual);
    setIsTemplatesValid(isTemplatesNotEqual);
    setNewTags(newTags);
    onChange(newContent);
  }

  return (
    <>
      <JoditEditor
        className="jodit-editor mt-3"
        ref={editor}
        value={value}
        config={config}
        onBlur={handleEditorDataChange} // preferred to use only this option to update the content for performance reasons
      />
      {tagsMismatch ? (
        <section className="mt-2">
          Tags mismatch error. <br/>
          Expected: {initialTags.join(', ')} <br/>
          Received: {newTags.join(', ')}
        </section>
      ) : null}
    </>
  );
}
