import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import SimpleBar from "simplebar-react";
import { useTranslate } from '../../hooks/useTranslate';
import Icon from "../Icon";
import joinClassNames from "../../helpers/joinClassNames";
import TemplateLink from "./TemplateLink";
import { useService } from "../../hooks/useService";
import TemplateService from "../../../services/TemplateService";
import { APP_GROUP_LINKS } from "../../../groups/app/config";
import { useTemplates, useUpdateTemplates } from "../../context/templates";
import { SIDEBAR_ITEMS_TYPE } from "./Sidebar";
import { KEY_USER } from "../../constants/storage";
import StorageService from "../../../services/StorageService";
import { USER_ROLES } from "../../constants/shared";

const ACTIVE_LINK = 'waves-effect active mm-active';
const ARROW = 'has-arrow';
const CONDENSED = 'condensed';

export default function generateSidebarLinks(linksArray, sidebarType) {
  /**
   * @type {TemplateService}
   */
  const templateService = useService(TemplateService);
  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);

  const location = useLocation();
  const currentLocation = useMemo(() => location.pathname, [location]);
  const navigate = useNavigate();
  const user = storage.get(KEY_USER, { firstName: "", lastName: "" });

  const isCondensed = sidebarType === CONDENSED;

  const [translate] = useTranslate();

  const [activeLink, setActiveLink] = useState(currentLocation);

  function getLinkClassName(link, arrow = '') {
    if(currentLocation.includes(link)) {
      return `${ACTIVE_LINK} ${arrow}`;
    }
    return `waves-effect ${arrow}`;
  }

  function isOpenedCollapse(link) {
    if(link?.length > activeLink?.length) {
      return;
    }
    return activeLink?.startsWith(link)
  }

  const toggleCollapse = (link, parentLink) => {
    setActiveLink(prevState => {
      return prevState === link ? parentLink : link;
    });
  }

  function getLinksFloor(children, parentLink) {

    return children.map((child, index) => {
      if (user.role === USER_ROLES.practitioner) {
        if (!child.allowedRoles) {
          return null;
        }

        if (!child.allowedRoles.includes(user.role)) {
          return null;
        }
      }
      const hasChilds = !!child.children?.length;

      if (child.type === SIDEBAR_ITEMS_TYPE.DIVIDER) {
        return <div
            key={`divider-${index}`}
            className="border-1 border border-secondary my-3 w-100"
        />
      }

      if (child.type === SIDEBAR_ITEMS_TYPE.TITLE) {
        return <li
            key={`title-${index}`}
            className="sidebar-title"
        >
          {translate(child.label)}
        </li>
      }

      return (<li key={child.linkTo}>
        <a
          className={joinClassNames(hasChilds || child.templates ? getLinkClassName(child.linkTo, ARROW) : getLinkClassName(child.linkTo), "d-flex align-items-center w-100")}
          onClick={(event) => {
            event.preventDefault()
            if(hasChilds) {
              toggleCollapse(child.linkTo, parentLink)
            } else {
              setActiveLink(child.linkTo)
              navigate(child.linkTo)
            }
            event.stopPropagation()
          }}
          href={child.linkTo}
        >
          {child.icon &&
            <Icon
              icon={child.icon}
              className={joinClassNames("link-icon", activeLink?.includes(child.linkTo) && "active")}
            />}
          <span>{translate(child.label)}</span>
        </a>
        {(hasChilds && (!isCondensed ? <Collapse isOpen={isOpenedCollapse(child.linkTo)}>
          <ul className="sub-menu">
            {getLinksFloor(child.children, child.linkTo)}
          </ul>
        </Collapse> : <ul className="sub-menu">
          {getLinksFloor(child.children, child.linkTo)}
        </ul>))}

        {(!!child.templates?.length &&
            <Collapse isOpen={isOpenedCollapse(child.linkTo)}>
              <label className="sidebar-templates__title">Favorite templates:</label>
              <ul className="sub-menu sidebar-templates custom-scrollbar me-2">
                {child.templates.map((template) => {
                  return (
                      <li key={template?.id?.toString()}>
                        <TemplateLink
                            template={template}
                            setActiveLinkToCustomerResults={setActiveLinkToCustomerResults}
                        />
                      </li>
                  )
                })}
              </ul>
            </Collapse>
        )}
      </li>)
    })
  }

  const setActiveLinkToCustomerResults = () => {
    setActiveLink(APP_GROUP_LINKS.CUSTOMER_RESULTS)
  };

  return (
    <SimpleBar style={{height: "calc(100vh - 70px)"}}>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled custom-sidebar-menu">
          {getLinksFloor(linksArray)}
        </ul>
      </div>
      <div id="sidebar-footer">
        <div className="powered-by-label">
          Powered by
        </div>
        <div className="powered-by-logo">
          <span className="logo-lg">
            <Icon icon="sidebarLogo"/>
          </span>
        </div>
      </div>
    </SimpleBar>
  )
}

generateSidebarLinks.propTypes = {
  linksArray: PropTypes.array,
}
