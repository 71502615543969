import React, { useState } from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import joinClassNames from "../../../../../base/helpers/joinClassNames";
import Checkbox from "../../../../../base/components/Checkbox";

const CHECKBOXES_COUNT_FOR_CLEAR_OPTION = 3;

export default function FilterDropdown({
  label,
  value,
  selectAllCheckbox,
  listOfCheckboxes,
  clearOption,
  containerClassName = "",
  showPlaceholder = false,
  placeholder = "",
  showClearOption = true,
  disabled,
}) {
  const [isOpen, updateIsOpen] = useState(false);

  return (
    <section className={joinClassNames("filter-dropdown mb-3 ", containerClassName)}>
      <label>{label}</label>

      <Dropdown
        isOpen={isOpen}
        toggle={() => updateIsOpen(prevState => !prevState)}
        className={joinClassNames(
          "d-inline-block",
          disabled && "disabled-dropdown"
        )}
      >
        <DropdownToggle
          className={joinClassNames("filter-toggle", isOpen && "with-border")}
          tag="section"
        >
          <span className={joinClassNames(
            "ms-2 me-1 pointer-events-none user-select-none",
            showPlaceholder && "text-secondary"
          )}>
            {showPlaceholder ? placeholder : value}
          </span>
          <i className={joinClassNames("mdi mdi-chevron-down pointer-events-none user-select-none", isOpen && "mdi-rotate-180")}/>
        </DropdownToggle>

        <DropdownMenu className="filter-menu pb-0 w-100">

          <section className="ms-2 mt-2 d-flex justify-content-between align-items-center">
            <Checkbox {...selectAllCheckbox} className="ms-1"/>
            {listOfCheckboxes.length > CHECKBOXES_COUNT_FOR_CLEAR_OPTION && showClearOption &&
              <span className="text-danger me-3 cursor-pointer" onClick={clearOption}>Clear</span>
            }
          </section>


          <div className="dropdown-divider my-2 mt-3"/>

          <section className="filter-options ms-2 ">
            {listOfCheckboxes.map((checkboxData, index) => {
              return (
                <Checkbox {...checkboxData} key={index} className="my-2 ms-1"/>
              )
            })}
          </section>
        </DropdownMenu>
      </Dropdown>

    </section>
  )
}