export const MEALPLAN_GOALS = {
    LOSE_WEIGHT: 'loseWeight',
    GAIN_WEIGHT: 'gainWeight',
    MUSCLE_WEIGHT: 'gainMuscle',
    MAINTAIN_WEIGHT: 'maintainWeight'
}

export const MEALPLAN_GOALS_LABELS = {
    [MEALPLAN_GOALS.LOSE_WEIGHT]: 'Lose weight',
    [MEALPLAN_GOALS.GAIN_WEIGHT]: 'Gain weight',
    [MEALPLAN_GOALS.MUSCLE_WEIGHT]: 'Muscle gain',
    [MEALPLAN_GOALS.MAINTAIN_WEIGHT]: 'Maintain weight'
}

export const TRAINING_INTENSITY = {
    SLOW_AND_STEADY: '0.25',
    MODERATE: '0.5',
    MODERATE_AGGRESSIVE: '0.75',
    AGGRESSIVE: '1'
}

export const TRAINING_INTENSITY_LABELS = {
    [TRAINING_INTENSITY.SLOW_AND_STEADY]: 'Slow and steady',
    [TRAINING_INTENSITY.MODERATE]: 'Moderate',
    [TRAINING_INTENSITY.MODERATE_AGGRESSIVE]: 'Moderate-aggressive',
    [TRAINING_INTENSITY.AGGRESSIVE]: 'Aggressive'
}

export const ACTIVITY_LEVEL = {
    SEDENTARY: 'sedentary',
    LIGHTLY_ACTIVE: 'lightlyActive',
    MODERATELY_ACTIVE: 'moderatelyActive',
    HEAVILY_EXERCISE: 'veryActive',
    ATHLETE: 'extremelyActive'
};

export const ACTIVITY_LEVEL_LABEL = {
    [ACTIVITY_LEVEL.SEDENTARY]: 'Sedentary',
    [ACTIVITY_LEVEL.LIGHTLY_ACTIVE]: 'Lightly Active',
    [ACTIVITY_LEVEL.MODERATELY_ACTIVE]: 'Moderately Active',
    [ACTIVITY_LEVEL.HEAVILY_EXERCISE]: 'Heavily Exercise',
    [ACTIVITY_LEVEL.ATHLETE]: 'Athlete'
};
