import * as _ from "lodash";

import { initService } from "../base/hooks/useService";
import Http from "./Http";
import StorageService from "./StorageService";
import { DateTime } from "luxon";

const CACHE_TIME = 60; // 60 seconds

export const ThemeTypes = {
  white: 1,
  dark: 2
};

export const SystemTypes = {
  backend: 1,
  frontend: 2,
  admin: 3,
}

class ConfigService extends Http  {

  static $displayName = "ConfigService";

  async initConfig() {
    const storageService = initService(StorageService);

    let cachedConfig = storageService.get("system:variables");

    if (!cachedConfig) {
      const { data } = await this.fetchConfig();

      cachedConfig = _.chain(data).keyBy("variable").mapValues("value").value();
      storageService.set("system:variables", cachedConfig);
    }

    return cachedConfig;
  }

  async initThemeSettings() {
    const storageService = initService(StorageService);

    let cachedThemeSettings = storageService.get("system:themeSettings");

    if (!cachedThemeSettings) {
      const { data } = await this.fetchThemeSettings();

      cachedThemeSettings = _.chain(data)
        .groupBy("themeType")
        .mapValues(theme =>
          _.chain(theme).keyBy("propertyName").mapValues("value").value()
        )
        .value();
      storageService.set("system:themeSettings", cachedThemeSettings);
    }

    return cachedThemeSettings;
  }

  async initServicesSettings(organisationId) {
    const storageService = initService(StorageService);

    let cachedServicesSettings = storageService.get("system:servicesSettings");

    const settingsCachedAt = storageService.get("system:settings:cachedAt");
    const isCacheExpired =
      DateTime.now().toUnixInteger() - settingsCachedAt > CACHE_TIME;

    if (!cachedServicesSettings || isCacheExpired) {
      const { data } = await this.fetchServiceSettings(organisationId, { isEnabled: true });

      cachedServicesSettings = _.chain(data)
        .map(setting => _.pick(setting, ["name", "isEnabled"]))
        .keyBy("name")
        .mapValues("isEnabled")
        .value();

      storageService.set("system:servicesSettings", cachedServicesSettings);
      storageService.set(
        "system:settings:cachedAt",
        DateTime.now().toUnixInteger()
      );
    }

    return cachedServicesSettings;
  }

  async fetchConfig() {
    const ADMIN_TYPE = 3;
    return await this.get(
      `/system/admin/variables`, {
        params: {
          type: ADMIN_TYPE
        }
      }
    );
  }

  async fetchThemeSettings() {
    return await this.get(
      `/system/admin/settings`
    );
  }

  async fetchCustomerConfig(SYSTEM_TYPE = SystemTypes.frontend) {
    return await this.get(
      `/system/admin/variables`, {
        params: {
          type: SYSTEM_TYPE
        }
      }
    );
  }

  async updateCustomerConfig(config) {
    return await this.put('/system/admin/settings/variables', { data: config })
  }

  async updateThemeSettings(settings) {
    return await this.put('/system/admin/settings/properties', { data: settings })
  }

  async fetchServiceSettings(organisationId, params) {
    return this.get(`/organisations/${organisationId}/services`, { params });
  }
}

export default ConfigService;
