import React, { useState } from 'react';
import { LABELS_DUE_TO_TYPE, MEDICATION_TYPE } from "./constants";
import Icon from "../../../../../base/components/Icon";
import { MAX_RULE_NAME_LENGTH } from "../constants";
import FormikInput from "../../../../../base/components/FormikInput";
import RangeSlider from "../../../../../base/components/RangeSlider";
import RichTextEditor from "../../../../../base/components/RichTextEditor";
import PlusButtonWithTooltip from "../../../../../base/components/PlusButtonWithTooltip";
import joinClassNames from "../../../../../base/helpers/joinClassNames";

export default function InteractionForm({
  keyForInteractionChange,
  interaction,
  setFieldValue,
  onDelete,
}) {
  const { order = 0, type = MEDICATION_TYPE, alsoKnowAs = "" } = interaction ?? {};
  const { name = "" } = LABELS_DUE_TO_TYPE[type] ?? {};

  const [showAlsoKnownAs, updateShowAlsoKnownAs] = useState(!!alsoKnowAs)

  return (
    <section className="my-4">
      <label className="d-flex align-items-center font-weight-semibold">
        {name} #{order}
        <Icon icon="trashIcon" className="cursor-pointer ms-3" onClick={onDelete}/>
      </label>

      <FormikInput
        name={`${keyForInteractionChange}.name`}
        label={name}
        placeholder={`${name} name`}
        maxLength={MAX_RULE_NAME_LENGTH}
        containerClassName="w-100 mt-3"
      />

      {!showAlsoKnownAs && !!interaction.name &&
        <PlusButtonWithTooltip
          id="addMedication"
          onClick={() => updateShowAlsoKnownAs(true)}
          buttonText="Also known as"
          containerClassName=""
          buttonClassName="ps-0"
        />
      }

      {showAlsoKnownAs &&
        <section className={joinClassNames("d-flex mt-3 position-relative")}>
          <FormikInput
            name={`${keyForInteractionChange}.alsoKnowAs`}
            label="Also known as"
            placeholder="Also known as"
            maxLength={MAX_RULE_NAME_LENGTH}
            containerClassName="w-100"
          />

          <div className="trash-icon-placeholder"/>
          <Icon
            icon="trashIcon"
            className="text-danger cursor-pointer input-trash-icon ms-3"
            onClick={() => {
              setFieldValue(`${keyForInteractionChange}.alsoKnowAs`, "")
              updateShowAlsoKnownAs(false);
            }}
          />
        </section>
      }

      <RangeSlider
        value={interaction.impact ?? 0}
        onChange={(value) => {
          if (value === interaction.impact) return
          setFieldValue(`${keyForInteractionChange}.impact`, value)
        }}
        label="Set impact"
        containerClassName="mt-3"
      />

      <RichTextEditor
        value={interaction?.effects || ""}
        onChange={(text) => {
          setFieldValue(`${keyForInteractionChange}.effects`, text)
        }}
        name={name}
        label={`${name} effects`}
        description="Enter information about the interaction main effects and causes. Add a link to the evidence or study."
        placeholder={`${name} effects`}
      />
    </section>
  )
}