import React, { useCallback, useEffect, useState } from 'react';
import BaseLayoutWithCard from "../../../../base/components/BaseLayoutWithCard";
import { RECOMMENDATIONS_LINKS } from "../config";
import RecommendationsForms from "./form";
import { useNavigate, useParams } from "react-router-dom";
import { useService } from "../../../../base/hooks/useService";
import RecommendationsService from "../../../../services/RecommendationsService";
import { prepareRecommendation, transformRecommendation } from "./components";
import useUploadImages from "../../../../base/components/Dropzone/useUploadImages";
import ToasterService from "../../../../services/ToastService";
import { MAX_SIZE_MB, MAX_WIDTH_OR_HEIGHT, USE_WEB_WORKER } from "../../../../base/constants/image";
import imageCompression from "browser-image-compression";

const breadcrumbs = {
  title: "Edit recommendation",
  breadcrumbItems: [
    { title: "Recommendations", link: RECOMMENDATIONS_LINKS.LIST },
    { title: "Edit recommendation" },
  ]
}

export default function EditRecommendation() {
  /**
   * @type {RecommendationsService}
   */
  const recommendationsService = useService(RecommendationsService);
  /**
   * @type {ToasterService}
   */
  const toastService = useService(ToasterService);

  const { id } = useParams();
  const [isSubmitting, updateIsSubmitting] = useState(false);
  const [recommendationIsLoading, updateRecommendationIsLoading] = useState(true);
  const [recommendation, updateRecommendation] = useState({});
  const navigate = useNavigate()
  const uploadImage = useUploadImages();

  const afterSuccess = () => {
    toastService.success("Recommendation has been successfully saved");
    navigate(`${RECOMMENDATIONS_LINKS.LIST}/${id}`);
    updateIsSubmitting(false);
  }

  const editRecommendation = ({
    file,
    ...otherValues
  }) => {
    updateIsSubmitting(true)

    const preparedValues = prepareRecommendation(otherValues);

    if (file[0].link) {
      recommendationsService.editRecommendation(id, {
        ...preparedValues,
        fileId: file[0].id,
      }).then(afterSuccess).finally(() => updateIsSubmitting(false))
      return;
    }

    const options = {
      maxSizeMB: MAX_SIZE_MB,
      maxWidthOrHeight: MAX_WIDTH_OR_HEIGHT,
      useWebWorker: USE_WEB_WORKER,
    }
    imageCompression(file[0].file, options).then((compressedFile) => {
      return uploadImage(compressedFile).then(({ file }) => {
        return recommendationsService.editRecommendation(id, {
          ...preparedValues,
          fileId: file.id,
        });
      });
    }).then(afterSuccess).finally(() => updateIsSubmitting(false))
  }

  const getRecommendation = useCallback(() => {
    updateRecommendationIsLoading(true)
    recommendationsService.getRecommendation(id).then((recommendation) => {
      updateRecommendation(transformRecommendation(recommendation))
    }).finally(() => {
      updateRecommendationIsLoading(false);
    })
  }, [id])

  useEffect(() => {
    getRecommendation()
  }, [getRecommendation]);

  return (
    <BaseLayoutWithCard breadcrumbs={breadcrumbs} loading={recommendationIsLoading}>
      <RecommendationsForms isEdit id={id} initialValues={recommendation} onSubmit={editRecommendation}
                            isSubmitting={isSubmitting}/>
    </BaseLayoutWithCard>
  )
}