import React from "react";
import { Button as ButtonReactstrap } from "reactstrap";
import Wrapper from "./Wrapper";

const Button = ({ blok }) => {
    if (!blok) {
        return null;
    }

    function linkTo(link) {
        if (link.linktype === "story") {
            window.open(`${window.location.origin}/articles/${link.cached_url}`, "_blank");
        }

        if (!url) return
        window.open(link.url, "_blank");
    }

    return (
        <Wrapper blok={{ ...blok, width: "content-width" }}>
            <ButtonReactstrap
                onClick={() => linkTo(blok.link)}
                className={`btn-${blok.size}`}
                style={{ backgroundColor: blok.buttonColor.color }}
            >
                <span style={{ color: blok.textColor.color, }}>{blok.label}</span>
            </ButtonReactstrap>
        </Wrapper>
    );
};

export default Button;
