export const PROGRAMS_GROUP_ROUTES = {
  BASE: "/programs/*",
  LIST: "/",
  CREATE_EDIT: `/create-edit`,
  DETAILS: `/:id`,
  CREATE_EDIT_RULE: "/:programId/rule-form",
  RULE_GOAL_LIST: "/:programId/rule-goal"
};

export const PROGRAMS_GROUP_LINKS = {
  BASE: "/app/health-program/nutrition",
  LIST: "/app/health-program/nutrition",
  CREATE_EDIT: `/app/health-program/nutrition/create-edit`,
  DETAILS: `/app/health-program/nutrition/:id`,
  CREATE_EDIT_RULE: "/app/health-program/nutrition/:programId/rule-form",
  RULE_GOAL_LIST: "/app/health-program/nutrition/:programId/rule-goal"
};