import React, { useMemo, useRef, useState } from 'react';
import joinClassNames from "../../../../../base/helpers/joinClassNames";
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import { RECOMMENDATION_TABS, SKIN_RECOMMENDATION_TABS } from "./constants";
import { useHighlight } from "../../../../../base/hooks/useHighlight";
import TableSearch from "../../../../../base/components/Table/tableSearch";

const LIMIT_OF_SKIN_TABS = 6;
const LIMIT_OF_BLOOD_TABS = 10;

const TabsPagination = ({
  children,
  updateCurrentPage,
  currentPage,
  totalCount,
  limit,
}) => {

  const hasNext = useMemo(() => (currentPage + 1) * limit < totalCount, [currentPage, totalCount, limit]);
  const hasPrev = useMemo(() => currentPage > 0, [currentPage, totalCount, limit]);

  return (
    <>
      {hasPrev &&
        <div className="search-arrow left"
             onClick={() => {
               updateCurrentPage(prevState => --prevState)
             }}
        >
          <i className="bx bx-chevron-left font-size-16"/>
        </div>
      }
      {children}
      {hasNext &&
        <div className="search-arrow right"
             onClick={() => {
               updateCurrentPage(prevState => ++prevState)
             }}
        >
          <i className="bx bx-chevron-right font-size-16"/>
        </div>
      }
    </>
  )
}

export default function RecommendationSelect({
  value,
  canBeDeleted,
  query,
  onSearch,
  onToggle,
  loading,
  options,
  onSelect,
  currentRecommendationIndex,
  toggleDropdown,
  isOpen,
  isSkinCategory,
  isDNAmCategory,
}) {
  const dropdownRef = useRef();
  const { decorateText } = useHighlight(query.search);

  const [currentPage, updateCurrentPage] = useState(0);

  const currentTabs = useMemo(() => {
    if(!isSkinCategory && !isDNAmCategory) return RECOMMENDATION_TABS;
    const step = currentPage * LIMIT_OF_SKIN_TABS;
    return SKIN_RECOMMENDATION_TABS.slice(step, step + LIMIT_OF_SKIN_TABS);
  }, [currentPage, isSkinCategory, isDNAmCategory]);

  return (
    <section
      ref={dropdownRef}
      className={joinClassNames("w-100 filter-dropdown rules-dropdown form-recommendations--select form-recommendations--select__max-width", !canBeDeleted && "me-0")}
    >
      <Dropdown
        isOpen={isOpen}
        toggle={toggleDropdown}
        className="d-inline-block"
      >
        <DropdownToggle
          className={joinClassNames("filter-toggle", isOpen && "with-border")}
          tag="section"
        >
          <section className="rules-dropdown--value">
            <span
              className={joinClassNames("ms-2 me-1 text-truncate rules-dropdown--value__span", !value && "text-secondary")}
            >
              {value ?? "Select recommendation"}
            </span>
            <section className="me-1 d-flex align-items-center">
              <i className={joinClassNames("mdi mdi-chevron-down", isOpen && "mdi-rotate-180")}/>
            </section>
          </section>
        </DropdownToggle>

        <DropdownMenu className="rules-menu pb-0 w-100">

          <section className="p-2 px-3">
            <TableSearch
              search={query.search}
              onSearch={onSearch}
            />
          </section>

          <Nav tabs className={joinClassNames(
            "nav-tabs-custom nav-justified mb-1 position-relative",
            currentPage ? "ps-4" : "pe-4"
          )}>
            <TabsPagination
              updateCurrentPage={updateCurrentPage}
              currentPage={currentPage}
              totalCount={(isSkinCategory || isDNAmCategory) ? SKIN_RECOMMENDATION_TABS.length : RECOMMENDATION_TABS}
              limit={(isSkinCategory || isDNAmCategory) ? LIMIT_OF_SKIN_TABS : LIMIT_OF_BLOOD_TABS}
            >
              {currentTabs.map(({ label, value }) => (
                <NavItem key={label}>
                  <NavLink
                    className={classnames({
                      active: query.category === value,
                    }, "cursor-pointer")}
                    onClick={() => {
                      onToggle(value);
                    }}
                  >
                    <span className="no-text-wrap">{label}</span>
                  </NavLink>
                </NavItem>
              ))}
            </TabsPagination>
          </Nav>

          {loading ?
            <section className="d-flex justify-content-center my-5 ">
              <Spinner color="info" className="mx-auto"/>
            </section>
            :
            <section className="rules-menu__options">
              {options.map((item, itemIndex) => {
                return (
                  <section key={item.id ?? itemIndex} className="rules-menu--option" onClick={() => {
                    onSelect(item, currentRecommendationIndex)
                    toggleDropdown();
                  }}>
                    <span {...decorateText(item.title)} />
                  </section>
                )
              })}
            </section>
          }

          {!options.length && !loading &&
            <section className="w-100 text-center my-5 text-secondary">
              <label className="mb-0">
                {query.search ? "No matches found" : "No recommendations for now"}
              </label>
            </section>
          }
        </DropdownMenu>
      </Dropdown>

    </section>
  )
}