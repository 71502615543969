import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import Table from "../../../../../base/components/Table";
import ConfirmPopup from "../../../../../base/components/ConfirmPopup";
import {
    SearchPlaceholder,
    TableHeader,
    columns,
    NoExercisePlaceholder, EXERCISE_ACTIONS
} from "./components";

import { DEFAULT_TABLE_LIMIT } from "../../../../../base/constants/shared";
import {
    usePaginationProvider,
    useLocationSource,
    useSearchProvider, useSortProvider, useFilterProvider
} from "../../../../../base/components/Table/hooks";
import ToasterService from "../../../../../services/ToastService";
import { useService } from "../../../../../base/hooks/useService";
import { useLoading } from "../../../../../base/hooks/useLoading";
import { useQueryString } from "../../../../../base/hooks/useQueryString";
import { LIMIT_OPTIONS, MIN_SEARCH_LENGTH } from "../../../../../base/constants/pagination";
import ExercisesService from "../../../../../services/ExercisesService";
import { FITNESS_GROUP_LINKS } from "../../config";

export default function Exercises() {

    /**
     * @type {ToasterService}
     */
    const toastService = useService(ToasterService);
    /**
     * @type {ExercisesService}
     */
    const exercisesService = useService(ExercisesService);

    const [isLoading, { registerPromise }] = useLoading(false);
    const { search: locationSearch, pathname } = useLocation();
    const {
        params: {
            limit = DEFAULT_TABLE_LIMIT,
            offset = 0,
            search,
            title,
            createdAt,
            status,
            equipmentFilter,
            statusFilter
        }
    } = useQueryString(locationSearch);

    const [exercises, updateExercises] = useState([]);
    const [exercisePagination, updateExercisePagination] = useState({});
    const [showDeletePopup, updateShowDeletePopup] = useState(null);

    const locationSource = useLocationSource();
    const navigate = useNavigate();

    const searchRequest = useMemo(() => search && search.toString()?.trim()?.length >= MIN_SEARCH_LENGTH
            ? search
            : null,
        [search]);

    const sortKeys = ["title", "createdAt", "status"];
    const getSortScope = (key) => [...sortKeys.filter(item => item !== key), "offset"];

    const titleSortProvider = useSortProvider({
        source: locationSource,
        alias: "title",
        scope: "",
        onApplyClearScope: getSortScope("title")
    });

    const createdAtSortProvider = useSortProvider({
        source: locationSource,
        alias: "createdAt",
        scope: "",
        onApplyClearScope: getSortScope("createdAt")
    });

    const statusSortProvider = useSortProvider({
        source: locationSource,
        alias: "status",
        scope: "",
        onApplyClearScope: getSortScope("status")
    });

    const paginationProvider = usePaginationProvider({
        source: locationSource,
        alias: "offset",
        scope: "",
        fallback: 0
    });

    const searchProvider = useSearchProvider({
        source: locationSource,
        scope: "",
        alias: 'search',
        onApplyClearScope: ["offset"]
    });

    const limitProvider = usePaginationProvider({
        source: locationSource,
        alias: "limit",
        scope: "",
        fallback: 10,
        onApplyClearScope: ["offset"]
    });

    const equipmentFilterProvider = useFilterProvider({
        source: locationSource,
        scope: "",
        alias: 'equipmentFilter',
        onApplyClearScope: ["offset"]
    });

    const statusFilterProvider = useFilterProvider({
        source: locationSource,
        scope: "",
        alias: 'statusFilter',
        onApplyClearScope: ["offset"]
    });

    const filterProvider = useMemo(() => ({
        equipment: equipmentFilterProvider,
        status: statusFilterProvider
    }), [equipmentFilterProvider, statusFilterProvider]);

    const goToEdit = (id) => {
        const queryParams = new URLSearchParams({ editExerciseId: id }).toString();
        navigate(`${FITNESS_GROUP_LINKS.FORM_EXERCISES}/?${queryParams}`);
    }

    const deleteExercise = (id) => {
        exercisesService.deleteExercise(id)
            .then(() => {
                getExercises()
                toastService.success("The exercise has been successfully deleted")
            })
    }


    const getExercises = useCallback(() => {
        const [orderBy, orderType] = Object.entries({
            title,
            status,
            createdAt
        }).find(([_, value]) => value) || [];

        registerPromise(exercisesService.getExercises({
            limit,
            offset,
            orderBy,
            orderType,
            query: searchRequest,
            equipment: equipmentFilter,
            status: statusFilter
        })).then(({ data, pagination }) => {
            updateExercises(data);
            updateExercisePagination(pagination);
        });
    }, [limit, offset, searchRequest, status, createdAt, title, equipmentFilter, statusFilter]);

    useEffect(() => {
        getExercises();
    }, [getExercises]);

    return (
        <>
            <Table
                columns={exercises.length ? columns : []}
                data={exercises}
                loading={isLoading}
                HeaderComponent={TableHeader}
                totalCount={exercisePagination.totalCount}
                limit={limitProvider.getValue()}
                offset={offset}
                paginationProvider={paginationProvider}
                searchProvider={searchProvider}
                commonPlaceholder={<NoExercisePlaceholder/>}
                placeholderForSearch={<SearchPlaceholder/>}
                filterProvider={filterProvider}
                hasActiveFilters={!!equipmentFilterProvider.getValue() || !!statusFilterProvider.getValue()}
                actions={{
                    [EXERCISE_ACTIONS.EDIT]: goToEdit,
                    [EXERCISE_ACTIONS.DELETE]: updateShowDeletePopup
                }}
                sortProviders={{
                    title: titleSortProvider,
                    createdAt: createdAtSortProvider,
                    status: statusSortProvider
                }}
                isLimitEditable
                limitProvider={limitProvider}
                limitOptions={LIMIT_OPTIONS}
                isRowClick
            />
            {showDeletePopup &&
                <ConfirmPopup
                    isOpen={showDeletePopup}
                    updateIsOpen={updateShowDeletePopup}
                    onSubmit={() => {
                        deleteExercise(showDeletePopup)
                        updateShowDeletePopup(null);
                    }}
                    title="Delete exercise"
                    description="Are you sure you want to delete the chosen exercise?"
                    submitBtnText="Delete"
                    className="upload-manually__popup"
                />
            }
        </>
    );
}
