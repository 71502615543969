
export const MIN_STRING_LENGTH = 1

export const MIN_VALUE = 0

export const MIN_VALUE_REPETITION = 1

export const MAX_PERCENTAGE_VALUE = 100

export const MIN_AGE_RANGE = 18

export const MAX_AGE_RANGE = 99

export const MIN_DIFF_AGE_RANGE = 1

export const SEX = {
    MALE: 1,
    FEMALE: 2
}

export const SEX_LABELS = {
    [SEX.MALE]: "Male",
    [SEX.FEMALE]: "Female"
}

export const UNIT = {
    KCAL: 'kcal',
    GRAM: 'g',
    MG: 'mg',
    ML: 'ml',
    UG: 'µg',
    PERCENTAGE: '%'
}

export const UNIT_VALUES = {
    [UNIT.GRAM]: 1,
    [UNIT.PERCENTAGE]: 2
}

export const SPECIAL_NUTRIENT_IDS = [2, 5, 3]

export const NUTRIENTS = {
    CALORIES: 1,
    CARBS: 2,
    PROTEIN: 3,
    FIBER: 4,
    FAT: 5,
    SODIUM: 6,
    SUGAR: 7,
    SATURATED_FAT: 8,
    MONOUNSATURATED_FAT: 9,
    POLYUNSATURATED_FAT: 10,
    CHOLESTEROL: 11,
    VITAMIN_A: 12,
    VITAMIN_C: 13,
    VITAMIN_E: 14,
    ALCOHOL: 15,
    CAFFEINE: 16
};


export const NUTRIENT_UNITS_BY_ID = {
    1: UNIT.KCAL,
    2: UNIT.PERCENTAGE,
    3: UNIT.PERCENTAGE,
    4: UNIT.GRAM,
    5: UNIT.PERCENTAGE,
    6: UNIT.MG,
    7: UNIT.GRAM,
    8: UNIT.GRAM,
    9: UNIT.GRAM,
    10: UNIT.GRAM,
    11: UNIT.MG,
    12: UNIT.UG,
    13: UNIT.MG,
    14: UNIT.MG,
    15: UNIT.ML,
    16: UNIT.MG
}

