import React from 'react'
import { Container } from "reactstrap";
import { Route, Routes } from "react-router-dom";
import CreateNewBiomarker from "./CreateEdit/createNewBiomarker";
import { BIOMARKERS_GROUP_ROUTES } from "./config";
import EditBiomarker from "./CreateEdit/EditBiomarker";
import Details from "./Details";
import BiomarkersList from "./list";

export default function Biomarkers() {
  return (
    <Container fluid className="content">
      <Routes>
        <Route
          path={BIOMARKERS_GROUP_ROUTES.LIST}
          element={<BiomarkersList />}
        />
        <Route
          path={BIOMARKERS_GROUP_ROUTES.CREATE_NEW}
          element={<CreateNewBiomarker />}
        />
        <Route
          path={BIOMARKERS_GROUP_ROUTES.EDIT}
          element={<EditBiomarker />}
        />
        <Route
          path={BIOMARKERS_GROUP_ROUTES.DETAILS}
          element={<Details />}
        />
      </Routes>
    </Container>
  )
}