import React, { useState } from 'react';
import ReactSwitch from "../../../../../base/components/Switch";
import "react-rangeslider/lib/index.css"
import { MAX_ALTERNATIVE_NAMES } from "../constants";
import PlusButtonWithTooltip from "../../../../../base/components/PlusButtonWithTooltip";
import { MEDICATION_TYPE, SUPPLEMENT_TYPE } from "./constants";
import InteractionForm from "./InteractionForm";
import Divider from "../../../../../base/components/Divider";
import { getInteractionOutput } from "../helpers/interactions";

export default function Interactions({
  setFieldValue, interactions = [], keyForInteractions
}) {
  const [isAvailable, updateIsAvailable] = useState(true);

  const toggleAvailability = () => {
    updateIsAvailable(prevState => !prevState);
  }

  const { currentSupplements, currentMedications, interactionsOutput } = getInteractionOutput(interactions);

  const addNewSupplement = () => {
    setFieldValue(keyForInteractions, [...(interactions ?? []), {
      type: SUPPLEMENT_TYPE
    }])
  }

  const addNewMedication = () => {
    setFieldValue(keyForInteractions, [...(interactions ?? []), {
      type: MEDICATION_TYPE
    }])
  }

  const deleteInteraction = (deletedIndex) => {
    const newInteractions = interactions.filter((_, index) => index !== deletedIndex);
    setFieldValue(keyForInteractions, newInteractions);
  }

  return (<section className="w-75 mt-5">
    <section className="w-100 d-flex justify-content-between align-items-center mb-2">
      <div className="d-flex flex-column">
        <label className="mb-1 font-weight-semibold">Interactions</label>
      </div>
      <ReactSwitch
        state={isAvailable}
        updateState={toggleAvailability}
      />
    </section>

    {isAvailable && interactionsOutput.map((interaction, index) => {
      return (
        <React.Fragment key={index}>
          <InteractionForm
            key={index}
            keyForInteractionChange={`${keyForInteractions}[${index}]`}
            interaction={interaction}
            setFieldValue={setFieldValue}
            onDelete={() => deleteInteraction(index)}
          />
          {index < interactionsOutput.length - 1 &&
            <Divider/>
          }
        </React.Fragment>
      )
    })}

    {isAvailable && <section className="mt-4 d-flex align-items-center">
      <PlusButtonWithTooltip
        id="addMedication"
        disabled={currentMedications.length >= MAX_ALTERNATIVE_NAMES || currentMedications.some(({ name }) => !name)}
        onClick={addNewMedication}
        showTooltip={currentMedications.length >= MAX_ALTERNATIVE_NAMES}
        buttonText="Add medication"
        tooltipText={`Max. ${MAX_ALTERNATIVE_NAMES} medications can be added.`}
        containerClassName=""
        buttonClassName="ps-0"
      />
      <span className="mx-2">or</span>
      <PlusButtonWithTooltip
        id="addSupplement"
        disabled={currentSupplements.length >= MAX_ALTERNATIVE_NAMES || currentSupplements.some(({ name }) => !name)}
        onClick={addNewSupplement}
        showTooltip={currentSupplements.length >= MAX_ALTERNATIVE_NAMES}
        buttonText="Add supplement"
        tooltipText={`Max. ${MAX_ALTERNATIVE_NAMES} supplements can be added.`}
        containerClassName=""
      />
    </section>}
  </section>)
}