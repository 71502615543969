import React from "react";
import Wrapper from "./Wrapper";

export default function YoutubeVideoSection({ blok }) {
    if (!blok?.video) {
        return null;
    }

    const getVideoId = () => {
        if (
            blok?.video?.startsWith(
                "https://www.youtube.com/watch?v="
            )
        ) {
            return blok.video.split("=")[1];
        } else if (blok?.video?.startsWith("https://youtu.be/")) {
            return blok.video.split("/")[3];
        }
    };

    return <Wrapper blok={blok}>
        <div className="video-container">
            <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${getVideoId()}`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        </div>
    </Wrapper>

}