import React, { useId, useCallback, useEffect, useState } from 'react'
import Icon from "../../../../../base/components/Icon";
import RecommendationSelect from "./RecommendationSelect";
import { Draggable } from "react-beautiful-dnd";
import { RECOMMENDATION_TABS } from "./constants";
import { useLoading } from "../../../../../base/hooks/useLoading";
import { useDebounce } from "../../../../../base/hooks/useDebounce";
import { useService } from "../../../../../base/hooks/useService";
import BiomarkersService from "../../../../../services/BiomarkersService";
import { HIERARCHY_OPTIONS } from "../../../Recommendations/CreateEdit/constants";

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  ...draggableStyle,
  width: isDragging ? "calc(75% - 285px)" : "100%",
});

export default function RecommendationItem({
  onDelete,
  recommendation,
  canBeDeleted,
  canBeDragged,
  currentRecommendationIndex,
  onSelect,
  value,
  sectionRecommendations,
  isSkinCategory,
  isDNAmCategory,
}) {
  const draggableId = useId();
  const { id } = recommendation ?? {};

  const [options, updateOptions] = useState([]);
  const [query, updateQuery] = useState({ category: RECOMMENDATION_TABS[0].value, search: "" });
  const [loading, { registerPromise }] = useLoading(true);
  const [debouncedQuery] = useDebounce(query.search)
  const [isOpenDropdown, updateIsOpen] = useState(false);

  const toggleDropdown = () => {
    // if (isOpen && (e.target === dropdownRef.current || !dropdownRef.current?.contains(e.target))) return;
    updateIsOpen(prevState => !prevState);
  }
  /**
   * @type {BiomarkersService}
   */
  const biomarkersService = useService(BiomarkersService);

  const toggleCategoryTab = tab => {
    if (query.category !== tab) {
      updateQuery(prevState => ({ ...prevState, category: tab }));
    }
  };

  const handleSearch = (value) => {
    updateQuery(prevState => ({ ...prevState, search: value }))
  }

  const getRecommendations = useCallback(() => {
    if(!isOpenDropdown) return;
    const category = Number(query.category);
    registerPromise(biomarkersService.getRecommendations({
      category: category || undefined,
      search: debouncedQuery,
      orderBy: "title",
      orderType: "asc",
      hierarchyType: HIERARCHY_OPTIONS.PARENT
    }).then(({ data }) => {
      updateOptions(data)
    }))
  }, [query.category, debouncedQuery, isOpenDropdown]);

  useEffect(() => {
    getRecommendations();
  }, [getRecommendations]);

  return (
    <Draggable draggableId={draggableId} index={currentRecommendationIndex} isDragDisabled={!canBeDragged}>
      {(provided, snapshot) => (
        <section
          className="form-recommendations--section__item d-flex align-items-center"
          {...provided.draggableProps}
          ref={provided.innerRef}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          <label
            className="me-3 mb-0 form-recommendations--section__item__label"
          >
            {currentRecommendationIndex + 1}.
          </label>
          <RecommendationSelect
            canBeDeleted={canBeDeleted}
            query={query}
            onSearch={handleSearch}
            onToggle={toggleCategoryTab}
            loading={loading}
            options={options.filter(({ id: optionId }) => sectionRecommendations?.every(({ id }) => id !== optionId))}
            onSelect={onSelect}
            value={value}
            currentRecommendationIndex={currentRecommendationIndex}
            toggleDropdown={toggleDropdown}
            isOpen={isOpenDropdown}
            isSkinCategory={isSkinCategory}
            isDNAmCategory={isDNAmCategory}
          />

          <div className="me-4 ms-3" {...provided.dragHandleProps}>
            {canBeDragged &&
              <Icon icon="dndIcon" className="dnd-icon"/>
            }
          </div>

          {canBeDeleted &&
            <Icon icon="trashIcon" onClick={() => onDelete(id, currentRecommendationIndex)}
                  className="ms-auto cursor-pointer form-recommendations--select__delete"/>
          }
        </section>
      )}
    </Draggable>
  )
}