import React, { useState } from 'react';
import { CustomModal, ModalHeader } from "../../../../base/components/CustomModal";
import RangeSlider from "../../../../base/components/RangeSlider";
import { IMPACT_TYPE_FIELDS, IMPACT_TYPE_FIELDS_VIEW, LOW_RANGE } from "../CreateEdit/constants";
import RichTextView from "../../Biomarkers/Details/RIchTextView";
import { ImpactPopupNav } from "../CreateEdit/components";
import ValueWithLabel from "../../Biomarkers/Details/ValueWIthLabel";

export default function ImpactPopupView({
  isOpen,
  onClose,
  impact,
  isDoctorOptionChosen,
  isSkinRecommendation,
}) {
  const [activeImpactTab, updateActiveImpactTab] = useState(LOW_RANGE)

  const valueOfDescription = impact[IMPACT_TYPE_FIELDS[activeImpactTab].editor];
  const keyForStudyLinks = IMPACT_TYPE_FIELDS[activeImpactTab].studyLinks;

  return (
    <CustomModal isOpen={isOpen} className="filter-modal">
      <ModalHeader onClose={onClose}>
        Impact on biomarker
      </ModalHeader>

      <ValueWithLabel
        label="Title"
        value={impact.biomarkerName}
        className="mx-3 mt-3"
      />

      <ImpactPopupNav
        activeImpactTab={activeImpactTab}
        updateActiveImpactTab={updateActiveImpactTab}
        isSkinRecommendation={isSkinRecommendation}
      />

      <section className="px-3 pb-3">
        {!!valueOfDescription &&
          <RichTextView
            label="Description"
            richText={valueOfDescription}
            containerClassName="my-3"
          />
        }
        {!isSkinRecommendation &&
          <>
            {!!impact[keyForStudyLinks].length &&
              <div className=" mb-3">
                <label className="font-weight-semibold">Study links:</label>

                {impact[keyForStudyLinks].map(({ title, content }, studyListIndex) => {
                  return (
                    <section className="d-flex flex-column mb-2" key={studyListIndex}>
                      <section className="d-flex align-items-center mb-1">
                        <label className="bullet-list__counter font-weight-normal me-3 mb-0">{studyListIndex + 1}.</label>
                        <label className="mb-0 text-truncate font-weight-semibold">{title}</label>
                      </section>
                      <label className="study-link-width text-truncate">{content}</label>
                    </section>
                  )
                })}
              </div>
            }

            {!isDoctorOptionChosen && IMPACT_TYPE_FIELDS_VIEW[activeImpactTab].impacts.map(({
              fieldKey,
              defaultValue,
              ...impactItem
            }) => {
              return (
                <RangeSlider
                  key={fieldKey}
                  {...impactItem}
                  value={impact[fieldKey] ?? defaultValue}
                  disabled
                  onChange={() => {
                  }}
                />
              )
            })}
          </>
        }
      </section>
    </CustomModal>
  )
}