import React, { useEffect, useState } from "react";
import WorkoutSetUpItem from "./components/WorkoutSetUpItem/WorkoutSetUpItem";
import { useService } from "../../../../../../base/hooks/useService";
import ExercisesService from "../../../../../../services/ExercisesService";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useField } from "formik";

const initialItem = {
    isEditTitle: true,
    name: '',
    exercises: []
};

const WorkoutSetUp = ({ name }) => {
    /**
     * @type {ExercisesService}
     */
    const exercisesService = useService(ExercisesService);
    const [exercises, setExercises] = useState([]);
    const [{ value = [] }, { error }, { setValue }] = useField(name);

    const loadExercise = () => {
        exercisesService.getSimpleExercises()
            .then(({ data }) => setExercises(data));
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        ...draggableStyle
    });

    const onDragEnd = (result) => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        const items = Array.from(value);

        const [reorderedItem] = items.splice(source.index, 1);

        items.splice(destination.index, 0, reorderedItem);

        setValue(items);
    };

    const handleRemoveItem = (index) => {
        const newItems = value.filter((_, i) => i !== index);
        setValue(newItems);
    };

    useEffect(() => {
        loadExercise();
    }, []);

    return <div>
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="workoutSetUpDND">
                {(provided) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {
                            value.map((item, index) =>
                                <Draggable
                                    key={index}
                                    draggableId={index.toString()}
                                    index={index}
                                    isDragDisabled={false}>

                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.draggableProps}
                                            ref={provided.innerRef}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                            className="mb-3"
                                        >
                                            <WorkoutSetUpItem
                                                item={item}
                                                name={`${name}[${index}]`}
                                                exercises={exercises}
                                                dragHandleProps={provided.dragHandleProps}
                                                deleteItem={() => handleRemoveItem(index)}
                                            />
                                        </div>)}
                                </Draggable>
                            )
                        }
                        {provided.placeholder}
                    </div>)}
            </Droppable>
        </DragDropContext>
<div className="d-flex justify-content-between w-100 mt-3 ">
    <div
        className="text-primary cursor-pointer w-fit-content"
        onClick={() => setValue([...value, initialItem])}
    >
        + Add section
    </div>

    <div className="invalid-feedback d-block w-fit-content">
        {typeof (error) === 'string' ? error : null}
    </div>
</div>

    </div>;
};

export default WorkoutSetUp;