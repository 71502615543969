// Libs
import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { ModalFooter } from "reactstrap";

// Components, Views, Screens
import Button from "../../../../../../base/components/Button";
import FormikInput from "../../../../../../base/components/FormikInput";


// Hooks, Utils, Helpers
import ToastService from "../../../../../../services/ToastService";
import { useService } from "../../../../../../base/hooks/useService";
import joinClassNames from "../../../../../../base/helpers/joinClassNames";
import { useModal } from "../../../../../../base/hooks/useModal";
import { useLoading } from "../../../../../../base/hooks/useLoading";
import { validationSchema, initialValues } from "./form";
import { BUTTON_COLORS } from "../../../../../../base/components/Button/appearance";
import TagService from "../../../../../../services/TagService";

export function TagModalForm({ isOpen, close, editTagId }) {
    /**
     * @type {TagService}
     */
    const tagService = useService(TagService);
    /**
     * @type {ToastService}
     */
    const toastService = useService(ToastService);

    const { Modal } = useModal();
    const [isLoading, { registerPromise }] = useLoading(true);

    const [isSubmitting, updateIsSubmitting] = useState(false);
    const [tag, setTag] = useState(null);

    const afterSuccess = () => {
        toastService.success("Tags has been successfully saved");
        updateIsSubmitting(false);
        close();
    };

    const apiFunction = (workType) => {
        if (editTagId) {
            return tagService.updateTag(editTagId, workType);
        }

        return tagService.createTag(workType);
    };

    const createWorkoutType = (values) => {
        updateIsSubmitting(true);

        apiFunction({
            name: values.name,
        })
            .then(afterSuccess)
            .finally(() => updateIsSubmitting(false));
    };

    const mapWorkoutTypeToForm = ({ name }) => ({ name });

    useEffect(() => {
        if (editTagId) {
            registerPromise(tagService.getTagById(editTagId))
                .then((data) => {
                    setTag(mapWorkoutTypeToForm(data));
                });
        }
    }, [editTagId]);

    return (
        <Modal isOpen={isOpen} toggle={() => close()} size="md">
            <Modal.Header onClose={() => close()}>
                {editTagId ? "Edit tag" : "Create tag"}
            </Modal.Header>

            <Formik
                initialValues={tag || initialValues}
                validationSchema={validationSchema}
                validateOnBlur
                onSubmit={createWorkoutType}
                enableReinitialize
            >
                {({ errors, handleSubmit }) => {

                    return (
                        <form
                            className={joinClassNames("form-horizontal p-2", isSubmitting && "pointer-events-none")}
                            onSubmit={handleSubmit}
                        >
                            <Modal.Body>
                                <div className="d-flex flex-column mb-3">
                                    <FormikInput
                                        name="name"
                                        label="Enter tag name:"
                                        placeholder="Enter tag name"
                                    />
                                </div>
                            </Modal.Body>

                            <ModalFooter>
                                <div className="d-flex justify-content-end gap-2">
                                    <Button
                                        color={BUTTON_COLORS.primaryOutline}
                                        type="button"
                                        onClick={close}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        color={BUTTON_COLORS.primary}
                                        type="submit"
                                        disabled={!!Object.keys(errors).length || isSubmitting}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </ModalFooter>
                        </form>
                    );
                }}
            </Formik>
        </Modal>);
}