import React from "react";
import {
    WORKOUT_INTENSITY_LABEL,
    WORKOUT_SECTION_TOPIC_TYPES,
    WORKOUT_SECTION_TOPIC_TYPES_LABELS
} from "../../../WorkoutForm/constants";
import Icon from "../../../../../../base/components/Icon";
import joinClassNames from "../../../../../../base/helpers/joinClassNames";

export const TableHeader = () => {
    return <div className="bg-body d-flex p-3">
        <div
            className="font-weight-semibold"
            style={{ width: '50%' }}>
            General
        </div>
        <div className="font-weight-semibold">
            Properties
        </div>
    </div>;
};

export const ExerciseGroupCollapseRows = ({ row }) => {

    return <>
        {
            row.exercises.map((exercise) => (
                <tr key={exercise.id} className="">
                    <td/>
                    <td/>
                    <td/>
                    <td>
                        {exercise.exerciseTitle}
                    </td>
                    <td>
                        {exercise.set.repetitions || "-"}
                    </td>
                    <td>
                        {exercise.set.time || "-"}
                    </td>
                    <td>
                        {exercise.set.rest || "-"}
                    </td>
                    <td>
                        {WORKOUT_INTENSITY_LABEL[exercise.set.intensity] ?? '-'}
                    </td>
                    <td>
                        {exercise.set.distance ? `${exercise.set.distance}km` : '-'}
                    </td>
                    <td>
                        {exercise.set.weight ? `${exercise.set.weight}kg` : '-'}
                    </td>
                </tr>
            )
            )}
    </>;

};

export const columns = [
    {
        Header: () => <div>
            <div className="mb-3">
                General
            </div>
            <div>
                #
            </div>
        </div>,
        accessor: "index",
        width: 55,
        Cell: ({ value }) => {
            return (
                <section className="ms-1">
                    {value}
                </section>
            );
        }
    },
    {
        Header: "Section",
        accessor: "name",
        width: 100,
        Cell: ({ value }) => {
            return (
                <section>
                    {value}
                </section>
            );
        }
    },
    {
        Header: "Type",
        accessor: "type",
        width: 100,
        Cell: ({ value, row: { original, index }, collapsedState, toggleCollapse, useCollapseRule }) => {
            const isCollapsed = collapsedState.includes(index);
            const canUseCollapse = useCollapseRule(original);

            if (Object.values(WORKOUT_SECTION_TOPIC_TYPES).includes(value?.toString())) {
                return <div
                    className="px-2 py-1"
                    onClick={(event) => {
                        event.stopPropagation();
                        if (!canUseCollapse) return;

                        toggleCollapse(original.id);
                    }}>
                    <div className="flex justify-content-between">
                        {WORKOUT_SECTION_TOPIC_TYPES_LABELS[value]}

                        {canUseCollapse &&
                            <Icon icon="arrowDown"
                                  className={joinClassNames("table-expand-icon", isCollapsed ? "rotate-180" : "rotate-360")}/>
                        }
                    </div>
                </div>;
            }

            return (
                <section>
                    {value}
                </section>
            );
        }
    },
    {
        Header: "Exercise",
        accessor: "exerciseTitle",
        width: 250,
        Cell: ({ value }) => {
            return (
                <section>
                    {value}
                </section>
            );
        }
    },
    {
        Header: () => <div>
            <div className="mb-3">
                Properties
            </div>
            <div>
                Reps
            </div>
        </div>,
        accessor: "set.repetitions",
        width: 100,
        Cell: ({ value }) => {
            return (
                <section>
                    {value ?? '-'}
                </section>
            );
        }
    },
    {
        Header: "Time",
        accessor: "set.time",
        width: 100,
        Cell: ({ value }) => {

            return (
                <section>
                    {value ?? '-'}
                </section>
            );
        }
    },
    {
        Header: "Rest",
        accessor: "set.rest",
        width: 100,
        Cell: ({ value }) => {

            return (
                <section>
                    {value ?? '-'}
                </section>
            );
        }
    },
    {
        Header: "Intensity",
        accessor: "set.intensity",
        width: 100,
        Cell: ({ value }) => {

            return (
                <section>
                    {WORKOUT_INTENSITY_LABEL[value] ?? '-'}
                </section>
            );
        }
    },
    {
        Header: "Distance",
        accessor: "set.distance",
        width: 100,
        Cell: ({ value }) => {

            return (
                <section>
                    {value ? `${value}km` : '-'}
                </section>
            );
        }
    },
    {
        Header: "Weight",
        accessor: "set.weight",
        width: 100,
        Cell: ({ value }) => {
            return (
                <section>
                    {value ? `${value}kg` : '-'}
                </section>
            );
        }
    }
];





