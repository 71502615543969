import React from 'react';
import { Container } from 'reactstrap';
import { Route, Routes } from 'react-router-dom';
import General from './General';
import { UI_CUSTOMISATION_ROUTES } from './config';
import { DarkModeContext } from "../../../base/context/darkMode";
import UISettings from "./UISettings";
import ThirdPartyServices from "./ThirdPartyServices";
import Emails from "./Emails";

export default function UICustomisation() {

    return (
        <Container fluid className="content">
            <DarkModeContext.Provider>
                <Routes>
                    <Route
                        path={UI_CUSTOMISATION_ROUTES.GENERAL}
                        element={<General/>}
                    />
                    <Route
                        path={UI_CUSTOMISATION_ROUTES.UI_SETTINGS}
                        element={<UISettings/>}
                    />

                    <Route
                        path={UI_CUSTOMISATION_ROUTES.EMAILS}
                        element={<Emails/>}
                    />

                    <Route
                        path={UI_CUSTOMISATION_ROUTES.THIRD_PARTY_SERVICES}
                        element={<ThirdPartyServices />}
                    />
                </Routes>
            </DarkModeContext.Provider>
        </Container>
    );
}