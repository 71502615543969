import { stringifyParams, useLocationQuery } from "../../../../base/hooks/useQueryString";
import { useHighlight } from "../../../../base/hooks/useHighlight";
import { tableCategories } from "../CreateEdit/constants";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import ConfirmPopup from "../../../../base/components/ConfirmPopup";
import Icon from "../../../../base/components/Icon";
import { RECOMMENDATIONS_LINKS } from "../config";
import { replaceUrlParams } from "../../../../base/helpers/url";
import { UncontrolledTooltip } from "reactstrap";
import joinClassNames from "../../../../base/helpers/joinClassNames";

export const columns = [
  {
    Header: "#",
    width: 35,
    Cell: (cellProps) => {
      const { params: { offset = 0 } } = useLocationQuery();
      return <label className="mb-0">{cellProps.row.index + 1 + (offset ?? 0)}</label>;
    },
  },
  {
    Header: "Name",
    accessor: "title",
    id: "title",
    width: 250,
    canSort: true,
    Cell: ({ value }) => {
      const { params } = useLocationQuery();
      const { decorateText } = useHighlight(params.search);
      return (
        <div {...decorateText(value)} className="table-cell-25 text-truncate"/>
      );
    },
  },
  {
    Header: "Category",
    accessor: "category",
    width: 250,
    canSort: true,
    Cell: ({ value }) => {
      const { params } = useLocationQuery();
      const { decorateText } = useHighlight(params.search);
      return (
        <div {...decorateText(tableCategories[value])} className="table-cell-25 text-truncate"/>
      )
    }
  },
  {
    Header: () => <div className="">Actions</div>,
    accessor: "actions",
    width: 150,
    Cell: ({ row: { original: { id, isArchived, isDeletable } }, onArchive, onDeleteItem }) => {
      const navigate = useNavigate();
      const [showArchivePopup, updateShowArchivePopup] = useState(false);
      const [showDeletePopup, updateShowDeletePopup] = useState(false);

      return (
        <section
          className="d-flex align-items-center recommendation-icons"
        >
          {
            showArchivePopup &&
            <ConfirmPopup
              isOpen={showArchivePopup}
              updateIsOpen={updateShowArchivePopup}
              onSubmit={() => onArchive(id, isArchived)}
              title="Archive recommendation"
              description={isArchived ? "Are you sure you want to unarchive chosen recommendations?" : "Are you sure you want to archive chosen recommendation?"}
              submitBtnText={isArchived ? "Unarchive" : "Archive"}
              className=""
            />
          }

          {showDeletePopup &&
            <ConfirmPopup
              isOpen={showDeletePopup}
              updateIsOpen={updateShowDeletePopup}
              onSubmit={() => onDeleteItem(id)}
              title="Delete recommendation"
              description="Are you sure you want to delete the chosen recommendation?"
              submitBtnText="Delete"
              className="upload-manually__popup"
            />
          }

          <Icon icon="copy" className="recommendation-icons__copy text-secondary"
                onClick={(event) => {
                  event.stopPropagation();
                  navigate({
                    pathname: RECOMMENDATIONS_LINKS.CREATE,
                    search: stringifyParams({ copy: id })
                  })
                }}
          />
          <Icon icon="edit" className="recommendation-icons__edit"
                onClick={(event) => {
                  event.stopPropagation();
                  navigate(replaceUrlParams(RECOMMENDATIONS_LINKS.EDIT, { id }))
                }}
          />
          <Icon icon={isArchived ? "unArchive" : "archive"} className="recommendation-icons__archive"
                onClick={(event) => {
                  event.stopPropagation();
                  updateShowArchivePopup(true)
                }}
          />

          <Icon
            id={`delete-${id}`}
            icon="trashIcon"
            className={joinClassNames(
              "recommendation-icons__trash outline-none",
              !isDeletable && "opacity-30 cursor-default"
            )}
            onClick={(event) => {
              if(!isDeletable) return;
              event.stopPropagation();
              updateShowDeletePopup(true)
            }}
          />

          <UncontrolledTooltip
            popperClassName={joinClassNames(
              "tooltip-alternative-name",
              (isDeletable) && "visibility-hidden"
            )}
            placement="bottom"
            target={`delete-${id}`}
          >
            Recommendation that is linked to the <br /> customer`s results can not be deleted.
          </UncontrolledTooltip>
        </section>
      );
    },
  },
];