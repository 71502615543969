export const CUSTOMER_MANAGEMENT_ROUTES = {
  BASE: "/customer-management/*",
  LIST: "/",
  DETAILS: "/details/:id",
  UPLOAD_MANUALLY: `/:id/upload-manually`,
};

export const CUSTOMER_MANAGEMENT_LINKS = {
  BASE: "/app/customer-management",
  LIST: "/app/customer-management",
  DETAILS: "/app/customer-management/:id",
  UPLOAD_MANUALLY: `/app/customer-management/:id/upload-manually`,
};