export const REC_SECTION_IDS = {
  criticalLow: 1,
  low: 2,
  subOptimal: 3,
  optimal: 4,
  supraOptimal: 5,
  high: 6,
  criticalHigh: 7,
}

const LOW_RANGE_KEYS = ["lowMin", "lowMax"];
const SUB_OPTIMAL_RANGE_KEYS = ["subOptimalMin", "subOptimalMax"];
const OPTIMAL_RANGE_KEYS = ["optimalMin", "optimalMax"];
const SUPRA_OPTIMAL_RANGE_KEYS = ["supraOptimalMin", "supraOptimalMax"];
const HIGH_RANGE_KEYS = ["highMin", "highMax"];

export const CRITICAL_LOW_KEY = "criticalLow";
export const LOW_KEY = "low";
export const SUB_OPTIMAL_KEY = "subOptimal";
export const OPTIMAL_KEY = "optimal";
export const SUPRA_OPTIMAL_KEY = "supraOptimal";
export const HIGH_KEY = "high";
export const CRITICAL_HIGH_KEY = "criticalHigh";

export const DNAm_RECOMMENDATIONS_GROUPS = {
  low: [],
  high: [REC_SECTION_IDS.high, REC_SECTION_IDS.criticalHigh]
}

export const SKIN_RECOMMENDATION_SECTIONS = [
  {
    name: OPTIMAL_KEY, label: "", rangesToApply: [
      "lowMin", "lowMax", "subOptimalMin", "subOptimalMax",
      "supraOptimalMin", "supraOptimalMax", "highMin", "highMax",
      "criticalLow", "criticalHigh", "criticalHighMax"
    ]
  }
];

export const DNAm_RECOMMENDATION_SECTIONS = [
  {
    name: HIGH_KEY,
    label: "High",
    rangesToApply: HIGH_RANGE_KEYS,
    idForGroup: REC_SECTION_IDS[HIGH_KEY],
    groupName: "high",
  },
  {
    name: CRITICAL_HIGH_KEY,
    label: "Critical high",
    rangesToApply: ["criticalHigh"],
    specialSymbol: "≥",
    idForGroup: REC_SECTION_IDS[CRITICAL_HIGH_KEY],
    groupName: "high",
  },
];

export const RECOMMENDATION_SECTIONS = [
  {
    name: CRITICAL_LOW_KEY,
    label: "Critical low",
    rangesToApply: ["criticalLow"],
    specialSymbol: "≤",
    idForGroup: REC_SECTION_IDS[CRITICAL_LOW_KEY],
    groupName: "low",
    canBeGroupedWith: [{ label: "Low", id: REC_SECTION_IDS.low }, {
      label: "Sub optimal",
      id: REC_SECTION_IDS.subOptimal
    }]
  },
  {
    name: LOW_KEY,
    label: "Low",
    rangesToApply: LOW_RANGE_KEYS,
    idForGroup: REC_SECTION_IDS[LOW_KEY],
    groupName: "low",
    canBeGroupedWith: [{ label: "Critical low", id: REC_SECTION_IDS.criticalLow }, {
      label: "Sub optimal",
      id: REC_SECTION_IDS.subOptimal
    }]
  },
  {
    name: SUB_OPTIMAL_KEY,
    label: "Sub optimal",
    rangesToApply: SUB_OPTIMAL_RANGE_KEYS,
    idForGroup: REC_SECTION_IDS[SUB_OPTIMAL_KEY],
    groupName: "low",
    canBeGroupedWith: [{ label: "Critical low", id: REC_SECTION_IDS.criticalLow }, {
      label: "Low",
      id: REC_SECTION_IDS.low
    }]
  },
  { name: OPTIMAL_KEY, label: "Optimal", rangesToApply: OPTIMAL_RANGE_KEYS },
  {
    name: SUPRA_OPTIMAL_KEY,
    label: "Supra optimal",
    rangesToApply: SUPRA_OPTIMAL_RANGE_KEYS,
    idForGroup: REC_SECTION_IDS[SUPRA_OPTIMAL_KEY],
    groupName: "high",
    canBeGroupedWith: [{ label: "High", id: REC_SECTION_IDS.high }, {
      label: "Critical high",
      id: REC_SECTION_IDS.criticalHigh
    }]
  },
  {
    name: HIGH_KEY,
    label: "High",
    rangesToApply: HIGH_RANGE_KEYS,
    idForGroup: REC_SECTION_IDS[HIGH_KEY],
    groupName: "high",
    canBeGroupedWith: [{ label: "Supra optimal", id: REC_SECTION_IDS.supraOptimal }, {
      label: "Critical high",
      id: REC_SECTION_IDS.criticalHigh
    }]
  },
  {
    name: CRITICAL_HIGH_KEY,
    label: "Critical high",
    rangesToApply: ["criticalHigh", "criticalHighMax"],
    idForGroup: REC_SECTION_IDS[CRITICAL_HIGH_KEY],
    groupName: "high",
    canBeGroupedWith: [{ label: "Supra optimal", id: REC_SECTION_IDS.supraOptimal }, {
      label: "High",
      id: REC_SECTION_IDS.high
    }]
  },
]

export const REC_RANGES_TO_APPLY_BY_ID = RECOMMENDATION_SECTIONS.reduce(
  (prevValue, currValue) => ({ ...prevValue, [currValue.idForGroup]: currValue }), {});

export const DEFAULT_GROUP_LOW = [REC_RANGES_TO_APPLY_BY_ID[REC_SECTION_IDS.criticalLow], REC_RANGES_TO_APPLY_BY_ID[REC_SECTION_IDS.low]];
export const DEFAULT_GROUP_HIGH = [REC_RANGES_TO_APPLY_BY_ID[REC_SECTION_IDS.high], REC_RANGES_TO_APPLY_BY_ID[REC_SECTION_IDS.criticalHigh]];

export const RECOMMENDATION_TABS = [
  { label: "All", value: "0" },
  { label: "Diet", value: "1" },
  { label: "Lifestyle", value: "2" },
  { label: "Supplement", value: "3" },
  { label: "Doctor", value: "4" },
  { label: "Test", value: "5" },
  { label: "Information", value: "6" },
]

export const SKIN_RECOMMENDATION_TABS = [
  ...RECOMMENDATION_TABS,
  { label: "Skin product", value: "7" },
  { label: "DIY", value: "8" },
  { label: "In home device", value: "9" },
]

export const LOW_TAB = {
  name: "low",
  label: "Critical low / Low",
  rangesToApply: ["criticalLow", ...LOW_RANGE_KEYS],
}

export const HIGH_TAB = {
  name: "high",
  label: "High / Critical high",
  rangesToApply: ["criticalHigh", ...HIGH_RANGE_KEYS],
}

export const WHAT_ARE_THE_RISKS_TABS = [
  LOW_TAB,
  HIGH_TAB,
]

export const WHAT_ARE_THE_CAUSES_TABS = [
  LOW_TAB,
  HIGH_TAB,
]

export const CAUSES_SKIN_TABS = [
  {
    name: "low",
    label: "Poor / Can do Better / Scope to improve",
    rangesToApply: [CRITICAL_LOW_KEY, ...LOW_RANGE_KEYS, ...SUB_OPTIMAL_RANGE_KEYS]
  }
]

export const CAUSES_DNAm_TABS = [
  {
    name: "high",
    label: "High / Critical high",
    rangesToApply: [CRITICAL_HIGH_KEY, ...HIGH_RANGE_KEYS]
  },
  {
    name: "low",
    label: "Optimal",
    rangesToApply: [CRITICAL_LOW_KEY]
  }
]

export const SKIN_RESULT_SUMMARY_SECTIONS = [
  {
    name: CRITICAL_LOW_KEY,
    secondKey: LOW_KEY,
    label: "Poor / Can do better",
    rangesToApply: [CRITICAL_LOW_KEY, ...LOW_RANGE_KEYS],
  },
  {
    name: SUB_OPTIMAL_KEY,
    label: "Scope to improve",
    rangesToApply: SUB_OPTIMAL_RANGE_KEYS,
  },
  {
    name: SUPRA_OPTIMAL_KEY,
    secondKey: HIGH_KEY,
    label: "Good / Great",
    rangesToApply: [...SUPRA_OPTIMAL_RANGE_KEYS, ...HIGH_RANGE_KEYS],
  },
  {
    name: CRITICAL_HIGH_KEY,
    label: "Optimal",
    rangesToApply: [CRITICAL_HIGH_KEY],
  },
]

export const DNAm_RESULT_SUMMARY_SECTIONS = [
  {
    name: CRITICAL_HIGH_KEY,
    secondKey: HIGH_KEY,
    label: "High / Critical high",
    rangesToApply: [CRITICAL_HIGH_KEY, ...HIGH_RANGE_KEYS],
  },
  {
    name: CRITICAL_LOW_KEY,
    label: "Optimal",
    rangesToApply: [CRITICAL_LOW_KEY],
  },
]

export const SKIN_RANGES_VIEW_SECTIONS = [
  {
    label: "Poor",
    rangesToApply: [CRITICAL_LOW_KEY],
    summaryKey: CRITICAL_LOW_KEY,
    specialSymbol: "≤",
  },
  {
    label: "Can do better",
    rangesToApply: LOW_RANGE_KEYS,
    summaryKey: LOW_KEY,
  },
  {
    label: "Scope to improve",
    rangesToApply: SUB_OPTIMAL_RANGE_KEYS,
    summaryKey: SUB_OPTIMAL_KEY,
  },
  {
    label: "Good",
    rangesToApply: SUPRA_OPTIMAL_RANGE_KEYS,
    summaryKey: SUPRA_OPTIMAL_KEY,
  },
  {
    label: "Great",
    rangesToApply: HIGH_RANGE_KEYS,
    summaryKey: HIGH_KEY,
  },
  {
    label: "Optimal",
    rangesToApply: [CRITICAL_HIGH_KEY],
    specialSymbol: "≥",
    summaryKey: CRITICAL_HIGH_KEY,
  },
]

export const DNAm_RANGES_VIEW_SECTIONS = [
  {
    label: "Optimal",
    summaryKey: OPTIMAL_KEY,
    specialSymbol: "≤",
    rangesToApply: [CRITICAL_LOW_KEY],
  },
  {
    label: "High",
    summaryKey: HIGH_KEY,
    rangesToApply: HIGH_RANGE_KEYS,
  },
  {
    label: "Critical high",
    summaryKey: CRITICAL_HIGH_KEY,
    specialSymbol: "≥",
    rangesToApply: [CRITICAL_HIGH_KEY],
  },
];

export const DNAm_RECOMMENDATION_VIEW_SECTIONS = [
  {
    name: HIGH_KEY,
    label: "High",
    rangesToApply: HIGH_RANGE_KEYS,
    idForGroup: REC_SECTION_IDS[HIGH_KEY],
    groupName: "high",
    canBeGroupedWith: [{
      label: "Critical high",
      id: REC_SECTION_IDS.criticalHigh
    }]
  },
  {
    name: CRITICAL_HIGH_KEY,
    label: "Critical high",
    rangesToApply: [CRITICAL_HIGH_KEY],
    specialSymbol: "≥",
    idForGroup: REC_SECTION_IDS[CRITICAL_HIGH_KEY],
    groupName: "high",
    canBeGroupedWith: [{
      label: "High",
      id: REC_SECTION_IDS.high
    }]
  },
];
