import {
  DEFAULT_GROUP_HIGH,
  DEFAULT_GROUP_LOW,
  REC_SECTION_IDS,
  CRITICAL_LOW_KEY,
  LOW_KEY,
  SUB_OPTIMAL_KEY,
  OPTIMAL_KEY,
  SUPRA_OPTIMAL_KEY,
  HIGH_KEY,
  CRITICAL_HIGH_KEY
} from "./Recommendations/constants";

export const MAX_ALTERNATIVE_NAMES = 10;
export const MIN_STRING_LENGTH = 1;
export const MAX_BIOMARKERS_NAME_LENGTH = 200;
export const MAX_BIOMARKERS_SHORT_NAME_LENGTH = 12;
export const MAX_BIOMARKERS_LABEL_NAME_LENGTH = 4;
export const MAX_RULE_NAME_LENGTH = 100;
export const MAX_RECOMMENDATION_LENGTH = 100;
export const MAX_RICH_TEXT_LENGTH = 10000;
export const MAX_TAG_NAME_LENGTH = 50;
export const MAX_SKU_LENGTH = 20;
export const MAX_TAG_STRING_LENGTH = 255;

export const MAX_LAB_NAME_LENGTH = 50;

export const MAX_SKIN_RECOMMENDATIONS = 30;
export const MAX_BLOOD_RECOMMENDATIONS = 100;

export const CATEGORY_OPTIONS = [
  { label: "Biochemistry", value: "Biochemistry" },
  { label: "Liver Function", value: " Liver Function" },
  { label: "Cholesterol (Lipids)", value: "Cholesterol (Lipids)" },
  { label: "Haematinics", value: "Haematinics" },
  { label: "Thyroid Function", value: "Thyroid Function" },
  { label: "Vitamins", value: "Vitamins" },
  { label: "Hormones", value: "Hormones" },
  { label: "Kidney Function", value: "Kidney Function" },
  { label: "Full Blood Count", value: "Full Blood Count" },
  { label: "Bone Screen", value: "Bone Screen" },
  { label: "Minerals", value: "Minerals" },
]

export const BiomarkerTypes = {
  blood: 1,
  bloodRule: 2,
  skin: 3,
  skinRule: 4,
  dnaAge: 5,
  dnaAgeRule: 6,
}

export const ruleTypes = [
  BiomarkerTypes.bloodRule,
  BiomarkerTypes.skinRule,
  BiomarkerTypes.dnaAgeRule,
];

export const BiomarkerCategoriesByRule = {
  [BiomarkerTypes.bloodRule]: [
    'Biochemistry',
    'Liver Function',
    'Cholesterol (Lipids)',
    'Haematinics',
    'Thyroid Function',
    'Vitamins',
    'Hormones',
    'Kidney Function',
    'Full Blood Count',
    'Bone Screen',
    'Minerals',
    'Prostate Function'
  ],
  [BiomarkerTypes.skinRule]: ['Skin'],
  [BiomarkerTypes.dnaAgeRule]: ['DNAm age']
};

export const UNIT_OPTIONS = [
  { label: "umol/L", value: "umol/L" },
  { label: "ml/min/1.73m2", value: "ml/min/1.73m2" },
  { label: "nmol/L", value: "nmol/L" },
  { label: "mmol/mol", value: "mmol/mol" },
  { label: "g/L", value: "g/L" },
  { label: "U/L", value: "U/L" },
  { label: "mmol/L", value: "mmol/L" },
  { label: "Ratio", value: "Ratio" },
  { label: "ug/L", value: "ug/L" },
  { label: "pmol/L", value: "pmol/L" },
  { label: "mIU/L", value: "mIU/L" },
  { label: "kU/L", value: "kU/L" },
  { label: "kIU/L", value: "kIU/L" },
  { label: "MDRD", value: "MDRD" },
  { label: "%", value: "%" },
  { label: "mm/hour", value: "mm/hour" },
  { label: "10 9/L", value: "10 9/L" },
  { label: "10 12/L", value: "10 12/L" },
  { label: "L/L", value: "L/L" },
  { label: "pg", value: "pg" },
  { label: "fL", value: "fL" },
  { label: "mg/L", value: "mg/L" },
  { label: "IU/L", value: "IU/L" },
]

export const SUMMARY_RICH_TEXT = "summary";
export const WHATISIT_RICH_TEXT = "whatIsIt";
export const CAUSES_RICH_TEXT = "whatAreTheCauses";
export const RISKS_RICH_TEXT = "whatAreTheRisks";
export const WHATTODO_RICH_TEXT = "whatCanYouDo";

const SUMMARY_SECTION = {
  itemKey: SUMMARY_RICH_TEXT,
  label: "In a Nutshell",
  description: "Enter information about the origin, prevalence and statistics.",
  placeholder: "Enter information about the origin, prevalence and statistics..."
}

export const arrayOfRichTextSections = [
  SUMMARY_SECTION,
  {
    itemKey: WHATISIT_RICH_TEXT,
    label: "The Test",
    description: "Enter information about testing this biomarker.",
    placeholder: "Enter information about testing this biomarker..."
  },
  {
    itemKey: WHATTODO_RICH_TEXT,
    label: "Biomarkers function",
    description: "Enter information biomarkers function and it’s effects.",
    placeholder: "Enter information biomarkers function and it’s effects..."
  },
];

export const arrayOfSkinSections = [
  SUMMARY_SECTION,
]

export const CRITICAL_LOW_RANGE_KEYS = ["criticalLow"]
export const LOW_RANGE_KEYS = ["lowMin", "lowMax"];
export const SUB_OPTIMAL_RANGE_KEYS = ["subOptimalMin", "subOptimalMax"];
export const OPTIMAL_RANGE_KEYS = ["optimalMin", "optimalMax"];
export const SUPRA_OPTIMAL_RANGE_KEYS = ["supraOptimalMin", "supraOptimalMax"];
export const HIGH_RANGE_KEYS = ["highMin", "highMax"];
export const CRITICAL_HIGH_RANGE_KEYS = ["criticalHigh"]
export const CRITICAL_HIGH_MAX_RANGE_KEYS = ["criticalHighMax"]

export const RANGES_PER_RANGE_NAME = [
  {
    rangeName: CRITICAL_LOW_KEY,
    ranges: CRITICAL_LOW_RANGE_KEYS
  },
  {
    rangeName: LOW_KEY,
    ranges: LOW_RANGE_KEYS
  },
  {
    rangeName: SUB_OPTIMAL_KEY,
    ranges: SUB_OPTIMAL_RANGE_KEYS
  },
  {
    rangeName: OPTIMAL_KEY,
    ranges: OPTIMAL_RANGE_KEYS
  },
  {
    rangeName: SUPRA_OPTIMAL_KEY,
    ranges: SUPRA_OPTIMAL_RANGE_KEYS
  },
  {
    rangeName: HIGH_KEY,
    ranges: HIGH_RANGE_KEYS
  },
  {
    rangeName: CRITICAL_HIGH_KEY,
    ranges: CRITICAL_HIGH_RANGE_KEYS
  },
]

export const RANGE_INPUTS = [
  {
    label: "Low",
    placeholder: "",
    itemKeys: LOW_RANGE_KEYS,
    groupItems: DEFAULT_GROUP_LOW,
    idForGroup: REC_SECTION_IDS.low,
    groupName: "low"
  },
  {
    label: "Sub optimal",
    placeholder: "",
    itemKeys: SUB_OPTIMAL_RANGE_KEYS,
    idForGroup: REC_SECTION_IDS.subOptimal,
    groupName: "low"
  },
  { label: "Optimal", placeholder: "", itemKeys: OPTIMAL_RANGE_KEYS, },
  {
    label: "Supra optimal",
    placeholder: "",
    itemKeys: SUPRA_OPTIMAL_RANGE_KEYS,
    idForGroup: REC_SECTION_IDS.supraOptimal,
    groupName: "high"
  },
  {
    label: "High",
    placeholder: "",
    itemKeys: HIGH_RANGE_KEYS,
    groupItems: DEFAULT_GROUP_HIGH,
    idForGroup: REC_SECTION_IDS.high,
    groupName: "high"
  },
]

export const allRangesKeys = [
  "lowMin",
  "lowMax",
  "subOptimalMin",
  "subOptimalMax",
  "optimalMin",
  "optimalMax",
  "supraOptimalMin",
  "supraOptimalMax",
  "highMin",
  "highMax",
  "criticalLow",
  "criticalHigh",
  "criticalHighMax"
];

export const RECOMMENDATION_TYPES_FOR_OUTPUT = {
  [1]: "criticalLow",
  [2]: "low",
  [3]: "subOptimal",
  [4]: "optimal",
  [5]: "supraOptimal",
  [6]: "high",
  [7]: "criticalHigh",
}

export const SKIN_CATEGORY_NAME = "Skin";
export const DNAm_CATEGORY_NAME = "DNAm age";
export const DNAm_CATEGORY_ID = 13;
export const SKIN_CATEGORY_ID = 12;
export const BLOOD_RULE_ID = 2;
export const SKIN_RULE_ID = 4;
export const DNAm_RULE_ID = 6;

export const DNA_RANGE_INPUTS = [
  {
    label: "High",
    placeholder: "",
    itemKeys: HIGH_RANGE_KEYS,
    copyResultSummary: "criticalHigh",
    currentRangeName: "high"
  },
]

export const SKIN_RANGE_INPUTS = [
  {
    label: "Can do better",
    placeholder: "",
    itemKeys: LOW_RANGE_KEYS,
    copyResultSummary: "criticalLow",
    currentRangeName: "low"
  },
  {
    label: "Scope to improve",
    placeholder: "",
    itemKeys: SUB_OPTIMAL_RANGE_KEYS,
  },
  {
    label: "Good",
    placeholder: "",
    itemKeys: SUPRA_OPTIMAL_RANGE_KEYS,
    copyResultSummary: "high",
    currentRangeName: "supraOptimal"
  },
  {
    label: "Great",
    placeholder: "",
    itemKeys: HIGH_RANGE_KEYS,
    copyResultSummary: "supraOptimal",
    currentRangeName: "high"
  },
]

export const HautAiMetricTypesArray = [
  { label: "Acne", value: 1, id: 1 },
  { label: "Eye Area Condition", value: 2, id: 2 },
  { label: "Hydration", value: 3, id: 3 },
  { label: "Lines", value: 4, id: 4 },
  { label: "Pigmentation", value: 5, id: 5 },
  { label: "Pores", value: 6, id: 6 },
  { label: "Redness", value: 7, id: 7 },
  { label: "Translucency", value: 8, id: 8 },
  { label: "Uniformness", value: 9, id: 9 },
  // { label: "Perceived age", value: 10, id: 10 },
]

export const RangeList = [
  ['lowMin', 'lowMax'],
  ['subOptimalMin', 'subOptimalMax'],
  ['optimalMin', 'optimalMax'],
  ['supraOptimalMin', 'supraOptimalMax'],
  ['highMin', 'highMax'],
  ['criticalHigh', 'criticalHighMax'],
]
