import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import Table from "../../../../../base/components/Table";
import ConfirmPopup from "../../../../../base/components/ConfirmPopup";
import {
    SearchPlaceholder,
    TableHeader,
    columns,
    NoWorkoutPlaceholder, WORKOUT_ACTIONS
} from "./components";

import { DEFAULT_TABLE_LIMIT } from "../../../../../base/constants/shared";
import {
    usePaginationProvider,
    useLocationSource,
    useSearchProvider, useSortProvider, useFilterProvider
} from "../../../../../base/components/Table/hooks";
import ToasterService from "../../../../../services/ToastService";
import { useService } from "../../../../../base/hooks/useService";
import { useLoading } from "../../../../../base/hooks/useLoading";
import { useQueryString } from "../../../../../base/hooks/useQueryString";
import { LIMIT_OPTIONS, MIN_SEARCH_LENGTH } from "../../../../../base/constants/pagination";
import { FITNESS_GROUP_LINKS } from "../../config";
import WorkoutService from "../../../../../services/WorkoutService";

export default function Workouts() {

    /**
     * @type {ToasterService}
     */
    const toastService = useService(ToasterService);

    /**
     * @type {WorkoutService}
     */
    const workoutService = useService(WorkoutService);
    const [isLoading, { registerPromise }] = useLoading(false);
    const { search: locationSearch, pathname } = useLocation();
    const {
        params: {
            limit = DEFAULT_TABLE_LIMIT,
            offset = 0,
            search,
            name,
            status,
            statusFilter
        }
    } = useQueryString(locationSearch);

    const [workouts, updateWorkouts] = useState([]);
    const [pagination, updatePagination] = useState({});
    const [showDeletePopup, updateShowDeletePopup] = useState(null);
    const navigate = useNavigate()

    const locationSource = useLocationSource();

    const sortKeys = ["name", "status"];
    const getSortScope = (key) => [...sortKeys.filter(item => item !== key), "offset"];

    const searchRequest = useMemo(() => search && search.toString()?.trim()?.length >= MIN_SEARCH_LENGTH
            ? search
            : null,
        [search]);

    const paginationProvider = usePaginationProvider({
        source: locationSource,
        alias: "offset",
        scope: "",
        fallback: 0
    });

    const searchProvider = useSearchProvider({
        source: locationSource,
        scope: "",
        alias: 'search',
        onApplyClearScope: ["offset"]
    });

    const limitProvider = usePaginationProvider({
        source: locationSource,
        alias: "limit",
        scope: "",
        fallback: 10,
        onApplyClearScope: ["offset"]
    });

    const nameSortProvider = useSortProvider({
        source: locationSource,
        alias: "name",
        scope: "",
        onApplyClearScope: getSortScope("name")
    });

    const statusSortProvider = useSortProvider({
        source: locationSource,
        alias: "status",
        scope: "",
        onApplyClearScope: getSortScope("status")
    });

    const filterProvider = useFilterProvider({
        source: locationSource,
        scope: "",
        alias: 'statusFilter',
        onApplyClearScope: ["offset"]
    });

    const handleClickCreate = () => {
        navigate(FITNESS_GROUP_LINKS.FORM_WORKOUTS);
    };




    const handleClickEdit = (id) => {
        const queryParams = new URLSearchParams({ editWorkoutId: id }).toString();
        navigate(`${FITNESS_GROUP_LINKS.FORM_WORKOUTS}/?${queryParams}`);
    };

    const deleteWorkout = (id) => {
        workoutService.deleteWorkout(id)
            .then(() => {
                getWorkouts()
                toastService.success("The workout has been successfully deleted")
            })
    }

    const getWorkouts = useCallback(() => {
        const [orderBy, orderType] = Object.entries({
            name,
            status
        }).find(([_, value]) => value) || [];

        registerPromise(workoutService.getWorkout({
            limit,
            offset,
            query: searchRequest,
            orderBy,
            orderType,
            status: statusFilter
        })).then(({ data, pagination }) => {
            updateWorkouts(data)
            updatePagination(pagination)
        })
    }, [limit, offset, searchRequest, name, status, statusFilter]);

    useEffect(() => {
        getWorkouts();
    }, [getWorkouts]);

    return (
        <>
            <Table
                columns={workouts.length ? columns : []}
                data={workouts}
                loading={isLoading}
                HeaderComponent={TableHeader}
                totalCount={pagination.totalCount}
                limit={limitProvider.getValue()}
                offset={offset}
                paginationProvider={paginationProvider}
                searchProvider={searchProvider}
                commonPlaceholder={<NoWorkoutPlaceholder/>}
                placeholderForSearch={<SearchPlaceholder/>}
                actions={{
                    [WORKOUT_ACTIONS.EDIT]: handleClickEdit,
                    [WORKOUT_ACTIONS.DELETE]: updateShowDeletePopup
                }}
                sortProviders={{
                    name: nameSortProvider,
                    status: statusSortProvider
                }}
                filterProvider={filterProvider}
                isLimitEditable
                limitProvider={limitProvider}
                limitOptions={LIMIT_OPTIONS}
                headerActions={{ handleClickCreate }}
                isRowClick
            />
            {showDeletePopup &&
                <ConfirmPopup
                    isOpen={showDeletePopup}
                    updateIsOpen={updateShowDeletePopup}
                    onSubmit={() => {
                        deleteWorkout(showDeletePopup)
                        updateShowDeletePopup(null);

                    }}
                    title="Delete workout"
                    description="Are you sure you want to delete this workout?"
                    submitBtnText="Delete Workout"
                    className="upload-manually__popup"
                />
            }
        </>
    );
}
