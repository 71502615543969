import Http from "./Http";

class MealplansService extends Http {
    static $displayName = "MealplansService";

    async getMealplans(params) {
        return await this.get(`/mealplans`, { params });
    }

    async getMealplanById(id) {
        return await this.get(`/mealplans/${id}`);
    }

    createMealplans(values) {
        return this.post(`/mealplans`, values);
    }

    deleteMealplans(id) {
        return this.delete(`/mealplans/${id}`);
    }

    updateMealplans(id, values) {
        return this.put(`/mealplans/${id}`, values);
    }

    matchProgram(values) {
        return this.post(`/programs/get-programs-playground`, values);
    }
}

export default MealplansService;
