import React from "react";
import { useField } from "formik";

import FormikInput from "../../../../../../../../../../base/components/FormikInput";
import Icon from "../../../../../../../../../../base/components/Icon";
import TimeInput from "../../../../../../../../../../base/components/TimeInput";
import FormikReactSelect from "../../../../../../../../../../base/components/FormikReactSelect";

import { MIN_VALUE, MIN_VALUE_REPETITION } from "./constants";
import { EXERCISE_TYPES } from "../../../../../../../ExerciseForm/constants";
import { WORKOUT_INTENSITY, WORKOUT_INTENSITY_LABEL } from "../../../../../../constants";

export const setInitialValues = {
    reps: null,
    rest: null,
    distance: null,
    weight: null,
    intensity: null,
    duration: null,
}

export const intensityOptions = Object.values(WORKOUT_INTENSITY).map((value) => ({
    value,
    id: value,
    label: WORKOUT_INTENSITY_LABEL[value]
}));

const getRepsColumn = ({ name, isRequired = false }) => {
    return {
        Header: "Reps (x)",
        headerClassName: 'bg-light-gray',
        accessor: "reps",
        width: 100,
        Cell: ({ row: { index } }) => {
            return (
                <section>
                    <FormikInput
                        name={`${name}[${index}].reps`}
                        type="number"
                        min={MIN_VALUE_REPETITION}
                        placeholder={isRequired ? "0 (required)" : "0 (optional)"}
                        withoutErrorText
                    />
                </section>
            );
        }
    };
};

const getRestColumn = ({ name, isRequired = false }) => {
    return {
        Header: "Rest (s)",
        headerClassName: 'bg-light-gray',
        accessor: "rest",
        width: 100,
        Cell: ({ row: { index } }) => {
            const [field] = useField(`${name}[${index}].rest`);

            return (
                <section>
                    <FormikInput
                        name={`${name}[${index}].rest`}
                        type="number"
                        min={MIN_VALUE}
                        placeholder={isRequired ? "0 (required)" : "0 (optional)"}
                        withoutErrorText
                    />
                </section>
            );
        }
    };
};

const getWeightColumn = ({ name, isRequired = false }) => {
    return {
        Header: "Weight (kg)",
        headerClassName: 'bg-light-gray',
        accessor: "weight",
        width: 100,
        Cell: ({ row: { index } }) => {
            const [field] = useField(`${name}[${index}].weight`);

            return (
                <section>
                    <FormikInput
                        name={`${name}[${index}].weight`}
                        type="number"
                        min={MIN_VALUE}
                        placeholder={isRequired ? "0 (required)" : "0 (optional)"}
                        withoutErrorText
                    />
                </section>
            );
        }
    };
};

const getDistanceColumn = ({ name, isRequired = false }) => {
    return {
        Header: "Distance (km)",
        headerClassName: 'bg-light-gray',
        accessor: "distance",
        width: 100,
        Cell: ({ row: { index } }) => {
            const [field] = useField(`${name}[${index}].distance`);

            return (
                <section>
                    <FormikInput
                        name={`${name}[${index}].distance`}
                        type="number"
                        min={MIN_VALUE}
                        placeholder={isRequired ? "0 (required)" : "0 (optional)"}
                        withoutErrorText
                    />
                </section>
            );
        }
    };
};

const getIntensityColumn = ({ name, isRequired = false }) => {
    return {
        Header: "Intensity",
        headerClassName: 'bg-light-gray',
        accessor: "intensity",
        width: 170,
        Cell: ({ row: { index } }) => {
            const [{ value }, meta, {setValue}] = useField(`${name}[${index}].intensity`);

            const selectValue = intensityOptions.find(({id}) => id === value)

            return (
                <section>
                    <FormikReactSelect
                        name="intensity"
                        options={intensityOptions}
                        value={selectValue}
                        setFieldValue={(name, value) => setValue(value)}
                        placeholder={isRequired ? "Intensity (required)" : "Intensity (optional)"}
                    />
                </section>
            );
        }
    };
};

const getDurationColumn = ({ name, isRequired = false }) => {
    return {
        Header: "Duration",
        headerClassName: 'bg-light-gray',
        accessor: "duration",
        width: 150,
        Cell: ({ row: { index } }) => {
            const [{ value }, meta, {setValue}] = useField(`${name}[${index}].duration`);

            return (
                <section>
                    <TimeInput
                        placeholder={isRequired ? "00m 00s (required)" : "00m 00s (optional)"}
                        name="duration"
                        value={value}
                        handleChange={(value) => {
                            if (Number(value.minutes) || Number(value.seconds)) {
                                return setValue(value)
                            }

                            return setValue(null)
                        }}
                        withoutHours
                    />
                </section>
            );
        }
    };
};

const getActionColumn = () => {
    return {
        Header: "Action",
        headerClassName: 'bg-light-gray',
        width: 50,
        Cell: ({ row: { index }, actions, data }) => {

            const isShowAction = data.length > 1

            return (
                <section className="d-flex justify-content-end align-items-center h-100">
                    {isShowAction && <Icon
                        icon={"trashIcon"}
                        width={20}
                        height={20}
                        onClick={(event) => {
                            event.stopPropagation();
                            actions.delete(index);
                        }}
                        className="cursor-pointer"
                    />}
                </section>
            );
        }
    };
};

const getExerciseWeightColumns = (name) => {
    return [
        getRestColumn({ name, isRequired: true }),
        getRepsColumn({ name, isRequired: true }),
        getWeightColumn({ name, isRequired: false }),
        getActionColumn()
    ]
}

const getExerciseCardioColumns = (name) => {
    return [
        getDurationColumn({ name, isRequired: true }),
        getRestColumn({ name, isRequired: true }),
        getIntensityColumn({ name, isRequired: false }),
        getDistanceColumn({ name, isRequired: false }),
        getActionColumn()
    ]
}

const getExerciseMobilityColumns = (name) => {
    return [
        getRepsColumn({ name, isRequired: false }),
        getDurationColumn({ name, isRequired: false }),
        getWeightColumn({ name, isRequired: false }),
        getRestColumn({ name, isRequired: false }),
        getIntensityColumn({ name, isRequired: false }),
        getDistanceColumn({ name, isRequired: false }),
        getActionColumn()
    ]
}

export const columnGenerationFunctionsByExerciseType = {
    [EXERCISE_TYPES.WEIGHTS]: getExerciseMobilityColumns,
    [EXERCISE_TYPES.CARDIO]: getExerciseMobilityColumns,
    [EXERCISE_TYPES.MOBILITY]: getExerciseMobilityColumns,
    [EXERCISE_TYPES.OTHER]: getExerciseMobilityColumns,
    [EXERCISE_TYPES.CORE]: getExerciseMobilityColumns,
    [EXERCISE_TYPES.UPPER_BODY]: getExerciseMobilityColumns,
    [EXERCISE_TYPES.LOWER_BODY]: getExerciseMobilityColumns,
    [EXERCISE_TYPES.BODY_WEIGHTS]: getExerciseMobilityColumns,
}