import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import Table from "../../../../base/components/Table";
import { DEFAULT_DEBOUNCE_DELAY, DEFAULT_TABLE_LIMIT } from "../../../../base/constants/shared";
import {
    usePaginationProvider,
    useLocationSource, useSearchProvider, useFilterProvider, useSortProvider
} from "../../../../base/components/Table/hooks";
import { useDebounce } from "../../../../base/hooks/useDebounce";
import { SearchPlaceholder, TableHeader, columns, NoOrganisationPlaceholder } from "./components";
import ToasterService from "../../../../services/ToastService";
import { useService } from "../../../../base/hooks/useService";
import { useLoading } from "../../../../base/hooks/useLoading";
import { useQueryString } from "../../../../base/hooks/useQueryString";
import ConfirmPopup from "../../../../base/components/ConfirmPopup";
import OrganisationsService from "../../../../services/OrganisationsService";
import BaseLayoutWithCard from "../../../../base/components/BaseLayoutWithCard";

const breadcrumbs = {
    title: "Organisations Management", breadcrumbItems: []
};

export default function OrganisationsList() {
    /**
     * @type {OrganisationsService}
     */
    const organisationsService = useService(OrganisationsService);
    /**
     * @type {ToasterService}
     */
    const toastService = useService(ToasterService);

    const [isLoading, { registerPromise }] = useLoading(true);
    const { search: locationSearch, pathname } = useLocation();
    const {
        params: {
            limit = DEFAULT_TABLE_LIMIT,
            offset = 0,
            search,
            name,
            domain,
            status,
            statusFilter,
        }
    } = useQueryString(locationSearch);

    const [organisations, updateOrganisations] = useState([]);
    const [organisationsPagination, updateOrganisationsPagination] = useState({});
    const [showDeletePopup, updateShowDeletePopup] = useState(null);
    const navigate = useNavigate();

    const locationSource = useLocationSource();

    const paginationProvider = usePaginationProvider({
        source: locationSource,
        alias: "offset",
        scope: "",
        fallback: 0
    });

    const [debouncedSearch] = useDebounce(search, DEFAULT_DEBOUNCE_DELAY, () => paginationProvider.setValue(0));

    const generateFilterProvidersParams = (names = []) => {
        return {
            source: locationSource,
            alias: names,
            scope: "",
            onApplyClearScope: ["offset"]
        };
    };

    const searchProvider = useSearchProvider({
        source: locationSource,
        scope: "",
        alias: 'search',
        onApplyClearScope: [""]
    });

    const filterProvider = useFilterProvider({
        source: locationSource,
        scope: "",
        alias: 'statusFilter',
        onApplyClearScope: ["offset"]
    });

    const sortKeys = ["name", "domain", "status"];
    const getSortScope = (key) => [...sortKeys.filter(item => item !== key), "offset"];

    const nameSortProvider = useSortProvider({
        source: locationSource,
        alias: "name",
        scope: "",
        onApplyClearScope: getSortScope("name")
    });

    const domainSortProvider = useSortProvider({
        source: locationSource,
        alias: "domain",
        scope: "",
        onApplyClearScope: getSortScope("domain")
    });

    const statusSortProvider = useSortProvider({
        source: locationSource,
        alias: "status",
        scope: "",
        onApplyClearScope: getSortScope("status")
    });


    const getOrganisations = useCallback(() => {
        const [orderBy, orderType] = Object.entries({
            name,
            domain,
            status,
        }).find(([_, value]) => value) || [];

        registerPromise(organisationsService.getOrganisations({
            limit,
            offset,
            orderBy,
            orderType,
            query: search,
            status: statusFilter
        }).then(({ data, pagination }) => {
                updateOrganisations(data);
                updateOrganisationsPagination(pagination);
            }));
    }, [limit, offset, name, domain, status, search, statusFilter]);

    const handleClickDeleteOrganisation = (organisation) => {
        updateShowDeletePopup(organisation);
    };

    const deleteOrganisation = (organisation) => {
        registerPromise(organisationsService.changeStatus(organisation.id, DELETED_STATUS_ID))
          .then(() => {
              getOrganisations()
              toastService.success("The organisation has been successfully deleted")
          });
    };

    useEffect(() => {
        getOrganisations();
    }, [getOrganisations]);

    return (
        <>
            <BaseLayoutWithCard breadcrumbs={breadcrumbs} >
            <Table
                columns={organisations.length ? columns : []}
                data={organisations}
                loading={isLoading}
                HeaderComponent={TableHeader}
                totalCount={organisationsPagination.totalCount}
                limit={DEFAULT_TABLE_LIMIT}
                offset={offset}
                paginationProvider={paginationProvider}
                filterProvider={filterProvider}
                searchProvider={searchProvider}
                commonPlaceholder={<NoOrganisationPlaceholder/>}
                placeholderForSearch={<SearchPlaceholder/>}
                isRowClick
                actions={{

                }}
                sortProviders={{
                    name: nameSortProvider,
                    domain: domainSortProvider,
                    status: statusSortProvider,
                }}
                tableClassName="overflow-x-visible"
            />

            {showDeletePopup &&
                <ConfirmPopup
                    isOpen={showDeletePopup}
                    updateIsOpen={updateShowDeletePopup}
                    onSubmit={() => {
                        deleteOrganisation(showDeletePopup);
                        updateShowDeletePopup(null);
                    }}
                    title="Delete organisation"
                    description="Are you sure you want to delete the chosen organisation?"
                    submitBtnText="Delete"
                    className="upload-manually__popup"
                />
            }
            </BaseLayoutWithCard>
        </>
    );
}
