export const toggleSelectItem = (item, value, onChange, keyId = "id") => {
    const itemIndex = value.findIndex(selectedItem => {
        return selectedItem[keyId] === item[keyId]
    });

    if (itemIndex !== -1) {
        const updatedValue = value.filter(selectedItem => selectedItem[keyId]!== item[keyId]);
        onChange(updatedValue);
    } else {
        const updatedValue = [...value, item];
        onChange(updatedValue);
    }
};
