import Http from "./Http";


class FoodIntoleranceService extends Http {
  static $displayName = "FoodIntoleranceService";

  async getFoodIntoleranceInfo() {
    return await this.get("/admins/food-intolerance");
  }

  async changeFoodIntoleranceInfo(data) {
    return await this.post("/admins/food-intolerance", data);
  }
}

export default FoodIntoleranceService;
