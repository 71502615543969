import React from "react";
import Wrapper from "./Wrapper";
import { DATE_FORMAT_STORYBLOK, formatDate } from "../../../helpers/date";
import { DEFAULT_DATE_OUTPUT_FORMAT } from "../../../constants/date";
import {ReactComponent as LogoIcon} from "../assets/image/logo.svg";

const Banner = ({ blok }) => {
    if (!blok) {
        return null;
    }

    const date = formatDate(blok.date, DATE_FORMAT_STORYBLOK, DEFAULT_DATE_OUTPUT_FORMAT)

    const imageStyle = {
        width: "44px",
        height: "44px",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "lightgray",
    }

    return (
        <Wrapper blok={{ ...blok }}>
            <div className="d-flex align-items-center gap-3">
                <div style={imageStyle}>
                    <LogoIcon style={{width: "24px", height: "24px"}} />
                </div>

                <span>
                    By Optimally Me
                    {blok.date && <span className="text-description"> | {date}</span>}
                </span>
            </div>
        </Wrapper>
    );
};

export default Banner;
