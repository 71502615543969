export const CUSTOMER_RESULTS_ROUTES = {
  BASE: "/customer-results/*",
  LIST: "/",
  // DETAILS: "/:id",
};

export const CUSTOMER_RESULTS_LINKS = {
  BASE: "/app/customer-results",
  LIST: "/app/customer-results",
  DETAILS: "/app/customer-management/details/:id",
};