import React from "react";

import TableSearch from "../../../../../../base/components/Table/tableSearch";
import Button from "../../../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../../../base/components/Button/appearance";
import Icon from "../../../../../../base/components/Icon";

import { useLocationQuery } from "../../../../../../base/hooks/useQueryString";
import { useHighlight } from "../../../../../../base/hooks/useHighlight";
import { SEX_LABELS } from "./CreateEditGoal/constants";

export const GOALS_ACTION = {
    COPY: 'copy',
    EDIT: 'edit',
    DELETE: 'delete'
};

export const SearchPlaceholder = () => (
    <>
        No goals found.
    </>
);

export const NoGoalPlaceholder = () => (
    <div className="text-center">
        No items found.<br/>
        Click the “Add goal” option to add a new one.
    </div>
);
export const TableHeader = ({ searchProvider, headerActions }) => {
    return (
        <section className="d-flex w-100 pt-1 my-3 align-items-center justify-content-between">
            <TableSearch
                className="biomarkers-search"
                search={searchProvider?.getValue()}
                onSearch={searchProvider.setValue}
                placeholder="Search goals..."
            />

            <div className="d-flex gap-3">
                <Button
                    color={BUTTON_COLORS.primary}
                    className="text-nowrap d-flex align-items-center gap-2"
                    onClick={headerActions.goToCreate}
                >
                    <i className="bx bx-plus"/> Add goal
                </Button>
                <Button
                    color={BUTTON_COLORS.primaryOutline}
                    className="text-nowrap d-flex align-items-center gap-2"
                    onClick={headerActions.goToEditPrograms}
                >
                    <Icon icon="edit" className="button-icon"/> Edit program
                </Button>
                <Button
                    color={BUTTON_COLORS.dangerOutline}
                    className="text-nowrap d-flex align-items-center gap-2"
                    onClick={headerActions.deleteProgram}
                >
                    <Icon icon="trashIcon" className="button-icon"/> Delete program
                </Button>
            </div>

        </section>
    );
};

export const columns = [
    {
        Header: "#",
        width: 35,
        Cell: (cellProps) => {
            const { params: { offset = 0 } } = useLocationQuery();
            return <label className="mb-0">{cellProps.row.index + 1 + (offset ?? 0)}</label>;
        }
    },
    {
        Header: "Bio sex",
        accessor: "sex",
        width: 410,
        canSort: true,
        Cell: ({ value }) => {
            const { params } = useLocationQuery();
            const { decorateText } = useHighlight(params.search);

            return (
                <section className="w-fit-content d-flex align-items-center">
                    <div {...decorateText(SEX_LABELS[value])} className="text-truncate mb-0"
                         style={{ maxWidth: "33vw" }}/>
                </section>
            );
        }
    },

    {
        Header: "Min age",
        accessor: "ageMinValue",
        width: 410,
        canSort: true,
        Cell: ({ value }) => {
            return (
                <section className="w-fit-content d-flex align-items-center">
                    {value}
                </section>
            );
        }
    },
    {
        Header: "Max age",
        accessor: "ageMaxValue",
        width: 410,
        canSort: true,
        Cell: ({ value }) => {
            return (
                <section className="w-fit-content d-flex align-items-center">
                    {value}
                </section>
            );
        }
    },
    {
        Header: "Actions",
        Cell: ({
                   row: {
                       original: { id }
                   },
                   actions
               }) => {

            return <section
                onClick={(event) => {
                    event.stopPropagation();
                }}
                className="d-flex justify-content-between align-items-center"
            >

                <Icon icon="copy" width={20} height={20} className="me-4 cursor-pointer text-secondary"
                      onClick={(event) => {
                          event.stopPropagation();
                          actions[GOALS_ACTION.COPY](id);
                      }}
                />
                <Icon icon="edit"
                      width={20}
                      height={20}
                      className="me-4 cursor-pointer impacts-section__view"
                      onClick={(event) => {
                          event.stopPropagation();
                          actions[GOALS_ACTION.EDIT](id);
                      }}
                />

                <Icon
                    icon={"trashIcon"}
                    width={20}
                    height={20}
                    onClick={(event) => {
                        event.stopPropagation();
                        actions[GOALS_ACTION.DELETE](id);
                    }}
                    className="me-4 cursor-pointer"
                />
            </section>;
        }
    }
];