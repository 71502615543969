import React from "react";
import { storyblokEditable } from "@storyblok/react";

const Wrapper = ({ blok, children }) => {
    if (!blok) {
        return null;
    }

    const wrapperStyles = {
        display: "flex",
        justifyContent: blok.contentPosition,
        textAlign: blok.textAlign,
        width: "100%",
        gap: blok.gap,
        marginBottom: blok.marginBottom || "1rem",
        marginTop: blok.marginTop || 0
    }

    return (
        <div style={wrapperStyles} {...storyblokEditable(blok)}>
            <div style={{ width: blok.width || "100%" }}>
                {children}
            </div>
        </div>
    );
};

export default Wrapper;
