
export const LIST_TABS = {
    RULES: "Rules",
    GOALS: "Nutritional goals"
}

export const RULES_STATUSES = {
    ACTIVE: 1,
    INACTIVE: 2
}

export const RULES_ACTIONS = {
    AVOID: 1,
    DECREASE: 2,
    INCREASE: 3
}

export const RULE_STATUSES_LABELS = {
    [RULES_STATUSES.ACTIVE]: 'Active',
    [RULES_STATUSES.INACTIVE]: 'Inactive'
}

export const RULE_ACTION_LABELS = {
    [RULES_ACTIONS.AVOID]: 'Avoid',
    [RULES_ACTIONS.INCREASE]: 'Increase',
    [RULES_ACTIONS.DECREASE]: 'Decrease'
}

export const RULE_ACTION_ICONS = {
    [RULES_ACTIONS.AVOID]: 'avoidIcon',
    [RULES_ACTIONS.INCREASE]: 'increaseIcon',
    [RULES_ACTIONS.DECREASE]: 'decreaseIcon'
}