import Http from "./Http";

class CoachService extends Http {
    static $displayName = "CoachService";

    async getCoaches(params) {
        return await this.get(`/coaches`, { params });
    }
}

export default CoachService;
