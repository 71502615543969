
export const MIN_STRING_LENGTH = 1
export const MAX_TITLE_LENGTH = 100

export const MAX_INSTRUCTIONS_LENGTH = 1000

export const ALLOWED_EXTENTIONS_FILE = ["png", "jpeg", "jpg"];
export const ERROR_ALLOWED_TYPE_MESSAGE = "Invalid format. Choose a PNG, JPG, JPEG image.";

export const ALLOWED_SYMBOLS_REGEX = /^[a-zA-Z0-9' _-]*$/;

export const ALLOWED_SYMBOLS_REGEX_INSTRUCTION = /^[a-zA-Z0-9<> /.,!?'\-]*(&nbsp;[a-zA-Z0-9<> /.,!?'\-]*)*$/

export const ALLOWED_SYMBOLS_MESSAGE = 'Text must contain only letters, numbers, spaces, apostrophes, dashes, and underscores';

export const ALLOWED_SYMBOLS_MESSAGE_INSTRUCTION = 'Text must contain only alphanumeric, spaces, punctuation marks';

export const EXERCISE_TYPES = {
    BODY_WEIGHTS: 1,
    CARDIO: 2,
    CORE: 3,
    LOWER_BODY: 4,
    MOBILITY: 5,
    OTHER: 6,
    UPPER_BODY: 7,
    WEIGHTS: 8,
}

export const EXERCISE_TYPES_LABEL = {
    [EXERCISE_TYPES.WEIGHTS]: "Weights",
    [EXERCISE_TYPES.CARDIO]: "Cardio",
    [EXERCISE_TYPES.MOBILITY]: "Mobility",
    [EXERCISE_TYPES.OTHER]: "Other",
    [EXERCISE_TYPES.BODY_WEIGHTS]: "Body weights",
    [EXERCISE_TYPES.CORE]: "Core",
    [EXERCISE_TYPES.LOWER_BODY]: "Lower body",
    [EXERCISE_TYPES.UPPER_BODY]: "Upper body",
}

export const EXERCISE_STATUSES = {
    ACTIVE: 1,
    INACTIVE: 2
}

export const EXERCISE_STATUSES_LABELS = {
    [EXERCISE_STATUSES.ACTIVE]: "Active",
    [EXERCISE_STATUSES.INACTIVE]: "Inactive"
}

export const EXERCISE_CONTENT_FORMAT = {
    TEXT: 1,
    VIDEO: 2,
    AUDIO: 3
}

export const EXERCISE_CONTENT_FORMAT_LABELS = {
    [EXERCISE_CONTENT_FORMAT.TEXT]: "Text",
    [EXERCISE_CONTENT_FORMAT.VIDEO]: "Video",
    [EXERCISE_CONTENT_FORMAT.AUDIO]: "Audio"
}