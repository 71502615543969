import React, { useCallback, useEffect, useRef, useState } from 'react';
import UICustomisationLayoutWithCard from "../../../../base/components/UICustomisationLayoutWithCard";
import { useNavigate } from "react-router-dom";
import { useDarkMode } from "../../../../base/context/darkMode";
import { UncontrolledAccordion, AccordionBody, AccordionHeader, AccordionItem, Col, Row } from "reactstrap";
import {
  ALLOWED_LOGO_FILE_EXTENSION,
  ASSETS_FILE_TYPE,
  BYTES_IN_KILOBYTE,
  ERROR_ALLOWED_LOGO_FILE_TYPE_MESSAGE,
  ERROR_LOGO_FILE_SIZE_MESSAGE,
  KILOBYTES_IN_MEGABYTE,
  LOGO_FILE_MASK,
  MAX_LOGO_FILE_SIZE,
} from "../../../../base/constants/shared";
import useUploadImages from "../../../../base/components/Dropzone/useUploadImages";
import {
  validateEmptyFile,
  validateFileSize,
  validateFileType
} from "../../../../validation/fileUploadAndProcessing";
import { Formik } from "formik";
import { useService } from "../../../../base/hooks/useService";
import ConfigService, { ThemeTypes } from "../../../../services/ConfigService";
import {
  MAX_RULE_NAME_LENGTH,
  MIN_STRING_LENGTH
} from "../../HealthProgram/Nutrition/CreateEditRule/constants";
import FormikInput from "../../../../base/components/FormikInput";
import Button from "../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";
import joinClassNames from "../../../../base/helpers/joinClassNames";
import yup from "../../../../validation/yup";
import * as _ from "lodash";
import FormikColorPicker from "../../../../base/components/FormikColorPicker";
import Switch from "../../../../base/components/Switch";
import { DropZoneCard } from "../../../../base/components/Dropzone";
import Icon from "../../../../base/components/Icon";
import ToasterService from "../../../../services/ToastService";
import { exerciseStatusesOption } from "../../Fitness/ExerciseForm/form";
import FormikReactSelect from "../../../../base/components/FormikReactSelect";

export const validationSchema = yup.object().shape({
  PRIVACY_POLICY_LINK: yup.string()
    .trim()
    .min(MIN_STRING_LENGTH)
    .max(MAX_RULE_NAME_LENGTH)
    .required(),
  TERMS_OF_USE_LINK: yup.string()
    .trim()
    .min(MIN_STRING_LENGTH)
    .max(MAX_RULE_NAME_LENGTH)
    .required(),
  SHOPIFY_LINK: yup.string()
    .trim()
    .min(MIN_STRING_LENGTH)
    .max(MAX_RULE_NAME_LENGTH)
    .required(),
});

const SHOPIFY_PLUS_STATUS = {
  ACTIVE: "1",
  INACTIVE: "0"
}

const DEFAULT_THEME_OPTIONS = [
  { value: "1", label: "Light Mode" },
  { value: "2", label: "Dark Mode" },
  // { value: "3", label: "Automatic" }
];

export default function General() {

  let navigate = useNavigate();
  const breadcrumbs = {
    title: "General customisation", breadcrumbItems: []
  };
  const isDarkMode = useDarkMode();
  const themeType = isDarkMode ? ThemeTypes.dark : ThemeTypes.white;
  /**
   * @type {ConfigService}
   */
  const configService = useService(ConfigService);

  const [open, setOpen] = useState('1');
  const [initialValues, setInitialValues] = useState({});
  const [isSubmitting, updateIsSubmitting] = useState(false);
  const [apiConfig, setApiConfig] = useState({});
  const [apiSettings, setApiSettings] = useState({});
  /**
   * @type {ToasterService}
   */
  const toastService = useService(ToasterService);

  const fetchData = () => {
    return Promise.all([
      configService.fetchCustomerConfig(),
      configService.fetchThemeSettings()
    ]);
  }

  useEffect(() => {
    fetchData().then(([rawConfig, rawThemeSettings]) => {
        const configFields = ["id", "variable", "value"];
        const config = _.chain(rawConfig)
          .get("data")
          .map(item => _.pick(item, configFields))
          .keyBy("variable")
          .value();

        setApiConfig(config);

        const themeFields = ["id", "propertyName", "fileId", "value", "file", "page", "themeType"];
        const themeSettings = _.chain(rawThemeSettings)
          .get("data")
          .filter({ themeType:  themeType })
          .map(item => _.pick(item, themeFields))
          .keyBy("propertyName")
          .value();

        setApiSettings(themeSettings);
        setSelectedFile({
          ...themeSettings.organisationLogo
        });

        setInitialValues((prev)  => ({
          ...prev,
          THEME_MODE: config.THEME_MODE.value,
          organisationLogo: themeSettings.organisationLogo.value,
          PRIVACY_POLICY_LINK: config.PRIVACY_POLICY_LINK.value,
          TERMS_OF_USE_LINK: config.TERMS_OF_USE_LINK.value,
          SHOPIFY_LINK: config.SHOPIFY_LINK.value,
          generalLinksColor: themeSettings.generalLinksColor.value,
          adminSidebarColor: themeSettings.adminSidebarColor.value,
          adminSidebarDefaultFontColor: themeSettings.adminSidebarDefaultFontColor.value,
          adminSidebarActiveFontColor: themeSettings.adminSidebarActiveFontColor.value,
          adminMainButtonColor: themeSettings.adminMainButtonColor.value,
          adminMainButtonTextColor: themeSettings.adminMainButtonTextColor.value,
          shopifyPlus: themeSettings.shopifyPlus.value,
        }));
      });
  }, [configService, isDarkMode]);

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const uploadImage = useUploadImages();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const handleAcceptedFile = useCallback(async (file) => {
    setErrorMessage("");

    try {
      validateFileType(file, ALLOWED_LOGO_FILE_EXTENSION, ERROR_ALLOWED_LOGO_FILE_TYPE_MESSAGE);
      validateFileSize(file, MAX_LOGO_FILE_SIZE, ERROR_LOGO_FILE_SIZE_MESSAGE);
      validateEmptyFile(file);
    } catch ({ message }) {
      toastService.error(message)
      setErrorMessage(message);
      return;
    }

    setSelectedFile((prev) => {
      return {
      ...prev,
      value: URL.createObjectURL(file),
      fileData: file,
      file: {
        name: file.name,
        bytes: file.size
      }
    }});

  }, [setErrorMessage]);

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === REACT_DROPZONE_INVALID_FILE_TYPE_ERROR) {
            toastService.error(ERROR_ALLOWED_LOGO_FILE_TYPE_MESSAGE);
          }
        });
      });

      if (!acceptedFiles.length) return;

      acceptedFiles.forEach((file) => handleAcceptedFile(file));

    },
    [handleAcceptedFile]
  );

  const deleteLogo = () => {
    setSelectedFile(null);
  }

  const submitSettings = async (values) => {
    updateIsSubmitting(true);
    try {
      const configToSubmit = _.pick(apiConfig, _.keys(values));
      const settingsToSubmit = _.pick(apiSettings, _.keys(values));

      _.map(settingsToSubmit, (setting) => {
        setting.value = values[setting.propertyName];
        return setting;
      });

      _.map(configToSubmit, (config) => {
        config.value = values[config.variable];
      });

      if (!selectedFile.id) {
        const uploadedFile = await uploadImage(selectedFile.fileData, ASSETS_FILE_TYPE); // uploadedFile.file.id
        settingsToSubmit.organisationLogo = {
          ...settingsToSubmit.organisationLogo,
          fileId: uploadedFile.file.id
        };
        setSelectedFile((prev) => ({
          ...prev,
          id: uploadedFile.file.id
        }));
      }

      settingsToSubmit.organisationLogo = _.omit(settingsToSubmit.organisationLogo, ['value', 'file']);

      const updatedSettings = await configService.updateThemeSettings(_.values(settingsToSubmit));
      const updatedConfig = await configService.updateCustomerConfig(_.values(configToSubmit));

      toastService.success("Settings was successfully uploaded");
    } catch (err) {
      console.error(err);
      toastService.error(err)
      setErrorMessage(err);
    } finally {
      updateIsSubmitting(false);
    }
  };

  return (
    <UICustomisationLayoutWithCard breadcrumbs={breadcrumbs}>
      <Col lg={6}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur
          onSubmit={submitSettings}
          enableReinitialize
        >
          {({ errors, handleSubmit, values, setFieldValue }) =>
            <form className={joinClassNames("form-horizontal p-0", isSubmitting && "pointer-events-none")}
                  onSubmit={handleSubmit}>
              <FormikReactSelect
                name="THEME_MODE"
                options={DEFAULT_THEME_OPTIONS}
                label="Default theme"
                setFieldValue={setFieldValue}
                containerClassName="mt-3 mb-5"
              />
              <UncontrolledAccordion defaultOpen={'1'} toggle={toggle} stayOpen={true} className="ui-customisation-accordion">
                <AccordionItem>
                  <AccordionHeader targetId="1">Logo</AccordionHeader>
                  <AccordionBody accordionId="1">
                    {!selectedFile && !isLoading &&
                      <DropZoneCard
                        onDrop={onDrop}
                        errorMessage={errorMessage}
                        isDropContainer={true}
                        className={""}
                        fileMask={LOGO_FILE_MASK}
                      >
                        <section className="upload-container--section">
                          <p className="upload-container--section__cloud-upload-desc">Upload logo</p>
                          <p className="upload-container--section__desc mb-0">Drop a PNG, JPG or JPEG file.</p>
                          <p className="upload-container--section__desc">Max {MAX_LOGO_FILE_SIZE / BYTES_IN_KILOBYTE / KILOBYTES_IN_MEGABYTE} Mb</p>
                        </section>
                      </DropZoneCard>
                    }
                    {selectedFile && !isLoading && !isUploading &&
                      <div>
                        <div className="d-flex align-items-center justify-content-between p-4 selected-file-container formik-file-input">
                          <div className="d-flex align-items-center">
                            <img src={selectedFile.value} alt="Logo" className="formik-file-input-logo" />
                            <div className="d-flex flex-column justify-content-between px-4">
                              <span className="selected-file-container__selected-file-name mb-1">{selectedFile.file.name}</span>
                              <span className="font-semibold">
                                {(selectedFile.file.bytes / BYTES_IN_KILOBYTE).toFixed(2)} KB
                              </span>
                            </div>
                          </div>
                          <Icon icon="trashIcon" className="cursor-pointer ms-3" onClick={deleteLogo}/>
                        </div>
                      </div>
                    }
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="2">Links</AccordionHeader>
                  <AccordionBody accordionId="2">
                    <FormikColorPicker
                      label="Links color"
                      placeholder=""
                      name="generalLinksColor"
                      maxLength={255}
                      containerClassName="mt-3"
                    />

                    <FormikInput
                      label="Link to Privacy Policy"
                      description="Provide link to your privacy policy"
                      placeholder="Link to privacy policy"
                      name="PRIVACY_POLICY_LINK"
                      maxLength={255}
                      containerClassName="mt-3"
                    />
                    <FormikInput
                      label="Link to Terms of Use"
                      description="Provide link to your Terms of Use"
                      placeholder="Link to Terms of Use"
                      name="TERMS_OF_USE_LINK"
                      maxLength={255}
                      containerClassName="mt-3"
                    />
                    <FormikInput
                      label="Link to Shopify"
                      description="Provide link to your Shopify store"
                      placeholder="Link to Shopify store"
                      name="SHOPIFY_LINK"
                      maxLength={255}
                      containerClassName="mt-3"
                    />

                    <div className="d-flex pt-3 justify-content-between">
                        <label className="d-flex align-items-center gap-1">
                          Shopify Plus
                        </label>
                        <Switch state={values.shopifyPlus === SHOPIFY_PLUS_STATUS.ACTIVE}
                                updateState={() => setFieldValue('shopifyPlus', values.shopifyPlus === SHOPIFY_PLUS_STATUS.ACTIVE
                                  ? SHOPIFY_PLUS_STATUS.INACTIVE
                                  : SHOPIFY_PLUS_STATUS.ACTIVE)
                                }
                        />
                    </div>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="3">Admin Customisation</AccordionHeader>
                  <AccordionBody accordionId="3">
                    <FormikColorPicker
                      label="Sidebar color"
                      placeholder=""
                      name="adminSidebarColor"
                      maxLength={255}
                      containerClassName="mt-3"
                    />
                    <FormikColorPicker
                      label="Sidebar default font color"
                      placeholder=""
                      name="adminSidebarDefaultFontColor"
                      maxLength={255}
                      containerClassName="mt-3"
                    />
                    <FormikColorPicker
                      label="Sidebar active font color"
                      placeholder=""
                      name="adminSidebarActiveFontColor"
                      maxLength={255}
                      containerClassName="mt-3"
                    />
                    <FormikColorPicker
                      label="Main button color"
                      placeholder=""
                      name="adminMainButtonColor"
                      maxLength={255}
                      containerClassName="mt-3"
                    />
                    <FormikColorPicker
                      label="Main button text color"
                      placeholder=""
                      name="adminMainButtonTextColor"
                      maxLength={255}
                      containerClassName="mt-3"
                    />
                  </AccordionBody>
                </AccordionItem>
              </UncontrolledAccordion>
              <div className="d-flex justify-content-end mt-5 gap-3">
                <Button
                  color={BUTTON_COLORS.primary}
                  type="submit"
                  disabled={!!Object.keys(errors).length || isSubmitting}
                >
                  Save changes
                </Button>
              </div>
            </form>
          }
        </Formik>
      </Col>
    </UICustomisationLayoutWithCard>
  );
}
