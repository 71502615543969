import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useService } from "../../../../base/hooks/useService";
import BaseLayoutWithCard from "../../../../base/components/BaseLayoutWithCard";
import { FITNESS_GROUP_LINKS } from "../config";

import Button from "../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";

import { useLoading } from "../../../../base/hooks/useLoading";
import PageSpinner from "../../../../base/components/PageSpinner";
import ValueWithLabel from "./components/ValueWIthLabel";
import RichTextView from "./components/RIchTextView";
import WorkoutsService from "../../../../services/WorkoutService";
import {
    WORKOUT_CONTENT_FORMAT_LABELS, WORKOUT_DESCRIPTION_TYPE,
    WORKOUT_LEVEL_LABEL,
    WORKOUT_STATUSES_LABELS
} from "../WorkoutForm/constants";
import { map } from "lodash";
import { WorkoutDescriptionTable } from "./components/WorkoutDescriptionTable";


export default function WorkoutDetails() {
    /**
     * @type {WorkoutService}
     */
    const workoutsService = useService(WorkoutsService);

    const [workout, setWorkout] = useState(null);

    const [isLoading, { registerPromise }] = useLoading(true);
    const navigate = useNavigate();

    const { id } = useParams();

    const mapWorkout = ({
                            file,
                            ...otherValue
                        }) => {
        return {
            image: file?.link,
            ...otherValue
        };
    };

    const goToEdit = () => {
        const queryParams = new URLSearchParams({ editWorkoutId: id }).toString();
        navigate(`${FITNESS_GROUP_LINKS.FORM_WORKOUTS}?${queryParams}`);
    };

    useEffect(() => {
        if (id) {
            registerPromise(workoutsService.getWorkoutById(id))
                .then((data) => {
                    setWorkout(mapWorkout(data));
                });
        }
    }, [id]);

    const breadcrumbs = {
        title: "Workout detailed view",
        breadcrumbItems: [
            { title: "Fitness", link: FITNESS_GROUP_LINKS.BASE },
            { title: "Workout detailed view" }
        ]
    };

    return <BaseLayoutWithCard breadcrumbs={breadcrumbs}>
        {isLoading
            ? <PageSpinner/>
            : <>
                <section className="d-flex align-items-start justify-content-between mb-4">
                    <label className="font-weight-semibold font-size-15">General information</label>
                    <div className="d-flex gap-3 align-items-center">
                        <Button
                            color={BUTTON_COLORS.primary}
                            onClick={goToEdit}
                            className="d-flex align-items-center"
                        >
                            <i className="bx bx-edit-alt me-2"/>
                            Edit workout
                        </Button>
                    </div>
                </section>
                <section className="d-flex flex-column gap-3">
                    {
                        workout?.image
                            ? <img
                                src={workout?.image}
                                alt="workout image"
                                width={240}
                            />
                            : null
                    }

                    <ValueWithLabel value={workout?.name} label="Name"/>

                    <ValueWithLabel value={WORKOUT_LEVEL_LABEL[workout?.level]} label="Workout level"/>

                    <ValueWithLabel value={workout?.type?.name} label="Workout type"/>

                    <ValueWithLabel value={map(workout?.segments, 'fullName').join(', ')} label="User segments"/>

                    <ValueWithLabel value={WORKOUT_STATUSES_LABELS[workout?.status]} label="Status"/>

                    <ValueWithLabel value={workout?.duration} label="Duration"/>

                    <ValueWithLabel value={WORKOUT_CONTENT_FORMAT_LABELS[workout?.content]} label="Workout content"/>
                </section>

                <label className="font-weight-semibold mt-5 mb-3 font-size-15">Workout descriptions</label>

                {
                    workout.descriptionType === WORKOUT_DESCRIPTION_TYPE.WORKOUT_SET_UP
                        ? <WorkoutDescriptionTable sections={workout?.sections}/>
                        : <ValueWithLabel
                            value={<a
                                href={workout?.videoUrl}
                                target="_blank"
                                rel="noreferrer">
                                {workout?.videoUrl}
                            </a>}
                            label="Video URL"
                        />
                }

                <section className="mt-5">
                    <label className="font-weight-semibold font-size-15 mb-2">Workout overview</label>
                    <div className="d-flex flex-column gap-3">
                        <RichTextView richText={workout?.overview}/>
                    </div>
                </section>

                {
                    workout?.whyRecommendedDescription ?
                    <section className="mt-5">
                        <label className="font-weight-semibold font-size-15 mb-2">Describe why system can recommend this workout</label>
                        <div className="d-flex flex-column gap-3">
                            <RichTextView richText={workout?.whyRecommendedDescription}/>
                        </div>
                    </section>
                    : null
                }
            </>
        }
    </BaseLayoutWithCard>;
}