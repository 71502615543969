import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TableSearch from "../../../../base/components/Table/tableSearch";
import { RECOMMENDATIONS_LINKS } from "../config";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import Checkbox from "../../../../base/components/Checkbox";
import Button from "../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";
import { activityOptions, categoryOptions } from "../CreateEdit/constants";

export const SearchPlaceholder = () => (
  <>
    Any recommendations for now.
    <br/>
    Click the “Create recommendation” option to add a new one.
  </>
)

const getArray = (itemOrArray) => {
  if (!itemOrArray) return [];
  return typeof itemOrArray === "object" ? itemOrArray : [itemOrArray]
}

export const TableFilter = ({ filterProvider }) => {
  const { status, category } = filterProvider;
  const [isOpen, updateIsOpen] = useState(false);
  const [categories, updateCategories] = useState(getArray(category.getValue()));
  const [statusFilter, updateStatusFilter] = useState(getArray(status.getValue()));

  const submitFilters = () => {
    category.setValue(categories);
    status.setValue(statusFilter);
    updateIsOpen(false);
  }

  const handleCheckboxChange = (id, setState) => {
    setState(prevState => {
      return prevState.includes(id) ? prevState.filter(item => item !== id) : [...prevState, id];
    });
  }

  return (
    <section className="d-flex align-items-center">
      <Dropdown
        isOpen={isOpen}
        toggle={() => updateIsOpen(prevState => !prevState)}
        className="d-inline-block"
      >
        <DropdownToggle
          className=" btn btn-outline-primary d-flex align-items-center"
          tag="button"
        >
          <i className="bx bx-filter-alt me-2"/> Add filter
        </DropdownToggle>

        <DropdownMenu className="filter-menu p-2 pt-3 rec-filter">

          <section className="d-flex justify-content-between">
            <section className="filter-options mx-1 me-4 custom-scrollbar rec-category-filter">
              <label className="text-secondary font-size-10 mb-1 font-weight-normal">BY CATEGORY</label>
              {categoryOptions.map((category, index) => {
                return (
                  <Checkbox
                    id={category.id}
                    text={category.label}
                    value={categories?.includes(category.id)}
                    onChange={() => handleCheckboxChange(category.id, updateCategories)}
                    key={index}
                    className="my-2 ms-1"
                  />
                )
              })}
            </section>

            <section className="filter-options mx-1 custom-scrollbar rec-activity-filter">
              <label className="text-secondary font-size-10 mb-1 font-weight-normal">BY STATUS</label>
              {activityOptions.map((activity, index) => {
                return (
                  <Checkbox
                    id={activity.label}
                    text={activity.label}
                    value={statusFilter?.includes(activity.id)}
                    onChange={() => handleCheckboxChange(activity.id, updateStatusFilter)}
                    key={index}
                    className="my-2 ms-1"
                  />
                )
              })}
            </section>
          </section>

          <section className="w-100 px-2 pb-2 mt-3">
            <Button color={BUTTON_COLORS.primary} onClick={submitFilters} className="w-100"
                    disabled={!(categories.length || statusFilter.length)}>
              Apply
            </Button>
          </section>

        </DropdownMenu>
      </Dropdown>

      {(!!status.getValue() || !!category.getValue()) &&
        <Button
          color={BUTTON_COLORS.transparent}
          onClick={() => {
            updateCategories([]);
            updateStatusFilter([])
            status.setValue();
            category.setValue();
          }}
          className="text-danger no-border ms-2"
        >
          Clear filter
        </Button>
      }
    </section>
  )
}

export const TableHeader = ({ searchProvider, filterProvider, categories }) => {
  const navigate = useNavigate();
  return (
    <section className="d-flex align-items-center justify-content-between mb-3">
      <section className="d-flex align-items-center">
        <TableSearch
          className="biomarkers-search me-3"
          search={searchProvider.getValue()}
          onSearch={searchProvider.setValue}
          placeholder="Search recommendation..."
        />
        <TableFilter filterProvider={filterProvider} listOfCategories={categories}/>
      </section>
      <Button
        color={BUTTON_COLORS.primary}
        onClick={() => navigate(RECOMMENDATIONS_LINKS.CREATE)}
      >
        + Create recommendation
      </Button>
    </section>
  )
}