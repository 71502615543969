export const WORKOUT_TYPE_STATUSES = {
    ACTIVE: 1,
    INACTIVE: 2
}

export const WORKOUT_TYPE_STATUSES_LABELS = {
    [WORKOUT_TYPE_STATUSES.ACTIVE]: 'Active',
    [WORKOUT_TYPE_STATUSES.INACTIVE]: 'Inactive',
}

export const HOMEPAGE_SECTIONS_TYPE = {
    WORKOUT: 1,
    PROGRAM: 2
}

export const QUANTITY_TO_DISPLAY_PER_LINE = {
    ONE: 1,
    TWO: 2,
}

export const HOMEPAGE_SECTIONS_TYPE_LABELS = {
    [HOMEPAGE_SECTIONS_TYPE.PROGRAM]: "Program",
    [HOMEPAGE_SECTIONS_TYPE.WORKOUT]: "Workout"
}

export const FILTER_GROUPS = {
    COACH: 6,
    LEVELS: 7,
    CATEGORIES: 8,
    PROGRAM_TYPES: 9
}