import Http from "./Http";

class WellnessService extends Http {
    static $displayName = "WellnessService";

    async getPrograms(params) {
        return await this.get(`/admins/wellness`, { params });
    }

    async getProgramById(id) {
        return await this.get(`/admins/wellness/${id}`);
    }

    createProgram(values) {
        return this.post(`/admins/wellness`, values);
    }

    deleteProgram(id) {
        return this.delete(`/admins/wellness/${id}`);
    }

    updateProgram(id, values) {
        return this.put(`/admins/wellness/${id}`, values);
    }

    copyProgram(id) {
        return this.post(`/admins/wellness/${id}/copy`);
    }

    changeStatus(id, status) {
        return this.patch(`/admins/wellness/${id}/status`, { status });
    }
}

export default WellnessService;
