import React, { useState, useEffect, useCallback } from 'react';
import { Container, Card, CardBody, Button } from 'reactstrap';
import { Formik } from 'formik';
import get from 'lodash.get';

import PageSpinner from '../../../base/components/PageSpinner';

import RichTextEditor from '../../../base/components/RichTextEditor';
import Breadcrumb from '../../../base/components/Common/Breadcrumb2';
import { BUTTON_COLORS } from '../../../base/components/Button/appearance';
import { useService } from '../../../base/hooks/useService';
import FoodIntoleranceService from '../../../services/FoodIntoleranceService';
import ToasterService from '../../../services/ToastService';
import { useLoading } from '../../../base/hooks/useLoading';

import { formInitialValues, planningDietSection, learnMoreSection } from './constants';

const breadcrumbs = {
  title: 'Food Intolerance',
  breadcrumbItems: [],
};

export default function FoodIntolerance() {
  /**
   * @type {FoodIntoleranceService}
   */
  const foodIntoleranceService = useService(FoodIntoleranceService);

  /**
   * @type {ToasterService}
   */
  const toastService = useService(ToasterService);

  const [isFieldsDisabled, setIsFieldsDisabled] = useState(true);
  const [isLoading, { registerPromise }] = useLoading(true);
  const [formValues, updateFormValues] = useState(formInitialValues);

  const getData = useCallback(() => {
    registerPromise(
      foodIntoleranceService.getFoodIntoleranceInfo().then(data => {
        if (!data) {
          updateFormValues(formInitialValues);
          setIsFieldsDisabled(false);
          return;
        }

        updateFormValues(data[0]);
      }),
    );
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const onFormSubmit = values => {
    const { id, remove, rotate, replaceWith, repair, reintroduce, theTest, howThisHelpMe, crossReactions } = values;

    const fields = { remove, rotate, replaceWith, repair, reintroduce, theTest, howThisHelpMe, crossReactions };

    const newValues = !id ? fields : { ...fields, id };

    registerPromise(
      foodIntoleranceService.changeFoodIntoleranceInfo(newValues).then(() => {
        updateFormValues(newValues);
        setIsFieldsDisabled(true);
        toastService.success('Info was successfully saved.');
      }),
    );
  };

  if (isLoading) return <PageSpinner />;

  return (
    <Container fluid className="content">
      <Breadcrumb {...breadcrumbs} />

      <Card>
        <CardBody>
          <Formik initialValues={formValues} validateOnBlur enableReinitialize onSubmit={values => onFormSubmit(values)}>
            {({ handleSubmit, values, setFieldValue }) => {
              const { id, remove, rotate, replaceWith, repair, reintroduce, theTest, howThisHelpMe, crossReactions } = values;

              return (
                <form onSubmit={handleSubmit}>
                  <div className="mb-5">
                    <div className="mb-4 d-flex justify-content-between">
                      <p className="mb-0 section-title">Planning a diet</p>
                      {formValues.id && (
                        <Button
                          color={BUTTON_COLORS.primary}
                          onClick={() => setIsFieldsDisabled(false)}
                          className="d-flex align-items-center"
                          style={{
                              height: 'fit-content',
                              visibility: isFieldsDisabled ? "visible" : "hidden"
                          }}
                        >
                          <i className="bx bx-edit-alt me-2" />
                          Edit
                        </Button>
                      )}
                    </div>

                    {planningDietSection.map(({ itemKey, label, description, placeholder }) => {
                      return (
                        <RichTextEditor
                          key={itemKey}
                          value={get(values, itemKey, '') ?? ''}
                          onChange={text => {
                            setFieldValue(itemKey, text);
                          }}
                          name={itemKey}
                          label={label}
                          description={description}
                          disabled={isFieldsDisabled}
                          placeholder={placeholder}
                        />
                      );
                    })}
                  </div>

                  <div className="mb-5">
                    <p className="section-title">Learn more</p>
                    {learnMoreSection.map(({ itemKey, label, description, placeholder }) => {
                      return (
                        <RichTextEditor
                          key={itemKey}
                          value={get(values, itemKey, '') ?? ''}
                          onChange={text => {
                            setFieldValue(itemKey, text);
                          }}
                          name={itemKey}
                          label={label}
                          description={description}
                          disabled={isFieldsDisabled}
                          placeholder={placeholder}
                        />
                      );
                    })}
                  </div>

                  <div className="d-flex justify-content-end">
                    {!(isFieldsDisabled && id) && (
                      <Button
                        color={BUTTON_COLORS.primary}
                        disabled={
                          !id && !remove && !rotate && !replaceWith && !repair && !reintroduce && !theTest && !howThisHelpMe && !crossReactions
                        }
                        type="submit"
                      >
                        Save
                      </Button>
                    )}
                  </div>
                </form>
              );
            }}
          </Formik>
        </CardBody>
      </Card>
    </Container>
  );
}
