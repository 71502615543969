import React, { useMemo, useState, useCallback, useEffect, useRef } from "react";
import { Dropdown, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem, Spinner } from "reactstrap";
import classnames from "classnames";
import TableSearch from "../../Table/tableSearch";
import Checkbox from "../../Checkbox";
import { useHighlight } from "../../../hooks/useHighlight";
import { EXERCISE_TYPES, EXERCISE_TYPES_LABEL } from "../../../../groups/app/Fitness/ExerciseForm/constants";
import { toggleSelectItem } from "../../../helpers/toggleSelectItem";
import { useService } from "../../../hooks/useService";
import ExercisesService from "../../../../services/ExercisesService";
import classes from "./ExerciseDropdown.module.scss";

const ALL_TAB_KEY = "all";
const MIN_SEARCH_LENGTH = 3;

const ExerciseMultiSelectDropdown = ({ onChange, value = [], placeholder, error }) => {
    /**
     * @type {ExercisesService}
     */
    const exercisesService = useService(ExercisesService);
    const [isOpen, updateIsOpen] = useState(false);
    const [activeTabs, setActiveTabs] = useState(ALL_TAB_KEY);
    const [search, setSearch] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [exercises, setExercises] = useState([]);
    const lastElementRef = useRef(null);

    const { decorateText } = useHighlight(search && search.length >= MIN_SEARCH_LENGTH ? search : null);

    const valueIds = useMemo(() => value.map(item => item.id), [value]);

    const loadExercise = useCallback(() => {
        setIsLoading(true);
        exercisesService.getSimpleExercises()
            .then(({ data }) => {
                setExercises(data);
            })
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        loadExercise();
    }, [loadExercise]);

    const mappedExercises = useMemo(() => {
        const allExercisesTab = {
            exercises: exercises,
            id: 'all',
            name: 'All'
        };

        const groupedExercises = Object.values(EXERCISE_TYPES).map((type) => {
            return {
                exercises: exercises.filter((exercise) => exercise.type === type),
                id: type,
                name: EXERCISE_TYPES_LABEL[type]
            };
        });

        return [allExercisesTab, ...groupedExercises];
    }, [exercises]);

    const filterExercises = useMemo(() => {
        const lowerCaseSearch = search?.toLowerCase();

        return search && search.length >= MIN_SEARCH_LENGTH
            ? mappedExercises.map(attribute => {
                const filteredExercises = attribute.exercises.filter((item) => item.title?.toLowerCase().includes(lowerCaseSearch));

                return {
                    ...attribute,
                    exercises: filteredExercises
                };
            })
            : mappedExercises;
    }, [search, mappedExercises]);

    const selectedExercises = useMemo(() => {
        return filterExercises.find(item => item.id === activeTabs)?.exercises;
    }, [filterExercises, activeTabs]);

    const isSelectedAll = useMemo(() => {
        const allIds = selectedExercises?.map(exercise => exercise.id) || [];
        return !allIds.some(id => !valueIds.includes(id));
    }, [selectedExercises, valueIds]);

    const handleSelectAll = () => {
        if (isSelectedAll) {
            onChange(value.filter(({ id }) => !selectedExercises.some(exercise => exercise.id === id)));
        } else {
            const unselectedExercises = selectedExercises.filter(({ id }) => !valueIds.includes(id));
            onChange([...value, ...unselectedExercises]);
        }
    };

    const handleDeleteItem = (id) => {
        onChange(value.filter(item => item.id !== id));
    };

    const showPlaceholder = !value.length;

    return (
        <section className="d-flex align-items-center w-100">
            <Dropdown
                isOpen={isOpen}
                toggle={() => updateIsOpen(prevState => !prevState)}
                className="d-inline-block filter-dropdown cursor-pointer result-filter min-w-1-5 w-100"
                direction="down"
            >
                <DropdownToggle
                    className={classnames('filter-toggle w-100', { 'with-border': isOpen, 'is-invalid': !!error })}
                    tag="section"
                >
                    <span className={classnames('ms-2 me-1 pointer-events-none user-select-none text-truncate', { 'text-secondary': showPlaceholder })}>
                        {
                            showPlaceholder
                                ? placeholder
                                : value.map(item => item.title).join(', ')
                        }
                    </span>
                    <i className={classnames('mdi mdi-chevron-down pointer-events-none user-select-none', { 'mdi-rotate-180': isOpen })}/>
                </DropdownToggle>
                <DropdownMenu className="filter-menu pb-1 px-1 w-100 top-50" flip={false}>
                    <TableSearch onSearch={setSearch} placeholder="Search" search={search} className="my-2"/>

                    <section>
                        <ListGroup>
                            <ListGroupItem
                                className={classnames("d-flex bg-transparent justify-content-between", classes.ServiceItem)}>
                                <div>
                                    <Checkbox
                                        name="selectAllValues"
                                        text="Select all"
                                        value={isSelectedAll}
                                        onChange={handleSelectAll}
                                    />
                                </div>
                                <button type="button"
                                        className="text-danger border-0 bg-transparent"
                                        onClick={() => onChange([])}>
                                    Clear
                                </button>
                            </ListGroupItem>

                            <div className={classnames(classes.ItemsWrapper, "custom-scrollbar")}>
                                {selectedExercises?.length
                                    ? selectedExercises.map((item, index) => (
                                        <ListGroupItem className="bg-transparent border-0" key={item.id}>
                                            <Checkbox
                                                name="exercise"
                                                text={<div {...decorateText(item.title)} />}
                                                value={valueIds.includes(item.id)}
                                                onChange={() => toggleSelectItem(item, value, onChange)}
                                            />
                                            {
                                                index === selectedExercises.length - 1
                                                    ? <div
                                                        ref={lastElementRef}
                                                        className="d-flex justify-content-center">
                                                    </div>
                                                    : null
                                            }
                                        </ListGroupItem>
                                    ))
                                    : <div className="text-secondary py-3 w-100 text-center">No result</div>
                                }
                                {isLoading && <div className="d-flex my-2 justify-content-center">
                                    <Spinner size="sm" color="primary"/>
                                </div>}
                            </div>
                        </ListGroup>
                    </section>
                </DropdownMenu>
            </Dropdown>
        </section>
    );
};

export default ExerciseMultiSelectDropdown;
