import React from "react";
import classes from "./WorkoutSlideItem.module.scss";
import { Badge } from "reactstrap";
import { FITNESS_GROUP_LINKS } from "../../../../../../../groups/app/Fitness/config";

const MS_IN_MIN = 60000

export const WorkoutSlideItem = ({ item }) => {

    const goToDetails = () => {
        const url = `${FITNESS_GROUP_LINKS.WORKOUTS_LIST}/${item.id}`;
        window.open(url, '_blank');
    };

    return (
        <div
            className={classes.Container} style={{ backgroundImage: `url(${item?.file?.link})`}}
            onClick={goToDetails}
        >
            <div className="p-2 d-flex flex-column justify-content-between h-100">
                <div>
                    {item.durationInMs && <Badge
                        pill
                        className="d-flex text-black font-weight-normal py-1 px-2 w-fit-content align-items-center bg-white font-size-9"
                    >
                        { Math.floor(item.durationInMs / MS_IN_MIN) } min
                    </Badge>}
                </div>

                <div className="font-size-9 text-white">
                    <div>
                        { item.name }
                    </div>
                    <div className="font-size-8" style={{ height: "12px" }}>
                        { item.whyRecommendedDescription }
                    </div>
                </div>
            </div>
    </div>
    )
}