import yup from "../../../../validation/yup";
import {
  MAX_RULE_NAME_LENGTH,
  MAX_SKU_LENGTH,
  MIN_STRING_LENGTH
} from "../../Biomarkers/CreateEdit/constants";
import { SKIN_RECOMMENDATIONS_IDS } from "./constants";

const IdealTypesRequirements = (value, context) => {
  if(SKIN_RECOMMENDATIONS_IDS.includes(context.parent.category?.id)){
    return !!value?.id
  }
  return true
}

export const validationSchema = yup.object().shape({
  title: yup.string()
    .trim()
    .min(MIN_STRING_LENGTH)
    .max(MAX_RULE_NAME_LENGTH)
    .required(),
  category: yup.object().required(),
  file: yup.array().min(1).required(),
  idealFrequency: yup.object().test("is required", "Ideal frequency is required", IdealTypesRequirements),
  SKU: yup.string()
    .min(MIN_STRING_LENGTH)
    .max(MAX_SKU_LENGTH)
    .matches(/^[A-Za-z0-9_-]+$/ , 'SKU is not correct')
    .nullable()
    .optional()
});


export const impactValidationSchema = yup.object().shape({
  biomarker: yup.object().required(),
});