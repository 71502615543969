import React from "react";
import Wrapper from "./Wrapper";

const Quote = ({ blok }) => {
    if (!blok) {
        return null;
    }

    return (
        <Wrapper blok={{ ...blok }}>
            <div className="blockquote">
                ‘{blok.text}’
            </div>
        </Wrapper>
    );
};

export default Quote;
