import React, { useEffect, useState } from 'react';
import UICustomisationLayoutWithCard from "../../../../base/components/UICustomisationLayoutWithCard";
import { Col } from "reactstrap";
import { Formik } from "formik";
import * as _ from "lodash";
import Button from "../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";
import FormikInput from "../../../../base/components/FormikInput";
import joinClassNames from "../../../../base/helpers/joinClassNames";
import { useService } from "../../../../base/hooks/useService";
import ToasterService from "../../../../services/ToastService";

import JoditEditorComponent from "../../../../base/components/JoditEditor";
import EmailTemplatesService, {
  EmailTemplateTypes,
} from "../../../../services/EmailTemplates";

const emailTemplatesList = [
  EmailTemplateTypes.sendRestorePasswordEmail,
  EmailTemplateTypes.sendUserRestorePasswordEmail,
  EmailTemplateTypes.sendUserVerificationEmail,
  EmailTemplateTypes.sendAdminSampleIdError,
  EmailTemplateTypes.sendEmailFactorAuthenticationEmail,
  EmailTemplateTypes.emailSkeleton,
];

const customTemplateNames = {
  [EmailTemplateTypes.sendRestorePasswordEmail]: "Restore Password - Admin",
  [EmailTemplateTypes.sendUserRestorePasswordEmail]: "Restore Password - User",
  [EmailTemplateTypes.sendUserVerificationEmail]: "User Verification Email",
  [EmailTemplateTypes.sendAdminSampleIdError]: "Admin Sampled Error",
  [EmailTemplateTypes.sendEmailFactorAuthenticationEmail]: "MFA verification",
  [EmailTemplateTypes.emailSkeleton]: "Email Template",
};

export default function Emails() {
  const breadcrumbs = {
    title: "Emails",
    breadcrumbItems: [],
  };
  /**
   * @type {EmailTemplatesService}
   */
  const emailTemplatesService = useService(EmailTemplatesService);

  const [initialValues, setInitialValues] = useState({});
  const [isSubmitting, updateIsSubmitting] = useState(false);
  const [emailTemplatesByType, setEmailTemplatesByType] = useState({});
  /**
   * @type {ToasterService}
   */
  const toastService = useService(ToasterService);

  useEffect(() => {
    emailTemplatesService.fetchEmailTemplates().then((data) => {
      const EMAIL_FIELDS = ["title", "subject", "body", "type"];
      const emailTemplatesByType = _.chain(data)
        .get("data")
        .map((item) => _.pick(item, EMAIL_FIELDS))
        .keyBy("type")
        .value();
      setEmailTemplatesByType(emailTemplatesByType);
      setInitialValues(emailTemplatesByType);
    });
  }, [emailTemplatesService]);

  const [errorMessage, setErrorMessage] = useState("");
  const [isTemplatesValid, setIsTemplatesValid] = useState(true);

  const submitSettings = async (values) => {
    updateIsSubmitting(true);
    try {
      const updatedEmailTemplates =
        await emailTemplatesService.updateEmailTemplates(_.values(values));
      toastService.success("Settings was successfully uploaded");
    } catch (err) {
      console.error(err);
      toastService.error(err);
      setErrorMessage(err);
    } finally {
      updateIsSubmitting(false);
    }
  };

  return (
    <UICustomisationLayoutWithCard
      breadcrumbs={breadcrumbs}
      cardClassName="ui-customisation"
    >
      <Col className="sideform-wrapper">
        <Formik
          initialValues={initialValues}
          validateOnBlur
          onSubmit={submitSettings}
          enableReinitialize
        >
          {({ errors, handleSubmit, values, setFieldValue }) => (
            <form
              className={joinClassNames(
                "form-horizontal p-0",
                isSubmitting && "pointer-events-none"
              )}
              onSubmit={handleSubmit}
            >
              {emailTemplatesList.map((type, index) => (
                <section className="email-template-section" key={index}>
                  <h2>
                    {customTemplateNames[type]
                      ? customTemplateNames[type]
                      : emailTemplatesByType[type]?.title}
                  </h2>
                  <FormikInput
                    placeholder="Title"
                    name={`${type}.title`}
                    maxLength={100}
                    minLength={1}
                    containerClassName="col-lg-6 email-template-input"
                  />
                  <FormikInput
                    placeholder="Subject"
                    name={`${type}.subject`}
                    maxLength={100}
                    minLength={1}
                    containerClassName="col-lg-6 email-template-input"
                  />
                  <JoditEditorComponent
                    value={emailTemplatesByType[type]?.body || ""}
                    onChange={(text) => {
                      setFieldValue(`${type}.body`, text);
                    }}
                    setIsTemplatesValid={setIsTemplatesValid}
                  />
                </section>
              ))}
              <div className="d-flex justify-content-end mt-5 gap-3">
                <Button
                  color={BUTTON_COLORS.primary}
                  type="submit"
                  disabled={!!Object.keys(errors).length || isSubmitting || isTemplatesValid}
                >
                  Save changes
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Col>
    </UICustomisationLayoutWithCard>
  );
}
