// Libs
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import { ModalFooter, Dropdown } from "reactstrap";

// Components, Views, Screens
import Button from "../../../../../base/components/Button";
import FormikReactSelect from "../../../../../base/components/FormikReactSelect";
import FormikInput from "../../../../../base/components/FormikInput";

// Hooks, Utils, Helpers
import { useService } from "../../../../../base/hooks/useService";
import { useLoading } from "../../../../../base/hooks/useLoading";
import joinClassNames from "../../../../../base/helpers/joinClassNames";
import PageSpinner from "../../../../../base/components/PageSpinner";
import ToastService from "../../../../../services/ToastService";
import { BUTTON_COLORS } from "../../../../../base/components/Button/appearance";
import { initialValues, initialValuesRange, validationRangeSchema, validationSchema } from "./form";
import { useModal } from "../../../../../base/hooks/useModal";
import AttributesDropdown from "../../../../../base/components/Dropdowns/AttributesDropdown/AttributesDropdown";
import SegmentsService from "../../../../../services/SegmentsService";
import UserAttributesService from "../../../../../services/UserAttributes";

const ATTRIBUTE_TYPES = {
    string: 1,
    boolean: 2,
    number: 3,
    range: 4
};

const BOOLEAN_VALUES = [
  {
    id: 1,
    value: true,
    label: "True"
  },
  {
    id: 2,
    value: false,
    label: "False"
  }
]

const ValuesInput = ({ type, setFieldValue }) => {
  if (type === ATTRIBUTE_TYPES.range) {
    return (
      <>
        <FormikInput
          name="minValue"
          label="Min value"
          type="number"
          placeholder="Enter min value"
          containerClassName="mt-3 mb-1"
        />
        <FormikInput
          name="maxValue"
          label="Max value"
          type="number"
          placeholder="Enter max value"
          containerClassName="mt-3 mb-1"
        />
      </>
    )
  } else if (type === ATTRIBUTE_TYPES.boolean) {
    return (
      <FormikReactSelect
        name="value"
        options={BOOLEAN_VALUES}
        label="Value"
        setFieldValue={setFieldValue}
        containerClassName="mt-3 mb-1"
        placeholder="Select value"
        withError
      />
    );
  }

  return (
    <FormikInput
      name="value"
      label="Value"
      type={type === ATTRIBUTE_TYPES.number ? 'number' : 'text'}
      placeholder="Enter value"
      containerClassName="mt-3 mb-1"
    />
  )
}

export function CreateUserAttribute({ isOpen, close, addAttribute, afterSubmit, editingAttribute }) {
    /**
     * @type {SegmentsService}
     */
    const segmentsService = useService(SegmentsService);
    /**
     * @type {UserAttributesService}
     */
    const userAttributesService = useService(UserAttributesService);
    /**
     * @type {ToastService}
     */
    const toastService = useService(ToastService);
    const [isLoading, { registerPromise }] = useLoading(true);

    const { Modal } = useModal();

    const [isSubmitting, updateIsSubmitting] = useState(false);
    const [attribute, setAttribute] = useState(null);
    const [categories, setCategories] = useState([]);

    const { id: userId } = useParams();

    const isEdit = Boolean(editingAttribute);

    const updateAttribute = (newAttribute, resetForm) => {
        resetForm();
        setAttribute(newAttribute);
    };

    const afterSuccess = () => {
        toastService.success("Attribute has been successfully assigned to user");
        updateIsSubmitting(false);
        afterSubmit();
        close();
    };

    const getCategories = () => {
        registerPromise(segmentsService.getAttributesCategories({ userExcludeId: userId }).then(({ data }) => {
            setCategories(data);
        }));
    };

    const mapEditingAttribute = ({ valueType, name }) => {
        return {
            name,
            type: valueType
        };
    };

    const mapEditingValue = ({ value, valueType }) => {
        if (valueType === ATTRIBUTE_TYPES.range) {
            const [minValue, maxValue] = value.split("-");
            return { minValue, maxValue };
        }

        return { value };
    };

    const apiFunction = (values) => {
        if (isEdit) {
            const { value, maxValue, minValue } = values;
            return userAttributesService.updateUserAttribute(editingAttribute.id, { value, maxValue, minValue });
        }

        return userAttributesService.createUserAttribute(values);
    };

    const getCurrentInitialValue = () => {
        if (isEdit) {
            return mapEditingValue(editingAttribute);
        }

        return isRange ? initialValuesRange : initialValues;
    };

    const submitAttribute = (values) => {
        updateIsSubmitting(true);
        
        apiFunction({
            ...values,
            attributeId: attribute.id,
            userId
        })
            .then(afterSuccess)
            .finally(() => updateIsSubmitting(false));
    };

    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        if (isEdit) {
            setAttribute(mapEditingAttribute(editingAttribute));
            return;
        }

        setAttribute(null);
    }, [isOpen, editingAttribute]);

    const isRange = attribute?.type === ATTRIBUTE_TYPES.range;

    return (
        <Modal isOpen={isOpen} toggle={() => close()}>
            <Modal.Header onClose={() => close()}>
                {isEdit ? "Edit attribute" : "Add attribute"}
            </Modal.Header>

            <Formik
                initialValues={getCurrentInitialValue()}
                validationSchema={isRange ? validationRangeSchema : validationSchema}
                validateOnBlur
                onSubmit={submitAttribute}
                enableReinitialize
            >
                {({ errors, handleSubmit, resetForm, setFieldValue }) => {

                    return (
                        <form
                            className={joinClassNames("form-horizontal p-2", isSubmitting && "pointer-events-none")}
                            onSubmit={handleSubmit}
                        >
                            <Modal.Body>
                                <div>
                                    <AttributesDropdown
                                        attributes={categories}
                                        placeholder="Select attribute (required)"
                                        value={attribute?.name}
                                        onChange={(attribute) => {
                                            updateAttribute(attribute, resetForm);
                                        }}
                                        label="Attribute"
                                        disabled={isEdit}
                                    />
                                </div>

                                {attribute ? (
                                  <ValuesInput type={attribute.type} setFieldValue={setFieldValue}/>
                                ) : null}
                            </Modal.Body>

                            <ModalFooter>
                                <div className="d-flex justify-content-end gap-2 mt-3">
                                    <Button
                                        color={BUTTON_COLORS.primaryOutline}
                                        type="button"
                                        onClick={close}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        color={BUTTON_COLORS.primary}
                                        type="submit"
                                        disabled={isSubmitting || Object.keys(errors).length || !attribute}
                                    >
                                        {isEdit ? "Edit" : "Add"}
                                    </Button>
                                </div>
                            </ModalFooter>
                        </form>
                    );
                }}
            </Formik>
        </Modal>);
}