import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import Verification from "./pages/Verification";
import MfaVerification from "./pages/Mfa";

import { AUTH_GROUP_LINKS, AUTH_GROUP_ROUTES } from "./config";
import LinkSent from "./pages/ForgetPassword/linkSent";

export default function AuthRoutes() {
  return (
    <Routes>
      <Route
        path={AUTH_GROUP_ROUTES.LINK_LOGIN}
        element={<Login/>}
      />

      <Route
        path={AUTH_GROUP_ROUTES.LINK_RESET_PASSWORD}
        element={<ResetPassword/>}
      />

      <Route
        path={AUTH_GROUP_ROUTES.LINK_MFA}
        element={<MfaVerification/>}
      />

      <Route
        path={AUTH_GROUP_ROUTES.LINK_FORGOT_PASSWORD}
        element={<ForgetPassword/>}
      />

      <Route
        path={AUTH_GROUP_ROUTES.LINK_EXPIRED}
        element={<Verification/>}
      />

      <Route
        path={AUTH_GROUP_ROUTES.LINK_SENT}
        element={<LinkSent/>}
      />

      <Route
        path={AUTH_GROUP_ROUTES.LINK_LOG_OUT}
        element={<Logout/>}
      />

      <Route
        element={<Navigate to={AUTH_GROUP_LINKS.LINK_LOGIN} replace/>}
      />
    </Routes>
  );
}
