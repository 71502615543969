import React from 'react'
import RulesDropdown from "../CreateEdit/components/RuleDropdown";
import {
  DNAm_RANGES_VIEW_SECTIONS,
  RECOMMENDATION_SECTIONS,
  SKIN_RANGES_VIEW_SECTIONS,
} from "../CreateEdit/Recommendations/constants";
import { checkOnDefined } from "../CreateEdit/fromValidation";
import { usePowerUnitFormat } from '../../../../base/hooks/usePowerUnitFormat';

export default function FiltersView({
                                      filters,
                                      unit,
                                      selectedFilterIndex,
                                      updateSelectedFilterIndex,
                                      isSkinBiomarker,
                                      isDNAmBiomarker
                                    }) {

  const {powerFormat} = usePowerUnitFormat()
  const getViewSections = () => {
    if (isSkinBiomarker) return SKIN_RANGES_VIEW_SECTIONS;
    if (isDNAmBiomarker) return DNAm_RANGES_VIEW_SECTIONS;
    return RECOMMENDATION_SECTIONS;
  }

  return (
    <section className="filters-view w-50 mt-3">
      {!isSkinBiomarker && !isDNAmBiomarker &&
        <RulesDropdown
          value={filters[selectedFilterIndex]?.name ?? ""}
          label="Filter"
          onChange={(_, index) => updateSelectedFilterIndex(index)}
          options={filters}
          containerClassName=""
          selectedFilterIndex={selectedFilterIndex}
        />
      }

      <section className="row filter-container--heading ms-0 me-0">
        <div className='col-3 ps-0 '>
          <label>Range</label>
        </div>
        <div className='col-4 ps-5px pe-0'>
          <label>Values</label>
        </div>
        <div className='col-5 ps-35rem'>
          <label>Override Scale</label>
        </div>
      </section>

      <section className="mt-3">
        {(getViewSections()).map(({label, rangesToApply, specialSymbol, summaryKey, name}, index) => {

          if (!rangesToApply.some(rangeKey => checkOnDefined(filters[selectedFilterIndex][rangeKey]))) {
            return null;
          }

          const minValue = filters[selectedFilterIndex][rangesToApply[0]];
          const maxValue = filters[selectedFilterIndex][rangesToApply[1]];
          const percentageVal = filters[selectedFilterIndex][`${summaryKey || name}Percentage`];

          return (
            <div className='row mb-3' key={index}>
              <div className='col-3'>
                <label className="mb-0 font-weight-normal">{label}</label>
              </div>

              <div className='col-4 pe-0'>
                {specialSymbol &&
                  <label className="font-weight-normal mb-0 ">
                    {specialSymbol && <span className="me-2">{specialSymbol}</span>}
                  </label>}

                <label className={`${specialSymbol ? 'ms-1': ""} font-weight-normal mb-0`}>
                  {minValue}
                </label>

                <label className="font-weight-normal mb-0 ms-1">
                  {!!(checkOnDefined(minValue) && checkOnDefined(maxValue)) && " - "}
                </label>

                <label className="font-weight-normal mb-0 ms-1">
                  {maxValue}
                </label>

                <label className="font-weight-normal mb-0 ms-1">
                  <span>{powerFormat(unit)}</span>
                </label>
              </div>

              {percentageVal && <div className='col-2 ps-2'>
                <label className="font-weight-normal mb-0 ">
                  <span>{percentageVal}%</span>
                </label>
              </div>}
            </div>
          )
        })}
      </section>
    </section>
  )
}
