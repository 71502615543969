import React from "react";
import Wrapper from "../Wrapper";

import "./index.scss"
import { StoryblokComponent } from "@storyblok/react";

const ExercisesWrapper = ({ blok }) => {
    if (!blok) {
        return null;
    }
    const exerciseLength = blok?.exerciseList?.length

    return (
        <Wrapper blok={blok}>
            <div className="exercise-wrapper">
                <div className="exercise-header">
                <div className="title">{blok?.title}</div>
                <div className="description">{blok?.description}</div>
                <div className="exercise-info">
                    <div>{exerciseLength} exercises</div>
                    <div>{blok?.durationInMinutes} min</div>
                </div>
                </div>
                <div className="exercise-list-wrapper">
                    {
                        blok?.exerciseList.map((blok) => (
                            <div key={blok._uid}>
                                <StoryblokComponent blok={blok}/>
                            </div>
                        ))
                    }
                </div>
            </div>
        </Wrapper>
    );
};

export default ExercisesWrapper;
