import React from 'react';
import { Container } from 'reactstrap';
import { Route, Routes } from 'react-router-dom';

import { PROGRAMS_GROUP_ROUTES } from './config';

import List from './List';
import { CreateEditProgram } from './CreateEditProgram';
import ProgramDetails from './ProgramDetails';
import RuleGoalList from './RulesGoalsList';
import { CreateEditRule } from './CreateEditRule';

export default function NutritionPrograms() {
    return (
        <Container fluid className="content">
            <Routes>
                <Route
                    path={PROGRAMS_GROUP_ROUTES.LIST}
                    element={<List/>}
                />
                <Route
                    path={PROGRAMS_GROUP_ROUTES.CREATE_EDIT}
                    element={<CreateEditProgram/>}
                />

                <Route
                    path={PROGRAMS_GROUP_ROUTES.DETAILS}
                    element={<ProgramDetails/>}
                />

                <Route
                    path={PROGRAMS_GROUP_ROUTES.RULE_GOAL_LIST}
                    element={<RuleGoalList/>}
                />

                <Route
                    path={PROGRAMS_GROUP_ROUTES.CREATE_EDIT_RULE}
                    element={<CreateEditRule/>}
                />
            </Routes>
        </Container>
    );
}