import React from 'react';
import { MAX_RANGE_LENGTH } from "./constants";
import CustomInput from "../../../../../base/components/Input";
import { checkOnDefined } from "../fromValidation";
import joinClassNames from "../../../../../base/helpers/joinClassNames";
import { useField } from "formik";
import { usePowerUnitFormat } from '../../../../../base/hooks/usePowerUnitFormat';

export default function RangeInputWithUnit({
  onChange,
  unit,
  placeholder,
  disabled,
  name,
  withAdditionalSymbols = false,
  additionalSymbol,
  inputClassName,
  symbol,
  forceValidation
}) {
  const [field, meta] = useField({ name });
  const isError = meta.error && meta.touched && (forceValidation || !checkOnDefined(field.value));
  const { powerFormat } = usePowerUnitFormat()

  return (
    <section className={joinClassNames("range-input w-100")}>
      {withAdditionalSymbols &&
        <span className="range-input__additional_symbol">{additionalSymbol}</span>
      }
      <CustomInput
        {...field}
        value={field.value?.toString() || ""}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={MAX_RANGE_LENGTH}
        disabled={disabled}
        className={joinClassNames(isError && "is-invalid without-error-icon", inputClassName)}
      />
      {(!!checkOnDefined(field.value)) && !withAdditionalSymbols &&
        <span className={joinClassNames(
          "range-input__unit",
        )}>{powerFormat ( unit )}</span>
      }
      {withAdditionalSymbols && (symbol || !checkOnDefined(field.value)) &&
        <span className={joinClassNames(
          "range-input__unit text-secondary",
        )}>{symbol ? symbol: powerFormat ( unit )}</span>
      }
    </section>
  )
}
