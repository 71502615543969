import React from "react";
import Wrapper from "../Wrapper";
import { SERVICE_BLOK_FIELDS } from "../../../../constants/storyblok";
import Tag from "./components/Tag";

const Tags = ({ blok }) => {
    if (!blok) {
        return null;
    }

    const ignoreFields = [...SERVICE_BLOK_FIELDS]

    const getTag = (value) => {
        if (Array.isArray(value)) {
            return value.map(text => <Tag key={text} value={text}/>);
        } else if (typeof value === 'string' || typeof value === 'number') {
            return <Tag key={value} value={value}/>;
        }

        return null
    }

    return (
        <Wrapper blok={{ ...blok, width: "content-width" }}>
            <div>
                {
                    Object.keys(blok)
                        .filter((key => !ignoreFields.includes(key)))
                        .map(key => getTag(blok[key]))
                }
            </div>
        </Wrapper>
    );
};

export default Tags;