import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { FITNESS_GROUP_LINKS, FITNESS_GROUP_ROUTES } from './config';

import Lists from "./Lists";
import { ExerciseForm } from "./ExerciseForm";
import { WorkoutForm } from "./WorkoutForm";
import ExerciseDetails from "./ExerciseDetails";
import WorkoutDetails from "./WorkoutDetails";
import { SectionForm } from "./HomepageSectionForm";

export default function Fitness() {
    const location = useLocation()

    return (
        <Container fluid className="content">
            <Routes>
                <Route
                    path={FITNESS_GROUP_ROUTES.LISTS}
                    element={<Lists/>}
                />
                <Route
                    path={FITNESS_GROUP_ROUTES.FORM_EXERCISES}
                    element={<ExerciseForm/>}
                />
                <Route
                    path={FITNESS_GROUP_ROUTES.EXERCISE_DETAILS}
                    element={<ExerciseDetails/>}
                />
                <Route
                    path={FITNESS_GROUP_ROUTES.WORKOUT_DETAILS}
                    element={<WorkoutDetails/>}
                />
                <Route
                    path={FITNESS_GROUP_ROUTES.FORM_WORKOUTS}
                    element={<WorkoutForm/>}
                />

                <Route
                  path={FITNESS_GROUP_ROUTES.FORM_HOMEPAGE_SECTION}
                  element={<SectionForm/>}
                />
                <Route path={FITNESS_GROUP_ROUTES.BASE} element={<Navigate to={FITNESS_GROUP_LINKS.LIST} />} />
            </Routes>
        </Container>
    );
}