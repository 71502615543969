import yup from "../../../../../validation/yup";
import {
    ALLOWED_SYMBOLS_REGEX,
    ALLOWED_SYMBOLS_MESSAGE,
    MAX_ORDER,
    MAX_RULE_NAME_LENGTH,
    MAX_SOURCE_TITLE_LENGTH,
    MIN_ORDER,
    MIN_STRING_LENGTH,
    RULE_TYPE, MIN_PRIORITY, MAX_PRIORITY
} from "./constants";
import { RULE_ACTION_LABELS, RULES_ACTIONS, RULES_STATUSES } from "../RulesGoalsList/constants";
import { URL_PATTERN } from "../../../../../base/constants/patterns";


export const defaultLinksItem = {
    title: '',
    links: []
}
export const initialValues = {
    fullName: "",
    publicName: "",
    simpleName: "",
    actionType: RULES_ACTIONS.AVOID,
    type: RULE_TYPE.INGREDIENT_GROUP,
    priority: null,
    status: RULES_STATUSES.INACTIVE,
    collectionByType: [],
    userSegments: [],
    order: null,
    sources: [],
    simpleExamples: "",
    publicComment: ""
}

const urlValidator = yup
    .string()
    .matches(
        URL_PATTERN,
        'Invalid url'
    )
    .required();

export const validationSchema = yup.object().shape({
    fullName: yup.string()
        .trim()
        .min(MIN_STRING_LENGTH)
        .max(MAX_RULE_NAME_LENGTH)
        .matches(ALLOWED_SYMBOLS_REGEX, ALLOWED_SYMBOLS_MESSAGE)
        .required(),
    publicName: yup.string()
        .trim()
        .max(MAX_RULE_NAME_LENGTH)
        .matches(ALLOWED_SYMBOLS_REGEX, ALLOWED_SYMBOLS_MESSAGE),
    simpleName: yup.string()
        .trim()
        .max(MAX_RULE_NAME_LENGTH)
        .matches(ALLOWED_SYMBOLS_REGEX, ALLOWED_SYMBOLS_MESSAGE),
    sources: yup.array().of(
        yup.object().shape({
            title: yup
                .string()
                .required()
                .max(MAX_SOURCE_TITLE_LENGTH),
            links: yup.array().of(urlValidator),
        })
    ),
    order: yup.number()
        .required()
        .min(MIN_ORDER)
        .max(MAX_ORDER),
    priority: yup
        .number()
        .min(MIN_PRIORITY)
        .max(MAX_PRIORITY)
        .required(),

});

export const ruleActionOptions = Object.values(RULES_ACTIONS).map((value) => ({
    value,
    id: value,
    label: RULE_ACTION_LABELS[value]
}))


