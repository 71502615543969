import Http from "./Http";

class HealthProgramService extends Http {
    static $displayName = "HealthProgramService";

   getWellnessPrograms(params) {
       return this.get(`/admins/wellness`, { params })
   }

    async getWellnessTypeAndSubcategories(wellnessType, params) {
        return await this.get(`/admins/wellness/types-and-subcategories/${wellnessType}`, { params });
    }
}

export default HealthProgramService;
