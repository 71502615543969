import yup from "../../../../validation/yup";

export const LENGTH_OF_EMAIL_CODE = 6;

export const validationSchema = yup.object().shape({
  code: yup
    .string()
    .test(
      "for length",
      `Please enter a valid code`,
      (value = "") => value.length === LENGTH_OF_EMAIL_CODE
    )
    .required(),
});

export const initialValues = {
  code: "",
};
