import React from 'react'
import { Container } from "reactstrap";
import { Route, Routes } from "react-router-dom";
import { CUSTOMER_RESULTS_ROUTES } from "./config";
import ResultsList from "./ResultsList";

export default function CustomerResults() {
  return (
    <Container fluid className="content">
      <Routes>
        <Route
          path={CUSTOMER_RESULTS_ROUTES.LIST}
          element={<ResultsList />}
        />
      </Routes>
    </Container>
  )
}