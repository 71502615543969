import React from "react";
import { useLocationQuery } from "../../../../../../base/hooks/useQueryString";
import { useHighlight } from "../../../../../../base/hooks/useHighlight";
import { formatISODate } from "../../../../../../base/helpers/date";
import joinClassNames from "../../../../../../base/helpers/joinClassNames";
import Icon from "../../../../../../base/components/Icon";

import { PROGRAM_STATUSES, PROGRAM_STATUSES_LABELS } from "../constants";


export const PREDEFINED_ACTIONS = {
    DEACTIVATE: 'deactivate',
    ACTIVATE: 'activate',
    COPY: 'copy'
};

export const SearchPlaceholder = () => (
    <>
        No predefined found.
    </>
);

export const NoProgramPlaceholder = () => (
    <div className="text-center">
        No programs for now.<br/>
        Click the “Create program” option to add a new one.
    </div>
);
export const columns = [
    {
        Header: "#",
        width: 35,
        Cell: (cellProps) => {
            const { params: { offset = 0 } } = useLocationQuery();
            return <label className="mb-0">{cellProps.row.index + 1 + (offset ?? 0)}</label>;
        }
    },
    {
        Header: "Name",
        accessor: "fullName",
        width: 200,
        canSort: true,
        Cell: ({ value }) => {
            const { params } = useLocationQuery();
            const { decorateText } = useHighlight(params.search);

            return (
                <section className="w-fit-content d-flex align-items-center">
                    <div {...decorateText(value)} className="text-truncate mb-0" style={{ maxWidth: "33vw" }}/>
                </section>
            );
        }
    },
    {
        Header: "Author",
        accessor: "author",
        width: 200,
        canSort: true,
        Cell: ({ value }) => <section className="w-fit-content d-flex align-items-center">
            <span className="text-truncate mb-0" style={{ maxWidth: "33vw" }}>{value}</span>
        </section>
    },
    {
        Header: "Created on",
        accessor: "createdAt",
        width: 100,
        canSort: true,
        Cell: ({ value }) => <section className="w-fit-content d-flex align-items-center">
            {formatISODate(value)}
        </section>
    },
    {
        Header: "Active",
        accessor: "status",
        width: 100,
        canSort: true,
        Cell: ({ value }) => <div className={joinClassNames(
            value === PROGRAM_STATUSES.ACTIVE ? "active-pill" : "inactivated-pill",
            "w-fit-content"
        )}>
            {PROGRAM_STATUSES_LABELS[value]}
        </div>
    },
    {
        Header: "Actions",
        width: 60,
        Cell: ({
                   row: {
                       original: { id, status }
                   },
                   actions
               }) => {

            return <section
                onClick={(event) => {
                    event.stopPropagation();
                }}
                className="d-flex justify-content-between align-items-center"
            >
                <Icon icon="eye"
                      width={20}
                      height={20}
                      className="me-4 cursor-pointer impacts-section__view"
                      onClick={(event) => {
                          event.stopPropagation();

                      }}
                />
                <Icon
                    icon={"activate"}
                    width={20}
                    height={20}
                    onClick={(event) => {
                        event.stopPropagation();
                        actions[status === PROGRAM_STATUSES.ACTIVE
                            ? PREDEFINED_ACTIONS.DEACTIVATE
                            : PREDEFINED_ACTIONS.ACTIVATE
                            ](id);
                    }}
                    className="me-4 cursor-pointer"
                />

                <Icon icon="copy" width={20} height={20} className="me-4 cursor-pointer text-secondary"
                      onClick={(event) => {
                          event.stopPropagation();
                          actions[PREDEFINED_ACTIONS.COPY]({ id });
                      }}
                />
            </section>;
        }
    }
];