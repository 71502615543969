import React, { useState } from 'react';
import Icon from "../../../../../base/components/Icon";
import { Collapse } from "reactstrap";
import joinClassNames from "../../../../../base/helpers/joinClassNames";

export default function RecommendationsCollapse({
  label,
  withGroupOption,
  GroupComponent,
  children
}) {
  const [isOpenSection, updateIsOpenSection] = useState(true)

  return (
    <section className={joinClassNames(
      "form-recommendations--section mb-2",
      !label && "pt-1"
    )}>
      {!!label &&
        <section
          className="form-recommendations--section__heading cursor-pointer"
          onClick={() => updateIsOpenSection(prevState => !prevState)}
        >
          <label>{label}</label>
          <section className="d-flex align-items-center">
            {withGroupOption && GroupComponent}
            <Icon icon="arrowDown" className={joinClassNames(isOpenSection ? "rotate-180" : "rotate-360")}/>
          </section>
        </section>
      }
      <Collapse isOpen={isOpenSection}>
        {children}
      </Collapse>
    </section>
  )
}